import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Link,
  Modal,
  TextField,
  MenuItem,
  Select,
  Popover,
  Stack,
  IconButton,
  FormControl,
  Tooltip,
  MenuList,
  Rating,
  Menu,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel,
  FormGroup,
  PaginationItem,
  Pagination
} from "@mui/material";
import CandidateServiceClass from "../../../Services/Enterprise/Candidate.service";
import { useLocation, useNavigate } from "react-router-dom";
import defaultUser from "./../../../assets/images/default-user.svg";
import eduIcon from "../../../assets/images/eduIcon.svg";
import locationIcon from "../../../assets/images/locationIcon.svg";
import NewJobsImage from "./../noJobsImage";
import NoJobFound from "../../../assets/images/Noresultglobalsearch.svg";
import Loader from "../../Loader";
import ControlCheckboxGroup from "../../../Components/Checkbox/ControlCheckboxGroup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import overallExp from "../../../assets/images/overall-exp.svg";
import educationIcon from "../../../assets/images/education-icon.svg";
import linkIcon from "../../../assets/images/link-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "../../../assets/images/chatBlueIcon.svg";
import ControlMenu from "../../../Components/Checkbox/ControlMenu";
import ControlFilterYearOfExperience from "../../../Components/Checkbox/ControlFilterYearOfExperience";
import ControlFilterPayRate from "../../../Components/Checkbox/ControlFilterPayRate";
import ApiCalls from "../../../api/customAxios";
import { toast } from "react-toastify";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import ResumeSvg from "../../../assets/images/Resume.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactStars from "react-rating-stars-component";
import RatingsAndReviewsView from "./_JobCandidateDetailsPartials/RatingsAndReviewsView";
import starEmpty from "../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../assets/images/star-icon-filled.svg";
import { functiondisable, functionenable } from "../../../helpers/helper";
import * as Constants from "../../../helpers/constants";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import cellIcon from "../../../assets/images/cellIcon.svg";
import { success, danger } from "../../../helpers/message-box";
import CandidateProfileVideoPreview from "../../../Components/shared/CandidateProfileVideoPreview";
import {
  checkIsImage,
  handleSpaces,
  formatDate,
} from "../../../helpers/helper-data";
import playIcon from "../../../assets/images/playIcon.svg";
import iconLocation from "../../../assets/images/location-icon.svg";
import { Helmet } from "react-helmet";
import AdvanceFilter from "./AdvanceFilter";
import filterIcon from "../../../assets/images/filter-new-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GridViewActiveIcon from "../../../assets/images/grid-view-icon.svg";
import GridViewInactiveIcon from "../../../assets/images/grid-view-inactive-icon.svg";
import ListViewActiveIcon from "../../../assets/images/list-view-active-icon.svg";
import ListViewInactiveIcon from "../../../assets/images/list-view-icon.svg";
import DefaultProfile from "../../../assets/images/default-user.svg";
import TalentPoolManagementServiceClass from "../../../Services/Enterprise/TalentPoolManagement.service";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment/moment";
import availabilityIcon from "../../../assets/images/pool-availability.svg";
import InfoIcon from "@mui/icons-material/Info";
import CandidateListActionButton from "./CandidateListActionButton";
import ReviewModal from "./ReviewModal";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import Rehire from "./Rehire";
import { ReactComponent as LocationIcon } from "../../../assets/images/location-new-icon-gray.svg";
import { ReactComponent as PriorityIcon } from "../../../assets/images/priority-pool-icon.svg";
import { ReactComponent as PriorityIconGreen } from "../../../assets/images/starRename.svg";
import { ReactComponent as PrivateIcon } from "../../../assets/images/private-pool-icon.svg";
import sortByIcon from "../../../assets/images/sortby-new-icon.svg";
import { ReactComponent as OccupiedIcon } from "../../../assets/images/occupied-calendar.svg";
import { ReactComponent as ArrowBackIcon } from "../../../assets/images/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/images/arrow-right.svg";
import EnterpriseCandidateDescriptionDrawer from "../../EnterprisePortal/Project/EnterpriseCandidateDescriptionDrawer";
//import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
import { useSelector } from "react-redux";
import AvailabilityStatusLabels from "../../../Components/shared/AvailabilityStatusLabels";
import KeycloakService from "../../../keycloak";
import { useCallback } from "react";

export default function CandidateList() {
  const appOrigin = useSelector((state) => state.location.origin);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewDetails, setReviewDetails] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = KeycloakService.isLoggedIn();
  const numberOfResults = 10;
  const [rowsperpage, setRowsPerPage] = React.useState(10);

  const params = new URLSearchParams(location.search);
  const selectedjobTitleFilter = params.get("keyword")
    ? encodeURIComponent(params.get("keyword"))
    : "";
  const selectedjobTitleFilterType = params.get("type");
  const selectedSkillId = params.get("skillid");
  const selectedChecklistId = params.get("checklistid");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [candidateResponse, setCandidateResponse] = React.useState(undefined);
  const [queryAdvanceFilterString, setQueryAdvanceFilterString] =
    React.useState(undefined);
  const [advanceFilterObj, setAdvanceFilterObj] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState(undefined);
  const [selectedCandidateId, setSelectedCandidateId] =
    React.useState(undefined);
  const [selectedCandidateRating, setSelectedCandidateRating] =
    React.useState(0);
  const [filterCity, setFilterCity] = React.useState(undefined);
  const [filterState, setFilterState] = React.useState(undefined);
  const [filterCountry, setFilterCountry] = React.useState(undefined);
  const [filterMinExperience, setFilterMinExperience] =
    React.useState(undefined);
  const [filterMaxExperience, setFilterMaxExperience] =
    React.useState(undefined);
  const [filterMinPayRate, setFilterMinPayRate] = React.useState(undefined);
  const [filterMaxPayRate, setFilterMaxPayRate] = React.useState(undefined);
  const [loadMore, setLoadMore] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [openSortBy, setOpenSortBy] = useState(false);
  const [sort, setSort] = useState(
    params.get("relevance") === null
      ? "2"
      : params.get("relevance") === "true"
      ? "2"
      : "1"
  );
  const options = [
    { label: "Relevance", value: "2" },
    { label: "Latest", value: "1" },
  ];
  const [openRatingAndReview, setRatingAndReview] = useState(true);
  const ratingAndReviewsRef = React.useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [candidateList, setCandidateList] = useState(undefined);
  const [sortValues, setSortValues] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openAdvanceFilter, setOpenAdvanceFilter] = React.useState(false);
  const handleOpenAdvanceFilter = useCallback(() => {
    setOpenAdvanceFilter(true);
  }, []);
  const handleCloseAdvanceFilter = useCallback(() => {
    setOpenAdvanceFilter(false);
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const anchorRef = React.useRef(null);
  const handleClose = () => setOpen(false);
  const [openAction, setOpenAction] = useState(false);
  const [viewType, setViewType] = useState("list-view");
  const [isLoadingTabel, setIsLoadingTable] = useState(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState(false);
  const [talentPoolList, setTalentPoolList] = useState({
    allChecked: false,
    list: [],
  });
  const [candidateId, setCandidateId] = useState("");

  const handleOpenVideoPreview = (e, profileVideo, candidateName) => {
    e.stopPropagation();
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  };
  const openRatingAndReviewSection = (e) => {
    e.stopPropagation();
    setRatingAndReview(true);
    if (openRatingAndReview) {
      ratingAndReviewsRef?.current?.scrollIntoView();
    }
  };

  /* React.useEffect(() => {
        if (openRatingAndReview) {
            ratingAndReviewsRef?.current?.scrollIntoView();
        }
    }, [openRatingAndReview])*/
  const handleLocationSelect = (data) => {
    setFilterState(data.state);
    setFilterCity(data.city);
    setFilterCountry(data.country);
  };
  const handleExperienceSelect = (low, high) => {
    setFilterMinExperience(low);
    setFilterMaxExperience(high);
  };
  const handlePayRateSelect = (low, high) => {
    setFilterMinPayRate(low);
    setFilterMaxPayRate(high);
  };
  const handleChangeJob = (event) => {
    if (params.get("relevance") === null) {
      navigate(
        location.pathname +
          (location.search ? location.search + "&" : "?") +
          "relevance=" +
          (event.target.value === "2" ? "true" : "false")
      );
    } else if (params.get("relevance") !== null) {
      navigate(
        location.pathname +
          location.search.replace(
            "relevance=" + params.get("relevance"),
            "relevance=" + (event.target.value === "2" ? "true" : "false")
          )
      );
    }
    setSort(event.target.value);
    // sortByDate(event.target.value)
  };
  const resetLocationSelect = (data) => {
    setFilterState(undefined);
    setFilterCity(undefined);
    setFilterCountry(undefined);
  };
  const resetExperienceSelect = (low, high) => {
    setFilterMinExperience(undefined);
    setFilterMaxExperience(undefined);
  };
  const resetPayRateSelect = (low, high) => {
    setFilterMinPayRate(undefined);
    setFilterMaxPayRate(undefined);
  };

  const resetAll = useCallback(() => {
    handleCloseAdvanceFilter();
    localStorage.removeItem("advanceFilterObj");
    setQueryAdvanceFilterString("");
    setAdvanceFilterObj(undefined);
    // setFilterState(undefined);
    // setFilterCity(undefined);
    // setFilterCountry(undefined);
    // setFilterMinExperience(undefined);
    // setFilterMaxExperience(undefined);
    // setFilterMinPayRate(undefined);
    // setFilterMaxPayRate(undefined);
    if (isAuthenticated) {
      navigate(`/enterprise/CandidateList?&talentPool=${"A"}`);
    } else {
      navigate(`/CandidateList?&talentPool=${"A"}`);
    }
  }, []);

  const [showMore, setShowMore] = useState(false);
  const [openSendMessage, setSendMessage] = useState(false);

  const [JobTitle, setJobTitle] = React.useState({
    allChecked: false,
    list: [],
  });
  const getPastExperience = (workHistory) => {
    var latest = 0;
    if (workHistory) {
      for (var i = 0; i < workHistory.length; i++) {
        if (
          workHistory[i].workedTill > latest ||
          workHistory[i].workedTill > "1900-01-01"
        ) {
          latest = workHistory[i];
        }
      }
      if (latest) {
        return (
          latest.title + (latest.workedWith ? ", " + latest.workedWith : "")
        );
      }
    }
    return "No past experience added";
  };
  const getCurrentExperience = (workHistory) => {
    if (workHistory) {
      for (var i = 0; i < workHistory.length; i++) {
        if (
          workHistory[i].workedTill < 0 ||
          workHistory[i].workedTill === "1900-01-01"
        ) {
          return (
            workHistory[i].title +
            (workHistory[i].workedWith ? ", " + workHistory[i].workedWith : "")
          );
        }
      }
    }
    return "No current experience added";
  };
  const gotoCandidateDescription = (e, candidate) => {
    setRatingAndReview(true);
    setSelectedCandidateId(candidate.id);
    setSelectedCandidateRating(candidate.candidateRating);
  };
  const ResumeStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: selectedCandidateId,
      isResumeDownloaded: true,
    };
    CandidateServiceClass.getStatus(data).then((res) => {});
  };
  const [candidateProfileDrawer, setCandidateProfileDrawer] = useState(false);
  const [searchDate, setSearchData] = useState(false);
  const [candidateListId, setCandidateListId] = useState();
  const [candidateInfo, setCandidateInfo] = useState({});
  const gotoCandidateDetail = (candidateData, candidateInfo) => {
    setCandidateListId(candidateData);
    setCandidateInfo(candidateInfo);
    setSearchData(location.search);
    setCandidateProfileDrawer(true);
    // if (isAuthenticated) {
    //   window.open(
    //     "/enterprise/candidate-detail/" + selectedCandidateId + location.search
    //   );
    // } else {
    //   window.open("/candidate-detail/" + selectedCandidateId + location.search);
    // }
  };
  const handleOpenChat = () => {
    setSendMessage(true);
  };
  const handleCloseChat = () => {
    setSendMessage(false);
  };
  const sendMeassage = () => {
    var formData = new FormData();
    formData.append(
      "messages",
      new Blob(
        [
          JSON.stringify({
            message: message,
            messageTo: selectedCandidateId,
            byEnterprise: true,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    ApiCalls.put("candidates/messages", formData)
      .then((response) => {
        success("Message sent successfully.");
      })
      .catch(function (error) {
        if (error.statuscode != 500) {
          danger("Something went Wrong");
      }
      });
  };
  const [isShortlistDropdownOpen, setIsShortlistDropdownOpen] =
    React.useState(false);
  const getQuery = (pgNo) => {
    let query = "";
    if (params.get("city")) {
      query += `city=${params.get("city")}&`;
    }
    if (params.get("state")) {
      query += `state=${params.get("state")}&`;
    }
    if (params.get("country")) {
      query += `country=${params.get("country")}&`;
    }
    if (
      params.get("minYearsOfExperience") ||
      params.get("minYearsOfExperience") == 0
    ) {
      query += `minYearsOfExperience=${params.get("minYearsOfExperience")}&`;
    }
    if (
      params.get("maxYearsOfExperience") ||
      params.get("maxYearsOfExperience") == 0
    ) {
      let maxYearsOfExperience = params.get("maxYearsOfExperience");
      if (maxYearsOfExperience.includes("+")) {
        maxYearsOfExperience = maxYearsOfExperience.replace(
          "+",
          encodeURIComponent("+")
        );
      }
      query += `maxYearsOfExperience=${maxYearsOfExperience}&`;
    }
    if (params.get("payRateMin") || params.get("payRateMin") == 0) {
      query += `payRateMin=${params.get("payRateMin")}&`;
    }
    if (params.get("payRateMax") || params.get("payRateMax") == 0) {
      let payRateMax = params.get("payRateMax");
      if (payRateMax.includes("+")) {
        payRateMax = payRateMax.replace("+", encodeURIComponent("+"));
      }
      query += `payRateMax=${payRateMax}&`;
    }
    if (params.get("skill")) {
      query += `skill=${params.get("skill")}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "S") {
      query += `skill=${selectedSkillId}&`;
    }
    if (params.get("talentPool")) {
      query += `talentPool=${params.get("talentPool")}&`;
    }
    if (params.get("levelOfEducation")) {
      query += `levelOfEducation=${params.get("levelOfEducation")}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "L") {
      query += `levelOfEducation=${selectedjobTitleFilter.split(",")}&`;
    }
    if (params.get("profileTitle")) {
      query += `profileTitle=${params.get("profileTitle")}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "T") {
      query += `profileTitle=${selectedjobTitleFilter.split(",")}&`;
    }
    if (localStorage.getItem("enterpriseId")) {
      query += `enterpriseId=${localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")}&`;
    }
    if (params.get("company")) {
      query += `company=${params.get("company")}&`;
    }
    if (
      (selectedjobTitleFilter || params.get("input")) &&
      selectedjobTitleFilterType !== "S" &&
      selectedjobTitleFilterType !== "C" &&
      selectedjobTitleFilterType !== "L" &&
      selectedjobTitleFilterType !== "T"
    ) {
      query += `${
        selectedjobTitleFilterType === "K" ||
        selectedjobTitleFilterType === null
          ? "input"
          : selectedjobTitleFilterType === "D"
          ? "nameOfDegree"
          : selectedjobTitleFilterType === "I"
          ? "instituteName"
          : selectedjobTitleFilterType === "M"
          ? "modeOfEducation"
          : selectedjobTitleFilterType === "U"
          ? "universityName"
          : "checklist"
      }=${
        selectedjobTitleFilterType === "CL"
          ? selectedChecklistId
          : localStorage.getItem("searchId") == null
          ? selectedjobTitleFilter.split(",")
          : params.get("input")
      }&`;
    }
    if (params.get("certifications") && selectedjobTitleFilterType == "C") {
      query += `certifications=${params.get("certifications")}&`;
    }
    if (params.get("workType")) {
      query += `workType=${params.get("workType")}&`;
    }
    if (params.get("workPlace")) {
      query += `workPlace=${params.get("workPlace")}&`;
    }
    if (
      (params.get("input") || localStorage.getItem("searchId") !== null) &&
      (selectedjobTitleFilterType == "S" ||
        selectedjobTitleFilterType == "L" ||
        selectedjobTitleFilterType == "T")
    ) {
      query += `input=${params.get("input")}&`;
    }
    if (pgNo !== 0) {
      sortValues?.map((sortValue) => {
        query += "sortValues=" + sortValue + "&";
      });
    }
    query += `relevance=${sort === "2" ? true : false}`;
    return query;
  };
  const [viewMoreSkills, setViewMoreSkills] = useState(false);
  const [toggleSkills, setToggleSkills] = useState(false);

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [candidateState, setCandidateState] = React.useState(null);

  const handleViewAllSkills = (event, candidate) => {
    event.stopPropagation();
    setCandidateState(candidate);
    setAnchorEl2(event.currentTarget);
    setViewMoreSkills(!viewMoreSkills);
    functiondisable();
  };

  const handleCloseSkillsPopover = () => {
    setAnchorEl2(null);
    setViewMoreSkills(!viewMoreSkills);
    functionenable();
  };

  const openSkills = Boolean(anchorEl2);

  React.useEffect(() => {
    // setIsLoading(true);
    setFirstTimeLoad(true);
    setPageNumber(1);
    if (location.search === "") {
      setSort("2");
      setQueryAdvanceFilterString(null);
    }
    CandidateServiceClass.allCandidates(getQuery(0), 0, numberOfResults)
      .then((response) => {
        if (response.length > 0) {
          setSortValues(response[response.length - 1].sortValues);
        }
        if (response.length < numberOfResults) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        if (response.length > 0) {
          setSelectedCandidateId(response[0].id);
          setSelectedCandidateRating(response[0].candidateRating);
        }
        statusCapture(response);
        setCandidateResponse(response);
        // setCandidateList(response);
        setIsLoading(false);
        setFirstTimeLoad(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setFirstTimeLoad(false);
      });
  }, [
    selectedjobTitleFilter,
    filterCity,
    filterState,
    filterCountry,
    filterMinExperience,
    filterMaxExperience,
    filterMinPayRate,
    filterMaxPayRate,
    sort,
    params.get('triggerSearch')
  ]);
  const handleChangePage = async (event, newPage) => {
    if (viewType === "list-view") {
      // setIsLoadingTable(true);
      setFirstTimeLoad(true);
    }
    CandidateServiceClass.allCandidates(
      getQuery(newPage - 1),
      newPage - 1,
      numberOfResults,
      sortValues
    ).then((response) => {
      if (response.length > 0) {
        setSortValues(response[response.length - 1].sortValues);
      }
      if (response.length !== 0) {
        window.scrollTo({ top: 0, behavior: "smooth" }); //scroll to the top of the page
        setPageNumber(newPage);
      }
      setCandidateResponse(response);

      // setCandidateResponse([...candidateResponse, ...response]);
      // setTimeout( ()=>{
      // },2000);
      // for capturing status
      let ids = response && response.map((c) => c.id);
      let data = {
        candidateIds: ids,
        isProfileSearched: true,
      };
      ApiCalls.put("enterprises/pool/candidates/status/search", data).then(
        (res) => {}
      );
      if (viewType === "list-view") {
        setIsLoadingTable(false);
        setFirstTimeLoad(false);
      }
    });
  };

  // const applyAdvanceFilter = (queryString) => {
  //   setPageNumber(0);
  //   CandidateServiceClass.allCandidates(queryString, 0, numberOfResults).then(
  //     (response) => {
  //       if (response.length > 0) {
  //         setSortValues(response[response.length-1].sortValues);
  //       }
  //       if (response.length < numberOfResults) {
  //         setLoadMore(false);
  //       } else {
  //         setLoadMore(true);
  //       }
  //       setCandidateResponse([...candidateResponse, ...response]);
  //       // setTimeout( ()=>{
  //       // },2000);
  //     }
  //   );
  // };

  useEffect(() => {
    if (location.search === "") {
      localStorage.removeItem("searchId");
      setAdvanceFilterObj(undefined);
    }
  }, [params]);
  useEffect(() => {
    if (queryAdvanceFilterString || queryAdvanceFilterString === "") {
      setIsLoading(true);
      setPageNumber(1);
      let query = queryAdvanceFilterString;
      if (query === "" && localStorage.getItem("enterpriseId")) {
        query += `enterpriseId=${localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")}&`;
      }
      CandidateServiceClass.allCandidates(
        query,
        0,
        numberOfResults
      )
        .then((response) => {
          if (response.length > 0) {
            setSortValues(response[response.length - 1].sortValues);
          }
          if (response.length < numberOfResults) {
            setLoadMore(false);
          } else {
            setLoadMore(true);
          }
          if (response.length > 0) {
            setSelectedCandidateId(response[0].id);
            setSelectedCandidateRating(response[0].candidateRating);
          }
          statusCapture(response);
          setCandidateResponse(response);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [queryAdvanceFilterString]);
  const convertPayment = (value) => {
    if (value >= 99999) {
      return Math.floor(value / 1000) + "K";
    }
    return value;
  };

  const statusCapture = (res) => {
    let check =
      params.get("minYearsOfExperience") ||
      params.get("maxYearsOfExperience") ||
      params.get("levelOfEducation") ||
      params.get("profileTitle") ||
      params.get("skill") ||
      params.get("company") ||
      params.get("payRateMax") ||
      params.get("payRateMin") ||
      params.get("city");
    if (check === null) {
      let ids = res && res.map((c) => c.id);
      let data = {
        candidateIds: ids,
        isProfileSearched: true,
      };
      ApiCalls.put("enterprises/pool/candidates/status/search", data).then(
        (res) => {}
      );
    }
  };

  React.useEffect(() => {
    if (selectedCandidateId) {
      let responseData;
      if (candidateResponse) {
        responseData = candidateResponse.find(
          (o) => o.id === selectedCandidateId
        );
      }
      let location = {};
      if (responseData) {
        location = {
          city: responseData.city,
          state: responseData.state,
          country: responseData.country,
          workHistory: responseData.workHistory,
        };
      }
      setIsLoader(true);
      CandidateServiceClass.getProfile(selectedCandidateId).then((response) => {
        setSelectedCandidate({ ...response, ...location });
        setIsLoader(false);
      });
    }
  }, [selectedCandidateId]);
  const handleViewAllSkills1 = (event) => {
    setViewMoreSkills(!viewMoreSkills);
    setAnchorEl(event.currentTarget);
    functiondisable();
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    setViewMoreSkills(!viewMoreSkills);
    functionenable();
  };

  // console.log(isLoading);
  // if (isLoading) {
  //   return <Loader />;
  // }
  if (candidateResponse && candidateResponse.length <= 0) {
    return (
      <>
        <Grid item sx={{ mt: 6, mr: 3, px: 3 }}>
          <Grid
            container
            item
            spacing={1}
            lg={12}
            md={12}
            xs={12}
            className="m-0 pt-0"
          >
            <Grid item lg={12} md={12} xs={12}>
              <Grid item className="">
                <Grid
                  item
                  className="d-flex align-center vertical-align-middle justify-between responsive-block"
                >
                  <Grid
                    item
                    className="d-flex align-center vertical-align-middle add-checklist-align"
                  >
                    <Grid item className="d-flex gray9 f-16 font-weight-600">
                      <span>
                        Found 0 Candidate
                        {candidateResponse.length > 1 ? "s" : ""}
                      </span>
                    </Grid>

                    {/* <Grid item className="d-flex">
                      <>
                        <Typography className="d-flex align-center">
                          <ControlMenu
                            onApply={handleLocationSelect}
                            resetLocationSelect={resetLocationSelect}
                            title={"Location"}
                            buttonTitle={filterCity ? filterCity : "Location"}
                            submitButtonTitle={"Apply"}
                            filterCity={filterCity}
                          />
                        </Typography>
                        <Typography className="d-flex align-center">
                          <ControlFilterYearOfExperience
                            onApply={handleExperienceSelect}
                            resetExperienceSelect={resetExperienceSelect}
                            title={"Years of Experience"}
                            buttonTitle={"Years of Experience"}
                            submitButtonTitle={"Apply"}
                            filterMinExperience={filterMinExperience}
                            filterMaxExperience={filterMaxExperience}
                          />
                        </Typography>
                        <Box
                          onClick={() => resetAll()}
                          className="f-14 font-weight-500 cursor-hover text-decoration-none ml-10 m-r-10 d-flex align-center"
                        >
                          Reset All
                        </Box>
                      </>
                    </Grid> */}
                  </Grid>
                  <Grid
                    item
                    className="d-flex align-center vertical-align-middle"
                  >
                    {/* <Box
                          onClick={() => resetAll()}
                          className="f-14 font-weight-500 cursor-hover text-decoration-none ml-10 m-r-10 d-flex align-center"
                        >
                          Reset All
                        </Box> */}
                    <Typography
                      onClick={handleOpenAdvanceFilter}
                      onKeyPress={handleOpenAdvanceFilter}
                      tabIndex={0}
                      className="mr-10 cursor-hover d-flex matching-details-align bg-gray3 border-radius-6 p-10 filter-responsive galaxy-filter"
                    >
                      <img width="20" height="24" src={filterIcon} alt="filter" />
                      <span className="ml-5 f-16 font-weight-400 gray7">
                        Filter
                      </span>
                    </Typography>
                    <Grid className="d-flex align-center bg-gray3 border-radius-6 pl-10 filter-responsive p-5 talentPool-btn-align matching-details-align">
                      <Typography
                        className="f-16 gray7 sortByDropdown matching-dropdown align-center cursor-hover"
                        style={{ display: "contents" }}
                        onClick={() => setOpenSortBy(!openSortBy)}
                      >
                        <span className="d-flex align-center">
                          <img width="20" height="20" src={sortByIcon} alt="img" className="mr-5" />
                        </span>
                        <Box>Sort by:</Box>
                        <FormControl className="sortby-align relevance-align">
                          <Select
                            open={openSortBy}
                            onClose={() => setOpenSortBy(false)}
                            inputProps={{
                              name: "age",
                              id: "uncontrolled-native",
                            }}
                            value={sort} // this is coming from state
                            onChange={handleChangeJob} // we can setFruit directly here
                            className="pl-0 pr-0 bg-grey sort-responsive dropdownSort"
                          >
                            {options.map((item) => {
                              return (
                                <MenuItem
                                  className="relevance-align"
                                  value={item.value}
                                  key={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {/* <NewJobsImage /> */}
          <Grid container item spacing={1} lg={12} md={12} xs={12}>
            <Grid item lg={6} md={6} xs={12} className="m-auto">
              <Typography className="text-center" sx={{ mt: 8 }}>
                <Box>
                  <img
                    width="300px"
                    height="300px"
                    className="profile-video-thumbnail"
                    src={NoJobFound}
                    alt="img"
                  />
                </Box>
                <Box className="gray10 font-weight-500 f-16 mt-5">
                  Oops! We could not find any results for you
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <AdvanceFilter
          resetAll={resetAll}
          openAdvanceFilter={openAdvanceFilter}
          handleOpenAdvanceFilter={handleOpenAdvanceFilter}
          handleCloseAdvanceFilter={handleCloseAdvanceFilter}
          setOpenAdvanceFilter={setOpenAdvanceFilter}
          filterMaxExperience={filterMaxExperience}
          filterMinExperience={filterMinExperience}
          // applyAdvanceFilter={applyAdvanceFilter}
          setQueryAdvanceFilterString={setQueryAdvanceFilterString}
          setAdvanceFilterObj={setAdvanceFilterObj}
          advanceFilterObj={advanceFilterObj}
          selectedjobTitleFilter={selectedjobTitleFilter}
          sortValues={sortValues}
          sort={sort}
          selectedjobTitleFilterType={selectedjobTitleFilterType}
          selectedSkillId={selectedSkillId}
        />
      </>
    );
  }
  if (!selectedCandidateId || !selectedCandidate) {
    // return <Loader />;
    return (
      <>
        <Grid
          item
          sx={{ mt: 6, px: 2 }}
          className={
            isAuthenticated
              ? "globalList-align candidateList-align mr-30 side-view talentPool-ipad pb-45"
              : "globalList-align candidateList-align mr-30 side-view ml-20 talentPool-ipad"
          }
        >
          <Grid
            container
            item
            spacing={1}
            lg={12}
            md={12}
            xs={12}
            className="m-0 pt-0"
          >
            <Grid item lg={12} md={12} xs={12}>
              <Grid item className="">
                <Grid
                  item
                  className="d-flex align-center vertical-align-middle justify-between responsive-block side-view galaxy-block"
                >
                  <Grid
                    item
                    className="d-flex align-center vertical-align-middle add-checklist-align"
                  >
                    <Grid item className="d-flex">
                      <Typography className="d-flex align-center">
                        <Typography className="heading-text-color f-20 font-weight-600">
                          Candidates{" "}
                        </Typography>
                        {/* <Box className="gray7 f-14 ml-5 font-weight-400">
                          <span>( </span>

                          <span>)</span>
                        </Box> */}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className="d-flex align-center vertical-align-middle add-checklist-align galaxy-block"
                  >
                    <Typography
                      onClick={handleOpenAdvanceFilter}
                      onKeyPress={handleOpenAdvanceFilter}
                      tabIndex={0}
                      className="mr-10 cursor-hover d-flex matching-details-align bg-gray3 border-radius-6 p-10 filter-responsive galaxy-filter"
                    >
                      <img width="20" height="24" src={filterIcon} alt="filter1" />
                      <span className="ml-5 f-16 font-weight-400 gray7">
                        Filter
                      </span>
                    </Typography>
                    <Grid className="d-flex align-center bg-gray3 border-radius-6 pl-10 filter-responsive p-5 talentPool-btn-align matching-details-align">
                      <Typography
                        className="f-16 gray7 sortByDropdown matching-dropdown align-center cursor-hover"
                        style={{ display: "contents" }}
                        onClick={() => setOpenSortBy(!openSortBy)}
                      >
                        <span className="d-flex align-center">
                          <img width="20" height="20" src={sortByIcon} alt="img" className="mr-5" />
                        </span>
                        <Box>Sort by:</Box>
                        <FormControl className="sortby-align relevance-align">
                          <Select
                            open={openSortBy}
                            onClose={() => setOpenSortBy(false)}
                            inputProps={{
                              name: "age",
                              id: "uncontrolled-native",
                            }}
                            value={sort} // this is coming from state
                            onChange={handleChangeJob} // we can setFruit directly here
                            className="pl-0 pr-0 bg-grey sort-responsive dropdownSort"
                          >
                            {options.map((item) => {
                              return (
                                <MenuItem
                                  className="relevance-align"
                                  value={item.value}
                                  key={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="w-100 mt-5 add-checklist-align">
              {[...Array(12)].map((e, i) => (
                <Typography className="display-flex align-center" key={i}>
                  <div className="lazy-loader">
                    <div className="">
                      <Grid container spacing={1} className="">
                        <Grid className="shimmerBG img mx-8 px-8"></Grid>
                        <Grid
                          lg={2}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={1}
                          md={2}
                          xs={2}
                          className="title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={0.5}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={1}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={1.5}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={1}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={1.5}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={0.5}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid
                          lg={0.5}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={2.5}
                          md={2}
                          xs={2}
                          className="title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={1.5}
                          md={2}
                          xs={2}
                          className="shimmerBG title-line mx-8 px-8"
                        ></Grid>
                        <Grid
                          lg={0}
                          md={2}
                          xs={2}
                          className="title-line mx-8 px-8"
                        ></Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                      </Grid>
                    </div>
                  </div>
                </Typography>
              ))}
              {/* </InfiniteScroll> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  // const [anchorEl, setAnchorEl7] = React.useState<null | HTMLElement>(null);
  const openActionMenu = Boolean(anchorEl);
  // const handleClickAction = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };
  const handleToggle1 = () => {
    setOpenAction((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenAction(false);
    } else if (event.key === "Escape") {
      setOpenAction(false);
    }
  }
  const viewTypeHandler = (type) => {
    setViewType(type);
  };

  // Add candidate into multiple talent pool
  const handleOpenAddTalentPool = (candidate) => {
    navigate(
      "/enterprise/leads-talent-pool-list/" +
        (candidate.id ? candidate.id : selectedCandidateId) +
        location.search,
      { state: { name: candidate.firstName + " " + candidate.lastName } }
    );
  };

  const handleOpenReviewModal = (event, candidateId) => {
    event.stopPropagation();
    setIsLoading(true);
    setSelectedCandidateId(candidateId);
    CandidateService.getReviewDetails(candidateId, 0).then((response) => {
      setReviewDetails(response);
      setOpenReviewModal(true);
      setIsLoading(false);
    });
  };
  const getExclusiveEnterprise = (id) => {
    const currentEnterprise = localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId");
    const enterprisesList = JSON.parse(localStorage.getItem("enterprisesList"));
    const childEnterprisesList = JSON.parse(localStorage.getItem("mspEnterprsieData"))?.childEnterprises;
    const childEnterprise = childEnterprisesList?.find((ent) => ent.id == id);
    const enterprise = enterprisesList?.find((ent) => ent.id == id);
    return currentEnterprise != id ? (enterprise ? "To " + enterprise?.establishmentName : childEnterprise ? "To " + childEnterprise?.establishmentName : "") : "";
  };
  return (
    <>
      <Helmet>
        {appOrigin === "N" ? (
          <title>Global Search | {Constants.APP_NAME_NEURODIVERSIFY}</title>
        ) : appOrigin === "H" ? <title>Global Search | {Constants.APP_NAME_DIRECT_HEALTH}</title> : (
          <title>Global Search | {Constants.APP_NAME_GENERAL}</title>
        )}
        {/* <title>Global Search | hireHQ</title> */}
        <meta name="description" content={`Global Search | ${Constants.APP_NAME_GENERAL}`} />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      {firstTimeLoad ? (
        <>
          <Grid
            item
            sx={{ mt: 6, px: 2 }}
            className={
              isAuthenticated
                ? "globalList-align candidateList-align mr-30 side-view talentPool-ipad pb-45"
                : "globalList-align candidateList-align mr-30 side-view ml-20 talentPool-ipad"
            }
          >
            <Grid
              container
              item
              spacing={1}
              lg={12}
              md={12}
              xs={12}
              className="m-0 pt-0"
            >
              <Grid item lg={12} md={12} xs={12}>
                <Grid item className="">
                  <Grid
                    item
                    className="d-flex align-center vertical-align-middle justify-between responsive-block side-view galaxy-block"
                  >
                    <Grid
                      item
                      className="d-flex align-center vertical-align-middle add-checklist-align"
                    >
                      <Grid item className="d-flex">
                        <Typography className="d-flex align-center">
                          <Typography className="heading-text-color f-20 font-weight-600">
                            Candidates{" "}
                          </Typography>
                          {/* <Box className="gray7 f-14 ml-5 font-weight-400">
                            <span>(</span>
                            <span>)</span>
                          </Box> */}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      className="d-flex align-center vertical-align-middle add-checklist-align galaxy-block"
                    >
                      <Typography
                        onClick={handleOpenAdvanceFilter}
                        onKeyPress={handleOpenAdvanceFilter}
                        tabIndex={0}
                        className="mr-10 cursor-hover d-flex matching-details-align bg-gray3 border-radius-6 p-10 filter-responsive galaxy-filter"
                      >
                        <img width="20" height="24" src={filterIcon} alt="filter2" />
                        <span className="ml-5 f-16 font-weight-400 gray7">
                          Filter
                        </span>
                      </Typography>
                      <Grid className="d-flex align-center bg-gray3 border-radius-6 pl-10 filter-responsive p-5 talentPool-btn-align matching-details-align">
                        <Typography
                          className="f-16 gray7 sortByDropdown matching-dropdown align-center cursor-hover"
                          style={{ display: "contents" }}
                          onClick={() => setOpenSortBy(!openSortBy)}
                        >
                          <span className="d-flex align-center">
                            <img width="20" height="20" src={sortByIcon} alt="img" className="mr-5" />
                          </span>
                          <Box>Sort by:</Box>
                          <FormControl className="sortby-align relevance-align">
                            <Select
                              open={openSortBy}
                              onClose={() => setOpenSortBy(false)}
                              inputProps={{
                                name: "age",
                                id: "uncontrolled-native",
                              }}
                              value={sort} // this is coming from state
                              onChange={handleChangeJob} // we can setFruit directly here
                              className="pl-0 pr-0 bg-grey sort-responsive dropdownSort"
                            >
                              {options.map((item) => {
                                return (
                                  <MenuItem
                                    className="relevance-align"
                                    value={item.value}
                                    key={item.value}
                                  >
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="w-100 mt-5 add-checklist-align">
                {[...Array(12)].map((e, i) => (
                  <Typography className="display-flex align-center" key={i}>
                    <div className="lazy-loader">
                      <div className="">
                        <Grid container spacing={1} className="">
                          <Grid className="shimmerBG img mx-8 px-8"></Grid>
                          <Grid
                            lg={2}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={1}
                            md={2}
                            xs={2}
                            className="title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={0.5}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={1}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={1.5}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={1}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={1.5}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={0.5}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid
                            lg={0.5}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={2.5}
                            md={2}
                            xs={2}
                            className="title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={1.5}
                            md={2}
                            xs={2}
                            className="shimmerBG title-line mx-8 px-8"
                          ></Grid>
                          <Grid
                            lg={0}
                            md={2}
                            xs={2}
                            className="title-line mx-8 px-8"
                          ></Grid>
                          <Grid></Grid>
                          <Grid></Grid>
                          <Grid></Grid>
                          <Grid></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Typography>
                ))}
                {/* </InfiniteScroll> */}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {isLoadingTabel && <Loader />}
          {candidateResponse.length <= 0 ? (
            <Grid item>
              {/* <NewJobsImage /> */}
              <Grid container item spacing={1} lg={12} md={12} xs={12}>
                <Grid item lg={6} md={6} xs={12} className="m-auto">
                  <Typography className="text-center" sx={{ mt: 8 }}>
                    <Box>
                      <img
                        width="300px"
                        height="300px"
                        className="profile-video-thumbnail"
                        src={NoJobFound}
                        alt="img"
                      />
                    </Box>
                    <Box className="gray10 font-weight-500 f-16 mt-5">
                      Oops! We could not find any results for you
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
          ) : (
            <Grid
              item
              sx={{ mt: 6, px: 2 }}
              className={
                isAuthenticated
                  ? "globalList-align candidateList-align mr-30 side-view talentPool-ipad pb-45"
                  : "globalList-align candidateList-align mr-30 side-view ml-20 talentPool-ipad"
              }
            >
              <Grid
                container
                item
                spacing={1}
                lg={12}
                md={12}
                xs={12}
                className="m-0 pt-0"
              >
                <Grid item lg={12} md={12} xs={12}>
                  <Grid item className="">
                    <Grid
                      item
                      className="d-flex align-center vertical-align-middle justify-between responsive-block side-view galaxy-block"
                    >
                      <Grid
                        item
                        className="d-flex align-center vertical-align-middle add-checklist-align"
                      >
                        <Grid item className="d-flex">
                          <Typography className="d-flex align-center">
                            <Typography className="heading-text-color f-20 font-weight-600">
                              Candidates{" "}
                            </Typography>
                            <Box className="gray7 f-14 ml-5 font-weight-400">
                              <span>(</span>
                              {candidateResponse[0].totalCount >= 10000
                                ? "10000+"
                                : candidateResponse[0].totalCount}
                              <span>)</span>
                            </Box>
                            {/* {candidateResponse.length > 1 ? "s" : ""} */}
                          </Typography>
                        </Grid>

                        {/* <Grid item className="d-flex">
                      <>
                        <Typography className="d-flex align-center">
                          <ControlMenu
                            onApply={handleLocationSelect}
                            resetLocationSelect={resetLocationSelect}
                            title={"Location"}
                            buttonTitle={filterCity ? filterCity : "Location"}
                            submitButtonTitle={"Apply"}
                            filterCity={filterCity}
                          />
                        </Typography>
                        <Typography className="d-flex align-center">
                          <ControlFilterYearOfExperience
                            onApply={handleExperienceSelect}
                            resetExperienceSelect={resetExperienceSelect}
                            title={"Years of Experience"}
                            buttonTitle={"Years of Experience"}
                            submitButtonTitle={"Apply"}
                            filterMinExperience={filterMinExperience}
                            filterMaxExperience={filterMaxExperience}
                          />
                        </Typography>
                        <Box
                          onClick={() => resetAll()}
                          className="f-14 font-weight-500 cursor-hover text-decoration-none ml-10 m-r-10 d-flex align-center"
                        >
                          Reset All
                        </Box>
                      </>
                    </Grid> */}
                      </Grid>
                      <Grid
                        item
                        className="d-flex align-center vertical-align-middle add-checklist-align galaxy-block"
                      >
                        <Typography
                          onClick={handleOpenAdvanceFilter}
                          onKeyPress={handleOpenAdvanceFilter}
                          tabIndex={0}
                          className="mr-10 cursor-hover d-flex matching-details-align bg-gray3 border-radius-6 p-10 filter-responsive galaxy-filter"
                        >
                          <img width="20" height="24" src={filterIcon} alt="filter3"/>
                          <span className="ml-5 f-16 font-weight-400 gray7">
                            Filter
                          </span>
                        </Typography>
                        <Grid className="d-flex align-center bg-gray3 border-radius-6 pl-10 filter-responsive p-5 talentPool-btn-align matching-details-align">
                          <Typography
                            className="f-16 gray7 sortByDropdown matching-dropdown align-center cursor-hover"
                            style={{ display: "contents" }}
                            onClick={() => setOpenSortBy(!openSortBy)}
                          >
                            <span className="d-flex align-center">
                              <img
                              width="20" height="20"
                                src={sortByIcon}
                                alt="img"
                                className="mr-5"
                              />
                            </span>
                            <Box>Sort by:</Box>
                            <FormControl className="sortby-align relevance-align">
                              <Select
                                open={openSortBy}
                                onClose={() => setOpenSortBy(false)}
                                inputProps={{
                                  name: "age",
                                  id: "uncontrolled-native",
                                }}
                                value={sort} // this is coming from state
                                onChange={handleChangeJob} // we can setFruit directly here
                                className="pl-0 pr-0 bg-grey sort-responsive dropdownSort"
                              >
                                {options.map((item) => {
                                  return (
                                    <MenuItem
                                      className="relevance-align"
                                      value={item.value}
                                      key={item.value}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Typography>
                        </Grid>
                        {/* <Box
                          onClick={() => resetAll()}
                          className="f-14 font-weight-500 cursor-hover text-decoration-none ml-10 m-r-10 d-flex align-center"
                        >
                          Reset All
                        </Box> */}
                        {/* <Typography className="f-19 gray7 d-flex sortByDropdown sort-dropdown justify-end sortby-align">
                      <Typography
                        className="f-14 gray7 jobsFound ml-20 cursor-normal d-flex align-center responsive-checklist"
                        onChange={handleChangeJob}
                      >
                        Sort by:
                        <span
                          className="text-black cursor-hover pl-5"
                          onChange={handleChangeJob}
                          onClick={() => setOpen(true)}
                          onKeyPress={() => setOpen(true)}
                          tabIndex={0}
                        >
                          {sort === "1" ? " Latest" : " Relevance"}
                        </span>
                        <Select
                          open={open}
                          onClose={() => setOpen(false)}
                          onOpen={() => setOpen(true)}
                          onChange={handleChangeJob}
                          displayEmpty
                          className="cursor-hover mr-5"
                        >
                          {sort === "2" ? (
                            <MenuItem value="1">Latest</MenuItem>
                          ) : (
                            <MenuItem value="2">Relevance</MenuItem>
                          )}
                        </Select>
                      </Typography>
                    </Typography> */}
                        {/* <Typography className="d-flex align-center mt-10 talent-poolFilter">
                        <Typography
                          className="mr-10 ml-10 responsive-checklist"
                          onClick={() => viewTypeHandler("grid-view")}
                        >
                          <img
                            className={`cursor-hover ${
                              viewType === "grid-view"
                                ? "active-list-view"
                                : "inactive-list-view"
                            }`}
                            src={
                              viewType === "grid-view"
                                ? GridViewActiveIcon
                                : GridViewInactiveIcon
                            }
                            alt="grid view"
                          />
                        </Typography>
                        <Typography
                          onClick={() => viewTypeHandler("list-view")}
                        >
                          <img
                            className={`cursor-hover ${
                              viewType === "list-view"
                                ? "active-list-view"
                                : "inactive-list-view"
                            }`}
                            src={
                              viewType === "list-view"
                                ? ListViewActiveIcon
                                : ListViewInactiveIcon
                            }
                            alt="grid view"
                          />
                        </Typography>
                      </Typography> */}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*   <Typography className="font-weight-600 f-18 align-center d-flex">
                                <span>Found {candidateResponse.length} candidate{candidateResponse.length > 1 ? 's' : ''}</span>
                                 Found {candidateResponse.length} {selectedjobTitleFilter} candidates {filterCity?`in ${filterCity}`:''}
                                <Typography className="d-flex align-center" >
                                    <ControlMenu
                                        onApply={handleLocationSelect}
                                        resetLocationSelect={resetLocationSelect}
                                        title={"Select Location"}
                                        buttonTitle={filterCity?filterCity:"Location"}
                                        submitButtonTitle={"Apply"}
                                        filterCity={filterCity}
                                    />
                                </Typography>
                                <Typography className="d-flex align-center" >
                                    <ControlFilterYearOfExperience
                                        onApply={handleExperienceSelect}
                                        resetExperienceSelect={resetExperienceSelect}
                                        title={"Years of Experience"}
                                        buttonTitle={"Years of Experience"}
                                        submitButtonTitle={"Apply"}
                                        filterMinExperience={filterMinExperience}
                                        filterMaxExperience={filterMaxExperience}
                                    />
                                </Typography>
                                 <Typography className="d-flex align-center" >
                                                        <ControlFilterPayRate
                                                            onApply={handlePayRateSelect}
                                                            resetPayRateSelect={resetPayRateSelect}
                                                            title={"Pay Rate"}
                                                            buttonTitle={"Pay Rate"}
                                                            submitButtonTitle={"Apply"}
                                                            filterMinPayRate={filterMinPayRate}
                                                filterMaxPayRate={filterMaxPayRate}
                                                        />
                                                    </Typography>
                                <Box onClick={() => resetAll()} className="f-14 font-weight-500 cursor-hover text-decoration-none ml-10 m-r-10">Reset All</Box>
                                <Grid item lg={6} md={8} xs={12}  className="align-item-right ">
                                    <Typography className="f-19 gray7 d-flex sortByDropdown justify-end cursor-hover">
                                        <Typography className="f-14 gray7 jobsFound ml-20"  onChange={handleChangeJob}>
                                            Sort by: {sort === "1" ? "Latest" : "Relevance"}
                                            <Select
                                                onChange={handleChangeJob}
                                                displayEmpty
                                                className=""
                                            >
                                                {sort === "2"? <MenuItem value="1">Latest</MenuItem>:
                                                    <MenuItem value="2">Relevance</MenuItem>}
                                            </Select>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Typography>*/}

                  {/* {viewType === "grid-view" && (
                <Grid
                  item
                  direction="row"
                  container
                  spacing={1}
                  style={{ marginTop: "30px", paddingLeft: "8px" }}
                  className="pb-40"
                >
                  <Grid
                    item
                    id="scrollableDiv"
                    container
                    lg={4}
                    md={4}
                    xs={12}
                    style={{
                      background: "white",
                      overflow: "auto",
                      display: "block",
                      padding: "0px",
                      position: "absolute",
                      width: "410px",
                    }}
                    className="joblist-position shadow-2 border-right"
                  >
                    <InfiniteScroll
                      dataLength={candidateResponse.length}
                      next={() => handleChangePage()}
                      hasMore={loadMore}
                      loader={<Loader />}
                      inverse={false}
                      scrollableTarget="scrollableDiv"
                    >
                      {candidateResponse &&
                        candidateResponse.map((candidate, index) => (
                          <Grid
                            item
                            key={index}
                            className="p-20"
                            style={{
                              borderBottom: "1px solid lightgray",
                              cursor:
                                selectedCandidateId === candidate.id
                                  ? "default"
                                  : "pointer",
                              background:
                                selectedCandidateId === candidate.id
                                  ? "rgba(68, 90, 199, 0.1)"
                                  : "white",
                            }}
                            onClick={(e) =>
                              gotoCandidateDescription(e, candidate)
                            }
                            tabIndex={0}
                            onKeyPress={(e) =>
                              gotoCandidateDescription(e, candidate)
                            }
                          >
                            <Grid
                              item
                              className="d-flex align-between responsive-block"
                            >
                              <Grid item className="d-flex mr-5" width="65%">
                                <Grid item className={"p-relative"}>
                                  {(candidate.mediaFileSigned === null ||
                                    candidate.mediaFileSigned === "null" ||
                                    candidate.mediaFileSigned === "") && (
                                    <Typography
                                      className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                                      width="40px"
                                      height="40px"
                                      aria-label="Default image"
                                    >
                                      {candidate.firstName
                                        .trim()
                                        .substring(0, 1)}
                                    </Typography>
                                  )}
                                  {candidate.mediaFileSigned !== null &&
                                    candidate.mediaFileSigned !== "null" &&
                                    candidate.mediaFileSigned !== "" &&
                                    !checkIsImage(
                                      candidate.mediaFileSigned
                                    ) && (
                                      <>
                                        <video
                                          width="50px"
                                          height="50px"
                                          style={{
                                            cursor:
                                              selectedCandidateId ===
                                              candidate.id
                                                ? "default"
                                                : "pointer",
                                          }}
                                          className="default-img-border profile-video-thumbnail"
                                          src={candidate.mediaFileSigned}
                                          onClick={(e) => {
                                            handleOpenVideoPreview(
                                              e,
                                              candidate.mediaFileSigned,
                                              candidate.firstName +
                                                " " +
                                                candidate.lastName
                                            );
                                          }}
                                          onKeyPress={(e) => {
                                            handleOpenVideoPreview(
                                              e,
                                              candidate.mediaFileSigned,
                                              candidate.firstName +
                                                " " +
                                                candidate.lastName
                                            );
                                          }}
                                          tabIndex={0}
                                          muted
                                        />
                                        <img
                                          onClick={(e) => {
                                            handleOpenVideoPreview(
                                              e,
                                              candidate.mediaFileSigned,
                                              candidate.firstName +
                                                " " +
                                                candidate.lastName
                                            );
                                          }}
                                          className="play-icon-profile"
                                          style={{
                                            cursor:
                                              selectedCandidateId ===
                                              candidate.id
                                                ? "default"
                                                : "pointer",
                                          }}
                                          src={playIcon}
                                          alt="img"
                                          onKeyPress={(e) => {
                                            handleOpenVideoPreview(
                                              e,
                                              candidate.mediaFileSigned,
                                              candidate.firstName +
                                                " " +
                                                candidate.lastName
                                            );
                                          }}
                                          tabIndex={0}
                                        />
                                      </>
                                    )}
                                  {candidate.mediaFileSigned !== null &&
                                    candidate.mediaFileSigned !== "null" &&
                                    candidate.mediaFileSigned !== "" &&
                                    checkIsImage(candidate.mediaFileSigned) && (
                                      <img
                                        height="40px"
                                        width="40px"
                                        src={candidate.mediaFileSigned}
                                        className="default-img-border"
                                        alt="img"
                                      />
                                    )}
                                </Grid>
                                <Grid item>
                                  <Typography className="d-flex align-center">
                                    <Typography className="align-center d-flex pl-10">
                                      <Typography>
                                        <Box
                                          style={{
                                            cursor:
                                              selectedCandidateId ===
                                              candidate.id
                                                ? "default"
                                                : "pointer",
                                          }}
                                          className=" f-15 font-weight-600"
                                        >
                                          <span
                                            onClick={(e) =>
                                              gotoCandidateDescription(
                                                e,
                                                candidate
                                              )
                                            }
                                            variant="h5"
                                            tabIndex={0}
                                            onKeyPress={(e) =>
                                              gotoCandidateDescription(
                                                e,
                                                candidate
                                              )
                                            }
                                          >
                                            {candidate.firstName}{" "}
                                            {candidate.lastName}
                                          </span>
                                        </Box>
                                        <Box
                                          className="gray9 f-14 d-flex"
                                          onClick={(e) =>
                                            gotoCandidateDescription(
                                              e,
                                              candidate
                                            )
                                          }
                                          tabIndex={0}
                                          onKeyPress={(e) =>
                                            gotoCandidateDescription(
                                              e,
                                              candidate
                                            )
                                          }
                                        >
                                          {candidate.firstName}{" "}
                                          {candidate.lastName}
                                        </span>
                                      </Box>
                                      <Box
                                        className="light-text f-14 d-flex"
                                        onClick={(e) =>
                                          gotoCandidateDescription(e, candidate)
                                        }
                                        tabIndex={0}
                                        onKeyPress={(e) =>
                                          gotoCandidateDescription(e, candidate)
                                        }
                                      >
                                        {candidate.profileTitle}
                                      </Box>
                                      <Typography
                                        variant="span"
                                        className="sub-text-color f-14 l-h-1 cursor-hover"
                                      >
                                        {(candidate?.noOfRatings) ?
                                          <Typography onClick={(e)=>handleOpenReviewModal(e, candidate.id)}>
                                            <Rating
                                              count={5}
                                              // edit={false}
                                              value={candidate?.candidateRating ? candidate?.candidateRating : 0}
                                              // size={24}
                                              isHalf={true}
                                              // edit={false}
                                              // activeColor="#ffd700"
                                              readOnly
                                              sx={{
                                                "& .MuiRating-icon": {
                                                    width: '18px'
                                                }
                                              }}
                                              icon={
                                                <img
                                                  src={starFilled}
                                                  style={{
                                                    width: "16px",
                                                    height: "16px",
                                                  }}
                                                  alt="img"
                                                />
                                              }
                                              emptyIcon={
                                                <img
                                                  src={starEmpty}
                                                  style={{
                                                    width: "16px",
                                                    height: "16px",
                                                  }}
                                                  alt="img"
                                                />
                                              }
                                            />
                                            <span className="f-12 link-color font-weight-400 cursor-hover d-block" tabIndex={0}>
                                              ({candidate.noOfRatings}{" "}
                                              {candidate.noOfRatings === 1
                                                ? "rating"
                                                : "ratings"}
                                              )
                                            </span>
                                          </Typography>
                                          :
                                          <Typography className="f-12 sub-text-color align-center d-flex">
                                            No rating available
                                          </Typography>
                                        }
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item>
                                {candidate?.talentPool === "Z" && (
                                  <span className="priorityStatus f-14 font-weight-400 gray9">
                                    Priority
                                  </span>
                                )}
                                {candidate?.talentPool === "P" && (
                                  <span className="myCandidate-Ongoing f-14 font-weight-400 gray9">
                                    Private
                                  </span>
                                )}
=======
                            </Grid>
                            <Grid item>
                              {candidate?.talentPool === "Z" &&
                                <span className="priorityStatus f-14 font-weight-400 light-text">Priority</span>}
                              {candidate?.talentPool === "P" &&
                                <span className="myCandidate-Ongoing f-14 font-weight-400 light-text">Private</span>}
                            </Grid>
                            <Grid item className="rating-align" style={{ paddingLeft: "3px" }} width="35%">
                              <Typography className="align-item-center">
                                <span className="f-14 sub-text-color p-r-1 m-t-m-10">
                                  {candidate.ratePerHour ? "$" : ""}
                                </span>
                                <span className="font-weight-700 f-15 primary-text-color">
                                  {convertPayment(
                                    candidate.ratePerHour
                                      ? candidate.ratePerHour
                                      : ""
                                  )}
                                </span>
                                <span className="f-12 sub-text-color">
                                  &nbsp; {candidate.ratePerHour ? "/hour" : ""}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item className="ml-5">
                            <Box className="d-flex mt-20">
                              <span className="f-14 light-text pl-0 word-break">
                                {(!candidate?.availableDate && candidate.inCandidatePool == true) && ("Available immediately")}
                                {(!candidate?.availableDate && candidate.inCandidatePool == false) && ("May be available")}
                                {(candidate?.availableDate && candidate.inCandidatePool == true) && ("Available from ")}
                                {(candidate?.availableDate && candidate.inCandidatePool == true) && (moment(formatDate(new Date(JSON.parse(JSON.stringify(candidate?.availableDate).replace("23:59:59", "00:00:00"))))).format("MMM DD, YYYY"))}
                              </span>
                              <Tooltip
                                placement="right"
                                title="This information is based on projects the candidate executed on this platform."
                              >
                                <IconButton style={{ alignItems: "baseline", padding: "2px" }} aria-label="Info icon">
                                  <InfoIcon className="f-16" aria-label="Info icon" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                          <Grid container item spacing={1}>
                            <Grid item lg={12} md={12} xs={12}>
                              <Grid item className="align-between mt-10">
                                <Typography>
                                  <Box className="f-14 light-text align-center d-flex">
                                    <LocationOnIcon className="m-r-6 sub-text-color" aria-label="Location icon" />
                                    {" "}
                                    <i>
                                    {candidate.city}
                                    {candidate.city?.length > 0 ? ", " : ""}
                                    {candidate.state}{candidate.state?.length > 0 ? ", " : ""}{" "} {candidate.country}
                                    {(!candidate.city && !candidate.state && !candidate.country) && ("No location")}
                                    </i>
                                  </Box>
                                  <span className="font-weight-700 f-15 gray9">
                                    {convertPayment(
                                      candidate.ratePerHour
                                        ? candidate.ratePerHour
                                        : ""
                                    )}
                                  </span>
                                  <span className="f-12 gray7">
                                    &nbsp;{" "}
                                    {candidate.ratePerHour ? "/hour" : ""}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item className="ml-5">
                              <Box className="d-flex mt-20">
                                <span className="f-14 gray9 pl-0 word-break">
                                  {!candidate?.availableDate &&
                                    candidate.inCandidatePool == true &&
                                    "Available immediately"}
                                  {!candidate?.availableDate &&
                                    candidate.inCandidatePool == false &&
                                    "May be available"}
                                  {candidate?.availableDate &&
                                    candidate.inCandidatePool == true &&
                                    "Available from "}
                                  {candidate?.availableDate &&
                                    candidate.inCandidatePool == true &&
                                    moment(
                                      formatDate(
                                        new Date(
                                          JSON.parse(
                                            JSON.stringify(
                                              candidate?.availableDate
                                            ).replace("23:59:59", "00:00:00")
                                          )
                                        )
                                      )
                                    ).format("MMM DD, YYYY")}
                                </span>
                                <Tooltip
                                  placement="right"
                                  title="This information is based on projects the candidate executed on this platform."
                                >
                                  <IconButton
                                    style={{
                                      alignItems: "baseline",
                                      padding: "2px",
                                    }}
                                    aria-label="Info icon"
                                  >
                                    <InfoIcon
                                      className="f-16"
                                      aria-label="Info icon"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            <Grid container item spacing={1}>
                              <Grid item lg={12} md={12} xs={12}>
                                <Grid item className="align-between mt-10">
                                  <Typography>
                                    <Box className="f-14 gray9 align-center d-flex">
                                      <LocationOnIcon
                                        className="m-r-6 gray7"
                                        aria-label="Location icon"
                                      />{" "}
                                      <i>
                                        {candidate.city}
                                        {candidate.city?.length > 0 ? ", " : ""}
                                        {candidate.state}
                                        {candidate.state?.length > 0
                                          ? ", "
                                          : ""}{" "}
                                        {candidate.country}
                                        {!candidate.city &&
                                          !candidate.state &&
                                          !candidate.country &&
                                          "No location"}
                                      </i>
                                    </Box>
                                  </Typography>
                                </Grid>
                                {candidate?.hidePhoneEmail === "N" && (
                                  <Grid item>
                                    {candidate?.mobileNumber && (
                                      <Typography>
                                        <Box className="f-14 gray9 align-center d-flex mt-10">
                                          <img
                                            className="f-14"
                                            src={cellIcon}
                                            alt="img"
                                          />
                                          <span className="f-14 gray9 pl-5">
                                            {candidate?.mobileNumber}
                                          </span>
                                        </Box>
                                      </Typography>
                                    )}
                                    {candidate?.emailAddress && (
                                      <Typography>
                                        <Box className="f-14 gray9 align-center d-flex mt-10">
                                          <MailOutlineIcon
                                            className="f-14 gray7"
                                            aria-label="Email icon"
                                          />

                                          <span className="f-14 gray9 pl-5">
                                            {candidate?.emailAddress}
                                          </span>
                                        </Box>
                                      </Typography>
                                    )}
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  xs={12}
                                  className="mt-10"
                                >
                                  <Typography className="break-all">
                                    <Box className="f-14 gray9 pl-5">
                                      {candidate?.brief
                                        ? candidate?.brief.substring(0, 200) +
                                          ((candidate.brief
                                            ? candidate.brief.length
                                            : 0) > 200
                                            ? "..."
                                            : "")
                                        : ""}
                                      </Box>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container item spacing={1} className="mt-5">
                              <Grid item lg={12} md={12} xs={12}>
                                <Grid item>
                                  {candidate?.skills?.map((skill, index) => (
                                    <>
                                      {index < 7 && (
                                        <span className="skills" style={{}}>
                                          {skill.skills.keyword}
                                        </span>
                                      )}
                                    </>
                                  ))}
                                  <Typography>
                                    {candidate?.skills?.length > 7 && (
                                      <span
                                        className="link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                        onClick={(e) =>
                                          handleViewAllSkills(e, candidate)
                                        }
                                        onKeyPress={(e) =>
                                          handleViewAllSkills(e, candidate)
                                        }
                                        tabIndex={0}
                                      >
                                        {`+${
                                          candidate.skills?.length - 7
                                        } more`}
                                      </span>
                                    )}
                                  </Typography>

                                  {candidate?.candidateSkills?.map(
                                    (skill, index) => (
                                      <>
                                        {index < 7 && (
                                          <span className="skills">
                                            {skill.skills.keyword}
                                          </span>
                                        )}
                                      </>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item lg={2} md={1} xs={2}>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      <Popover
                        id="simple-popover-list"
                        open={openSkills}
                        anchorEl={anchorEl2}
                        onClose={handleCloseSkillsPopover}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                      >
                        <Grid item sx={{ p: 1 }}>
                          <Box className="align-between">
                            <Grid item xs={12}>
                              <Typography
                                id="modal-title"
                                className="d-flex align-between p-10"
                              >
                                <Typography
                                  variant="h5"
                                  className="f-16 font-weight-600"
                                >
                                  Skills
                                </Typography>
                                <Typography
                                  align="right"
                                  className="cursor-hover gray7 icon-button"
                                >
                                  <CloseIcon
                                    onClick={handleCloseSkillsPopover}
                                    onKeyPress={handleCloseSkillsPopover}
                                    aria-label="Close icon"
                                    tabIndex={0}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                          </Box>
                          <Grid
                            item
                            className="p-10"
                            style={{
                              maxHeight: "250px",
                              overflow: "auto",
                              maxWidth: "300px",
                            }}
                          >
                            <Box>
                              {candidateState?.skills?.map((skill, index) => {
                                return (
                                  <span key={index} className="skills">
                                    {skill.skills.keyword}
                                  </span>
                                );
                              })}
                            </Box>
                          </Grid>
                        </Grid>
                      </Popover>
                    </InfiniteScroll>
                  </Grid>
                  {isLoader ? (
                    <Loader />
                  ) : (
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      className="bg-white shadow-2 filter-right-align"
                      sx={{ ml: 51 }}
                      style={{ height: "910px", overflowX: "auto" }}
                      tabIndex={0}
                    >
                      <Typography className="mt-10 w-100">
                        <Grid item sx={{ p: 3 }} className="pt-5">
                          <Grid container item spacing={1} lg={12}>
                            <Grid
                              item
                              onClick={() =>
                                gotoCandidateDetail(selectedCandidateId)
                              }
                              onKeyPress={() =>
                                gotoCandidateDetail(selectedCandidateId)
                              }
                              className="jobProfile pl-0 mr-10 cursor-hover p-relative"
                              tabIndex={0}
                            >
                              {(selectedCandidate.mediaFileSigned === null ||
                                selectedCandidate.mediaFileSigned === "null" ||
                                selectedCandidate.mediaFileSigned === "") && (
                                <Typography
                                  className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                                  width="40px"
                                  height="40px"
                                  aria-label="Default image"
                                >
                                  {selectedCandidate.firstName
                                    .trim()
                                    .substring(0, 1)}
                                </Typography>
                              )}
                              {selectedCandidate.mediaFileSigned !== null &&
                                selectedCandidate.mediaFileSigned !== "null" &&
                                selectedCandidate.mediaFileSigned !== "" &&
                                !checkIsImage(
                                  selectedCandidate.mediaFileSigned
                                ) && (
                                  <>
                                    <video
                                      width="50px"
                                      height="50px"
                                      className="default-img-border profile-video-thumbnail cursor-hover p-relative"
                                      src={selectedCandidate.mediaFileSigned}
                                      onClick={(e) => {
                                        handleOpenVideoPreview(
                                          e,
                                          selectedCandidate.mediaFileSigned,
                                          selectedCandidate.firstName +
                                            " " +
                                            selectedCandidate.lastName
                                        );
                                      }}
                                      muted
                                      onKeyPress={(e) => {
                                        handleOpenVideoPreview(
                                          e,
                                          selectedCandidate.mediaFileSigned,
                                          selectedCandidate.firstName +
                                            " " +
                                            selectedCandidate.lastName
                                        );
                                      }}
                                      tabIndex={0}
                                    />
                                    <img
                                      onClick={(e) => {
                                        handleOpenVideoPreview(
                                          e,
                                          selectedCandidate.mediaFileSigned,
                                          selectedCandidate.firstName +
                                            " " +
                                            selectedCandidate.lastName
                                        );
                                      }}
                                      className="play-icon-profile-detail cursor-hover"
                                      src={playIcon}
                                      alt="img"
                                      tabIndex={0}
                                      onKeyPress={(e) => {
                                        handleOpenVideoPreview(
                                          e,
                                          selectedCandidate.mediaFileSigned,
                                          selectedCandidate.firstName +
                                            " " +
                                            selectedCandidate.lastName
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              {selectedCandidate.mediaFileSigned !== null &&
                                selectedCandidate.mediaFileSigned !== "null" &&
                                selectedCandidate.mediaFileSigned !== "" &&
                                checkIsImage(
                                  selectedCandidate.mediaFileSigned
                                ) && (
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={selectedCandidate.mediaFileSigned}
                                    alt="img"
                                    className="default-img-border"
                                  />
                                )}
                            </Grid>
                            <Grid
                              item
                              lg={10}
                              md={10}
                              xs={8}
                              className="pl-0 pt-0"
                            >
                              <Stack spacing={0}>
                                <Grid
                                  item
                                  className="d-flex justify-between"
                                  onClick={() =>
                                    gotoCandidateDetail(selectedCandidateId)
                                  }
                                  onKeyPress={() =>
                                    gotoCandidateDetail(selectedCandidateId)
                                  }
                                >
                                  <Typography>
                                    <Box
                                      style={{ cursor: "pointer" }}
                                      className="f-16 font-weight-600"
                                      variant="h5"
                                      tabIndex={0}
                                    >
                                      <span variant="h5">
                                        {selectedCandidate.firstName}{" "}
                                        {selectedCandidate.lastName}
                                      </span>
                                    </Box>
                                    <Box
                                      style={{ cursor: "pointer" }}
                                      className="gray9 f-14 d-flex"
                                      tabIndex={0}
                                    >
                                      {selectedCandidate.profileTitle}
                                    </Box>
                                    <Box
                                      variant="span"
                                      className="gray7 f-14 d-flex l-h-1 responsive-block aboutEnterprise"
                                      style={{ marginTop: "2px" }}
                                    >
                                      {selectedCandidate.noOfRatings ? (
                                        <>
                                          <Rating
                                            count={5}
                                            value={
                                              selectedCandidateRating
                                                ? selectedCandidateRating
                                                : 0
                                            }
                                            isHalf={true}
                                            readOnly
                                            sx={{
                                              "& .MuiRating-icon": {
                                                width: "20px",
                                              },
                                            }}
                                            icon={
                                              <img
                                                src={starFilled}
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                }}
                                                alt="img"
                                              />
                                            }
                                            emptyIcon={
                                              <img
                                                src={starEmpty}
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                }}
                                                alt="img"
                                              />
                                            }
                                          />
                                          <span
                                            onClick={(e) =>
                                              openRatingAndReviewSection(e)
                                            }
                                            className="link-color responsive-block"
                                            style={{ paddingTop: "4px" }}
                                            onKeyPress={(E) =>
                                              openRatingAndReviewSection(e)
                                            }
                                            tabIndex={0}
                                          >
                                            ({selectedCandidate.noOfRatings}{" "}
                                            {selectedCandidate.noOfRatings === 1
                                              ? "rating"
                                              : "ratings"}
                                            )
                                          </span>
                                        </>
                                      ) : (
                                        <Typography className="f-12 sub-text-color align-center d-flex cursor-hover">
                                          No rating available
                                        </Typography>
                                      )}
                                    </Box>
                                    <Grid item>
                                      <Box className="d-flex mt-10">
                                        <span className="f-14 gray9 pl-0 word-break">
                                          {!selectedCandidate?.availableDate &&
                                            selectedCandidate.inCandidatePool ==
                                              true &&
                                            "Available immediately"}
                                          {!selectedCandidate?.availableDate &&
                                            selectedCandidate.inCandidatePool ==
                                              false &&
                                            "May be available"}
                                          {selectedCandidate?.availableDate &&
                                            selectedCandidate.inCandidatePool ==
                                              true &&
                                            "Available from "}
                                          {selectedCandidate?.availableDate &&
                                            selectedCandidate.inCandidatePool ==
                                              true &&
                                            moment(
                                              formatDate(
                                                new Date(
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      selectedCandidate?.availableDate
                                                    ).replace(
                                                      "23:59:59",
                                                      "00:00:00"
                                                    )
                                                  )
                                                )
                                              )
                                            ).format("MMM DD, YYYY")}
                                        </span>
                                        <Tooltip
                                          placement="right"
                                          title="This information is based on projects the candidate executed on this platform."
                                        >
                                          <IconButton
                                            style={{
                                              alignItems: "baseline",
                                              padding: "2px",
                                            }}
                                            aria-label="Info icon"
                                          >
                                            <InfoIcon
                                              className="f-16"
                                              aria-label="Info icon"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Grid>
                                  </Typography>
                                </Grid>
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                              className="mt-10"
                            >
                              <Typography className="gray9 sub-text-font font-weight-600">
                                {selectedCandidate.ratePerHour
                                  ? `$${selectedCandidate.ratePerHour} per hour`
                                  : ""}
                              </Typography>
                            </Grid>
                            {isAuthenticated && (
                              <Grid item lg={8} md={8} xs={12}>
                                <Grid item className="align-between mt-10">
                                  <Typography className="d-flex">
                                    <Typography className="d-flex align-center mr-10">
                                      <ControlCheckboxGroup
                                        title={"Jobs"}
                                        buttonTitle={"Shortlist +"}
                                        submitButtonTitle={"Shortlist"}
                                        candidateId={selectedCandidateId}
                                        className={
                                          "btn primary-button small-text-font"
                                        }
                                        candidateName={
                                          selectedCandidate.firstName +
                                          " " +
                                          selectedCandidate.lastName
                                        }
                                      />
                                    </Typography>
                                    <Box>
                                      <Button
                                        className="btn secondary-button"
                                        onClick={() =>
                                          handleOpenAddTalentPool(
                                            selectedCandidate
                                          )
                                        }
                                      >
                                        Add to Talent Pool
                                      </Button>
                                    </Box>
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                            <Grid item lg={12} md={12} xs={12}>
                              <Grid
                                item
                                className="align-between mt-10 break-all"
                              >
                                <Typography>
                                  <Typography>
                                    <Box className="f-14 gray9 align-center">
                                      {showMore ||
                                      selectedCandidate?.brief?.length <= 200
                                        ? selectedCandidate?.brief
                                        : `${
                                            selectedCandidate?.brief
                                              ? selectedCandidate?.brief.substring(
                                                  0,
                                                  200
                                                ) + "..."
                                              : ""
                                          }`}
                                      <Typography inputProps={{ tabIndex: 0 }}>
                                        {(selectedCandidate.brief
                                          ? selectedCandidate.brief.length
                                          : 0) > 200 && (
                                          <Link
                                            className="link-color cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                                            onClick={() =>
                                              setShowMore(!showMore)
                                            }
                                            tabIndex={0}
                                            onKeyPress={() =>
                                              setShowMore(!showMore)
                                            }
                                          >
                                            {" "}
                                            {showMore
                                              ? "view less"
                                              : "view more"}
                                          </Link>
                                        )}
                                      </Typography>
                                    </Box>
                                  </Typography>
                                  <Typography>
                                    <Box className="f-14 gray9 align-center d-flex mt-10">
                                      <img
                                        src={iconLocation}
                                        style={{ marginRight: "11px" }}
                                        alt="img"
                                      />{" "}
                                      <i>
                                        {selectedCandidate.city}
                                        {selectedCandidate.city?.length > 0
                                          ? ","
                                          : ""}{" "}
                                        {selectedCandidate.state}
                                        {selectedCandidate.state?.length > 0
                                          ? ","
                                          : ""}{" "}
                                        {selectedCandidate.country}{" "}
                                        {!selectedCandidate.city &&
                                          !selectedCandidate.state &&
                                          !selectedCandidate.country &&
                                          "No location"}
                                      </i>
                                    </Box>
                                  </Typography>
                                  <Box className="f-14 gray9 align-center d-flex mt-10">
                                    <img
                                      className="mr-10"
                                      src={educationIcon}
                                      alt="img"
                                    />
                                    <>
                                      {selectedCandidate?.education ? (
                                        selectedCandidate.education[0] ? (
                                          <span>
                                            {
                                              selectedCandidate.education[0]
                                                .nameOfDegree
                                            }
                                            ,{" "}
                                            {
                                              selectedCandidate.education[0]
                                                .nameOfInstitute
                                            }
                                          </span>
                                        ) : (
                                          <span>No education added</span>
                                        )
                                      ) : (
                                        <span>No education added</span>
                                      )}
                                    </>
                                  </Box>

                                  <Typography>
                                    <Box className="f-14 gray9 align-center d-flex mt-10">
                                      <img
                                        className="mr-10"
                                        src={overallExp}
                                        alt="img"
                                      />
                                      <>
                                        {selectedCandidate?.overallYearsOfExperience >=
                                        2 ? (
                                          <span>
                                            {
                                              selectedCandidate?.overallYearsOfExperience
                                            }{" "}
                                            years of experience
                                          </span>
                                        ) : (
                                          <span>
                                            {
                                              selectedCandidate?.overallYearsOfExperience
                                            }{" "}
                                            year of experience
                                          </span>
                                        )}
                                      </>
                                    </Box>
                                  </Typography>
                                  {selectedCandidate?.hidePhoneEmail ===
                                    "N" && (
                                    <Grid item>
                                      {selectedCandidate?.mobileNumber && (
                                        <Typography>
                                          <Box className="f-14 gray9 align-center d-flex mt-10">
                                            <img
                                              className="f-14"
                                              src={cellIcon}
                                              alt="img"
                                            />
                                            <span className="f-14 gray9 pl-10">
                                              {selectedCandidate?.mobileNumber}
                                            </span>
                                          </Box>
                                        </Typography>
                                      )}
                                      {selectedCandidate?.emailAddress && (
                                        <Typography>
                                          <Box className="f-14 gray9 align-center d-flex mt-10">
                                            <MailOutlineIcon className="f-14 gray7" />

                                            <span className="f-14 gray9 pl-10">
                                              {selectedCandidate?.emailAddress}
                                            </span>
                                          </Box>
                                        </Typography>
                                      )}
                                    </Grid>
                                  )}
                                  <Typography>
                                    <Box className=" align-center d-flex mt-15 responsive-block">
                                      <span className="f-14 gray7">
                                        Current Experience
                                      </span>
                                      <span className="f-14 gray9 ml-10 responsive-block matching-location-align">{`${getCurrentExperience(
                                        selectedCandidate
                                          ? selectedCandidate.workHistory
                                          : ""
                                      )}`}</span>
                                    </Box>
                                  </Typography>

                                  <Typography>
                                    <Box className=" align-center d-flex mt-15 responsive-block">
                                      <span className="f-14 gray7">
                                        Past Experience
                                      </span>
                                      <span className="f-14 gray9 ml-30 responsive-block matching-location-align">{`${getPastExperience(
                                        selectedCandidate
                                          ? selectedCandidate.workHistory
                                          : ""
                                      )}`}</span>
                                    </Box>
                                  </Typography>
                                  <Typography>
                                    <Box className="mt-15">
                                      <ul className="pl-0 mt-10">
                                        {selectedCandidate?.portfolioUrl1 && (
                                          <li className="mt-10 gray7 d-flex">
                                            <img
                                              className="mr-10 mt-10"
                                              src={linkIcon}
                                              alt="img"
                                            />
                                            <a
                                              className="small-text-font textEllipse mt-10 w-300 link-color"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={
                                                selectedCandidate?.portfolioUrl1.indexOf(
                                                  "://"
                                                ) === -1
                                                  ? "http://" +
                                                    selectedCandidate?.portfolioUrl1
                                                  : selectedCandidate?.portfolioUrl1
                                              }
                                            >
                                              {selectedCandidate?.portfolioUrl1}
                                            </a>
                                          </li>
                                        )}
                                        {selectedCandidate?.portfolioUrl2 && (
                                          <li className="mt-10 gray7 d-flex">
                                            <img
                                              className="mr-10 mt-10"
                                              src={linkIcon}
                                              alt="img"
                                            />
                                            <a
                                              className="small-text-font textEllipse mt-10 w-300 link-color"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={
                                                selectedCandidate?.portfolioUrl2.indexOf(
                                                  "://"
                                                ) === -1
                                                  ? "http://" +
                                                    selectedCandidate?.portfolioUrl2
                                                  : selectedCandidate?.portfolioUrl2
                                              }
                                            >
                                              {selectedCandidate?.portfolioUrl2}
                                            </a>
                                          </li>
                                        )}
                                        {selectedCandidate?.portfolioUrl3 && (
                                          <li className="mt-10 gray7 d-flex">
                                            <img
                                              className="mr-10 mt-10"
                                              src={linkIcon}
                                              alt="img"
                                            />
                                            <a
                                              className="small-text-font textEllipse mt-10 w-300 link-color"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={
                                                selectedCandidate?.portfolioUrl3.indexOf(
                                                  "://"
                                                ) === -1
                                                  ? "http://" +
                                                    selectedCandidate?.portfolioUrl3
                                                  : selectedCandidate?.portfolioUrl3
                                              }
                                            >
                                              {selectedCandidate?.portfolioUrl3}
                                            </a>
                                          </li>
                                        )}
                                        {selectedCandidate?.portfolioUrl4 && (
                                          <li className="mt-10 gray7 d-flex">
                                            <img
                                              className="mr-10 mt-10"
                                              src={linkIcon}
                                              alt="img"
                                            />
                                            <a
                                              className="small-text-font textEllipse mt-10 w-300 link-color"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={
                                                selectedCandidate?.portfolioUrl4.indexOf(
                                                  "://"
                                                ) === -1
                                                  ? "http://" +
                                                    selectedCandidate?.portfolioUrl4
                                                  : selectedCandidate?.portfolioUrl4
                                              }
                                            >
                                              {selectedCandidate?.portfolioUrl4}
                                            </a>
                                          </li>
                                        )}
                                      </ul>
                                    </Box>
                                  </Typography>

                                  {selectedCandidate.resume.length > 0 ? (
                                    selectedCandidate.resume.map(
                                      (uploadResume, index) => {
                                        return (
                                          <a
                                            key={index}
                                            href={uploadResume.urlSigned}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: "none" }}
                                            onClick={() =>
                                              ResumeStatusCapture()
                                            }
                                            onKeyPress={() =>
                                              ResumeStatusCapture()
                                            }
                                            tabIndex={0}
                                          >
                                            <Grid
                                              item
                                              className="mt-10 d-flex uploadResume"
                                              style={{ alignItems: "center" }}
                                            >
                                              <Box>
                                                <img
                                                  style={{ width: "45px" }}
                                                  src={ResumeSvg}
                                                  alt="img"
                                                />
                                              </Box>
                                              <span className="link-color gray9 small-text-font break-word ml-10">
                                                {uploadResume?.title}
                                              </span>
                                            </Grid>
                                          </a>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 gray7 mt-10"></Typography>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={1} className="mt-5">
                            <Grid item lg={10} md={11} xs={10}>
                              <Grid item>
                                {selectedCandidate?.skills?.map(
                                  (skill, index) => {
                                    return index < 7 ? (
                                      <span className="skills">
                                        {skill.skills.keyword}
                                      </span>
                                    ) : null;
                                  }
                                )}
                                <Typography>
                                  {selectedCandidate?.skills?.length > 7 && (
                                    <span
                                      className="link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                      onClick={(e) => handleViewAllSkills1(e)}
                                      tabIndex={0}
                                      onKeyPress={(e) =>
                                        handleViewAllSkills1(e)
                                      }
                                    >
                                      {`+${
                                        selectedCandidate.skills?.length - 7
                                      } more`}
                                    </span>
                                  )}
                                </Typography>
                                <Popover
                                  id="simple-popover-list"
                                  open={open1}
                                  anchorEl={anchorEl}
                                  onClose={handleClosePopover}
                                  anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                  }}
                                >
                                  <Grid item sx={{ p: 1 }}>
                                    <Box className="align-between mb-5">
                                      <Grid item xs={12}>
                                        <Typography
                                          id="modal-title"
                                          className="d-flex align-between p-10"
                                        >
                                          <Typography
                                            variant="h5"
                                            className="f-16 font-weight-600"
                                          >
                                            Skills
                                          </Typography>
                                          <Typography
                                            align="right"
                                            className="cursor-hover gray7 icon-button"
                                          >
                                            <CloseIcon
                                              onClick={handleClosePopover}
                                              onKeyPress={handleClosePopover}
                                              aria-label="Close icon"
                                              tabIndex={0}
                                            />
                                          </Typography>
                                        </Typography>
                                      </Grid>
                                    </Box>
                                    <Grid
                                      item
                                      className="p-10"
                                      style={{
                                        maxHeight: "250px",
                                        overflow: "auto",
                                        maxWidth: "300px",
                                      }}
                                    >
                                      <Box>
                                        {selectedCandidate?.skills?.map(
                                          (skill, index) => {
                                            return (
                                              <span
                                                key={index}
                                                className="skills"
                                              >
                                                {skill.skills.keyword}
                                              </span>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Popover>

                                {selectedCandidate?.candidateSkills?.map(
                                  (skill, index) => (
                                    <>
                                      {
                                        <span className="skills">
                                          {skill.skills.keyword}
                                        </span>
                                      }
                                    </>
                                  )
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Grid container item spacing={1} sx={{ px: 4 }}>
                        {openRatingAndReview && (
                          <Grid
                            item
                            ref={ratingAndReviewsRef}
                            className="bg-white p-20 mt-10 pl-0 pr-0"
                            xs={12}
                            lg={12}
                            md={12}
                          >
                            <RatingsAndReviewsView
                              candidateId={selectedCandidateId}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )} */}
                </Grid>
                {/* {viewType === "list-view" && ( */}
                <Grid
                  item
                  className="w-100 mt-5 add-checklist-align"
                  id="candidateList"
                >
                  {/* <InfiniteScroll
                dataLength={candidateResponse.length}
                next={() => handleChangePage()}
                hasMore={loadMore}
                // loader={<Loader />}
                inverse={false}
                scrollableTarget="scrollableDiv"
                // style={{ height: "910px" }}
              > */}
                  <TableContainer
                    component={Paper}
                    className="global-serach-table"
                  >
                    <Table aria-label="simple table" className="matching-table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{
                              width: "40%",
                            }}
                          >
                            <Typography className="py-10 f-12 font-weight-500 gray9">
                              Candidate Details
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                            align="left"
                            style={isAuthenticated ? {} : { width: "10%" }}
                          >
                            <Typography className="d-flex w-max-content">
                              <Box className="d-flex">
                                <span
                                  style={{ margin: "auto" }}
                                  className="f-12 font-weight-600 gray7 pl-0 word-break"
                                >
                                  Talent Pool
                                </span>
                                <Tooltip
                                  title={
                                    <div className="d-flex pt-15">
                                      <InfoIcon aria-label="Info icon" />
                                      <div>
                                        <div style={{ paddingLeft: "10px" }}>
                                          <h3 className="mt-0">
                                            Private Talent Pool
                                          </h3>
                                          <p>
                                            These are the candidates with whom
                                            you have interacted on our platform
                                          </p>
                                        </div>
                                        <div style={{ paddingLeft: "10px" }}>
                                          <h3 className="mt-0">
                                            Priority Talent Pool
                                          </h3>
                                          <p>
                                            These are the candidates who have
                                            come to the platform through you and
                                            only you have the access to their
                                            profile
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  placement="bottom-end"
                                >
                                  <IconButton
                                    style={{
                                      verticalAlign: "middle",
                                      padding: "2px",
                                    }}
                                    aria-label="Info icon"
                                  >
                                    <InfoIcon aria-label="Info icon" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </TableCell> */}
                          <TableCell
                            align="left"
                            style={{ lineHeight: "20px" }}
                            width=""
                          >
                            <Typography className="py-10 f-12 font-weight-500 gray9">
                              #Experience
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ width: "15%" }}
                            width=""
                          >
                            <Typography className="py-10 f-12 font-weight-500 gray9">
                              Pay Rate
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                            align="left"
                            style={{ width: "20%" }}
                            width=""
                          >
                            Skills
                          </TableCell> */}
                          <TableCell
                            align="left"
                            style={{ width: "16%" }}
                            width=""
                            className="status-minw"
                          >
                            <Typography className="py-10 f-12 font-weight-500 gray9">
                              Status
                            </Typography>{" "}
                          </TableCell>
                          {isAuthenticated && (localStorage.getItem("userType") != "A") && (
                            <TableCell
                              align="left"
                              style={{
                                width: "15%",
                              }}
                              width=""
                              className="align-right"
                            >
                              <Typography className="py-10 f-12 font-weight-500 gray9">
                                Actions
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody className="bg-white">
                        {candidateResponse &&
                          candidateResponse.map((candidate, index) => (
                            <TableRow key={index} hover>
                              <TableCell align="left">
                                <Grid item tabIndex={0}>
                                  <Grid
                                    item
                                    className="d-flex align-between responsive-block"
                                  >
                                    <Grid item className="d-flex mr-5">
                                      <Grid style={{ width: "110px" }}>
                                        <Grid className="p-relative">
                                          <Grid
                                            item
                                            className="p-relative pl-0 cursor-hover d-flex justify-center"
                                            onClick={() =>
                                              gotoCandidateDetail(candidate.id, candidate)
                                            }
                                            onKeyPress={() =>
                                              gotoCandidateDetail(candidate.id, candidate)
                                            }
                                          >
                                            {(candidate.mediaFileSigned ===
                                              null ||
                                              candidate.mediaFileSigned ===
                                                "null" ||
                                              candidate.mediaFileSigned ===
                                                "" ||
                                              candidate.mediaFile === "****") && (
                                              <Typography
                                                className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                                                width="45px"
                                                height="45px"
                                                aria-label="Default image"
                                              >
                                                {candidate.firstName
                                                  .trim()
                                                  .substring(0, 1)}
                                              </Typography>
                                            )}
                                            {candidate.mediaFile !== "****" && candidate.mediaFileSigned !==
                                              null &&
                                              candidate.mediaFileSigned !==
                                                "null" &&
                                              candidate.mediaFileSigned !==
                                                "" &&
                                              !checkIsImage(
                                                candidate.mediaFileSigned
                                              ) && (
                                                <>
                                                  <video
                                                    width="45px"
                                                    height="45px"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="default-img-border profile-video-thumbnail"
                                                    src={
                                                      candidate.mediaFileSigned
                                                    }
                                                    onClick={(e) => {
                                                      handleOpenVideoPreview(
                                                        e,
                                                        candidate.mediaFileSigned,
                                                        candidate.firstName +
                                                          " " +
                                                          candidate.lastName
                                                      );
                                                    }}
                                                    onKeyPress={(e) => {
                                                      handleOpenVideoPreview(
                                                        e,
                                                        candidate.mediaFileSigned,
                                                        candidate.firstName +
                                                          " " +
                                                          candidate.lastName
                                                      );
                                                    }}
                                                    tabIndex={0}
                                                    muted
                                                  />
                                                  <img
                                                    onClick={(e) => {
                                                      handleOpenVideoPreview(
                                                        e,
                                                        candidate.mediaFileSigned,
                                                        candidate.firstName +
                                                          " " +
                                                          candidate.lastName
                                                      );
                                                    }}
                                                    className="play-icon-global"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    src={playIcon}
                                                    alt="img"
                                                    onKeyPress={(e) => {
                                                      handleOpenVideoPreview(
                                                        e,
                                                        candidate.mediaFileSigned,
                                                        candidate.firstName +
                                                          " " +
                                                          candidate.lastName
                                                      );
                                                    }}
                                                    tabIndex={0}
                                                  />
                                                </>
                                              )}
                                            {candidate.mediaFile !== "****" && candidate.mediaFileSigned !==
                                              null &&
                                              candidate.mediaFileSigned !==
                                                "null" &&
                                              candidate.mediaFileSigned !==
                                                "" &&
                                              checkIsImage(
                                                candidate.mediaFileSigned
                                              ) && (
                                                <img
                                                  height="45px"
                                                  width="45px"
                                                  src={
                                                    candidate.mediaFileSigned
                                                  }
                                                  className="default-img-border"
                                                  alt="img"
                                                />
                                              )}
                                          </Grid>
                                          <Grid>
                                            <Tooltip
                                              title={
                                                <div className="mt-15">
                                                  <Typography
                                                    // style={{ width: `${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "110px": "80px"}` }}
                                                  >
                                                    <div className="d-flex align-center">
                                                    <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "mspExclusive": "priorityStatus"}  d-flex align-center justify-center`}>
                                                      <span className="d-flex align-center">
                                                        {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                                                      </span>
                                                      <span className="pl-5">
                                                        {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}
                                                      </span>
                                                    </span>
                                                    <span className="f-12 c-white pl-5">{getExclusiveEnterprise(candidate?.sourceEnterpriseId)}</span>
                                                    </div>
                                                  </Typography>
                                                  <Typography className="f-12 c-white mt-5">
                                                    {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE_Detail :"These are the candidates who have come to the platform through you and only you have the access to their profile"}
                                                  </Typography>
                                                  <br />
                                                </div>
                                              }
                                              placement="top"
                                              trigger="mouseenter"
                                              animation="fade"
                                              arrow
                                            >
                                              <Typography>
                                                {candidate?.talentPool ===
                                                  "Z" && (
                                                  <span
                                                    className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "mspExclusive global-search-list-mspExclusive": "priorityStatus global-search-list-exclusive"} d-flex align-center justify-center p-absolute`}
                                                    style={{
                                                      bottom: "-20px !important",
                                                      left: `${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "2px !important": "15px !important"}`,
                                                    }}
                                                  >
                                                    <span className="d-flex align-center">
                                                      {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                                                    </span>
                                                    <span className="pl-5">
                                                      {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}
                                                    </span>
                                                  </span>
                                                )}
                                              </Typography>
                                            </Tooltip>
                                            <Tooltip
                                              title={
                                                <div className="mt-15">
                                                  <Typography
                                                    style={{ width: "80px" }}
                                                  >
                                                    <span className="myCandidate-Ongoing d-flex align-center justify-center">
                                                      <span className="d-flex align-center">
                                                        <PrivateIcon />
                                                      </span>
                                                      <span className="pl-5">
                                                        Qualified
                                                      </span>
                                                    </span>
                                                  </Typography>
                                                  <Typography className="f-12 c-white mt-5">
                                                    These are the candidates
                                                    with whom you have
                                                    interacted on our platform
                                                  </Typography>
                                                  <br />
                                                                                                  </div>
                                              }
                                              placement="top"
                                              trigger="mouseenter"
                                              animation="fade"
                                              arrow
                                            >
                                              <Typography>
                                                {candidate?.talentPool ===
                                                  "P" && (
                                                  <span
                                                    className="myCandidate-Ongoing d-flex align-center justify-center p-absolute"
                                                    style={{
                                                      bottom: "-20px",
                                                      left: "15px",
                                                    }}
                                                  >
                                                    <span className="d-flex align-center">
                                                      <PrivateIcon />
                                                    </span>
                                                    <span className="pl-5">
                                                      Qualified
                                                    </span>
                                                  </span>
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item className="ml-15">
                                        <Typography>
                                          <Typography
                                            className="cursor-hover main-text-color f-14 font-weight-600 w-200 word-break"
                                            onClick={() =>
                                              gotoCandidateDetail(candidate.id, candidate)
                                            }
                                            onKeyPress={() =>
                                              gotoCandidateDetail(candidate.id, candidate)
                                            }
                                          >
                                            <span tabIndex={0}>
                                              {candidate.firstName}{" "}
                                              {candidate.lastName}
                                            </span>
                                          </Typography>
                                          {/* <Box
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  gotoCandidateDetail(
                                                    candidate.id
                                                  )
                                                }
                                                onKeyPress={() =>
                                                  gotoCandidateDetail(
                                                    candidate.id
                                                  )
                                                }
                                                className="gray7 f-14 d-flex"
                                              >
                                                {candidate.profileTitle}
                                              </Box> */}
                                          <Typography
                                            variant="span"
                                            className="mt-5 gray7 f-12 font-weight-400 align-center cursor-hover d-flex userName-align jobDetail-user rating-details responsive-block"
                                          >
                                            {candidate.noOfRatings ? (
                                              <Typography
                                                className="d-flex"
                                                onClick={(e) =>
                                                  handleOpenReviewModal(
                                                    e,
                                                    candidate.id
                                                  )
                                                }
                                              >
                                                <Rating
                                                  count={5}
                                                  value={
                                                    candidate.candidateRating
                                                      ? candidate.candidateRating
                                                      : 0
                                                  }
                                                  name="half-rating"
                                                  readOnly
                                                  sx={{
                                                    "& .MuiRating-icon": {
                                                      width: "18px",
                                                    },
                                                  }}
                                                  icon={
                                                    <img
                                                      src={starFilled}
                                                      style={{
                                                        width: "15px",
                                                        height: "15px",
                                                      }}
                                                      alt="img"
                                                    />
                                                  }
                                                  emptyIcon={
                                                    <img
                                                      src={starEmpty}
                                                      style={{
                                                        width: "15px",
                                                        height: "15px",
                                                      }}
                                                      alt="img"
                                                    />
                                                  }
                                                />
                                                <Typography className="cursor-hover f-12 font-weight-400 matchingJobSpace">
                                                  <span
                                                    className="link-color pt-5 cursor-hover"
                                                    tabIndex={0}
                                                  >
                                                    ({candidate.noOfRatings}
                                                    {/* {candidate.noOfRatings <= 1
                                                  ? "review"
                                                  : "reviews"} */}
                                                    )
                                                  </span>
                                                </Typography>
                                              </Typography>
                                            ) : (
                                              <Typography
                                                className="f-12 gray7 font-weight-400 align-center d-flex"
                                                onClick={() =>
                                                  gotoCandidateDetail(
                                                    candidate.id, candidate
                                                  )
                                                }
                                              >
                                                No rating available
                                              </Typography>
                                            )}
                                          </Typography>
                                          {candidate?.city &&
                                          candidate?.country ? (
                                            <Typography className="mt-5 f-12 gray7">
                                              <Box className="f-12 gray7 font-weight-400 align-center d-flex">
                                                <LocationIcon
                                                  width="15px"
                                                  className="mr-5 gray7 f-12 font-weight-400 icon-margin"
                                                />{" "}
                                                {candidate.city}
                                                {candidate?.city
                                                  ? ","
                                                  : ""}{" "}
                                                {candidate.country}
                                                {/* {candidate.city}
                                          {candidate.city?.length > 0
                                            ? ", "
                                            : ""} */}
                                                {/* {candidate.state}
                                          {candidate.state?.length > 0
                                            ? ", "
                                            : ""}{" "} */}
                                                {/* {candidate.country}
                                          {!candidate.city &&
                                            !candidate.country &&
                                            "No location"} */}
                                              </Box>
                                            </Typography>
                                          ) : (
                                            <Typography className="pt-20"></Typography>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              {/* <TableCell align="left">
                                <Grid item className="">
                                  {candidate?.talentPool === "Z" && (
                                    <span className="priorityStatus f-14 font-weight-400 gray9">
                                      Priority
                                    </span>
                                  )}
                                  {candidate?.talentPool === "P" && (
                                    <span className="myCandidate-Ongoing f-14 font-weight-400 gray9">
                                      Private
                                    </span>
                                  )}
                                  {!candidate?.talentPool && (
                                    <span className="f-14 font-weight-400 gray9">
                                      -
                                    </span>
                                  )}
                                </Grid>
                              </TableCell> */}
                              <TableCell align="left">
                                <Grid item>
                                  <Box className="f-14 font-weight-400 gray10 align-center d-flex">
                                    <>
                                      {candidate?.overallYearsOfExperience >=
                                      2 ? (
                                        <span>
                                          {candidate?.overallYearsOfExperience}{" "}
                                          Years
                                        </span>
                                      ) : (
                                        <span>
                                          {candidate?.overallYearsOfExperience}{" "}
                                          Year
                                        </span>
                                      )}
                                    </>
                                  </Box>
                                </Grid>
                              </TableCell>
                              <TableCell align="left">
                                <Grid item>
                                  <Typography className="gray9 f-14 font-weight-400">
                                    {candidate.ratePerHour
                                      ? `${
                                          candidate.currency
                                            ? candidate.currency
                                            : "USD"
                                        } ${candidate.ratePerHour} / hour`
                                      : "-"}
                                  </Typography>
                                </Grid>
                              </TableCell>
                              {/* <TableCell align="left">
                                <Grid item>
                                  <Grid item>
                                    <Grid container item spacing={1}>
                                      <Grid item lg={12} md={12} xs={12}>
                                        <Grid
                                          item
                                          className="d-flex align-center"
                                        >
                                          {candidate?.skills.length > 0
                                            ? candidate?.skills?.map(
                                                (skill, index) => (
                                                  <>
                                                    {index < 1 && (
                                                      <span
                                                        className="skills"
                                                        style={{}}
                                                      >
                                                        {skill.skills.keyword}
                                                      </span>
                                                    )}
                                                  </>
                                                )
                                              )
                                            : "-"}
                                          <Typography>
                                            {candidate?.skills?.length > 1 && (
                                              <span
                                                className="link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                                onClick={(e) =>
                                                  handleViewAllSkills(
                                                    e,
                                                    candidate
                                                  )
                                                }
                                                onKeyPress={(e) =>
                                                  handleViewAllSkills(
                                                    e,
                                                    candidate
                                                  )
                                                }
                                                tabIndex={0}
                                              >
                                                {`+${
                                                  candidate.skills?.length - 1
                                                } more`}
                                              </span>
                                            )}
                                          </Typography>

                                          {candidate?.candidateSkills?.map(
                                            (skill, index) => (
                                              <>
                                                {index < 1 && (
                                                  <span className="skills">
                                                    {skill.skills.keyword}
                                                  </span>
                                                )}
                                              </>
                                            )
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Popover
                                    id="simple-popover-list"
                                    open={openSkills}
                                    anchorEl={anchorEl2}
                                    onClose={handleCloseSkillsPopover}
                                    anchorOrigin={{
                                      vertical: "center",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "center",
                                      horizontal: "right",
                                    }}
                                  >
                                    <Grid item sx={{ p: 1 }}>
                                      <Box className="align-between">
                                        <Grid item xs={12}>
                                          <Typography
                                            id="modal-title"
                                            className="d-flex align-between p-10"
                                          >
                                            <Typography
                                              variant="h5"
                                              className="f-16 font-weight-600"
                                            >
                                              Skills
                                            </Typography>
                                            <Typography
                                              align="right"
                                              className="cursor-hover gray7 icon-button"
                                            >
                                              <CloseIcon
                                                onClick={
                                                  handleCloseSkillsPopover
                                                }
                                                onKeyPress={
                                                  handleCloseSkillsPopover
                                                }
                                                aria-label="Close icon"
                                                tabIndex={0}
                                              />
                                            </Typography>
                                          </Typography>
                                        </Grid>
                                      </Box>
                                      <Grid
                                        item
                                        className="p-10"
                                        style={{
                                          maxHeight: "250px",
                                          overflow: "auto",
                                          maxWidth: "300px",
                                        }}
                                      >
                                        <Box>
                                          {candidateState?.skills?.map(
                                            (skill, index) => {
                                              return (
                                                <span
                                                  key={index}
                                                  className="skills"
                                                >
                                                  {skill.skills.keyword}
                                                </span>
                                              );
                                            }
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Popover>
                                </Grid>
                              </TableCell> */}
                              <TableCell align="left">
                                <Grid item>
                                  <Typography className="d-flex matching-location-align candidate-info-align matching-details-align skill-labl nav-user-img">
                                    <AvailabilityStatusLabels
                                      candidate={candidate}
                                      candidateProfileDrawer={candidateProfileDrawer}
                                      setCandidateProfileDrawer={setCandidateProfileDrawer}
                                      setCandidateListId={setCandidateListId}
                                      candidate_id={candidateListId}
                                      searchDate={searchDate}
                                    />
                                  </Typography>
                                </Grid>
                              </TableCell>
                              {isAuthenticated && (localStorage.getItem("userType") != "A") && (
                                <TableCell align="left">
                                  <Grid className="d-flex align-center justify-end">
                                    <Typography className="d-flex">
                                      <Rehire user={candidate} />
                                    </Typography>
                                    <Typography className="ml-10 global-job">
                                      <CandidateListActionButton
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoadingTable}
                                        candidate={candidate}
                                      />
                                    </Typography>
                                  </Grid>
                                  {/* <Grid item className="d-flex align-center">
                                <Typography className="link-color f-12 font-weight-400 mr-5">
                                  <ControlCheckboxGroup
                                    title={"Jobs"}
                                    buttonTitle={"Shortlist"}
                                    submitButtonTitle={"Shortlist"}
                                    candidateId={candidate.id}
                                    className={"shortList"}
                                    candidateName={
                                      candidate.firstName +
                                      " " +
                                      candidate.lastName
                                    }
                                  />
                                </Typography>
                                <Grid item onClick={() => handleOpenAddTalentPool(candidate)}>
                                  <Typography className="link-color f-14 font-weight-400">
                                    Add to Talent Pool
                                  </Typography>
                                </Grid>
                              </Grid> */}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* </InfiniteScroll> */}
                </Grid>
                {candidateResponse.length !== 0 && (
                  <>
                    {" "}
                    <Grid
                      container
                      item
                      spacing={1}
                      className="align-between align-center pagenation-design mt-15 pb-20"
                    >
                      {candidateResponse[0].totalCount > 10 && (
                        <>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            xs={12}
                            className="pagenation-table pt-5"
                          >
                            <Typography className="d-flex f-14 align-center font-weight-500">
                              <Box className="gray7">Showing</Box>
                              <span className="count-jobs mx-6 p-5">10</span>
                              <Box className="gray7">candidates per page</Box>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            xs={12}
                            className="justify-end d-flex pagenation-table navbarUser pt-5"
                          >
                            <Stack spacing={2} className="pagenation-joblist">
                              <Pagination
                                shape="rounded"
                                className="position-issue"
                                // color="primary"
                                rowsperpage={rowsperpage}
                                count={
                                  Math.floor(
                                    candidateResponse[0].totalCount / 10
                                  ) +
                                  (candidateResponse[0].totalCount % 10 === 0
                                    ? 0
                                    : 1)
                                }
                                page={pageNumber}
                                onChange={handleChangePage}
                                renderItem={(item) => (
                                  <PaginationItem
                                    components={{
                                      last: (props) => (
                                        <button
                                          className="btn primary-button profileButton"
                                          {...props}
                                        >
                                          Last
                                        </button>
                                      ),

                                      next: (props) => (
                                        <Box
                                          className="d-flex align-center cursor-hover"
                                          {...props}
                                        >
                                          <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                            Next
                                          </Box>
                                          <ArrowForwardIcon
                                            height="13px"
                                            width="13px"
                                          />{" "}
                                        </Box>
                                      ),

                                      first: (props) => (
                                        <Box
                                          className="d-flex align-center cursor-hover"
                                          {...props}
                                        >
                                          <ArrowBackIcon
                                            height="13px"
                                            width="13px"
                                          />{" "}
                                          <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                            Previous
                                          </Box>
                                        </Box>
                                      ),

                                      previous: (props) => (
                                        <Box
                                          className="d-flex align-center cursor-hover"
                                          {...props}
                                        >
                                          <ArrowBackIcon
                                            height="13px"
                                            width="13px"
                                          />{" "}
                                          <Box className="gray7 f-14 font-weight-500 ml-10 mr-20">
                                            Previous
                                          </Box>
                                        </Box>
                                      ),
                                    }}
                                    {...item}
                                  />
                                )}
                                showFirstButton={false}
                                showLastButton={false}
                              />
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                {/* )} */}
              </Grid>
            </Grid>
          )}
        </>
      )}
      {/* Send Message */}
      <Modal
        open={openSendMessage}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Grid container item id="modal-title">
            <Grid item xs={6}>
              <Typography className="f-20 font-weight-600 heading-text-color modalTitle">
                Send Message to candidate
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleCloseChat}
                  onKeyPress={handleCloseChat}
                  tabIndex={0}
                  aria-label="close icon"
                />
              </Typography>
            </Grid>
          </Grid>

          {/* <form> */}
          <Grid item id="modal-description">
            <Grid container item spacing={1}>
              <Grid item lg={12} md={12} xs={12}>
                <Box>
                  <Typography className="small-text-font m-b-0 font-weight-500">
                    Type your message<span className="text-black">*</span>{" "}
                  </Typography>
                  <TextField
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    onKeyPress={handleSpaces}
                    variant="outlined"
                    aria-label="minimum height"
                    multiline
                    rows={5}
                    rowsmax={10}
                    type="text"
                    margin="normal"
                    name="applicationRemarks"
                    className="form-control input-group textarea-description p-10 inputcontrasts"
                    placeholder="Write a note to the candidate"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item id="modal-footer" className="justify-center d-flex mt-20">
            <Button
              onClick={() => {
                // sendMeassage()
              }}
              className="btn primary-button profileButton"
              variant="contained"
              aria-label="Send message"
              disableripple={true}
            >
              Send Message
            </Button>
          </Grid>
          {/* </form> */}
        </Box>
      </Modal>

      {/* Advance filter */}
      <AdvanceFilter
        resetAll={resetAll}
        openAdvanceFilter={openAdvanceFilter}
        handleOpenAdvanceFilter={handleOpenAdvanceFilter}
        handleCloseAdvanceFilter={handleCloseAdvanceFilter}
        setOpenAdvanceFilter={setOpenAdvanceFilter}
        filterMaxExperience={filterMaxExperience}
        filterMinExperience={filterMinExperience}
        // applyAdvanceFilter={applyAdvanceFilter}
        setQueryAdvanceFilterString={setQueryAdvanceFilterString}
        setAdvanceFilterObj={setAdvanceFilterObj}
        advanceFilterObj={advanceFilterObj}
        selectedjobTitleFilter={selectedjobTitleFilter}
        sortValues={sortValues}
        sort={sort}
        selectedjobTitleFilterType={selectedjobTitleFilterType}
        selectedSkillId={selectedSkillId}
        selectedChecklistId={selectedChecklistId}
      />
      <CandidateProfileVideoPreview
        open={openVideoPreview}
        setOpen={setOpenVideoPreview}
        videoSrc={videoSrc}
        candidateName={candidateName}
      />
      <ReviewModal
        openReviewModal={openReviewModal}
        setOpenReviewModal={setOpenReviewModal}
        reviewDetails={reviewDetails}
        candidateId={selectedCandidateId}
      />
      {candidateListId && (
        <EnterpriseCandidateDescriptionDrawer
          candidateProfileDrawer={candidateProfileDrawer}
          setCandidateProfileDrawer={setCandidateProfileDrawer}
          candidate_id={candidateListId}
          setCandidateListId={setCandidateListId}
          searchDate={searchDate}
          setIsLoading={setIsLoading}
          isFromGlobalSearch={true}
          candidate={candidateInfo}
        />
      )}
    </>
  );
}
