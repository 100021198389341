import ApiCalls from "../../api/customAxios";
class ProfileServiceClass {
  candidateProfileBaseUrl = process.env.REACT_APP_API_URL + "enterprises/pool/candidates";
  publicSkillsUrl = `${process.env.REACT_APP_API_URL.replace(
    "api/v1/",
    "public/api/v1/"
  )}skills?keyword=`;
  getCandidateReviewUrl =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "enterprises/candidate-review/";
  locations = process.env.REACT_APP_API_URL + "places/";
  aiGeneratedProfileSummaryUrl = process.env.REACT_APP_API_URL + "candidates/get/ai/generated/profile/summary";
  skillCertificationsUrl = process.env.REACT_APP_API_URL + "skills/certifications?keyword=";
  getCertificationsUrl = process.env.REACT_APP_API_URL + "candidates/certifications/";

  getProfile(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id);
  }

  updateProfile(payload) {
    return ApiCalls.put(this.candidateProfileBaseUrl, payload);
  }

  // work histoy
  getAllWorkhistories(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id + "/work-history");
  }
  addWorkhistory(payload, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/work-history", payload);
  }
  deleteWorkhistory(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/work-history/" + id);
  }
  deleteWorkExperienceDocument(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/work-history/showcases/" + id);
  }
  // education
  getAllEducations(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id + "/educations");
  }
  addEducation(payload, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/educations", payload);
  }
  deleteEducation(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/educations/" + id);
  }

  deleteEducationDocument(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/educations/documents/" + id);
  }

  // certification
  getAllCertifications(candidate_id) {
    return ApiCalls.get(this.getCertificationsUrl + candidate_id);
  }
  addCertification(payload, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/certifications", payload);
  }
  deleteCertification(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/certifications/delete/" + id);
  }

  // licensure
  getAllLicensures(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id + "/licences");
  }
  addLicensure(payload, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/licences", payload);
  }
  deleteLicensure(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/licences/" + id);
  }

  // references
  getAllReferences(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id + "/references");
  }
  addReference(payload, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/references", payload);
  }
  deleteReference(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/references/" + id);
  }

  uploadFile(payload, candidate_id) {
    return ApiCalls.post(this.candidateProfileBaseUrl + "/" + candidate_id + "/upload-file", payload);
  }

  // resume
  getAllResume(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id + "/resume");
  }
  uploadResume(payload, candidate_id) {
    return ApiCalls.post(this.candidateProfileBaseUrl + "/" + candidate_id + "/resume", payload);
  }

  uploadResumeMail(payload, candidate_id) {
    return ApiCalls.post(this.candidateProfileBaseUrl + "/" + candidate_id + "/resume/mail", payload);
  }

  deleteResume(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/resume/" + id);
  }

  // skills
  getSkills(keyword) {
    // this is public base skills
    return ApiCalls.get(this.publicSkillsUrl + keyword);
  }

  // get candidate skills
  getCandidateSkills(candidate_id) {
    return ApiCalls.get(this.candidateProfileBaseUrl + "/" + candidate_id + "/skills");
  }

  updateSkills(payload, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/skills", payload);
  }

  deleteSkill(id, candidate_id) {
    return ApiCalls.delete(this.candidateProfileBaseUrl + "/" + candidate_id + "/skills/" + id);
  }

  async getCandidateReview(id) {
    return ApiCalls.get(this.getCandidateReviewUrl + id);
  }

  async putCandidateRemark(jobId, remark, candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/pool/candidate-remark", {
      jobId: jobId,
      ratingRemarksByCandidate: remark,
    });
  }
  async getLocations(query) {
    return ApiCalls.get(this.locations + query);
  }
  async eraseDOB(candidate_id) {
    return ApiCalls.put(this.candidateProfileBaseUrl + "/" + candidate_id + "/erase/date-of-birth");
  }
  async getAiGeneratedProfileSummary(payload) {
    return ApiCalls.post(this.aiGeneratedProfileSummaryUrl, payload);
  }
  async getSkillCertifications(keyword) {
    return ApiCalls.get(this.skillCertificationsUrl + keyword);
  }
}

const ProfileService = new ProfileServiceClass();
export default ProfileService;
