import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Popover,
  Typography,
  Tooltip,
  Modal,
  Rating,
  Checkbox,
  Tab,
  Stack,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import cellIcon from "../../../assets/images/cellIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import bgImage from "../../../assets/images/matchingBg.svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import microsoftLogo from "../../../assets/images/microsoftLogo.svg";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import Loader from "../../Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AccessService from "../../../Services/Enterprise/Access.service";
import moment from "moment";
import Skills from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/Skills";
import ActionButtons from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/ActionButtons";
import Education from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/Education";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import hourlyRateImage from "../../../assets/images/hourlyRate-profile.svg";
import overallExp from "../../../assets/images/overallExp-profile.svg";
import viewAssessment from "../../../assets/images/view-assessment.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { functiondisable, functionenable } from "../../../helpers/helper";
import * as Constants from "../../../helpers/constants";
import RatingsAndReviews from "./_JobCandidateDetailsPartials/RatingsAndReviews";
import starEmpty from "../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../assets/images/star-icon-filled.svg";
import workLogo from "../../../assets/images/workExp.svg";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CandidateProfileVideoPreview from "../../../Components/shared/CandidateProfileVideoPreview";
import { checkIsImage, dateFormat, formatDate, getCandidateShiftTimings, PreferredCommunicationTooltip } from "../../../helpers/helper-data";
import playIcon from "../../../assets/images/playIcon.svg";
import licensure from "../../../assets/images/licensure.svg";
import references from "../../../assets/images/references.svg";
import linkIcon from "../../../assets/images/link-icon.svg";
import ChecklistForm from "./Checklist/ChecklistForm";
import ApiCalls from "../../../api/customAxios";
import checklistIndustry from "../../../assets/images/checklist-new-icon.svg";
import { success, danger } from "../../../helpers/message-box";
import { Helmet } from "react-helmet";
import hourlyRateIcon from "../../../assets/images/payRate-new-icon.svg";
import overExpIcon from "../../../assets/images/exp-new-icon.svg";
import downloadIcon from "../../../assets/images/download-icon.svg";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import availabilityIcon from "../../../assets/images/pool-availability.svg";
import InfoIcon from "@mui/icons-material/Info";
import WorkplaceIcon from "../../../assets/images/workplace.svg";
import WorkTypeIcon from "../../../assets/images/worktype.svg";
import Certification from "./Certification";
import ReferenceName from "./ReferenceName";
import ratingIcon from "../../../assets/images/rating-profile-icon.svg";
import { ReactComponent as PrivateIcon } from "../../../assets/images/private-pool-icon.svg";
import { ReactComponent as PriorityIcon } from "../../../assets/images/priority-pool-icon.svg";
import { ReactComponent as PriorityIconGreen  } from "../../../assets/images/starRename.svg";
import { ReactComponent as NewBriefCase } from "../../../assets/images/new-brief-case.svg";
import { ReactComponent as NewLocation } from "../../../assets/images/new-location.svg";
import { ReactComponent as OccupiedIcon } from "../../../assets/images/occupied-calendar.svg";
import { ReactComponent as BacktoJob } from "../../../assets/images/back-to-job.svg";
import { ReactComponent as ReferralIcon } from "../../../assets/images/ReferralIcon.svg";
import TimelineCandidate from "./_JobCandidateDetailsPartials/TimelineCandidate";
import Notes from "./_JobCandidateDetailsPartials/Notes";
import disciplineIcon from "../../../assets/images/education-new-icon.svg";
import specializationIcon from "../../../assets/images/specializationIcon.svg";
import shiftPreferenceIcon from "../../../assets/images/shift-preferance-img.svg";
import jobPreferenceIcon from "../../../assets/images/job-preferance-icon.svg";
import startDateIcon from "../../../assets/images/hc-start-date-icon.svg";
import { useSelector } from "react-redux";
import nueroIcon from "../../../assets/images/nuero-icon.svg";
import { TooltipTrigger, TooltipContent } from "react-tippy";
import AvailabilityStatusLabels from "../../../Components/shared/AvailabilityStatusLabels";
import KeycloakService from "../../../keycloak";
import ProjectLabelEnhancement from "./_JobCandidateDetailsPartials/ProjectLabelEnhancement";
import DocumentListing from "./DocumentListing";
import { ReactComponent as AddSkills } from "../../../assets/images/addSkills.svg";
import UploadResumeModal from "./UploadResumeModal";
import FileUploadModal from "./FileUploadModal";
import InfiniteScroll from "react-infinite-scroll-component";
import CandidateListActionButton from "./CandidateListActionButton";
import EditTalentActionButton from "../TalentPool/EditTalentActionButton";
import MyCandidateListActionButton from "../MyCandidate/CandidateListActionButton";
import MessageDialogModel from "../../../Components/shared/MessageDialogModel";
import { ReactComponent as ActionIcon } from "../../../assets/images/action-cta-icon.svg";
import TooltipShared from "../../../Components/shared/TooltipShared";
import GdprRestrictDownloadModal from "../../../Components/shared/GdprRestrictDownloadModal";
import GdprRestrictEditCandidateModal from "../../../Components/shared/GdprRestrictEditCandidateModal";
import ContactInfo from "./ContactInfo";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function EnterpriseCandidateDescription(props) {
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  const appOrigin = useSelector((state) => state.location.origin);
  const [specializationString, setSpecializationString] = useState("");
  const anchorRef = useRef(null);
  const [openGDPREditCandidateRestrictPopup, setOpenGDPREditCandidateRestrictPopup] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [confirmSendSms, setConfirmSendSms] = React.useState(false);
  const [message, setMessage] = useState("");
  const [openGDPRDownloadRestrictPopup, setOpenGDPRDownloadRestrictPopup] = useState(false);

  const handleOpenJobType = (event) => {
    setAnchorEl2(event.currentTarget);
    functiondisable();
  };

  const handleCloseJobType = () => {
    setAnchorEl2(null);
    functionenable();
  };

  const openJobType = Boolean(anchorEl2);
  // const [valueTabs, setValueTabs] = React.useState('1');

  // const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
  //   setValueTabs(newValue);
  // };
  const [valueTabs, setValueTabs] = React.useState("basic info");
  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
    setEnterpriseSelectedFiles([]);
    setEnterpriseSelectedResumes([]);
    setAllDocuments([]);
    setPageNumber(0);
    setResumeDocuments([]);
    setOtherDocuments([]);
  };
  const [value, setValue] = useState(4);
  let navigate = useNavigate();
  const isAuthenticated = KeycloakService.isLoggedIn();
  const { state } = useLocation();
  const LicenseStatus = {
    A: "Current License",
    I: "Inactive License",
    P: "License Applied",
  };
  const LicenseType = {
    L: "License is limited",
    U: "License is unlimited",
  };
  let candidateApplicationD;
  if (state) {
    candidateApplicationD = state.candidateApplicationData;
  } else {
    candidateApplicationD = undefined;
  }
  // const { candidateApplicationData } = state;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAssessment, setOpenAssessment] = React.useState(false);
  const handleOpenAssessment = () => setOpenAssessment(true);
  const handleCloseAssessment = () => setOpenAssessment(false);

  const [openOffer, setOpenOffer] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMoreWorkSummary, setShowMoreWorkSummary] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [job, setJob] = React.useState({});
  const [candidate, setCandidate] = React.useState({});
  const [candidateApplicationData, setCandidateApplication] = useState(
    candidateApplicationD === null ? undefined : candidateApplicationD
  );
  const [candidateChecklist, setCandidateChecklist] = useState([]);
  const [selectedCandidateChecklist, setSelectedCandidateChecklist] = useState(
    []
  );
  const [showCandidateChecklistOverlay, setShowCandidateChecklistOverlay] =
    useState(false);
  const [shortlistingRemarks, setShortlistingRemarks] = useState("");
  const [answers, setAnswers] = useState([]);
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [openRatingAndReview, setRatingAndReview] = useState(true);
  const ratingAndReviewsRef = React.useRef(null);

  const [videoSrc, setVideoSrc] = useState(null);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    profile: true,
    checkList: [],
    resumes: [],
  });
  const [openAddResumeModal, setOpenAddResumeModal] = useState(false);
  const [openAddFileModal, setOpenAddFileModal] = useState(false);
  const [resumeDocuments, setResumeDocuments] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [enterpriseSelectedResumes, setEnterpriseSelectedResumes] = useState([]);
  const [enterpriseSelectedFiles, setEnterpriseSelectedFiles] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [allDocuments, setAllDocuments] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  // const [searchParams, setSearchParams] = useSearchParams();
  const intervalRef = useRef(null);
  const resumeRef = useRef(null);
  const handleOpenVideoPreview = (profileVideo, candidateName) => {
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  };
  const openRatingAndReviewSection = () => {
    setRatingAndReview(true);
    // setRatingAndReview(!openRatingAndReview);
    if (openRatingAndReview) {
      ratingAndReviewsRef?.current?.scrollIntoView();
    }
  };
  const translateLicenseStatus = (status) => {
    return LicenseStatus[status] || status;
  };
  const translateLicenseType = (type) => {
    return LicenseType[type] || type;
  };
  /* React.useEffect(()=>{
         if(openRatingAndReview)
         {
             ratingAndReviewsRef?.current?.scrollIntoView();
         }
     },[openRatingAndReview])*/

     const sendSmsToCandidates = async() => {
      setIsLoading(true);
      var payload = {
        message: message,
      };
      setConfirmSendSms(false);
      await AccessService.sendSMS(params.candidate_id, payload).then((res)=>{
        if (res) {
          setIsLoading(false);
          success("SMS sent successfully to the candidate.");
        } else {
          setIsLoading(false);
          success("Something went wrong.");
        }
        setMessage("");
      }).catch((res)=>{
        setIsLoading(false);
        setMessage("");
      });
    };

  const handleGotoJobsList = () => {
    navigate("/enterprise/JobList");
  };
  useEffect(()=>{
    if (candidate?.workHistory?.length) {
      setShowMoreWorkSummary(Array.from({ length: candidate?.workHistory?.length }, ()=>false));
    } else {
      setShowMoreWorkSummary([]);
    }
  }, [candidate?.workHistory]);
  useEffect(() => {
    if (valueTabs === "download") {
      const jobIdParam = params.job_id ? params.job_id : undefined;
      setIsLoading(true);
      AccessService.getResumeDocuments(pageNumber, size, localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"), params.candidate_id, jobIdParam).then((resp) => {
        if (resp?.length > 0) {
          let newResumeDocuments = [];
          let newOtherDocuments = [];
          if (resp?.length < 10) {
            setLoadMore(false);
          } else {
            setLoadMore(true);
          }
          setAllDocuments(resp);
          resp.forEach((data) => {
            if (data.documentCategory === "R") {
              newResumeDocuments.push(data);
            } else {
              newOtherDocuments.push(data);
            }
          });
          setResumeDocuments(newResumeDocuments);
          setOtherDocuments(newOtherDocuments);
        } else {
          setResumeDocuments([]);
          setOtherDocuments([]);
        }
        setIsLoading(false);
      }).catch((error) => {
        console.error("Error fetching documents:", error);
        setIsLoading(false);
      });
    }
  }, [valueTabs, params.candidate_id]);

  const handleGotoJob = () => {
    navigate(
      "/enterprise/jobs/" +
        params.job_id +
        "/matching-candidates?tab=" +
        params.status
    );
  };
  useEffect(() => {
    //for capturing status
    let data = {
      candidateId: params.candidate_id,
      isProfileViewed: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  }, []);

  // useEffect(async () => {
  //   if (isAuthenticated) {
  //     setIsLoading(true);
  //     await ApiCalls.get(`enterprises/skillChecklist/${params.candidate_id}`)
  //       .then((response) => {
  //         if (response) {
  //           setCandidateChecklist(
  //             response.filter((chkList) => chkList.status !== "D")
  //           );
  //         }
  //         setIsLoading(false);
  //       })
  //       .catch(function (error) {
  //         setIsLoading(false);
  //       });
  //   }
  // }, []);

  useEffect(async () => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const enterpriseId = searchParams.get("enterpriseid");
    await (enterpriseId
      ? ApiCalls.get(
          `enterprises/user/my-candidates/details/${
            params.candidate_id
          }?enterpriseCandidateId=${localStorage.getItem(
            "candidateId"
          )}&enterpriseId=${localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")}`
        )
      : CandidateService.getCandidateDetails(params.candidate_id)
    )
      .then((response) => {
        setCandidate(response);
        let specialization = [];
        response?.skills?.map((skill, index) => {
          specialization.push(skill.skills.keyword);
        });
        setSpecializationString(specialization.join(", "));
        setCandidateApplication(response);
        if (isAuthenticated && response?.candidateSkillChecklistResponse?.length > 0) {
          setCandidateChecklist(
            [...response.candidateSkillChecklistResponse]
          );
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }, []);

  // useEffect(async () => {
  //     setIsLoading(true);
  //     await CandidateService.getAssessmentReponse(params.job_id, params.candidate_id)
  //         .then((response) => {
  //             if(response.data.answers !== null){
  //                 setAnswers(JSON.parse(response.data.answers));
  //             }
  //             setIsLoading(false);
  //         })
  //         .catch(function (error) {
  //             setIsLoading(false);
  //         });

  // }, []);

  function workDuration(workedFrom, workedTo) {
    var currentDate = moment().format("YYYY-MM-DD");
    var start = moment(workedFrom, "YYYY-MM-DD");
    var end = moment(
      workedTo === "1900-01-01" ? currentDate : workedTo,
      "YYYY-MM-DD"
    );
    var years = end.diff(start, "years");
    var months = end.diff(start, "months");
    var output = "";
    if (years > 0 && months === 0) {
      output =
        years === 0 ? "" : `${years} ${years === 1 ? "Year " : "Years "}`;
    }
    if (years === 0 && months > 0) {
      output =
        months === 0
          ? "1 month"
          : `${months} ${months === 1 ? "month " : "months "}`;
    }
    if (years === 0 && months === 0) output = "1 month";
    if (years > 0 && months % years === 0) {
      output = `${years} ${years === 1 ? "Year " : "Years "}`;
    }
    if (years > 0 && months % 12 > 0) {
      output =
        `${years} ${years === 1 ? "Year " : "Years "}` +
        `${months % 12} ${months % 12 === 1 ? "month " : "months "}`;
    }

    return output;
  }
  // useEffect(()=>{
  //     if(!candidateApplicationData){
  //         setIsLoading(true);
  //         ApiCalls.get(`${process.env.REACT_APP_API_URL.replace('api/v1/', 'public/api/v1/')}candidates/`+params.candidate_id)
  //         .then(
  //             (response) => {
  //                 setCandidateApplication(response.data)
  //                 setIsLoading(false);
  //             },
  //             (error) => {
  //                 setIsLoading(false);
  //             })
  //     }
  // },[]);

  // const [candidate, setCandidate] = React.useState({});
  // const [isLoading, setIsLoading] = useState(false);
  // const params = useParams();

  // useEffect(async () => {
  //     setIsLoading(true);
  //     await CandidateService.getCandidateDetails(params.candidate_id)
  //         .then((response) => {
  //             setCandidate(response.data);
  //             setIsLoading(false);
  //         })
  //         .catch(function (error) {
  //             setIsLoading(false);
  //         });

  // }, []);

  const goToAssessment = () => {
    navigate("/enterprise/assessment");
  };

  const [candidatePopUp, setCandidatePopUp] = useState();
  const [preferredExp, setPreferredExp] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open3 = Boolean(anchorEl);
  const id = open3 ? "simple-popover" : undefined;

  const handleClick = (event, candidateApplicationD) => {
    setCandidatePopUp(candidateApplicationD);
    setAnchorEl(event.currentTarget);
    functiondisable();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    functionenable();
  };

  const getFormattedSkills = (skills) => {
    let s = skills.split(",");
    if (s.length > 1) {
      let skl = "";
      s.map((o, i) => {
        skl += o + ", ";
        if ((i + 1) % 2 === 0) {
          skl += "\n  ";
        }
      });

      return skl.trim().slice(0, -1);
    }

    return skills;
  };

  React.useEffect(() => {
    if (props.query?.includes("yearsOfExperience")) {
      let q = props.query?.split("&");
      q.map((x) => {
        if (x.includes("yearsOfExperience")) {
          setPreferredExp(x.split("=")[1] + " Years");
        }
      });
    } else {
      setPreferredExp(null);
    }
  }, [props.query]);
  if (!candidateApplicationData) {
    return <Loader />;
  }

  const ratingChanged = (newRating) => {};
  const ProflieStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: params.candidate_id,
      isProfileDownloaded: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  };
  const ResumeStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: params.candidate_id,
      isResumeDownloaded: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  };
  const DownloadProfile = () => {
    if ((candidate.candidateName === "**** ****") || (candidate.firstName === "****" &&
      (candidate.lastName !== null ||
      candidate.lastName !== "" ||
      candidate.lastName === "****"))) {
      setOpenGDPRDownloadRestrictPopup(true);
      return;
    }
    let obj = {
      profile: selectedValues.profile,
      checkList: selectedValues.checkList,
      enterpriseId: enterpriseDetails.id
    };
    if (selectedValues.profile || selectedValues.checkList.length > 0) {
      CandidateService.getPdfs(params.candidate_id, obj)
        .then((response) => {
          const data = [...response, ...selectedValues.resumes, ...enterpriseSelectedFiles, ...enterpriseSelectedResumes];
          downloadPdf(data);
          success("File downloaded successfully.");
        })
        .catch((error) => {
          danger("Something went wrong.");
        });
    }
    if (!selectedValues.profile && !selectedValues.checkList.length) {
      ResumeStatusCapture();
      const data = [...selectedValues.resumes, ...enterpriseSelectedFiles, ...enterpriseSelectedResumes];
      downloadPdf(data);
    }
    if (
      selectedValues.profile &&
      !selectedValues.resumes.length &&
      !selectedValues.checkList.length
    ) {
      ProflieStatusCapture();
    }
    if (
      (selectedValues.profile &&
        selectedValues.resumes.length &&
        selectedValues.checkList.length) ||
      (selectedValues.profile && selectedValues.resumes.length)
    ) {
      let data = {
        candidateId: params.candidate_id,
        isResumeDownloaded: true,
        isProfileDownloaded: true,
      };
      CandidateService.getStatus(data).then((res) => {});
    }
    setDownloadPopup(false);
    setSelectedValues({ profile: true, checkList: [], resumes: [] });
    setEnterpriseSelectedFiles([]);
    setEnterpriseSelectedResumes([]);
  };

  const handleSelectCheklist = (e, value) => {
    let check = e.target.checked;
    if (check) {
      selectedValues.checkList.push(value);
      setSelectedValues({
        ...selectedValues,
        checkList: [...selectedValues.checkList],
      });
    } else {
      var idsList = selectedValues.checkList.filter((compare) => {
        return compare != value;
      });
      setSelectedValues({ ...selectedValues, checkList: [...idsList] });
    }
  };
  const handleSelectResumes = (e, value) => {
    let check = e.target.checked;
    if (check) {
      selectedValues.resumes.push({
        name: value.title,
        urlSigned: value.urlSigned,
        id: value.resumeId,
      });
      setSelectedValues({
        ...selectedValues,
        resumes: [...selectedValues.resumes],
      });
    } else {
      var idsList = selectedValues.resumes.filter((compare) => {
        return compare.id != value.resumeId;
      });
      setSelectedValues({ ...selectedValues, resumes: [...idsList] });
    }
  };
  const handleSelectCheklist1 = (value) => {
    if (selectedValues.checkList && selectedValues.checkList.length > 0) {
      let list = selectedValues.checkList.filter((list) => list == value);
      if (list.length > 0) {
        var idsList = selectedValues.checkList.filter((compare) => {
          return compare != value;
        });
        setSelectedValues({ ...selectedValues, checkList: [...idsList] });
      } else {
        setSelectedValues({
          ...selectedValues,
          checkList: [...selectedValues.checkList, value],
        });
      }
    } else {
      setSelectedValues({
        ...selectedValues,
        checkList: [...selectedValues.checkList, value],
      });
    }
  };
  const handleSelectResumes1 = (value) => {
    if (selectedValues.resumes && selectedValues.resumes.length > 0) {
      let list = selectedValues.resumes.filter(
        (list) => list.id == value.resumeId
      );
      if (list.length > 0) {
        var idsList = selectedValues.resumes.filter((compare) => {
          return compare.id != value.resumeId;
        });
        setSelectedValues({ ...selectedValues, resumes: [...idsList] });
      } else {
        let x = {
          name: value.title,
          urlSigned: value.urlSigned,
          id: value.resumeId,
        };
        setSelectedValues({
          ...selectedValues,
          resumes: [...selectedValues.resumes, x],
        });
      }
    } else {
      let y = {
        name: value.title,
        urlSigned: value.urlSigned,
        id: value.resumeId,
      };
      setSelectedValues({
        ...selectedValues,
        resumes: [...selectedValues.resumes, y],
      });
    }
  };
  const downloadPdf = (data) => {
    function download_next(i) {
      if (i >= data.length) {
        return;
      }
      var a = document.createElement("a");
      a.href = data[i].urlSigned;
      if ("download" in a) {
        a.download = data[i].urlSigned;
      }
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click();
      } else {
        window.open(data[i].urlSigned);
      }
      a.parentNode.removeChild(a);
      setTimeout(function () {
        download_next(i + 1);
      }, 1500);
    }
    download_next(0);
  };
  const goToCandidatePage = () => {
    if (location.pathname.includes("my-candidate")) {
      navigate("/enterprise/my-candidate");
    } else if (location.pathname.includes("talent-pool-candidate-list")) {
      gotToAddCandidate();
    } else if (searchParams.get("talentPoolName")) {
      gotToTalentPoolView();
    } else if (searchParams.get("talentConsoleStatus")) {
      const encodedString = window.btoa(JSON.stringify({ label: JSON.parse(window.atob(searchParams.get("talentConsoleStatus")))?.listName, keyword: "" }));
      navigate("/enterprise/talent-console-candidates/?status=" + encodedString);
    } else {
      navigate(
        isAuthenticated
          ? "/enterprise/CandidateList" + location.search
          : "/CandidateList" + location.search
      );
    }
  };
  const gotToAddCandidate = () => {
    navigate("/enterprise/talent-pool-candidate-list" + location.search);
  };

  const gotToTalentPoolView = () => {
    navigate(
      `/enterprise/talent-pool/${
        searchParams.get("talentPoolId")
          ? searchParams.get("talentPoolId")
          : params.id
      }`
    );
  };
  const goToTalentPoolListing = () => {
    navigate(`/enterprise/talent-pool-management`);
  };
  const handleChangeReview = (newValue) => {
    setValueTabs(newValue);
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
  };
  const getFormattedWorkplace = (workPlace) => {
    let work_place = workPlace?.split(",");
    if (work_place.length === 0) {
      return "-";
    }
    let formatted_work_place = [];
    if (work_place.includes("R")) {
      formatted_work_place.push("Remote");
    }
    if (work_place.includes("O")) {
      formatted_work_place.push("Onsite");
    }
    if (work_place.includes("H")) {
      formatted_work_place.push("Hybrid");
    }
    return formatted_work_place.join(", ");
    // if (workPlace === "R") {
    //     return "Remote";
    // }
    // if (workPlace === "H") {
    //     return "Hybrid";
    // }
    // if (workPlace === "O") {
    //     return "Onsite";
    // }
  };
  const getFormattedWorkType = (workType, viewAll) => {
    let work_type = workType?.replace(/\s|\[|\]/g, "")
      ? workType.replace(/\s|\[|\]/g, "").split(",")
      : [];
    if (work_type.length === 0) {
      return "-";
    }
    let formatted_work_type = [];
    if (work_type.includes("FT")) {
      formatted_work_type.push("Full Time");
    }
    if (work_type.includes("PT")) {
      formatted_work_type.push("Part Time");
    }
    if (work_type.includes("FL")) {
      formatted_work_type.push("Contingent / Contract");
    }
    if (work_type.includes("IN")) {
      formatted_work_type.push("Internship");
    }
    if (work_type.includes("OT")) {
      formatted_work_type.push("Other");
    }
    if (!viewAll && formatted_work_type.length > 1) {
      formatted_work_type = formatted_work_type.slice(0, 1);
    }
    return formatted_work_type.join(", ");
  };
  const getFormattedShift = (preferredShift) => {
    if (preferredShift === "D") {
      return "Day Shift";
    } else if (preferredShift === "E") {
      return "Evening Shift";
    } else if (preferredShift === "N") {
      return "Night Shift";
    }
    return "Flexible Shift";
  };
  const handleOpenJobPreference = (event) => {
    setAnchorEl3(event.currentTarget);
    functiondisable();
  };

  const handleCloseJobPreference = () => {
    setAnchorEl3(null);
    functionenable();
  };

  const openJobPreference = Boolean(anchorEl3);

  const handleOpenSpecialization = (event) => {
    setAnchorEl4(event.currentTarget);
    functiondisable();
  };

  const handleCloseSpecialization = () => {
    setAnchorEl4(null);
    functionenable();
  };

  const openSpecialization = Boolean(anchorEl4);
  const getExclusiveEnterprise = (id) => {
    const currentEnterprise = localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId");
    const enterprisesList = JSON.parse(localStorage.getItem("enterprisesList"));
    const childEnterprisesList = JSON.parse(localStorage.getItem("mspEnterprsieData"))?.childEnterprises;
    const childEnterprise = childEnterprisesList?.find((ent) => ent.id == id);
    const enterprise = enterprisesList?.find((ent) => ent.id == id);
    return currentEnterprise != id ? (enterprise ? "To " + enterprise?.establishmentName : childEnterprise ? "To " + childEnterprise?.establishmentName : "") : "";
  };
  const goToProjectTab = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValueTabs("ProjectDetails");
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
  };
  const addNewResumeDocument = () => {
    setOpenAddResumeModal(true);
  };
  const addNewFileDocument = () => {
    setOpenAddFileModal(true);
  };
  const fetchMoreDocumentsOthers = () => {
    setIsLoading(true);
    let newPageNo = pageNumber + 1;
    setPageNumber(newPageNo);
    const jobIdParam = params.job_id ? params.job_id : undefined;
      AccessService.getResumeDocuments(newPageNo, size, localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"), params.candidate_id, jobIdParam).then((resp) => {
      if (resp?.length < 10) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      if (resp?.length > 0) {
        let newResumeDocuments = [];
        let newOtherDocuments = [];
        setAllDocuments([...allDocuments, resp]);
        resp.forEach((data) => {
          if (data.documentCategory === "R") {
            newResumeDocuments.push(data);
          } else {
            newOtherDocuments.push(data);
          }
        });
        setResumeDocuments([...resumeDocuments, ...newResumeDocuments]);
        setOtherDocuments([...otherDocuments, ...newOtherDocuments]);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error("Error fetching documents:", error);
      setIsLoading(false);
    });
  };
  const handleToggle = () => {
    setOpenStatus((prevOpen) => !prevOpen);
  };
  const handleCloseStatus = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenStatus(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenStatus(false);
    } else if (event.key === "Escape") {
      setOpenStatus(false);
    }
  }
  const goToEditCandidate = (e, candidate) => {
    e.preventDefault();
    setOpenStatus(false);
    if (candidate.candidateName === "**** ****" || (candidate.firstName === "****" && candidate.lastName === "****")) {
      setOpenGDPREditCandidateRestrictPopup(true);
      return;
    }
    var candidate_id = candidate.candidateId ? candidate.candidateId : candidate.id ? candidate.id : params.candidate_id;
    candidate_id = window.btoa(candidate_id);
    window.open(
      `/enterprise/edit-candidate-details/${candidate_id}`,
      "_blank"
    );
  };
  const wageExpectations = {
    HR: "Hourly",
    DA: "Daily",
    WK: "Weekly",
    FN: "Fortnightly",
    MN: "Monthly",
    QA: "Quarterly",
    HY: "Half-Yearly",
    AL: "Annually",
    EP: "End of Project",
  };
  return (
    <>
      <Helmet>
        {appOrigin === "N" ? (
          <title>{`${candidate?.firstName} ${
            candidate.lastName ? candidate.lastName : ""
          } | ${Constants.APP_NAME_NEURODIVERSIFY}`}</title>
        ) : appOrigin === "H" ? (
          <title>{`${candidate?.firstName} ${
            candidate.lastName ? candidate.lastName : ""
          } | ${Constants.APP_NAME_DIRECT_HEALTH}`}</title>
        ) : (
          <title>{`${candidate?.firstName} ${
            candidate.lastName ? candidate.lastName : ""
          } | ${Constants.APP_NAME_GENERAL}`}</title>
        )}
        {/* <title>{`${candidate?.firstName} ${
          candidate.lastName ? candidate.lastName : ""
        } | hireHQ`}</title> */}
        <meta name="description" content={`Candidate Detail | ${Constants.APP_NAME_GENERAL}`} />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Grid
        container
        item
        lg={isAuthenticated ? 12 : 11.5}
        md={isAuthenticated ? 12 : 11.5}
        className={
          isAuthenticated ? "m-0 responsive-block" : "m-0 responsive-block"
        }
      >
        {isLoading && <Loader />}
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className={
            isAuthenticated
              ? "p-t-b-10 pr-5 p-relative mt-20"
              : "p-t-b-10 p-relative mt-20"
          }
          id="enterprise-candidate"
          // sx={{ mt: 4 }}
        >
          <Grid className="" sx={{ pb: 2 }}>
            <Grid className="job-list-header pt-20 pl-20 pr-20" sx={{ pb: 9 }}>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={
                  <NavigateNextIcon fontSize="small" aria-label="Next icon" />
                }
                className="pt-0"
              >
                {/* {!(params.id || location.pathname.includes("talent-pool-candidate-list")) && <Link
                  onClick={goToCandidatePage}
                  underline="hover"
                  className="cursor-hover font-weight-500"
                  to="#"
                  tabIndex={0}
                  onKeyPress={goToCandidatePage}
                >
                  <GroupOutlinedIcon sx={{ mr: 0.5 }} aria-label="List icon" />
                  {location.pathname.includes("my-candidate") ?
                    "Candidates" : "Candidates List"
                  }
                </Link>} */}

                {/* {params.id &&
                  <Link
                    onClick={goToTalentPoolListing}
                    underline="hover"
                    className="cursor-hover font-weight-500"
                    to="#"
                    onKeyPress={goToTalentPoolListing}
                    tabIndex={0}
                    variant="h5">
                    Talent Pool Management
                  </Link>} */}
                {/* {(params.id || location.pathname.includes("talent-pool-candidate-list")) &&
                  <Link
                    onClick={gotToTalentPoolView}
                    underline="hover"
                    className="cursor-hover font-weight-500"
                    to="#"
                    onKeyPress={gotToTalentPoolView}
                    tabIndex={0}
                    variant="h5">
                    {searchParams.get("talentPoolName")}
                  </Link>} */}
                <Link
                  onClick={goToCandidatePage}
                  className="link-color font-weight-600 cursor-hover"
                >
                  <BacktoJob className="mr-10" />
                  Back to Candidates
                </Link>
                {/* <Link
                  onClick={handleGotoJob}
                  onKeyPress={handleGotoJob}
                  underline="hover"
                  className="cursor-hover font-weight-600"
                  to="/enterprise/matching-candidate/matching-candidate-listing"
                  tabIndex={0}
                >
                  <BacktoJob className="mr-10" />
                  Back to Candidates
                </Link> */}
              </Breadcrumbs>
              {/* <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" aria-label="Next icon" />}
                aria-label="breadcrumb"
              >
                {!(params.id || location.pathname.includes("talent-pool-candidate-list")) && <Link
                  onClick={goToCandidatePage}
                  underline="hover"
                  className="cursor-hover font-weight-500"
                  to="#"
                  tabIndex={0}
                  onKeyPress={goToCandidatePage}
                >
                  <GroupOutlinedIcon sx={{ mr: 0.5 }} aria-label="List icon" />
                  {location.pathname.includes("my-candidate") ?
                    "Candidates" : "Candidates List"
                  }
                </Link>}

                {params.id &&
                  <Link
                    onClick={goToTalentPoolListing}
                    underline="hover"
                    className="cursor-hover font-weight-500"
                    to="#"
                    onKeyPress={goToTalentPoolListing}
                    tabIndex={0}
                    variant="h5">
                    Talent Pool Management
                  </Link>}
                {(params.id || location.pathname.includes("talent-pool-candidate-list")) &&
                  <Link
                    onClick={gotToTalentPoolView}
                    underline="hover"
                    className="cursor-hover font-weight-500"
                    to="#"
                    onKeyPress={gotToTalentPoolView}
                    tabIndex={0}
                    variant="h5">
                    {searchParams.get("talentPoolName")}
                  </Link>}
                {(location.pathname.includes("talent-pool-candidate-list")) &&
                  <Link
                    onClick={gotToAddCandidate}
                    underline="hover"
                    className="cursor-hover font-weight-500"
                    to="#"
                    onKeyPress={gotToAddCandidate}
                    tabIndex={0}
                    variant="h5">
                    Add Candidates
                  </Link>}
                <Link
                  to="/enterprise/matching-candidate/matching-candidate-listing"
                  className="link-color font-weight-600 cursor-normal"
                >
                  {candidate?.firstName} {candidate.lastName}
                </Link>
              </Breadcrumbs> */}
            </Grid>

            {/* <Grid item className="mt-10 p-20 c-white job-list-header" style={{ borderRadius: '10px' }}>
                        <JobHeader job={job} />
                    </Grid>*/}
            {/* <Grid item className="mt-10 p-20 danger-color candidateDecline font-weight-500">
                        The offer has been declined by the candidate
                    </Grid> */}

            <Grid
              className="p-absolute w-100"
              id="enterprise-candidate"
              style={{ top: "56px" }}
            >
              <Grid sx={{ mx: 3 }} className="54">
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className="bg-white d-flex pl-20 pr-20 pt-10 pb-20 jobSearch top-section-border notification-align"
                >
                  <Grid
                    item
                    lg={9}
                    md={9}
                    xs={10}
                    className="d-flex galaxy-block"
                  >
                    <Typography>
                      <Box className="justify-end d-flex">
                        <Grid item lg={12} md={12} xs={12} className="">
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            className="profile-pic justify-content-start"
                          >
                            {(candidate.mediaFile === "****" ||
                              candidate.mediaFileSigned === null ||
                              candidate.mediaFileSigned === "") && (
                              <Typography
                                className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-30 font-weight-700 text-capitalize profileImage"
                                width="100px"
                                height="100px"
                                left="2px"
                                aria-label="Default image"
                              >
                                {candidate.firstName.trim().substring(0, 1)}
                              </Typography>
                            )}
                            {candidate.mediaFile !== "****" &&
                              candidate.mediaFileSigned &&
                              candidate.mediaFileSigned !== null &&
                              candidate.mediaFileSigned !== "" &&
                              !checkIsImage(candidate.mediaFileSigned) && (
                                <>
                                  <video
                                    className="profileImage default-img-border cursor-hover p-relative"
                                    style={{ left: "2px" }}
                                    src={candidate.mediaFileSigned}
                                    onClick={() => {
                                      handleOpenVideoPreview(
                                        candidate.mediaFileSigned,
                                        candidate.firstName +
                                          " " +
                                          candidate.lastName
                                      );
                                    }}
                                    muted
                                    onKeyPress={() => {
                                      handleOpenVideoPreview(
                                        candidate.mediaFileSigned,
                                        candidate.firstName +
                                          " " +
                                          candidate.lastName
                                      );
                                    }}
                                    tabIndex={0}
                                  />
                                  <img
                                    onClick={() => {
                                      handleOpenVideoPreview(
                                        candidate.mediaFileSigned,
                                        candidate.firstName +
                                          " " +
                                          candidate.lastName
                                      );
                                    }}
                                    className="profileImage-overlay play-icon-profile cursor-hover"
                                    src={playIcon}
                                    style={{ top: "50px", left: "90px" }}
                                    alt="img"
                                    tabIndex={0}
                                    onKeyPress={() => {
                                      handleOpenVideoPreview(
                                        candidate.mediaFileSigned,
                                        candidate.firstName +
                                          " " +
                                          candidate.lastName
                                      );
                                    }}
                                  />
                                </>
                              )}
                            {candidate.mediaFile !== "****" &&
                              candidate.mediaFileSigned &&
                              candidate.mediaFileSigned !== null &&
                              candidate.mediaFileSigned !== "" &&
                              checkIsImage(candidate.mediaFileSigned) && (
                                <img
                                  className="profileImage default-img-border"
                                  style={{ left: "2px" }}
                                  src={candidate.mediaFileSigned}
                                  alt="img"
                                />
                              )}
                          </Grid>
                          <Grid className={`image-section ${candidate?.talentPool === "Z" && localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) && "ml-11"}`}>
                          <Tooltip
                                                    title={
                                                      <div className="mt-15">
                                                        <Typography
                                                          // style={{
                                                          //   width: `${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "110px" : "80px"}`,
                                                          // }}
                                                        >
                                                          <div className="d-flex align-center">
                                                          <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "mspExclusive": "priorityStatus"} d-flex align-center justify-center`}>
                                                            <span className="d-flex align-center">
                                                              {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                                                            </span>
                                                            <span className="pl-2">
                                                              {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}
                                                            </span>
                                                          </span>
                                                          <span className="f-12 c-white pl-5">{getExclusiveEnterprise(candidate?.sourceEnterpriseId)}</span>
                                                        </div>
                                                        </Typography>
                                                        <Typography className="f-12 c-white mt-5">
                                                          {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE_Detail :"These are the candidates who have come to the platform through you and only you have the access to their profile"}
                                                        </Typography>
                                                        <br />
                                                      </div>
                                                    }
                                                    position="top"
                                                    trigger="mouseenter"
                                                    animation="fade"
                                                    arrow
                                                  >
                            <Typography className="mt-5 mb-5">
                              {candidate?.talentPool === "Z" && (
                                <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "priority-image-status-mspExclusive": "priority-image-status"} d-flex align-center`}>
                                  <span className="d-flex align-center">
                                    {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                                  </span>
                                  <span className="pl-5">{localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}</span>
                                </span>
                              )}
                            </Typography>
                            </Tooltip>
                            <Tooltip
                                                    title={
                                                      <div className="mt-15">
                                                        <Typography
                                                          style={{
                                                            width: "110px",
                                                          }}
                                                        >
                                                          <span className="myCandidate-Ongoing d-flex align-center justify-center">
                                                            <span className="d-flex align-center">
                                                              <PrivateIcon />
                                                            </span>
                                                            <span className="pl-5">
                                                              Qualified
                                                            </span>
                                                          </span>
                                                        </Typography>
                                                        <Typography className="f-12 c-white mt-5">
                                                          These are the
                                                          candidates with whom
                                                          you have interacted on
                                                          our platform
                                                        </Typography>
                                                        <br />
                                                                                                              </div>
                                                    }
                                                    placement="top"
                                                    trigger="mouseenter"
                                                    animation="fade"
                                                    arrow
                                                  >
                            <Typography className="mt-5 mb-5">
                              {candidate?.talentPool === "P" && (
                                <span className="private-image-status d-flex align-center">
                                  <span className="d-flex align-center">
                                    <PrivateIcon />
                                  </span>
                                  <span className="pl-5">Qualified</span>
                                </span>
                              )}
                            </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                    <Typography>
                      <Box className="pl-20 userName-align">
                        <Typography className="align-center">
                          <Typography className="align-center d-flex font-weight-600 mt-10 f-16 gray9 break-word launchTitle userName-align width-responsive-100 history-align status-align responsive-block galaxy-block">
                            <Typography className="font-weight-600 f-16 main-text-color associate-status-align">
                              {candidate.firstName} {candidate.lastName}
                            </Typography>
                            <Typography className="gray9 font-weight-400 f-14 align-center d-flex userName-align responsive-block pl-20 responsive-view-align matching-details-align">
                              <AvailabilityStatusLabels
                              candidate={candidate}
                              isFromMatchingDrawer={true}
                              goToProjectTab={goToProjectTab}
                              />
                            </Typography>
                            {/* <VerifiedIcon className="ml-5 success-color small-text-font" aria-label="Verified icon" /> */}
                          </Typography>
                        </Typography>
                        <Box className="matching-details-align">
                          <Typography
                            variant="span"
                            className="gray9 pt-10 f-12 font-weight-500 d-flex userName-align justify-between responsive-block"
                          >
                            <Typography className="d-flex">
                              <NewBriefCase className="min-w-14 mt-3" width="14px" height="14px" />
                              <Typography className="gray7 f-14 font-weight-400 pl-5">
                                {candidate?.profileTitle || "-"}{" "}
                              </Typography>
                            </Typography>
                            {candidate?.city && candidate?.country && (
                              <Typography className="d-flex pl-20 responsive-view-align">
                                <NewLocation className="min-w-14 mt-3" width="14px" height="14px" />
                                <Typography className="gray7 f-14 font-weight-400 pl-5">
                                  {candidate.city}
                                  {candidate?.city ? "," : ""}{" "}
                                  {candidate.country}
                                  {/* {candidate?.city ? candidate?.city : "-"},{" "}
                                {candidate?.country
                                  ? candidate?.country
                                  : "-"} */}
                                </Typography>
                                {/* {candidate.address1?.length > 0 && (
                                <FiberManualRecordIcon className="px-5" aria-label="Dot icon" />
                              )}{" "}
                              {candidate.address1} */}
                              </Typography>
                            )}
                          </Typography>
                          <Typography
                            variant="span"
                            className="gray7 f-12 align-center d-flex userName-align jobDetail-user rating-details add-checklist-align responsive-block"
                          >
                            {candidate.candidateRating ? (
                              <>
                                <Rating
                                  count={5}
                                  onChange={ratingChanged}
                                  value={candidate?.candidateRating}
                                  name="half-rating"
                                  readOnly
                                  icon={
                                    <img
                                      src={starFilled}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      alt="img"
                                    />
                                  }
                                  emptyIcon={
                                    <img
                                      src={starEmpty}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      alt="img"
                                    />
                                  }
                                  className="pt-10 matchingJobSpace"
                                />
                                <Typography className="cursor-hover pr-30 pt-10 matchingJobSpace">
                                  <span
                                    className="link-color pt-5 cursor-hover f-12 font-weight-500"
                                    // onClick={openRatingAndReviewSection}
                                    // onKeyPress={openRatingAndReviewSection}
                                    onClick={() => {
                                      handleChangeReview("reviews");
                                      openRatingAndReviewSection;
                                    }}
                                    tabIndex={0}
                                  >
                                    ({candidate.noOfRatings}
                                    {/* {candidate.noOfRatings <= 1
                                      ? "rating"
                                      : "ratings"} */}
                                    )
                                  </span>
                                </Typography>
                              </>
                            ) : (
                              <Typography className="f-12 gray7 font-weight-400 align-center d-flex mt-5">
                                No rating available
                              </Typography>
                            )}
                          </Typography>
                          {candidate?.lastUpdatedTimestamp ?
                            <Typography className="d-flex align-center mt-8">
                              <Typography className="gray7 f-14 font-weight-400">
                                {`Last Updated: ${
                                  moment().diff(candidate?.lastUpdatedTimestamp, 'days') > 0
                                  ? moment().diff(candidate?.lastUpdatedTimestamp, 'days') > 100
                                  ? "100+ days ago"
                                  : moment().diff(candidate?.lastUpdatedTimestamp, 'days') + (moment().diff(candidate?.lastUpdatedTimestamp, 'days') === 1 ? " day " : " days ") + "ago"
                                  : "Today"}`}
                              </Typography>
                            </Typography>
                            : ""}
                        </Box>
                      </Box>
                      {(searchParams.get("talentPoolName") || searchParams.get("referrals")) && searchParams?.get("referrerName") && <Typography className="matching-location-align atching-details-align matching-details-align skill-labl nav-user-img mt-13">
                                    <Box className="f-10 gray7 font-weight-400 align-center d-flex candidate-info-align adding-skills">
                                        <Typography className="d-flex align-center">
                                          <span className="d-flex">
                                            <ReferralIcon width={"12px"} />
                                          </span>
                                          <span className="f-14 pl-5 text-decoration-none">
                                            Referred by
                                          </span>
                                            <span className="d-flex f-12 pl-5 gray-8 font-weight-600">
                                              {searchParams?.get("referrerName")} {`(${searchParams?.get("referrerEstablishmentName")})`}
                                            </span>
                                        </Typography>
                                    </Box>
                                  </Typography>}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={2} className="d-flex justify-end align-start">
                    <Typography className="d-flex descriptionSpace responsive-block score-align">
                      {params.status !== "" && (
                        <>
                          <Popover
                            id={id}
                            open={open3}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                          >
                            <Grid style={{ width: "260px" }} sx={{ p: 1 }}>
                              <Box className="align-between mb-5">
                                <Grid item xs={12}>
                                  <Typography
                                    align="right"
                                    className="cursor-hover gray7 icon-button"
                                  >
                                    <CloseIcon
                                      onClick={handleClosePopover}
                                      onKeyPress={handleClosePopover}
                                      aria-label="Close icon"
                                      tabIndex={0}
                                    />
                                  </Typography>
                                </Grid>
                              </Box>
                              <Box className="border-bottom-1">
                                <Box className="align-between mb-5 ">
                                  <span className="gray9 f-14 font-weight-600 pl-7">
                                    Overall Score
                                  </span>
                                  <span
                                    className="f-14 success-color font-weight-600"
                                    style={{ paddingRight: "17px" }}
                                  >
                                    {candidatePopUp
                                      ? Math.round(
                                          candidatePopUp.matchingScore
                                            ? candidatePopUp.matchingScore
                                            : 0
                                        )
                                      : ""}
                                    %
                                  </span>
                                </Box>
                              </Box>

                              <Grid
                                item
                                className="p-10"
                                style={{ maxHeight: "250px", overflow: "auto" }}
                              >
                                <Box className="mb-5 mt-10">
                                  <Typography className="align-between">
                                    <span className="gray9 f-14 font-weight-600">
                                      Skills
                                    </span>
                                    <span className="f-14 success-color text-lowercase font-weight-600">
                                      {candidatePopUp?.matchedFilters?.skills
                                        ?.score
                                        ? Math.round(
                                            candidatePopUp.matchedFilters.skills
                                              .score
                                          ) + "%"
                                        : ""}
                                    </span>
                                  </Typography>
                                  <Typography className="gray7 f-14 mt-5 text-lowercase ">
                                    <CheckCircleIcon
                                      className="success-color vertical-align-middle"
                                      aria-label="Matched icon"
                                    />
                                    <span className="pl-7 font-weight-600 text-capitalize">
                                      Matched
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        whiteSpace: "pre-line",
                                        width: "185px",
                                      }}
                                      className="gray9 pl-25 break-all d-flex"
                                    >
                                      {candidatePopUp?.matchedFilters.skills
                                        ?.keyword
                                        ? getFormattedSkills(
                                            candidatePopUp.matchedFilters.skills
                                              .keyword
                                          )
                                        : "-"}
                                      {/*  {(candidatePopUp?.matchedFilters?.skills)?getFormattedSkills(candidatePopUp.matchedFilters.skills.keyword):"-"}
                                                                {(candidatePopUp?.matchedFilters?.skills.keyword === "")&& "-"}*/}
                                    </span>
                                  </Typography>
                                  <Typography className="gray7 f-14 mt-5 text-lowercase">
                                    <CheckCircleIcon
                                      className="warn-color vertical-align-middle"
                                      aria-label="Matched icon"
                                    />
                                    <span className="pl-7 font-weight-600 text-capitalize">
                                      Partially Matched
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        whiteSpace: "pre-line",
                                        width: "185px",
                                      }}
                                      className="gray9 pl-25 break-all d-flex"
                                    >
                                      {candidatePopUp?.matchedFilters
                                        .partialSkills?.keyword
                                        ? getFormattedSkills(
                                            candidatePopUp.matchedFilters
                                              .partialSkills.keyword
                                          )
                                        : "-"}
                                    </span>
                                  </Typography>
                                  <Typography className="gray7 f-14 mt-5 text-lowercase">
                                    <CancelRoundedIcon
                                      className="danger-color vertical-align-middle"
                                      aria-label="Unmatched icon"
                                    />
                                    <span className="pl-7 font-weight-600 text-capitalize">
                                      Unmatched
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        whiteSpace: "pre-line",
                                        width: "185px",
                                      }}
                                      className="gray9 pl-25 break-all d-flex"
                                    >
                                      {candidatePopUp?.unmatchedFilters?.skills
                                        ? getFormattedSkills(
                                            candidatePopUp.unmatchedFilters
                                              .skills
                                          )
                                        : "-"}
                                    </span>
                                  </Typography>
                                </Box>

                                <Box className="mb-5 mt-10">
                                  <Typography className="align-between">
                                    <span className="gray9 f-14 font-weight-600">
                                      Experience
                                    </span>
                                    <span className="f-14 success-color font-weight-600">
                                      {preferredExp
                                        ? ""
                                        : candidatePopUp?.matchedFilters
                                            ?.overallYearsOfExperience?.score
                                        ? Math.round(
                                            candidatePopUp.matchedFilters
                                              .overallYearsOfExperience.score
                                          ) + "%"
                                        : candidatePopUp?.matchedFilters
                                            ?.partialYearsOfExperience?.score
                                        ? Math.round(
                                            candidatePopUp?.matchedFilters
                                              ?.partialYearsOfExperience?.score
                                          ) + "%"
                                        : "0%"}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    <span className="gray7 f-14 font-weight-600">
                                      {candidatePopUp?.matchedFilters
                                        ?.overallYearsOfExperience
                                        ? "Matched"
                                        : candidatePopUp?.matchedFilters
                                            ?.partialYearsOfExperience
                                        ? "Partially Matched"
                                        : "Unmatched"}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    <Box className=" align-center mt-5">
                                      {candidatePopUp?.matchedFilters
                                        ?.overallYearsOfExperience ? (
                                        <CheckCircleIcon
                                          className="success-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                      ) : candidatePopUp?.matchedFilters
                                          ?.partialYearsOfExperience ? (
                                        <CheckCircleIcon
                                          className="warn-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                      ) : (
                                        <CancelRoundedIcon
                                          className="danger-color vertical-align-middle"
                                          aria-label="Unmatched icon"
                                        />
                                      )}
                                      <span className="f-14 gray7 pl-7 font-weight-600">
                                        Preferred Job Experience
                                      </span>
                                      <br />
                                      {job.overallExperienceMaximum >= 1 ? (
                                        <span className="f-14 gray9 pl-25">
                                          {job.overallExperienceMinimum}-
                                          {job.overallExperienceMaximum} Years
                                        </span>
                                      ) : (
                                        <span className="f-14 gray9 pl-25">
                                          {job.overallExperienceMinimum}-
                                          {job.overallExperienceMaximum} Year
                                        </span>
                                      )}
                                    </Box>
                                  </Typography>
                                  <Typography>
                                    <Box className=" align-center mt-5">
                                      {candidatePopUp?.matchedFilters
                                        ?.overallYearsOfExperience ? (
                                        <CheckCircleIcon
                                          className="success-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                      ) : candidatePopUp?.matchedFilters
                                          ?.partialYearsOfExperience ? (
                                        <CheckCircleIcon
                                          className="warn-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                      ) : (
                                        <CancelRoundedIcon
                                          className="danger-color vertical-align-middle"
                                          aria-label="Unmatched icon"
                                        />
                                      )}
                                      <span className="f-14 gray7 pl-7 font-weight-600">
                                        Candidate Experience
                                      </span>
                                      <br />
                                      <span className="f-14 gray9 pl-25">
                                        {candidatePopUp
                                          ? candidatePopUp.overallYearsOfExperience +
                                            (candidatePopUp.overallYearsOfExperience <=
                                            1.0
                                              ? " Year"
                                              : " Years")
                                          : ""}
                                      </span>
                                    </Box>
                                  </Typography>
                                </Box>
                                {(candidatePopUp?.matchedFilters?.location ||
                                  candidatePopUp?.unmatchedFilters
                                    ?.location) && (
                                  <Box className="m-b-5 mt-10">
                                    <Typography className="align-between">
                                      <span className="gray9 f-14 font-weight-600">
                                        Location{" "}
                                      </span>
                                      <span className="f-14 success-color font-weight-600">
                                        {candidatePopUp?.matchedFilters
                                          ?.location?.score
                                          ? Math.round(
                                              candidatePopUp.matchedFilters
                                                .location.score
                                            ) + "%"
                                          : ""}
                                      </span>
                                    </Typography>
                                    {/* {(props.jobs?.matchedFilters?.location?.keyword) && <Typography className="gray9 f-14 mt-5"><CheckCircleIcon style={{verticalAlign: 'middle',marginRight:5}} className='success-color vertical-align-middle'/><span className='p-l-5'>{props.jobs.matchedFilters.location.keyword}</span></Typography>} */}
                                    {/* {(props.jobs?.unmatchedFilters?.location) && <Typography className="gray9 f-14 mt-5"><CancelRoundedIcon style={{verticalAlign: 'middle',marginRight:5}} className='success-color vertical-align-middle'/><span className='p-l-5'>{props.jobs.unmatchedFilters.location}</span></Typography>} */}
                                    {candidatePopUp?.matchedFilters?.location
                                      ?.score ? (
                                      <Box className=" align-center mt-5">
                                        <CheckCircleIcon
                                          style={{
                                            verticalAlign: "middle",
                                            marginRight: 5,
                                          }}
                                          className="success-color vertical-align-middle"
                                          aria-label="Matched icon"
                                        />
                                        <span className="gray9 f-14 pl-28">
                                          {candidatePopUp?.city
                                            ? candidatePopUp?.country
                                              ? candidatePopUp.city +
                                                ", " +
                                                candidatePopUp.country
                                              : candidatePopUp.city
                                            : candidatePopUp?.country
                                            ? candidatePopUp.country
                                            : "-"}
                                        </span>
                                      </Box>
                                    ) : (
                                      <>
                                        <Box className=" align-center mt-5">
                                          {candidatePopUp?.unmatchedFilters
                                            ?.location ? (
                                            <CancelRoundedIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: 5,
                                              }}
                                              className="danger-color vertical-align-middle"
                                              aria-label="Unmatched icon"
                                            />
                                          ) : (
                                            <CheckCircleIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: 5,
                                              }}
                                              className="success-color vertical-align-middle"
                                              aria-label="Matched icon"
                                            />
                                          )}
                                          <span className="f-14 gray7 p-l-5 font-weight-600">
                                            Job Location
                                          </span>
                                          <br />
                                          {candidatePopUp?.unmatchedFilters
                                            ?.location && (
                                            <Typography className="gray9 f-14 pl-28">
                                              <span className="f-14 gray9 pl-25">
                                                {candidatePopUp.unmatchedFilters.location
                                                  .split(",")
                                                  .join(", ")}
                                              </span>
                                            </Typography>
                                          )}
                                        </Box>

                                        <Box className=" align-center mt-5">
                                          {candidatePopUp?.unmatchedFilters
                                            ?.location ? (
                                            <CancelRoundedIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: 5,
                                              }}
                                              className="danger-color vertical-align-middle"
                                              aria-label="Unmatched icon"
                                            />
                                          ) : (
                                            <CheckCircleIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: 5,
                                              }}
                                              className="success-color vertical-align-middle"
                                              aria-label="Matched icon"
                                            />
                                          )}
                                          <span className="f-14 gray7 p-l-5 font-weight-600">
                                            Candidate Location
                                          </span>
                                          <br />
                                          {
                                            <Typography className="gray9 f-14 pl-28">
                                              <span className="f-14 gray9 pl-25">
                                                {candidatePopUp?.city
                                                  ? candidatePopUp?.country
                                                    ? candidatePopUp.city +
                                                      ", " +
                                                      candidatePopUp.country
                                                    : candidatePopUp.city
                                                  : candidatePopUp?.country
                                                  ? candidatePopUp.country
                                                  : "-"}
                                              </span>
                                            </Typography>
                                          }
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                )}

                                <Box className="mb-5 mt-10">
                                  <Typography className="align-between">
                                    <span className="gray9 f-14 font-weight-600">
                                      Profile Title
                                    </span>

                                    {candidatePopUp?.matchedFilters.title && (
                                      <span className="f-14 success-color font-weight-600">
                                        {preferredExp
                                          ? ""
                                          : candidatePopUp?.matchedFilters
                                              ?.title?.score
                                          ? Math.round(
                                              candidatePopUp.matchedFilters
                                                .title.score
                                            ) + "%"
                                          : "0%"}
                                      </span>
                                    )}
                                    {candidatePopUp?.unmatchedFilters.title && (
                                      <span className="f-14 success-color font-weight-600">
                                        {preferredExp
                                          ? ""
                                          : candidatePopUp?.matchedFilters
                                              ?.title?.score
                                          ? Math.round(
                                              candidatePopUp.matchedFilters
                                                .title.score
                                            ) + "%"
                                          : "0%"}
                                      </span>
                                    )}
                                  </Typography>

                                  {candidatePopUp?.matchedFilters.title && (
                                    <Box className=" align-center mt-5">
                                      <CheckCircleIcon
                                        style={{
                                          verticalAlign: "middle",
                                          marginRight: 5,
                                        }}
                                        className="success-color vertical-align-middle"
                                        aria-label="Matched icon"
                                      />
                                      <span className="gray9 f-14 pl-28">
                                        {
                                          candidatePopUp?.matchedFilters.title
                                            .keyword
                                        }
                                      </span>
                                    </Box>
                                  )}

                                  {candidatePopUp?.unmatchedFilters.title && (
                                    <Box className=" align-center mt-5">
                                      <CancelRoundedIcon
                                        style={{
                                          verticalAlign: "middle",
                                          marginRight: 5,
                                        }}
                                        className="danger-color vertical-align-middle"
                                        aria-label="Unmatched icon"
                                      />
                                      <span className="gray9 f-14 pl-28">
                                        {candidatePopUp?.profileTitle}
                                      </span>
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Popover>
                        </>
                      )}
                      {/* <ActionButtons isLoading={isLoading} setIsLoading={setIsLoading} candidateApplicationData={candidateApplicationData} /> */}
                      {searchParams.get("isActionItem") == "global" &&
                        <Grid className="mt-5">
                          <CandidateListActionButton
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            candidate={candidate}
                            // candidate_id={props.candidateId}
                            candidate_id={params.candidate_id}
                            showEditCandidateDetails={(candidate?.talentPool === "Z" && localStorage.getItem("userType") !== "S")}
                            goToEditCandidate={goToEditCandidate}
                          />
                        </Grid>
                      }
                      {searchParams.get("isActionItem") == "tpname" &&
                        <Grid className="mt-5">
                          <EditTalentActionButton
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            user={candidate}
                            talentPool={searchParams.get("talentPoolName")}
                            isCandidateDetail={true}
                            candidateId={params.candidate_id}
                            isTalentDetail={location.pathname.match(/.*(?=\/candidate-detail)/)[0]}
                            showEditCandidateDetails={(candidate?.talentPool === "Z" && localStorage.getItem("userType") !== "S")}
                            goToEditCandidate={goToEditCandidate}
                          />
                        </Grid>
                      }
                      {searchParams.get("isActionItem") == "mycandidates" &&
                        <Grid className="candidate-cta-align mt-5">
                          <MyCandidateListActionButton
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            user={candidate}
                            status={props?.viewType}
                            candidate_id={params.candidate_id}
                            isFromMyCandidateNotes={true}
                            showEditCandidateDetails={(candidate?.talentPool === "Z" && localStorage.getItem("userType") !== "S")}
                            goToEditCandidate={goToEditCandidate}
                          />
                        </Grid>
                      }
                      {(searchParams.get("isActionItem") !== "global") && (searchParams.get("isActionItem") !== "tpname") && (searchParams.get("isActionItem") !== "mycandidates") &&
                        (candidate?.talentPool === "Z" && localStorage.getItem("userType") !== "S") &&
                        <Typography className="responsive-contents">
                          <Stack direction="row" spacing={2}>
                            <div className="d-grid">
                              <Button
                                ref={anchorRef}
                                aria-controls={
                                  openStatus
                                    ? "composition-menu"
                                    : undefined
                                }
                                aria-expanded={
                                  openStatus ? "true" : undefined
                                }
                                aria-haspopup="true"
                                onClick={handleToggle}
                                aria-label="Menu icon"
                                disableRipple="true"
                                role="button"
                              >
                                <Typography className="secondary-bg-color border-radius-4 p-7-10 d-flex">
                                  <ActionIcon width="16px" height="16px" className="svg-icons"/>
                                </Typography>
                              </Button>
                              <Popper
                                style={{ zIndex: "9" }}
                                open={openStatus}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-end"
                                transition
                                disablePortal
                                className="options-align"
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom-start"
                                          ? "left top"
                                          : "left bottom",
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener
                                        onClickAway={handleCloseStatus}
                                        tabIndex={0}
                                        onKeyPress={handleCloseStatus}
                                      >
                                        {localStorage.getItem("userType") != "S" && <MenuList
                                          autoFocusItem={openStatus}
                                          id="composition-menu"
                                          aria-labelledby="composition-button"
                                          onKeyDown={handleListKeyDown}
                                        >
                                          <MenuItem
                                            onClick={(e) => goToEditCandidate(e, candidate)}
                                            onKeyPress={(e) => goToEditCandidate(e, candidate)}
                                          >
                                            <Typography className="gray9 f-12 font-weight-400">
                                              <span>Edit Candidate Details</span>
                                            </Typography>
                                          </MenuItem>
                                        </MenuList>}
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </div>
                          </Stack>
                        </Typography>
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ width: "100%" }}>
                  <TabContext value={valueTabs}>
                    <Box className="bg-white  profile-tabs tabs-section-border matching-drawer-tab">
                      <TabList
                        onChange={handleChangeTabs}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                      >
                        <Tab label="Profile" value="basic info" />
                        <Tab label="Reviews" value="reviews" />
                        <Tab label="Notes" value="Notes" />
                        <Tab label="Timeline" value="Timeline" />
                        <Tab label="Documents" value="download" />
                        <Tab label="Project Details" value="ProjectDetails" />
                      </TabList>
                    </Box>
                  </TabContext>
                  <Grid item>
                    <Box sx={{ width: "100%" }}>
                      <TabContext value={valueTabs}>
                        <TabPanel value="basic info" className="p-0 mt-20">
                          <Grid item className="border-1 border-radius-7">
                            <Grid
                              xs={12}
                              md={12}
                              lg={12}
                              className={
                                isAuthenticated
                                  ? "bg-white p-20 d-flex galaxy-block responsive-tabs"
                                  : "bg-white  p-20 d-flex galaxy-block responsive-tabs"
                              }
                            >
                              <Grid item lg={3.5} md={3.5} xs={12}>
                                <Typography className="d-flex">
                                  <Typography>
                                    <img
                                      src={hourlyRateIcon}
                                      alt="hourly rate"
                                    />
                                  </Typography>
                                  <Typography className="pl-10">
                                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                                      Pay Rate
                                    </Box>
                                    <Typography className="f-16 gray11 font-weight-400">
                                      {/* <Box className="align-item-center">
                                        <span className="f-16 gray11 font-weight-400 p-r-1">
                                        {candidate.currency ? `${candidate.currency }` : "USD "}
                                        </span>
                                        <span className="f-16 gray11 font-weight-400">
                                        &nbsp;{candidate?.ratePerHour}
                                        </span>
                                        <span className="f-16 gray11 font-weight-400">
                                          &nbsp;/ hour
                                        </span>
                                      </Box> */}
                                      {candidate?.ratePerHour
                                        ? `${
                                            candidate?.currency
                                              ? candidate?.currency
                                              : "USD"
                                          } ${candidate?.ratePerHour} / hour`
                                        : "-"}
                                    </Typography>
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <Typography className="d-flex">
                                  <Typography>
                                    <img src={overExpIcon} alt="hourly rate" />
                                  </Typography>
                                  <Typography className="pl-10">
                                    <Typography className="gray7 f-14 font-weight-400 m-b-5">
                                      Total Experience
                                    </Typography>
                                    {candidate.overallYearsOfExperience >= 1 ? (
                                      <Typography
                                        // variant="h4"
                                        className="f-16 gray11 font-weight-400"
                                      >
                                        <span className="">
                                          {candidate?.overallYearsOfExperience}
                                        </span>
                                        <span className="pl-5">Years </span>
                                      </Typography>
                                    ) : (
                                      <Typography
                                        // variant="h4"
                                        className="f-16 gray11 font-weight-400"
                                      >
                                        <span className="">
                                          {candidate?.overallYearsOfExperience}
                                        </span>
                                        <span className="pl-5">Year</span>
                                      </Typography>
                                    )}
                                  </Typography>
                                </Typography>
                              </Grid>

                              <Grid item lg={4.5} md={4.5} xs={12}>
                                <Typography className="d-flex">
                                  <Typography>
                                    <img src={WorkplaceIcon} alt="workplace" />
                                  </Typography>
                                  <Typography className="ml-10">
                                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                                      Workplace
                                    </Box>
                                    <Typography
                                      className={
                                        candidate?.workPlace
                                          ? "f-16 gray11 font-weight-400"
                                          : "f-16 gray11 font-weight-400 ml-5"
                                      }
                                    >
                                      {candidate?.workPlace
                                        ? getFormattedWorkplace(
                                            candidate?.workPlace
                                          )
                                        : "-"}
                                    </Typography>
                                  </Typography>
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                                className="jobHeader-align"
                              >
                                <Typography className="d-flex">
                                  <Typography>
                                    <img src={WorkTypeIcon} alt="WorkType" />
                                  </Typography>
                                  <Typography className="ml-10">
                                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                                      Work Type
                                    </Box>
                                    <Typography
                                      className={
                                        candidate?.workType
                                          ? "f-16 gray11 font-weight-400"
                                          : "f-16 gray11 font-weight-400 ml-5"
                                      }
                                    >
                                      {candidate?.workType
                                        ? getFormattedWorkType(
                                            candidate?.workType,
                                            false
                                          )
                                        : "-"}
                                      {candidate?.workType
                                        ?.replace(/\s|\[|\]/g, "")
                                        ?.split(",")?.length > 1 && (
                                        <Typography
                                          className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                          onClick={(e) => handleOpenJobType(e)}
                                          tabIndex={0}
                                          onKeyPress={(e) =>
                                            handleOpenJobType(e)
                                          }
                                        >
                                          {`+${
                                            candidate?.workType
                                              ?.replace(/\s|\[|\]/g, "")
                                              ?.split(",")?.length - 1
                                          } more`}
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                            {appOrigin === "H" ? (
                              <>
                                <Grid
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className={
                                    isAuthenticated
                                      ? "bg-white p-20 d-flex galaxy-block responsive-tabs"
                                      : "bg-white  p-20 d-flex galaxy-block responsive-tabs"
                                  }
                                >
                                  <Grid item lg={3.5} md={3.5} xs={12}>
                                    <Typography className="d-flex">
                                      <Typography className="hc-startDate-icon">
                                        <img
                                          src={startDateIcon}
                                          alt="startDateIcon"
                                        />
                                      </Typography>
                                      <Typography className="pl-10">
                                        <Box className="gray7 f-14 font-weight-400 m-b-5">
                                          Start Date
                                        </Box>
                                        <Typography>
                                          <Box
                                            className={
                                              candidate?.idealStartDate
                                                ? "f-16 gray11 font-weight-400"
                                                : "f-16 gray11 font-weight-400 ml-5"
                                            }
                                          >
                                            {candidate?.idealStartDate
                                              ? moment(
                                                  candidate?.idealStartDate
                                                ).format("MMM DD, YYYY")
                                              : "-"}
                                          </Box>
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={4} md={4} xs={12}>
                                    <Typography className="d-flex">
                                      <Typography>
                                        <img
                                          src={shiftPreferenceIcon}
                                          alt="shiftPreferenceIcon"
                                        />
                                      </Typography>
                                      <Typography className="pl-10">
                                        <Box className="gray7 f-14 font-weight-400 m-b-5">
                                          Shift Preferences
                                        </Box>
                                        <Typography>
                                          <Box
                                            className={
                                              candidate?.preferredShift
                                                ? "f-16 gray11 font-weight-400"
                                                : "f-16 gray11 font-weight-400 ml-5"
                                            }
                                          >
                                            {candidate?.preferredShift
                                              ? getFormattedShift(
                                                  candidate?.preferredShift
                                                )
                                              : "-"}
                                          </Box>
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={4.5} md={4.5} xs={12}>
                                    <Typography className="d-flex">
                                      <Typography className="edu-icon">
                                        <img
                                          src={disciplineIcon}
                                          alt="disciplineIcon"
                                        />
                                      </Typography>
                                      <Typography className="pl-10">
                                        <Box className="gray7 f-14 font-weight-400 m-b-5">
                                          Discipline
                                        </Box>
                                        <Typography>
                                          <Box className="f-16 gray11 font-weight-400">
                                            {candidate?.skills?.length > 0
                                              ? candidate?.skills[0].skills
                                                  .subcategoryKeyword
                                              : candidate?.candidateSkills
                                                  ?.length > 0
                                              ? candidate?.candidateSkills[0]
                                                  .skills.subcategoryKeyword
                                              : "-"}
                                          </Box>
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={4} md={4} xs={12}>
                                    <Typography className="d-flex">
                                      <Typography className="specialization-icon">
                                        <img
                                          src={specializationIcon}
                                          alt="specializationIcon"
                                        />
                                      </Typography>
                                      <Typography className="pl-10">
                                        <Box className="gray7 f-14 font-weight-400 m-b-5">
                                          Specialization
                                        </Box>
                                        <Typography>
                                          <Box className="f-16 gray11 font-weight-400">
                                            {specializationString
                                              ? specializationString.split(
                                                  ","
                                                )[0]
                                              : "-"}
                                            {specializationString?.split(",")
                                              ?.length > 1 && (
                                              <Typography
                                                className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                                onClick={(e) =>
                                                  handleOpenSpecialization(e)
                                                }
                                                tabIndex={0}
                                                onKeyPress={(e) =>
                                                  handleOpenSpecialization(e)
                                                }
                                              >
                                                {`+${
                                                  specializationString?.split(
                                                    ","
                                                  )?.length - 1
                                                } more`}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className={
                                    isAuthenticated
                                      ? "bg-white p-20 d-flex galaxy-block responsive-tabs"
                                      : "bg-white  p-20 d-flex galaxy-block responsive-tabs"
                                  }
                                >
                                  <Grid item lg={3.5} md={3.5} xs={12}>
                                    <Typography className="d-flex">
                                      <Typography className="specialization-icon">
                                        <img
                                          src={jobPreferenceIcon}
                                          alt="jobPreferenceIcon"
                                        />
                                      </Typography>
                                      <Typography className="pl-10">
                                        <Box className="gray7 f-14 font-weight-400 m-b-5">
                                          Job Preferences
                                        </Box>
                                        <Typography>
                                          <Box
                                            className={
                                              candidate?.jobPreferences
                                                ? "f-16 gray11 font-weight-400"
                                                : "f-16 gray11 font-weight-400 ml-5"
                                            }
                                          >
                                            {candidate?.jobPreferences
                                              ? candidate?.jobPreferences.split(
                                                  ","
                                                )[0]
                                              : "-"}
                                            {candidate?.jobPreferences?.split(
                                              ","
                                            )?.length > 1 && (
                                              <Typography
                                                className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                                onClick={(e) =>
                                                  handleOpenJobPreference(e)
                                                }
                                                tabIndex={0}
                                                onKeyPress={(e) =>
                                                  handleOpenJobPreference(e)
                                                }
                                              >
                                                {`+${
                                                  candidate?.jobPreferences?.split(
                                                    ","
                                                  )?.length - 1
                                                } more`}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3.5} md={3.5} xs={12}>
                                    <Typography className="d-flex">
                                      <Typography>
                                        <img
                                          src={nueroIcon}
                                          alt="hourly rate"
                                        />
                                      </Typography>
                                      <Typography className="pl-10">
                                        <Box className="gray7 f-14 font-weight-400 m-b-5">
                                          Neurodistinct Preference{" "}
                                        </Box>
                                        <Typography>
                                          <Box>
                                            <span className="f-16 gray11 font-weight-400">
                                              {candidate.neuroClassification ===
                                              "ND"
                                                ? "Neurodistinct Jobs Only"
                                                : "None"}
                                            </span>
                                          </Box>
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={9} md={9} xs={12}></Grid>
                                </Grid>
                              </>
                            ) : (
                              <Grid
                                item
                                className="bg-white p-20 d-flex notification-align responsive-tabs galaxy-block"
                                xs={12}
                                md={12}
                                lg={12}
                              >
                                <Grid item lg={3.5} md={3.5} xs={12}>
                                  <Typography className="d-flex">
                                    <Typography>
                                      <img src={nueroIcon} alt="hourly rate" />
                                    </Typography>
                                    <Typography className="pl-10">
                                      <Box className="gray7 f-14 font-weight-400 m-b-5">
                                        Neurodistinct Preference{" "}
                                      </Box>
                                      <Typography>
                                        <Box>
                                          <span className="f-16 gray11 font-weight-400">
                                            {candidate.neuroClassification ===
                                            "ND"
                                              ? "Neurodistinct Jobs Only"
                                              : "None"}
                                          </span>
                                        </Box>
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                            <Grid item className="">
                            <ContactInfo candidate={candidate}/>
                              <Grid
                                item
                                className="bg-white p-20"
                                xs={12}
                                lg={12}
                                md={12}
                              >
                                <Typography
                                  variant="h5"
                                  className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
                                >
                                  Personal Info
                                </Typography>
                                <Grid container spacing={1} lg={12} md={12} xs={12} className="mt-5">
                                  <Grid item lg={3} md={3} xs={12} className="mt-10">
                                    <Typography className="gray7 f-14 font-weight-400">
                                      Military Status
                                    </Typography>
                                    <Typography className="gray9 f-16 font-weight-400">
                                      {candidate?.militaryStatus ? candidate?.militaryStatus : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12} className="mt-10">
                                    <Typography className="gray7 f-14 font-weight-400">
                                      Known As
                                    </Typography>
                                    <Typography className="gray9 f-16 font-weight-400">
                                      {candidate?.knownAs ? candidate?.knownAs : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12} className="mt-10">
                                    <Typography className="gray7 f-14 font-weight-400">
                                      Wage Expectation
                                    </Typography>
                                    <Typography className="gray9 f-16 font-weight-400">
                                      {candidate?.wageExpectationCategory ? wageExpectations[candidate?.wageExpectationCategory] : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12} className="mt-10">
                                    <Typography className="gray7 f-14 font-weight-400">
                                      Address
                                    </Typography>
                                    <Typography className="gray9 f-16 font-weight-400">
                                      {candidate?.address ? candidate?.address : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12} className="mt-10">
                                    <Typography className="gray7 f-14 font-weight-400">
                                      Profile Created On
                                    </Typography>
                                    <Typography className="gray9 f-16 font-weight-400">
                                      {candidate?.createdTimestamp ? moment(candidate?.createdTimestamp).format("MMM DD, YYYY") : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {!(
                                (candidate?.mobileNumber === "" ||
                                  candidate?.mobileNumber === null) &&
                                (candidate?.emailAddress === "" ||
                                  candidate?.emailAddress === null)
                              ) && (
                                <Grid
                                  item
                                  className="bg-white p-20"
                                  xs={12}
                                  lg={12}
                                >
                                  <Typography
                                    variant="h5"
                                    className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
                                  >
                                    Profile Summary
                                  </Typography>
                                  <Typography className="pt-10 gray9 f-14 break-word">
                                    {showMore || candidate?.brief?.length <= 100
                                      ? candidate?.brief + " "
                                      : `${
                                          candidate?.brief
                                            ? candidate?.brief.substring(
                                                0,
                                                100
                                              ) +
                                              "..." +
                                              " "
                                            : ""
                                        }`}
                                    {(candidate.brief?.length
                                      ? candidate.brief?.length
                                      : 0) > 100 && (
                                      <Link
                                        className="link-color cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                                        onClick={() => setShowMore(!showMore)}
                                        tabIndex={0}
                                        onKeyPress={() =>
                                          setShowMore(!showMore)
                                        }
                                      >
                                        {" "}
                                        {showMore ? "view less" : "view more"}
                                      </Link>
                                    )}
                                    {(candidate.brief === "" ||
                                      candidate.brief === null) && (
                                      <Typography className="f-14 gray9">
                                        No profile summary added yet.
                                      </Typography>
                                    )}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item>
                              {(candidate?.mobileNumber === "" ||
                                candidate?.mobileNumber === null) &&
                                (candidate?.emailAddress === "" ||
                                  candidate?.emailAddress === null) && (
                                  <Grid
                                    item
                                    className="bg-white p-20"
                                    xs={12}
                                    lg={12}
                                  >
                                    <Typography
                                      variant="h5"
                                      className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
                                    >
                                      Profile Summary
                                    </Typography>
                                    <Typography className="pt-10 gray9 f-14 break-word">
                                      {showMore ||
                                      candidate?.brief?.length <= 100
                                        ? candidate?.brief + " "
                                        : `${
                                            candidate?.brief
                                              ? candidate?.brief.substring(
                                                  0,
                                                  100
                                                ) +
                                                "..." +
                                                " "
                                              : ""
                                          }`}
                                      {(candidate.brief?.length
                                        ? candidate.brief?.length
                                        : 0) > 100 && (
                                        <Link
                                          className="link-color cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                                          onClick={() => setShowMore(!showMore)}
                                          tabIndex={0}
                                          onKeyPress={() =>
                                            setShowMore(!showMore)
                                          }
                                        >
                                          {" "}
                                          {showMore ? "view less" : "view more"}
                                        </Link>
                                      )}
                                      {(candidate.brief === "" ||
                                        candidate.brief === null) && (
                                        <Typography className="f-14 gray9">
                                          No profile summary added yet.
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                            </Grid>
                            <Grid
                              item
                              className="bg-white p-20"
                              xs={12}
                              lg={12}
                            >
                              <Typography
                                variant="h5"
                                className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
                              >
                                Work Experience
                              </Typography>
                              <>
                                {candidate.workHistory?.length > 0 ? (
                                  candidate.workHistory?.map(
                                    (workhistory, index) => {
                                      return (
                                        <div key={index}>
                                          <Grid
                                            item
                                            className="mt-20 d-flex justify-between px-5"
                                          >
                                            <Typography className="d-flex">
                                              <Box>
                                                <img
                                                   width="45"
                                                   height="45"
                                                  src={workLogo}
                                                  alt="img"
                                                />
                                                {/* <img style={{width:'65%'}} src={workLogo} /> */}
                                              </Box>
                                              <Box className="px-5 mt-5 pl-10">
                                                <Typography
                                                  variant="h4"
                                                  className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy history-align"
                                                >
                                                  {workhistory.title}
                                                </Typography>
                                                <span className="mt-0 mb-0 f-12 gray7 font-weight-500 WorkExperienceBullet">
                                                  {workhistory?.workedWith}
                                                  {workhistory?.workedWith ? (
                                                    <span>
                                                      <FiberManualRecordIcon aria-label="Dot icon" />{" "}
                                                    </span>
                                                  ) : (
                                                    <span></span>
                                                  )}
                                                </span>
                                                <span className="f-12 gray7 font-weight-500 WorkExperienceBullet">
                                                  {moment(
                                                    workhistory?.workedFrom
                                                  ).format("MMM DD, YYYY")}{" "}
                                                  -{" "}
                                                  {workhistory?.workedTill ===
                                                    "1900-01-01" ||
                                                  workhistory?.workedTill ===
                                                    null
                                                    ? "Present"
                                                    : moment(
                                                        workhistory?.workedTill
                                                      ).format(
                                                        "MMM DD, YYYY"
                                                      )}{" "}
                                                  <FiberManualRecordIcon aria-label="Dot icon" />{" "}
                                                  {workDuration(
                                                    workhistory?.workedFrom,
                                                    workhistory.workedTill
                                                  )}
                                                </span>
                                                <Typography className="pt-10 gray9 f-14 break-word">
                                                {showMoreWorkSummary[index] ||
                                                    workhistory?.summaryOfWork
                                                      ?.length <= 100
                                                      ? workhistory?.summaryOfWork
                                                      : `${
                                                          workhistory?.summaryOfWork
                                                            ? workhistory?.summaryOfWork.substring(
                                                                0,
                                                                100
                                                              ) + "..."
                                                            : ""
                                                        }`}
                                                    {(workhistory?.summaryOfWork
                                                      ?.length
                                                      ? workhistory?.summaryOfWork
                                                          ?.length
                                                      : 0) > 100 && (
                                                      <Link
                                                        className="link-color cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                                                        onClick={() => {
                                                          let _showMoreWorkSummary = [...showMoreWorkSummary];
                                                          _showMoreWorkSummary[index] = !_showMoreWorkSummary[index];
                                                          setShowMoreWorkSummary(_showMoreWorkSummary);
                                                        }}
                                                        tabIndex={0}
                                                        onKeyPress={() => {
                                                          let _showMoreWorkSummary = [...showMoreWorkSummary];
                                                          _showMoreWorkSummary[index] = !_showMoreWorkSummary[index];
                                                          setShowMoreWorkSummary(_showMoreWorkSummary);
                                                        }}
                                                      >
                                                        {" "}
                                                        <span>
                                                          &nbsp;{" "}
                                                          {showMoreWorkSummary[index]
                                                            ? " view less"
                                                            : " view more"}
                                                        </span>
                                                      </Link>
                                                    )}
                                                </Typography>
                                              </Box>
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="mt-10 gray7 px-5"
                                          >
                                            <Box>
                                              {workhistory
                                                .candidateWorkHistoryShowcasesDto
                                                .length > 0 && (
                                                <span className="f-12">
                                                  Documents
                                                </span>
                                              )}
                                              <ul className="pl-15 mt-10">
                                                {workhistory?.candidateWorkHistoryShowcasesDto.map(
                                                  (show, index) => {
                                                    return (
                                                      <li key={index}>
                                                        <a
                                                          className="small-text-font gray7 mt-10 break-word"
                                                          href={
                                                            show.showcaseSignedUrl
                                                          }
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          {show?.showcaseSignedUrl &&
                                                            decodeURIComponent(
                                                              show?.showcaseSignedUrl
                                                                ?.split("/")
                                                                .pop()
                                                                .split("?")[0]
                                                            )}
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </Box>
                                          </Grid>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <Typography className="f-14 gray9 mt-10">
                                    No work experience details added.
                                  </Typography>
                                )}
                              </>

                              <>
                                {/*<Grid item className="mt-20 d-flex align-between" style={{display:'none'}}>
                                          <Typography className="d-flex" style={{display:'none'}}>
                                              <Box>
                                                  <img style={{ width: '45px' }} src={microsoftLogo} />
                                              </Box>
                                              <Box className="pl-10 mt-5">
                                                  <Typography variant="h4" className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy">
                                                      Software Developer
                                                  </Typography>
                                                  <Typography className="f-14 gray9">
                                                      Microsoft
                                                  </Typography>
                                                  <span className="f-12 gray7 align-center d-flex mt-5">Jan 2021 - Present <FiberManualRecordIcon className="px-5" /> 6 months</span>
                                              </Box>
                                          </Typography>
                                      </Grid>*/}
                              </>
                            </Grid>
                            {(appOrigin === "D" ||
                              appOrigin === "N") && (
                                <Grid className="bg-white p-20" xs={12} lg={12}>
                                  <Skills skills={candidate.skills} />
                                </Grid>
                              )}

                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                              className="bg-white p-20"
                            >
                              <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                Skill Checklist Submissions
                              </Typography>
                              <Grid>
                                {candidateChecklist &&
                                candidateChecklist.length > 0 ? (
                                  candidateChecklist.map(
                                    (checkListData, index) => {
                                      return (
                                        <Grid
                                          className="justify-between mt-10"
                                          key={index}
                                        >
                                          <Grid className="d-flex">
                                            <Typography className="m-r-10">
                                              <img
                                                src={checklistIndustry}
                                                alt="img"
                                              />
                                            </Typography>
                                            <Typography>
                                              <Box className="font-weight-500 f-16 gray11">
                                                {
                                                  checkListData.checkList
                                                    .checkListName
                                                }
                                              </Box>
                                              <Box>
                                                <Link
                                                  onClick={() => {
                                                    setSelectedCandidateChecklist(
                                                      checkListData
                                                    );
                                                    setShowCandidateChecklistOverlay(
                                                      true
                                                    );
                                                  }}
                                                  className="link-color f-14 font-weight-400 pr-10 text-decoration-none m-t-5"
                                                  tabIndex={0}
                                                  onKeyPress={() => {
                                                    setSelectedCandidateChecklist(
                                                      checkListData
                                                    );
                                                    setShowCandidateChecklistOverlay(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  View Checklist
                                                </Link>
                                              </Box>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      );
                                    }
                                  )
                                ) : (
                                  <Typography className="f-14 light-text mt-10">
                                    {"No skill checklist added."}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              className="bg-white p-20"
                            >
                              <Typography
                                className="font-weight-600 f-16 gray9 border-bottom-1 pb-10"
                              >
                                What shift are you willing to work?
                              </Typography>
                              <Typography className="font-weight-500 f-16 gray9 pt-10">
                                {getCandidateShiftTimings(candidate?.shiftStartTime, candidate?.shiftEndTime, candidate?.shiftTimezone)}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Grid item className="bg-white p-20">
                                <Grid
                                  item
                                  className="d-flex justify-between border-bottom-1 pb-10"
                                >
                                  <Typography
                                    variant="h5"
                                    className="font-weight-600 gray9 sub-text-font"
                                  >
                                    Licensure
                                  </Typography>
                                </Grid>
                                <>
                                  {candidate.candidateLicenceDtos?.length >
                                  0 ? (
                                    candidate.candidateLicenceDtos?.map(
                                      (license, index) => {
                                        return (
                                          <Grid item key={index}>
                                            <Grid
                                              item
                                              className="mt-20 d-flex justify-between"
                                            >
                                              <Typography className="d-flex">
                                                <Box>
                                                  <img
                                                    style={{ width: "45px" }}
                                                    src={licensure}
                                                    alt="img"
                                                  />
                                                  {/* <img style={{width:'65%'}} src={workLogo} /> */}
                                                </Box>
                                                <Box className="mt-5 pl-10">
                                                  <Typography
                                                    variant="h4"
                                                    className={`font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy history-align
                                                              ${
                                                                license.expirationDate &&
                                                                license.status !==
                                                                  "P" &&
                                                                !moment(
                                                                  license.expirationDate
                                                                )
                                                                  .add(
                                                                    1,
                                                                    "days"
                                                                  )
                                                                  .isAfter(
                                                                    moment().format(
                                                                      "MMM DD, YYYY"
                                                                    ),
                                                                    "day"
                                                                  )
                                                                  ? " danger-color"
                                                                  : ""
                                                              }
                                                              `}
                                                  >
                                                    {license.status === "P"
                                                      ? "License Applied"
                                                      : license.lincenseNumber
                                                      ? license.lincenseNumber
                                                      : "-"}
                                                  </Typography>
                                                  <p className="gray7 f-12 font-weight-500 mt-10 mb-0">
                                                    {license.status !== "P"
                                                      ? translateLicenseStatus(
                                                          license.status
                                                        ) + " |"
                                                      : ""}{" "}
                                                    {translateLicenseType(
                                                      license.type
                                                    )}
                                                  </p>
                                                  <Typography>
                                                    <span className="gray7 f-12 font-weight-500">
                                                      {license.licenseState}
                                                      {license.expirationDate &&
                                                        license.dateIssued &&
                                                        license.status !==
                                                          "P" && (
                                                          <>
                                                            <FiberManualRecordIcon
                                                              className="px-5 pt-10"
                                                              aria-label="Dot icon"
                                                            />{" "}
                                                            {moment(
                                                              license.dateIssued
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}{" "}
                                                            -{" "}
                                                            {moment(
                                                              license.expirationDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}
                                                          </>
                                                        )}
                                                    </span>
                                                  </Typography>
                                                </Box>
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 gray9 mt-10">
                                      {"No license details added."}
                                    </Typography>
                                  )}
                                </>
                              </Grid>
                              <Grid item className="bg-white p-20">
                                <Grid
                                  item
                                  className="d-flex justify-between border-bottom-1 pb-10 mb-20"
                                >
                                  <Typography
                                    variant="h5"
                                    className="font-weight-600 gray9 sub-text-font"
                                  >
                                    Reference
                                  </Typography>
                                </Grid>
                                <>
                                  {/* <Typography className="f-12 pt-5">
                                  <span className="font-weight-600">Note:</span>
                                  <span className="p-l-5">You can add upto 5 references.
                                  </span>
                              </Typography> */}
                                  {candidate.referencesDtos?.length > 0 ? (
                                    candidate.referencesDtos?.map(
                                      (reference, index) => {
                                        return (
                                          <div key={index}>
                                            <Grid
                                              item
                                              className="mt-10 d-flex justify-between pb-10"
                                            >
                                              <Typography className="d-flex">
                                                <Box>
                                                  <img
                                                    style={{ width: "45px" }}
                                                    src={references}
                                                    alt="img"
                                                  />
                                                  {/* <img style={{width:'65%'}} src={workLogo} /> */}
                                                </Box>
                                                <Box
                                                  className="pl-10"
                                                  style={{ marginTop: "6px" }}
                                                >
                                                  <Typography
                                                    variant="h4"
                                                    className="font-weight-600 small-text-font gray9 textEllipse w-300 history-align"
                                                  >
                                                    {
                                                      reference.referenceFullName
                                                    }
                                                  </Typography>
                                                  <p className="small-text-font mt-0 mb-5 textEllipse w-300">
                                                    {
                                                      reference.referenceOrganization
                                                    }
                                                  </p>
                                                  <Grid item>
                                                    {
                                                      <Typography className="d-flex align-center">
                                                        <img
                                                          className="f-14"
                                                          src={cellIcon}
                                                          alt="img"
                                                        />
                                                        <span className="f-14 gray9 pl-10">
                                                          {
                                                            reference.referenceMobileNumber
                                                          }
                                                        </span>
                                                      </Typography>
                                                    }
                                                    <Typography className="d-flex align-center responsive-tabs">
                                                      <Typography className="d-flex align-center">
                                                        <MailOutlineIcon
                                                          className="f-14 gray7"
                                                          aria-label="Email icon"
                                                        />
                                                        <span className="f-14 gray9 pl-10">
                                                          {
                                                            reference.referenceEmail
                                                          }
                                                        </span>
                                                      </Typography>
                                                      <Link
                                                        className="link-color small-text-font pr-10 text-decoration-none m-t-5 ml-40"
                                                        href={`mailto:${reference.referenceEmail}`}
                                                      >
                                                        Send Email
                                                      </Link>
                                                    </Typography>
                                                  </Grid>
                                                </Box>
                                              </Typography>
                                              {/* <Typography style={{display: "flex",justifyContent: "end",alignItems: "end"}}>
                                                          </Typography> */}
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 gray9 mt-10">
                                      {"No reference details added."}
                                    </Typography>
                                  )}
                                </>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              className="bg-white p-20"
                              xs={12}
                              lg={12}
                            >
                              <Education educations={candidate.education} />
                            </Grid>
                            <Grid item className="bg-white p-20">
                              <Grid
                                item
                                className="d-flex justify-between border-bottom-1 pb-10"
                              >
                                <Typography
                                  variant="h5"
                                  className="font-weight-600 sub-text-font gray9"
                                >
                                  Portfolio Links
                                </Typography>
                              </Grid>
                              <Box className="mt-10">
                                <ul className="pl-0 mt-10 mb-0">
                                  {/* <li> */}
                                  {(candidate?.portfolioUrl1 === null ||
                                    candidate?.portfolioUrl1 === "") &&
                                    (candidate?.portfolioUrl2 === null ||
                                      candidate?.portfolioUrl2 === "") &&
                                    (candidate?.portfolioUrl3 === null ||
                                      candidate?.portfolioUrl3 === "") &&
                                    (candidate?.portfolioUrl4 === null ||
                                      candidate?.portfolioUrl4 === "") && (
                                      <Typography className="f-14 gray9">
                                        No portfolio links added yet.
                                      </Typography>
                                    )}
                                  {/* </li> */}
                                  {candidate?.portfolioUrl1 && (
                                    <li className="mt-10 gray7 d-flex">
                                      <img
                                        className="mr-10 mt-13"
                                        src={linkIcon}
                                        alt="img"
                                        width="16"
                                          height="16"
                                      />
                                      <a
                                        className="small-text-font link-color textEllipse mt-10 w-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          candidate?.portfolioUrl1.indexOf(
                                            "://"
                                          ) === -1
                                            ? "http://" +
                                              candidate?.portfolioUrl1
                                            : candidate?.portfolioUrl1
                                        }
                                      >
                                        {candidate?.portfolioUrl1}
                                      </a>
                                    </li>
                                  )}
                                  {candidate?.portfolioUrl2 && (
                                    <li className="mt-10 gray7 d-flex">
                                      <img
                                        className="mr-10 mt-13"
                                        src={linkIcon}
                                        alt="img"
                                        width="16"
                                          height="16"
                                      />
                                      <a
                                        className="small-text-font link-color textEllipse mt-10 w-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          candidate?.portfolioUrl2.indexOf(
                                            "://"
                                          ) === -1
                                            ? "http://" +
                                              candidate?.portfolioUrl2
                                            : candidate?.portfolioUrl2
                                        }
                                      >
                                        {candidate?.portfolioUrl2}
                                      </a>
                                    </li>
                                  )}
                                  {candidate?.portfolioUrl3 && (
                                    <li className="mt-10 gray7 d-flex">
                                      <img
                                        className="mr-10 mt-13"
                                        src={linkIcon}
                                        alt="img"
                                        width="16"
                                          height="16"
                                      />
                                      <a
                                        className="small-text-font link-color textEllipse mt-10 w-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          candidate?.portfolioUrl3.indexOf(
                                            "://"
                                          ) === -1
                                            ? "http://" +
                                              candidate?.portfolioUrl3
                                            : candidate?.portfolioUrl3
                                        }
                                      >
                                        {candidate?.portfolioUrl3}
                                      </a>
                                    </li>
                                  )}
                                  {candidate?.portfolioUrl4 && (
                                    <li className="mt-10 gray7 d-flex">
                                      <img
                                        className="mr-10 mt-13"
                                        src={linkIcon}
                                        alt="img"
                                        width="16"
                                          height="16"
                                      />
                                      <a
                                        className="small-text-font link-color textEllipse mt-10 w-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          candidate?.portfolioUrl4.indexOf(
                                            "://"
                                          ) === -1
                                            ? "http://" +
                                              candidate?.portfolioUrl4
                                            : candidate?.portfolioUrl4
                                        }
                                      >
                                        {candidate?.portfolioUrl4}
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </Box>
                            </Grid>
                            <Certification
                              certifications={candidate?.certifications}
                            />
                          </Grid>
                        </TabPanel>
                        <TabPanel value="reviews" className="p-0 mt-20">
                          <Grid item className="border-1 border-radius-7">
                            {openRatingAndReview && (
                              <Grid
                                item
                                ref={ratingAndReviewsRef}
                                className="bg-white p-20"
                                xs={12}
                                lg={12}
                              >
                                <RatingsAndReviews />
                              </Grid>
                            )}
                          </Grid>
                        </TabPanel>
                        <TabPanel value="Notes" className="p-0 mt-20">
                          <Grid item className="border-1 border-radius-7">
                            <Grid item xs={12} md={12} lg={12}>
                              <Notes
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                candidateApplicationData={
                                  candidateApplicationData
                                }
                                JobId={searchParams.get("talentConsoleStatus") ? JSON.parse(window.atob(searchParams.get("talentConsoleStatus")))?.jobId : null}
                                label={searchParams.get("talentConsoleStatus") ? JSON.parse(window.atob(searchParams.get("talentConsoleStatus")))?.label : ""}
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value="Timeline" className="p-0 mt-20">
                          <TimelineCandidate
                            candidate={candidate}
                            candidateId={props.candidate_id}
                            setIsLoading={setIsLoading}
                          />
                        </TabPanel>
                        <TabPanel value="download" className="p-0 mt-20">
                          <Grid item className="border-1 border-radius-7">
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                              className="bg-white border-radius-7"
                            >
                            <InfiniteScroll
                              dataLength={allDocuments.length}
                              next={fetchMoreDocumentsOthers}
                              hasMore={loadMore}
                              inverse={false}
                              height={330}
                            >
                            <Grid className="pr-20 pl-20 pt-12 pb-15 document-view" item lg={12} md={12} xs={12}>
                              <Grid item lg={12} md={12} xs={12} className="border-bottom-1">
                                <Typography className="font-weight-600 f-14 gray9">
                                  Profile
                                </Typography>
                                <Typography className="align-left mt-10 mb-10">
                                  <Checkbox
                                    {...label}
                                    sx={{
                                      color: "#98A2B3",
                                      padding: "0px",
                                      "&.Mui-checked": {
                                        color: getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue("--primary-color"),
                                        padding: "0px",
                                        "&.hoveer": {
                                          background: "none",
                                          padding: "0px",
                                        },
                                      },
                                    }}
                                    checked={selectedValues.profile}
                                    onChange={(e) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        profile: e.target.checked,
                                      })
                                    }
                                  />
                                  <span
                                    className="small-text-font pl-10 text-capitalize gray9 cursor-hover"
                                    onClick={(e) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        profile: !selectedValues.profile,
                                      })
                                    }
                                    tabIndex={0}
                                    onKeyPress={(e) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        profile: !selectedValues.profile,
                                      })
                                    }
                                  >
                                    {candidate?.firstName}’s Profile
                                  </span>
                                </Typography>
                              </Grid>
                              {/* <div className="public-profile-download"></div> */}
                              <Grid item
                                lg={12}
                                md={12}
                                xs={12}
                                className="border-bottom-1 pt-12 pb-12">
                                <Typography className="font-weight-600 f-14 gray9">
                                  Checklist
                                </Typography>
                                {candidateChecklist &&
                                candidateChecklist.length > 0 ? (
                                  candidateChecklist.map((checkList, index) => (
                                    <Typography
                                      className="align-left mt-10 d-flex align-center"
                                      key={index}
                                    >
                                      <Checkbox
                                        {...label}
                                        sx={{
                                          color: "#98A2B3",
                                          padding: "0px",
                                          "&.Mui-checked": {
                                            color: getComputedStyle(
                                              document.documentElement
                                            ).getPropertyValue(
                                              "--primary-color"
                                            ),
                                            padding: "0px",
                                            "&.hoveer": {
                                              background: "none",
                                              padding: "0px",
                                            },
                                          },
                                        }}
                                        checked={
                                          selectedValues.checkList.find(
                                            (d) => d === checkList.checkList.id
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleSelectCheklist(
                                            e,
                                            checkList.checkList.id
                                          )
                                        }
                                      />
                                      <Box
                                        className="small-text-font pl-10 gray9 cursor-hover"
                                        onClick={(e) =>
                                          handleSelectCheklist1(
                                            checkList.checkList.id
                                          )
                                        }
                                        style={{ paddingTop: "2px" }}
                                        onKeyPress={(e) =>
                                          handleSelectCheklist1(
                                            checkList.checkList.id
                                          )
                                        }
                                        tabIndex={0}
                                      >
                                        {checkList.checkList.checkListName}
                                      </Box>
                                    </Typography>
                                  ))
                                ) : (
                                  <Typography
                                    className="mt-10"
                                    style={{ color: "darkgray" }}
                                  >
                                    There is no checklist available at this
                                    time.
                                  </Typography>
                                )}
                              </Grid>
                              {/* <div className="public-profile-download"></div> */}
                              <Grid item className="pt-12 border-bottom-1 pb-12">
                              <Typography className="d-flex">
                                  <Typography className="font-weight-600 f-14 gray9 mr-15">
                                  Resume
                                </Typography>
                                  <Typography className="d-flex" onClick={addNewResumeDocument}>
                                    <AddSkills
                                      style={{ width: "16px" }}
                                      className="cursor-hover svg-icons"
                                      tabIndex={0}
                                    />
                                    <Box
                                      className="link-color f-14 font-weight-400 ml-5 skill-labl cursor-hover textDecor"
                                      onClick={() => {
                                      }}
                                      onKeyPress={() => {
                                      }}
                                      tabIndex={0}
                                    >
                                      Add Resume
                                    </Box>
                                  </Typography>
                                </Typography>
                              {(candidate?.resume && candidate?.resume.length > 0) || (resumeDocuments && resumeDocuments.length) ? (
                                  <>
                                    {candidate?.resume?.map((uploadResume, index) => (
                                      <Typography className="align-left mt-10" key={index}>
                                        <Checkbox
                                          {...label}
                                          sx={{
                                            color: "#98A2B3",
                                            padding: "0px",
                                            "&.Mui-checked": {
                                              color: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
                                              padding: "0px",
                                              "&.hoveer": {
                                                background: "none",
                                                padding: "0px",
                                              },
                                            },
                                          }}
                                          checked={
                                            selectedValues.resumes.find((d) => d.id === uploadResume.resumeId)
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => handleSelectResumes(e, uploadResume)}
                                        />
                                        <span
                                          className="small-text-font pl-10 cursor-hover gray9 pt-5"
                                          onClick={(e) => handleSelectResumes1(uploadResume)}
                                          onKeyPress={(e) => handleSelectResumes1(uploadResume)}
                                          tabIndex={0}
                                        >
                                          {uploadResume?.title}
                                        </span>
                                        {(uploadResume?.createdBy === null || params?.candidate_id === uploadResume?.createdBy) ? <span className='ml-30 small-text-font gray9'><i>{`(Uploaded by candidate)`}</i></span> :
                                        <span className='ml-30 small-text-font gray9'><i>{`(Uploaded by ${uploadResume?.createdByName} on ${dateFormat(uploadResume?.createdTimestamp)})`}</i></span>}
                                      </Typography>
                                    ))}
                                    {resumeDocuments.length > 0 && (
                                      <DocumentListing
                                        document={resumeDocuments}
                                        setDocuments={setResumeDocuments}
                                        type={"Resume"}
                                        setSelectValues={setEnterpriseSelectedResumes}
                                        selectedValues={enterpriseSelectedResumes}
                                        setIsLoading={setIsLoading}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Typography className="mt-10" style={{ color: "darkgray" }}>
                                    There is no resume available at this time.
                                  </Typography>
                                )}
                              </Grid>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="pt-12 border-bottom-1 pb-12"
                              >
                                <Typography className="d-flex">
                                <Typography className="font-weight-600 f-14 gray9 mr-15">
                                  Others
                                </Typography>
                                <Typography className="d-flex" onClick = {addNewFileDocument}>
                                <AddSkills
                        style={{ width: "16px" }}
                        className="cursor-hover svg-icons"
                        tabIndex={0}
                      />
                      <Box
                        className="link-color f-14 font-weight-400 ml-5 skill-labl cursor-hover textDecor"
                        onClick={() => {
                        }}
                        onKeyPress={() => {
                        }}
                        tabIndex={0}
                      >
                        Add File
                      </Box>
                      </Typography>
                                </Typography>
                                {otherDocuments && otherDocuments?.length > 0 ? (<DocumentListing document={otherDocuments} setDocuments={setOtherDocuments} type={"Other"} setSelectValues={setEnterpriseSelectedFiles} selectedValues={enterpriseSelectedFiles} setIsLoading={setIsLoading}/>): (
                                  <Typography className="mt-10" style={{ color: "darkgray" }}>
                                    There is no file available at this time.
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            </InfiniteScroll>
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                              className="p-20"
                            >
                              <Grid item className="justify-end d-flex mb-30">
                                <Button
                                  className="btn primary-button mb-10"
                                  disabled={
                                    !selectedValues.profile &&
                                    !selectedValues.checkList.length > 0 &&
                                    !selectedValues.resumes.length > 0 &&
                                    !enterpriseSelectedFiles.length > 0 &&
                                    !enterpriseSelectedResumes.length > 0
                                  }
                                  onClick={() => DownloadProfile()}
                                  disableRipple="true"
                                >
                                  Download
                                </Button>
                              </Grid>
                            </Grid>

                            <Grid item lg={4} md={4} xs={12}>
                              {isAuthenticated && (
                                <Grid>
                                  <ActionButtons
                                    isLoading={isLoading}
                                    isNotes={true}
                                    setIsLoading={setIsLoading}
                                    candidateApplicationData={
                                      candidateApplicationData
                                    }
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value="ProjectDetails" className="p-0 mt-20">
                          <ProjectLabelEnhancement
                            candidate={candidate}
                            candidateId={params.candidate_id}
                            setIsLoading={setIsLoading}
                            // job={job}
                          />
                      </TabPanel>
                      </TabContext>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container item spacing={2} lg={12} md={12} xs={12} className="m-0">
              <Grid item lg={12} md={12} xs={12} className="pl-0">
                <Grid item>
                <Grid item className="bg-white d-flex align-between pl-20 pr-20 pt-10 pb-40 jobListFilter align-end responsive-block">
                  <Typography>
                    <Box className="justify-end d-flex p-absolute">
                      <Grid item className="user-pic-block">
                        <Grid item className="profile-pic justify-content-start">
                          {(candidate.mediaFileSigned === null ||
                            candidate.mediaFileSigned === "") && (
                              <Typography className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-30 font-weight-700 text-capitalize profileImage"
                                left="0px" aria-label="Default image">
                                {candidate.firstName.trim().substring(0, 1)}
                              </Typography>
                            )}
                          {candidate.mediaFileSigned &&
                            candidate.mediaFileSigned !== null &&
                            candidate.mediaFileSigned !== "" &&
                            !checkIsImage(candidate.mediaFileSigned) && (
                              <>
                                <video
                                  className="profileImage default-img-border cursor-hover cursor-hover p-relative"
                                  style={{ left: "0px" }}
                                  src={candidate.mediaFileSigned}
                                  onClick={() => {
                                    handleOpenVideoPreview(
                                      candidate.mediaFileSigned,
                                      candidate.firstName + " " + candidate.lastName
                                    );
                                  }}
                                  muted
                                  onKeyPress={() => {
                                    handleOpenVideoPreview(
                                      candidate.mediaFileSigned,
                                      candidate.firstName + " " + candidate.lastName
                                    );
                                  }}
                                  tabIndex={0}
                                />
                                <img
                                  onClick={() => {
                                    handleOpenVideoPreview(
                                      candidate.mediaFileSigned,
                                      candidate.firstName + " " + candidate.lastName
                                    );
                                  }}
                                  className="profileImage-overlay play-icon-profile cursor-hover"
                                  style={{ top: "-10px", left: "42px" }}
                                  src={playIcon}
                                  alt="img"
                                  tabIndex={0}
                                  onKeyPress={() => {
                                    handleOpenVideoPreview(
                                      candidate.mediaFileSigned,
                                      candidate.firstName + " " + candidate.lastName
                                    );
                                  }}
                                />
                              </>
                            )}
                          {candidate.mediaFileSigned &&
                            candidate.mediaFileSigned !== null &&
                            candidate.mediaFileSigned !== "" &&
                            checkIsImage(candidate.mediaFileSigned) && (
                              <img
                                className="profileImage default-img-border"
                                src={candidate.mediaFileSigned}
                                alt="img"
                                style={{ left: "0px" }}
                              />
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ ml: 14 }} className="degreeName">
                      <Box className="mt-15">
                        <Typography
                          variant="h5"
                          className="sub-heading-font gray9 font-weight-600 mt-10 font-14 break-word w-300 launchTitle userName-align width-responsive-100 history-align status-align"
                        >
                          {candidate.firstName} {candidate.lastName}
                          <VerifiedIcon className="ml-5 success-color small-text-font" aria-label="Verified icon" />
                        </Typography>
                        <Typography
                          variant="span"
                          className="gray7 f-14 align-center d-flex userName-align"
                        >
                          {candidate.profileTitle}{" "}
                          {candidate.address1?.length > 0 && (
                            <FiberManualRecordIcon className="px-5" aria-label="Dot icon" />
                          )}{" "}
                          {candidate.address1}
                        </Typography>
                        <Typography
                          variant="span"
                          className="gray7 f-14 align-center d-flex userName-align responsive-block"
                        >
                          {(candidate.noOfRatings) ? (
                            <>
                              <Rating
                                count={5}
                                onChange={ratingChanged}
                                value={candidate?.candidateRating ? candidate?.candidateRating : 0}
                                name="half-rating"
                                readOnly
                                sx={{
                                  "& .MuiRating-icon": {
                                    width: '20px'
                                  }
                                }}
                                icon={
                                  <img
                                    src={starFilled}
                                    style={{ width: "18px", height: "18px" }}
                                    alt="star icon"
                                  />
                                }
                                emptyIcon={
                                  <img
                                    src={starEmpty}
                                    style={{ width: "18px", height: "18px" }}
                                    alt="img"
                                  />
                                }
                              />
                              <span
                                onClick={() => {
                                  handleChangeReview("reviews");
                                  openRatingAndReviewSection;
                                }
                                }
                                className="link-color pt-5 responsive-block"
                                tabIndex={0}
                              >
                                ({candidate.noOfRatings}{" "}
                                {candidate.noOfRatings <= 1 ? "rating" : "ratings"})
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <Typography
                          variant="span"
                          className="gray7 pt-10 f-14 align-center d-flex userName-align responsive-block"
                        >
                          <img alt="Availability icon"
                            className="icon-margin"
                            src={availabilityIcon}
                          />
                          {candidate?.availableDate !== null && (
                            <span className="f-14 gray9 pl-5 word-break" style={{ paddingTop: "1px" }}>
                              Available from{" "}
                              {moment(formatDate(new Date(JSON.parse(JSON.stringify(candidate?.availableDate).replace("23:59:59", "00:00:00"))))).format("MMM DD, YYYY")}
                            </span>
                          )}
                          {candidate?.availableDate === null && (
                            <span className="f-14 gray9 pl-5 word-break" style={{ paddingTop: "1px" }}>
                              {candidate?.inCandidatePool === true ? "Available immediately" : "May be available"}
                            </span>
                          )}
                          <Tooltip
                            placement="right"
                            title="This information is based on projects the candidate executed on this platform."
                          >
                            <IconButton style={{ verticalAlign: "middle", padding: "4px 2px 2px 2px" }} aria-label="Info icon">
                              <InfoIcon aria-label="Info icon" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography>
                    <Typography className="d-flex align-center descriptionSpace mt-20 responsive-block score-align">
                      {params.status !== "" && (
                        <>
                          <Popover
                            id={id}
                            open={open3}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                          >
                            <Grid style={{ width: "260px" }} sx={{ p: 1 }}>
                              <Box className="align-between mb-5">
                                <Grid item xs={12}>
                                  <Typography
                                    align="right"
                                    className="cursor-hover gray7 icon-button"
                                  >
                                    <CloseIcon onClick={handleClosePopover} onKeyPress={handleClosePopover} aria-label="Close icon" tabIndex={0} />
                                  </Typography>
                                </Grid>
                              </Box>
                              <Box className="border-bottom-1">
                                <Box className="align-between mb-5 ">
                                  <span className="gray9 f-14 font-weight-600 pl-7">
                                    Overall Score
                                  </span>
                                  <span
                                    className="f-14 success-color font-weight-600"
                                    style={{ paddingRight: "17px" }}
                                  >
                                    {candidatePopUp
                                      ? Math.round(
                                        candidatePopUp.matchingScore
                                          ? candidatePopUp.matchingScore
                                          : 0
                                      )
                                      : ""}
                                    %
                                  </span>
                                </Box>
                              </Box>

                              <Grid item
                                className="p-10"
                                style={{ maxHeight: "250px", overflow: "auto" }}
                              >
                                <Box className="mb-5 mt-10">
                                  <Typography className="align-between">
                                    <span className="gray9 f-14 font-weight-600">
                                      Skills
                                    </span>
                                    <span className="f-14 success-color text-lowercase font-weight-600">
                                      {candidatePopUp?.matchedFilters?.skills?.score
                                        ? Math.round(
                                          candidatePopUp.matchedFilters.skills
                                            .score
                                        ) + "%"
                                        : ""}
                                    </span>
                                  </Typography>
                                  <Typography className="gray7 f-14 mt-5 text-lowercase ">
                                    <CheckCircleIcon className="success-color vertical-align-middle" aria-label="Matched icon" />
                                    <span className="pl-7 font-weight-600 text-capitalize">
                                      Matched
                                    </span>
                                    <br />
                                    <span
                                      style={{ whiteSpace: "pre-line", width: "185px" }}
                                      className="gray9 pl-25 break-all d-flex">
                                      {candidatePopUp?.matchedFilters.skills
                                        ?.keyword
                                        ? getFormattedSkills(
                                          candidatePopUp.matchedFilters.skills
                                            .keyword
                                        )
                                        : "-"}
                                    </span>
                                  </Typography>
                                  <Typography className="gray7 f-14 mt-5 text-lowercase">
                                    <CheckCircleIcon className="warn-color vertical-align-middle" aria-label="Matched icon" />
                                    <span className="pl-7 font-weight-600 text-capitalize">
                                      Partially Matched
                                    </span>
                                    <br />
                                    <span
                                      style={{ whiteSpace: "pre-line", width: "185px" }}
                                      className="gray9 pl-25 break-all d-flex">
                                      {candidatePopUp?.matchedFilters.partialSkills
                                        ?.keyword
                                        ? getFormattedSkills(
                                          candidatePopUp.matchedFilters
                                            .partialSkills.keyword
                                        )
                                        : "-"}
                                    </span>
                                  </Typography>
                                  <Typography className="gray7 f-14 mt-5 text-lowercase">
                                    <CancelRoundedIcon className="danger-color vertical-align-middle" aria-label="Unmatched icon" />
                                    <span className="pl-7 font-weight-600 text-capitalize">
                                      Unmatched
                                    </span>
                                    <br />
                                    <span
                                      style={{ whiteSpace: "pre-line", width: "185px" }}
                                      className="gray9 pl-25 break-all d-flex">
                                      {candidatePopUp?.unmatchedFilters?.skills
                                        ? getFormattedSkills(
                                          candidatePopUp.unmatchedFilters.skills
                                        )
                                        : "-"}
                                    </span>
                                  </Typography>
                                </Box>

                                <Box className="mb-5 mt-10">
                                  <Typography className="align-between">
                                    <span className="gray9 f-14 font-weight-600">
                                      Experience
                                    </span>
                                    <span className="f-14 success-color font-weight-600">
                                      {preferredExp
                                        ? ""
                                        : candidatePopUp?.matchedFilters
                                          ?.overallYearsOfExperience?.score
                                          ? Math.round(
                                            candidatePopUp.matchedFilters
                                              .overallYearsOfExperience.score
                                          ) + "%"
                                          : candidatePopUp?.matchedFilters
                                            ?.partialYearsOfExperience?.score
                                            ? Math.round(
                                              candidatePopUp?.matchedFilters
                                                ?.partialYearsOfExperience?.score
                                            ) + "%"
                                            : "0%"}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    <span className="gray7 f-14 font-weight-600">
                                      {candidatePopUp?.matchedFilters
                                        ?.overallYearsOfExperience
                                        ? "Matched"
                                        : candidatePopUp?.matchedFilters
                                          ?.partialYearsOfExperience
                                          ? "Partially Matched"
                                          : "Unmatched"}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    <Box className=" align-center mt-5">
                                      {candidatePopUp?.matchedFilters
                                        ?.overallYearsOfExperience ? (
                                        <CheckCircleIcon className="success-color vertical-align-middle" aria-label="Matched icon" />
                                      ) : candidatePopUp?.matchedFilters
                                        ?.partialYearsOfExperience ? (
                                        <CheckCircleIcon className="warn-color vertical-align-middle" aria-label="Matched icon" />
                                      ) : (
                                        <CancelRoundedIcon className="danger-color vertical-align-middle" aria-label="Unmatched icon" />
                                      )}
                                      <span className="f-14 gray7 pl-7 font-weight-600">
                                        Preferred Job Experience
                                      </span>
                                      <br />
                                      {job.overallExperienceMaximum >= 1 ? (
                                        <span className="f-14 gray9 pl-25">
                                          {job.overallExperienceMinimum}-
                                          {job.overallExperienceMaximum} Years
                                        </span>
                                      ) : (
                                        <span className="f-14 gray9 pl-25">
                                          {job.overallExperienceMinimum}-
                                          {job.overallExperienceMaximum} Year
                                        </span>
                                      )}
                                    </Box>
                                  </Typography>
                                  <Typography>
                                    <Box className=" align-center mt-5">
                                      {candidatePopUp?.matchedFilters
                                        ?.overallYearsOfExperience ? (
                                        <CheckCircleIcon className="success-color vertical-align-middle" aria-label="Matched icon" />
                                      ) : candidatePopUp?.matchedFilters
                                        ?.partialYearsOfExperience ? (
                                        <CheckCircleIcon className="warn-color vertical-align-middle" aria-label="Matched icon" />
                                      ) : (
                                        <CancelRoundedIcon className="danger-color vertical-align-middle" aria-label="Unmatched icon" />
                                      )}
                                      <span className="f-14 gray7 pl-7 font-weight-600">
                                        Candidate Experience
                                      </span>
                                      <br />
                                      <span className="f-14 gray9 pl-25">
                                        {candidatePopUp
                                          ? candidatePopUp.overallYearsOfExperience +
                                          (candidatePopUp.overallYearsOfExperience <=
                                            1.0
                                            ? " Year"
                                            : " Years")
                                          : ""}
                                      </span>
                                    </Box>
                                  </Typography>
                                </Box>
                                {(candidatePopUp?.matchedFilters?.location ||
                                  candidatePopUp?.unmatchedFilters?.location) && (
                                    <Box className="m-b-5 mt-10">
                                      <Typography className="align-between">
                                        <span className="gray9 f-14 font-weight-600">
                                          Location{" "}
                                        </span>
                                        <span className="f-14 success-color font-weight-600">
                                          {candidatePopUp?.matchedFilters?.location
                                            ?.score
                                            ? Math.round(
                                              candidatePopUp.matchedFilters.location
                                                .score
                                            ) + "%"
                                            : ""}
                                        </span>
                                      </Typography>
                                      {candidatePopUp?.matchedFilters?.location
                                        ?.score ? (
                                        <Box className=" align-center mt-5">
                                          <CheckCircleIcon
                                            style={{
                                              verticalAlign: "middle",
                                              marginRight: 5,
                                            }}
                                            className="success-color vertical-align-middle"
                                            aria-label="Matched icon"
                                          />
                                          <span className="gray9 f-14 pl-28">
                                            {candidatePopUp?.city
                                              ? candidatePopUp?.country
                                                ? candidatePopUp.city +
                                                ", " +
                                                candidatePopUp.country
                                                : candidatePopUp.city
                                              : candidatePopUp?.country
                                                ? candidatePopUp.country
                                                : "-"}
                                          </span>
                                        </Box>
                                      ) : (
                                        <>
                                          <Box className=" align-center mt-5">
                                            {candidatePopUp?.unmatchedFilters
                                              ?.location ? (
                                              <CancelRoundedIcon
                                                style={{
                                                  verticalAlign: "middle",
                                                  marginRight: 5,
                                                }}
                                                className="danger-color vertical-align-middle"
                                                aria-label="Unmatched icon"
                                              />
                                            ) : (
                                              <CheckCircleIcon
                                                style={{
                                                  verticalAlign: "middle",
                                                  marginRight: 5,
                                                }}
                                                className="success-color vertical-align-middle"
                                                aria-label="Matched icon"
                                              />
                                            )}
                                            <span className="f-14 gray7 p-l-5 font-weight-600">
                                              Job Location
                                            </span>
                                            <br />
                                            {candidatePopUp?.unmatchedFilters
                                              ?.location && (
                                                <Typography className="gray9 f-14 pl-28">
                                                  <span className="f-14 gray9 pl-25">
                                                    {candidatePopUp.unmatchedFilters.location
                                                      .split(",")
                                                      .join(", ")}
                                                  </span>
                                                </Typography>
                                              )}
                                          </Box>

                                          <Box className=" align-center mt-5">
                                            {candidatePopUp?.unmatchedFilters
                                              ?.location ? (
                                              <CancelRoundedIcon
                                                style={{
                                                  verticalAlign: "middle",
                                                  marginRight: 5,
                                                }}
                                                className="danger-color vertical-align-middle"
                                                aria-label="Unmatched icon"
                                              />
                                            ) : (
                                              <CheckCircleIcon
                                                style={{
                                                  verticalAlign: "middle",
                                                  marginRight: 5,
                                                }}
                                                className="success-color vertical-align-middle"
                                                aria-label="Matched icon"
                                              />
                                            )}
                                            <span className="f-14 gray7 p-l-5 font-weight-600">
                                              Candidate Location
                                            </span>
                                            <br />
                                            {
                                              <Typography className="gray9 f-14 pl-28">
                                                <span className="f-14 gray9 pl-25">
                                                  {candidatePopUp?.city
                                                    ? candidatePopUp?.country
                                                      ? candidatePopUp.city +
                                                      ", " +
                                                      candidatePopUp.country
                                                      : candidatePopUp.city
                                                    : candidatePopUp?.country
                                                      ? candidatePopUp.country
                                                      : "-"}
                                                </span>
                                              </Typography>
                                            }
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                  )}

                                <Box className="mb-5 mt-10">
                                  <Typography className="align-between">
                                    <span className="gray9 f-14 font-weight-600">
                                      Profile Title
                                    </span>

                                    {candidatePopUp?.matchedFilters.title && (
                                      <span className="f-14 success-color font-weight-600">
                                        {preferredExp
                                          ? ""
                                          : candidatePopUp?.matchedFilters?.title
                                            ?.score
                                            ? Math.round(
                                              candidatePopUp.matchedFilters.title
                                                .score
                                            ) + "%"
                                            : "0%"}
                                      </span>
                                    )}
                                    {candidatePopUp?.unmatchedFilters.title && (
                                      <span className="f-14 success-color font-weight-600">
                                        {preferredExp
                                          ? ""
                                          : candidatePopUp?.matchedFilters?.title
                                            ?.score
                                            ? Math.round(
                                              candidatePopUp.matchedFilters.title
                                                .score
                                            ) + "%"
                                            : "0%"}
                                      </span>
                                    )}
                                  </Typography>

                                  {candidatePopUp?.matchedFilters.title && (
                                    <Box className=" align-center mt-5">
                                      <CheckCircleIcon
                                        style={{
                                          verticalAlign: "middle",
                                          marginRight: 5,
                                        }}
                                        className="success-color vertical-align-middle"
                                        aria-label="Matched icon"
                                      />
                                      <span className="gray9 f-14 pl-28">
                                        {
                                          candidatePopUp?.matchedFilters.title
                                            .keyword
                                        }
                                      </span>
                                    </Box>
                                  )}

                                  {candidatePopUp?.unmatchedFilters.title && (
                                    <Box className=" align-center mt-5">
                                      <CancelRoundedIcon
                                        style={{
                                          verticalAlign: "middle",
                                          marginRight: 5,
                                        }}
                                        className="danger-color vertical-align-middle"
                                        aria-label="Unmatched icon"
                                      />
                                      <span className="gray9 f-14 pl-28">
                                        {candidatePopUp?.profileTitle}
                                      </span>
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Popover>
                        </>
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Modal
          open={openAssessment}
          onClose={handleCloseAssessment}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-content inputcontrasts">
            <Typography className="align-left">
              <Grid container item id="modal-title" className="align-center">
                <Grid item xs={6}>
                  <Typography
                    className="f-24 font-weight-600 heading-text-color"
                    variant="h5"
                  >
                    Screening Response
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="right"
                    className="cursor-hover gray7 icon-button"
                  >
                    <CloseIcon
                      onClick={handleCloseAssessment}
                      onKeyPress={handleCloseAssessment}
                      tabIndex={0}
                      aria-label="close icon"
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item id="modal-description">
                {answers?.length > 0
                  ? answers.map((answer, index) => (
                      <Grid
                        item
                        className={index === 0 ? "mt-20" : "mt-30"}
                        key={index}
                      >
                        <Typography className="font-weight-400 f-16 gray9">
                          Q{index + 1}. {answer.Question}
                        </Typography>
                        <Typography className="d-flex align-center mt-5">
                          <span className="gray7 f-14">Ans:</span>
                          {answer?.Answer ? (
                            <Box className="ml-5">
                              {moment(answer.Answer).isValid() &&
                              answer.Answer.length > 10
                                ? moment(answer.Answer).format("MMM DD, YYYY")
                                : typeof answer.Answer === "number" ? answer.Answer : typeof answer.Answer === "string"
                                ? (answer.Answer?.includes(",") && !answer.Answer?.includes(", ") ? answer.Answer?.split(",").join(", ") : answer.Answer)
                                : answer.Answer?.length > 0 ? answer.Answer.join(", ") : "-"}
                            </Box>
                          ) : (
                            <Box className="ml-5">-</Box>
                          )}
                        </Typography>
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Typography>
          </Box>
        </Modal>
        <CandidateProfileVideoPreview
          open={openVideoPreview}
          setOpen={setOpenVideoPreview}
          videoSrc={videoSrc}
          candidateName={candidateName}
        />
      </Grid>
      <Modal
        open={showCandidateChecklistOverlay}
        onClose={() => setShowCandidateChecklistOverlay(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content checklist-modal responsive-checklist-modal inputcontrasts">
          <Typography className="align-left">
            <Grid container item id="modal-title" className="align-center">
              <Grid item xs={10}>
                <Typography className="f-24 font-weight-600 heading-text-color pl-5">
                  View Checklist
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  align="right"
                  className="cursor-hover gray7 icon-button"
                >
                  <CloseIcon
                    onClick={() => setShowCandidateChecklistOverlay(false)}
                    aria-label="Close icon"
                    tabIndex={0}
                    onKeyPress={() => setShowCandidateChecklistOverlay(false)}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid item id="modal-description" style={{ maxHeight: "500px" }}>
              <ChecklistForm
                selectedCandidateChecklist={selectedCandidateChecklist}
              />
            </Grid>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={downloadPopup}
        onClose={() => {
          setDownloadPopup(false);
          setSelectedValues({ profile: true, checkList: [], resumes: [] });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Grid container item id="modal-title">
            <Grid item xs={6}>
              <Typography
                className="f-21 font-weight-600 mb-5 heading-text-color pl-5"
                variant="h5"
              >
                Download Profile
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={() => {
                    setDownloadPopup(false);
                    setSelectedValues({
                      profile: true,
                      checkList: [],
                      resumes: [],
                    });
                  }}
                  aria-label="Close icon"
                  onKeyPress={() => {
                    setDownloadPopup(false);
                    setSelectedValues({
                      profile: true,
                      checkList: [],
                      resumes: [],
                    });
                  }}
                  tabIndex={0}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid item id="modal-description">
            <Grid item>
              {/* <Grid item xs={12}>
                        <Typography className="gray9 pb-5">
                           You can select the following options to download
                        </Typography>
                </Grid> */}
              <Typography className="font-weight-600">Profile</Typography>
              <Typography className="align-left mt-5">
                <Checkbox
                  {...label}
                  sx={{
                    color: "#98A2B3",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  checked={selectedValues.profile}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      profile: e.target.checked,
                    })
                  }
                />
                <span
                  className="small-text-font pl-10 text-capitalize gray9 cursor-hover"
                  onClick={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      profile: !selectedValues.profile,
                    })
                  }
                  tabIndex={0}
                  onKeyPress={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      profile: !selectedValues.profile,
                    })
                  }
                >
                  {candidate?.firstName}’s Profile
                </span>
              </Typography>
            </Grid>
            <div className="public-profile-download"></div>
            <Grid item>
              <Typography className="font-weight-600 mt-10">
                Checklist
              </Typography>
              {candidateChecklist && candidateChecklist.length > 0 ? (
                candidateChecklist.map((checkList, index) => (
                  <Typography className="align-left mt-5" key={index}>
                    <Checkbox
                      {...label}
                      sx={{
                        color: "#98A2B3",
                        padding: "0px",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                          padding: "0px",
                          "&.hoveer": {
                            background: "none",
                            padding: "0px",
                          },
                        },
                      }}
                      checked={
                        selectedValues.checkList.find(
                          (d) => d === checkList.checkList.id
                        )
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleSelectCheklist(e, checkList.checkList.id)
                      }
                    />
                    <span
                      className="small-text-font pl-10 gray9 cursor-hover"
                      onClick={(e) =>
                        handleSelectCheklist1(checkList.checkList.id)
                      }
                      onKeyPress={(e) =>
                        handleSelectCheklist1(checkList.checkList.id)
                      }
                      tabIndex={0}
                    >
                      {checkList.checkList.checkListName}
                    </span>
                  </Typography>
                ))
              ) : (
                <Typography className="mt-10" style={{ color: "darkgray" }}>
                  There is no checklist available at this time.
                </Typography>
              )}
            </Grid>
            <div className="public-profile-download"></div>
            <Grid item>
              <Typography className="font-weight-600 mt-10">Resume</Typography>
              {candidate?.resume && candidate?.resume.length > 0 ? (
                candidate?.resume?.map((uploadResume, index) => (
                  <Typography className="align-left mt-5" key={index}>
                    <Checkbox
                      {...label}
                      sx={{
                        color: "#98A2B3",
                        padding: "0px",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                          padding: "0px",
                          "&.hoveer": {
                            background: "none",
                            padding: "0px",
                          },
                        },
                      }}
                      checked={
                        selectedValues.resumes.find(
                          (d) => d.id === uploadResume.resumeId
                        )
                          ? true
                          : false
                      }
                      onChange={(e) => handleSelectResumes(e, uploadResume)}
                    />
                    <span
                      className="small-text-font pl-10 gray9 cursor-hover"
                      onClick={(e) => handleSelectResumes1(uploadResume)}
                      tabIndex={0}
                      onKeyPress={(e) => handleSelectResumes1(uploadResume)}
                    >
                      {uploadResume?.title}
                    </span>
                  </Typography>
                ))
              ) : (
                <Typography className="mt-10" style={{ color: "darkgray" }}>
                  There is no resume available at this time.
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item id="modal-footer">
            <Grid item className="mt-18 justify-center d-flex">
              <Button
                className="btn primary-button"
                disabled={
                  !selectedValues.profile &&
                  !selectedValues.checkList.length > 0 &&
                  !selectedValues.resumes.length > 0
                }
                onClick={() => DownloadProfile()}
                disableRipple="true"
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Popover
        id="simple-popover-list-work"
        open={openJobType}
        anchorEl={anchorEl2}
        onClose={handleCloseJobType}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box>
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 heading-text-color font-weight-600">
                  Work Type
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover gray7 f-14 icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseJobType}
                    onKeyPress={handleCloseJobType}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="f-16 font-family">
              {getFormattedWorkType(candidate?.workType, true)}
            </Box>
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id="simple-popover-list"
        open={openJobPreference}
        anchorEl={anchorEl3}
        onClose={handleCloseJobPreference}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box>
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 heading-text-color font-weight-600">
                  Job Preferences
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover gray7 f-14 icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseJobPreference}
                    onKeyPress={handleCloseJobPreference}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="f-16 font-family">
              {candidate?.jobPreferences?.split(",").join(", ")}
            </Box>
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id="simple-popover-list"
        open={openSpecialization}
        anchorEl={anchorEl4}
        onClose={handleCloseSpecialization}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box>
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 heading-text-color font-weight-600">
                  Specialization
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover gray7 f-14 icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseSpecialization}
                    onKeyPress={handleCloseSpecialization}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="f-16 font-family">{specializationString}</Box>
          </Grid>
        </Grid>
      </Popover>
      <MessageDialogModel
        open={confirmSendSms}
        setOpen={setConfirmSendSms}
        title="Send SMS"
        description={"Message for Candidate"}
        message={message}
        setMessage={setMessage}
        handleConfirmYes={(e) => sendSmsToCandidates()}
      />
      <GdprRestrictDownloadModal
        openGDPRDownloadRestrictPopup={openGDPRDownloadRestrictPopup}
        setOpenGDPRDownloadRestrictPopup={setOpenGDPRDownloadRestrictPopup}
      />
      <UploadResumeModal open={openAddResumeModal} setOpenAddResumeModal={setOpenAddResumeModal} jobId={params.job_id ? params.job_id : undefined} candidateId={params.candidate_id} setIsLoading={setIsLoading} setResumeDocuments={setResumeDocuments} resumeDocuments={resumeDocuments}/>
      <FileUploadModal open={openAddFileModal} setOpenAddFileModal={setOpenAddFileModal} jobId={params.job_id ? params.job_id : undefined} candidateId={params.candidate_id} setIsLoading={setIsLoading} setOtherDocuments={setOtherDocuments} otherDocuments={otherDocuments}/>
      <GdprRestrictEditCandidateModal
        openGDPREditCandidateRestrictPopup={openGDPREditCandidateRestrictPopup}
        setOpenGDPREditCandidateRestrictPopup={setOpenGDPREditCandidateRestrictPopup}
      />
    </>
  );
}
