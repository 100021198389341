import * as React from "react";
import { Typography, Grid, Container, Link, Box } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DirectHealthLogo from "../../assets/images/direct-healthcare-logo.svg";
import { useSelector } from "react-redux";
import TwitterIconsvg from "../../assets/images/twitter-icon.svg";
import FacebookIconsvg from "../../assets/images/FacebookIconFooter.svg";
import LinkedIconsvg from "../../assets/images/LinkedinIconFooter.svg";
import Constants from "../../helpers/constants";

const styles = {
  footerIcon: {
    padding: "6px",
    color: "#FFF",
    background: "#696871",
    borderRadius: "50%",
    width: "28px",
    height: "28px",
    cursor: "pointer",
  },
};

export default function Footer() {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  const termsOfuse = (e) => {
    e.preventDefault();
    window.open("../termsofuse");
  };
  const privacyPolicy = (e) => {
    e.preventDefault();
    window.open("../privacypolicy");
  };
  const cookiePolicy = (e) => {
    e.preventDefault();
    window.open("../cookiepolicy");
  };
  const secrecy = (e) => {
    e.preventDefault();
    window.open("../secrecypolicy");
  };
  const goToClient = (e) => {
    e.preventDefault();
    navigate("../candidatePortal");
  };
  return (
    <Grid>
      <Grid sx={{ py: 3, mt: 3 }} className="" style={{ background: "#F9FAFB" }}>
        <Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            spacing={1}
            className="footerNav mt-10 justify-center launch-align body-container w-100"
          >
            <Grid item lg={4} md={4} xs={12} className="pl-0 pt-0">
              {/* <Typography className="font-weight-600" variant="h6">
                hireHQ
            </Typography> */}
              <Typography className="cursor-hover" onClick={()=>window.scrollTo(0, 0)}>
                {appOrigin === "D" ?
                  <img
                    src={Constants.APP_LOGO_GENERAL}
                    alt="Logo" width="120"
                  /> : appOrigin === "N" ?
                    <img
                      src={Constants.APP_LOGO_NEURODIVERSIFY}
                      alt="Logo" width="120"
                    /> : <img
                      src={Constants.APP_LOGO_HEALTHCARE}
                      alt="Logo" width="120"
                    />}
                {/* <img src="https://daas-prog-test.s3.amazonaws.com/app_logo.png" width="120px" /> */}
              </Typography>
              {/* <Box
              className="small-text-font mt-20"
              style={{ lineHeight: "20px" }}
            >
              Explore the stories behind our diverse workforce.
            </Box> */}
              {appOrigin === "D" ?
                <Box
                  className="launch-page-subtext mt-20"
                  style={{ lineHeight: "20px" }}
                >
                hireHQ is a technology solution developed to enable businesses to source and engage independent workers – and vice-versa. Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more.
                </Box>
                : appOrigin === "N" ?
                  <Box
                    className="launch-page-subtext mt-20"
                    style={{ lineHeight: "20px" }}
                  >
                    {Constants.APP_NAME_NEURODIVERSIFY} is a technology solution developed by Simplify to enable businesses
                    to source and engage independent workers and vice-versa.  Both businesses and
                    workers benefit in terms of transparency, time-to-value, quality of fit, and more.
                  </Box>
                  : <Box
                    className="launch-page-subtext mt-20"
                    style={{ lineHeight: "20px" }}
                  >
                    {constants.APP_NAME_DIRECT_HEALTH} is a technology solution developed by Simplify to enable businesses
                    to source and engage independent workers and vice-versa.  Both businesses and
                    workers benefit in terms of transparency, time-to-value, quality of fit, and more.
                  </Box>
              }
              {/* <Box
                className="launch-page-subtext mt-20"
                style={{ lineHeight: "20px" }}
              >
                hireHQ is a technology solution developed to enable businesses to source and engage independent workers – and vice-versa. Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more.
              </Box> */}
              <Box className="mt-10">
                <a
                  href="https://www.linkedin.com/company/11181300/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn link"
                >
                  <img src={LinkedIconsvg}
                    className="footerIcon"
                    width="35" height="35"
                    aria-label="LinkedIn icon" />
                </a>
                <a
                  href="https://www.facebook.com/simplifyworkforce/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook link"
                >
                  <img src={FacebookIconsvg}
                    className="footerIcon"
                    width="35" height="35"
                    aria-label="Facebook icon" />
                </a>

                <a
                  href="https://twitter.com/simplifychatter"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter link"
                >
                  <img src={TwitterIconsvg}
                    className="footerIcon pb-5"
                    width="35" height="35"
                    aria-label="Twitter icon" />
                </a>
                {/* <GoogleIcon style={styles.footerIcon} />*/}
              </Box>
            </Grid>
            <Grid item lg={1} md={1} xs={12}></Grid>
            <Grid item lg={2} md={2} xs={6}>
              {/* <Typography className="font-weight-600" variant="strong">
                About
              </Typography>
              <ul className="pl-0">
                <li>
                <Link className="supporting-text-large" href="#howitwork">How it works</Link>{" "}
              </li>
                <li><Link href="#luna">Luna</Link> </li>
                <li><Link href="#details">Get in touch</Link> </li>
              </ul> */}
            </Grid>
            {/* <Grid item lg={2} xs={6} className="footerLearn footerNav">
                        <Typography className="font-weight-600" variant="strong">
                            Learn
                        </Typography>
                            <ul style={{ paddingLeft: '0px' }}>
                                <li>
                                    <Link className="small-text-font link-color cursor-hover" style={{ textDecoration: 'none' }}>FAQS</Link>
                                </li>

                                <li>
                                    <Link className="small-text-font link-color cursor-hover" onClick={goToClient} style={{ textDecoration: 'none' }}>Client</Link>
                                </li>
                            </ul>

                    </Grid> */}
            <Grid item lg={2.5} md={12} xs={12}>
              <Typography className="font-weight-600" variant="strong">
                Legal
              </Typography>
              <ul style={{ paddingLeft: "0px" }}>
                <li>
                  <Link
                    tabIndex={0}
                    className="cursor-hover launch-page-subtext decorationcolor"
                    to="../termsofuse"
                    onClick={termsOfuse}
                    onKeyPress={termsOfuse}
                  >
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link
                    tabIndex={0}
                    className="cursor-hover launch-page-subtext decorationcolor"
                    to="../privacypolicy"
                    onClick={privacyPolicy}
                    onKeyPress={privacyPolicy}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    tabIndex={0}
                    className="cursor-hover launch-page-subtext decorationcolor"
                    to="../cookiepolicy"
                    onClick={cookiePolicy}
                    onKeyPress={cookiePolicy}
                  >
                    Cookie Policy
                  </Link>
                </li>
                {/* <li>
                            <Link className="cursor-hover link-color decorationcolor" to="" onClick={secrecy}>Secrecy Policy</Link>
                            </li> */}
              </ul>
            </Grid>
            {/* <Grid item lg={3} xs={6} className="footerLearn">
                        <Typography className="font-weight-600" variant="strong">
                            Contact us
                        </Typography>
                        <Box sx={{ pt: 3 }}>
                            <Link className="small-text-font link-color" style={{ textDecoration: 'none' }} href="#">support@simplify.com</Link>
                            <Link className="small-text-font link-color mt-10" style={{ display: 'block', textDecoration: 'none' }} href="#" >+133-394-3439-1435</Link>
                        </Box>
                    </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
