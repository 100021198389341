import axios from "axios";
import ApiCalls from "../../api/customAxios";
import { authHeader } from "../../helpers/auth-header";

class AccessServiceClass {
  panelMembersUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/interview-panels/members";
  enterpriseUrl = process.env.REACT_APP_API_URL + "enterprises";
  enterpriseUrlExists = process.env.REACT_APP_API_URL + "enterprises/exists";
  authToken = process.env.REACT_APP_API_PUBLIC_URL + "candidates/oauth2/token";
  enterpriseUserUrl = process.env.REACT_APP_API_URL + "enterprises/user";
  updateCandidateUrl = process.env.REACT_APP_API_URL + "candidates";
  skillsRecommendation = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "skills?keyword=";
  postJob = process.env.REACT_APP_API_URL + "enterprises/jobs";
  enterpriseJobsUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/";
  enterpriseTopJobsUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/initial/";
  jobDetails = process.env.REACT_APP_API_URL + "enterprises/jobs/";
  updateSKills = process.env.REACT_APP_API_URL + "enterprises/jobs/skills/";
  deleteSkillUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/skills/";
  uploadJDUrl = process.env.REACT_APP_API_URL + "enterprises/uploadJd";
  enterpriseLogoutUrl = process.env.REACT_APP_API_URL + "candidates/logout";
  enterpriseListUrl = process.env.REACT_APP_API_URL + "enterprises/user";
  uploadFileUrl = process.env.REACT_APP_API_URL + "upload-file/enterprises";
  industriesURl = process.env.REACT_APP_API_URL + "skills/industry";
  addDeviceTokenUrl =
    process.env.REACT_APP_API_URL + "candidates/notification/device";
  analyticsUrl = process.env.REACT_APP_API_URL + "candidates/reports";
  inviteuser = process.env.REACT_APP_API_URL + "enterprises/user/invite";
  inviteuserExist = process.env.REACT_APP_API_URL + "enterprises/user/invite/exists";
  uploadBlukJobsUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/bulk/uploads";
  uploadBlukViaExcelJobsUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/excel/upload";
  saveAdvancedSearch = process.env.REACT_APP_API_URL + "enterprises/user/search";
  AllSavedsearch = process.env.REACT_APP_API_URL + "enterprises/user/search/all?";
  particularSearch = process.env.REACT_APP_API_URL + "enterprises/user/search";
  refreshTimeStamp = process.env.REACT_APP_API_URL + "enterprises/jobs/refresh/";
  blukJobsLogUrl = process.env.REACT_APP_API_URL + "enterprises/batch/upload/";
  enterpriseId = localStorage.getItem("enterpriseId");
  mspEnterprisesListing = process.env.REACT_APP_API_URL + "enterprises/msp/approval-enterprises/";
  mspEnterprsiesRequest = process.env.REACT_APP_API_URL + "enterprises/msp/approve-enterprises";
  mspInviteUrl = process.env.REACT_APP_API_URL + 'enterprises/msp/invite-enterprise';
  verifyCodeUrl = process.env.REACT_APP_API_URL + "enterprises/msp/approve-invite";
  parentMspListUrl = process.env.REACT_APP_API_URL + "enterprises/msp/parent-enterprises/";
  feedBackUrl = process.env.REACT_APP_API_URL + "feedback";
  feedBackExist = process.env.REACT_APP_API_URL + "feedback/exists";
  payrateSuggestion = process.env.REACT_APP_API_URL + "payrate/reports";
  accessibilityUrl = process.env.REACT_APP_API_URL + "enterprises/user/accessibility";
  interviewQuestionsAiUrl = process.env.REACT_APP_API_URL + "candidates/pool/get/ai/generated/interview/questions";
  edit_enterpriseEmail = process.env.REACT_APP_API_URL + "enterprises/communication/mail";
  JobWizardApiUrl = process.env.REACT_APP_API_URL + "enterprises/";
  generateSampleForWizardUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/sample/jd";
  MyJobSApiCallUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/";
  // AllJobApiCallUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/sample/jd";
  // /0/10?myJobs=true
  scheduleInterview = process.env.REACT_APP_API_URL +"enterprises/pool/interview/update";
  cancelinterview = process.env.REACT_APP_API_URL +"enterprises/interviews/schedules/cancel";
  locationTimezoneUrl = process.env.REACT_APP_API_URL + "places/timezone";
  getLoggedInUserDetailsUrl = process.env.REACT_APP_API_URL +"enterprises/user/";
  sendShortlistEmailUrl = process.env.REACT_APP_API_URL +"enterprises/pool/shortlist/send/email";
  sendSMSUrl = process.env.REACT_APP_API_URL +"enterprises/sendSms";
  projectLabelDetails = process.env.REACT_APP_API_URL +"enterprises/pool/candidate/project/details";
  enterpriseDocuments =   process.env.REACT_APP_API_URL + "enterprises/candidates/documents";
  smartFilterUrl = process.env.REACT_APP_API_URL + "enterprises/user/smart-filters";
  async cancelthisinterview(payLoad) {
    return ApiCalls.put(this.cancelinterview, payLoad);
  }
  generateOfferLetterUrl = process.env.REACT_APP_API_URL + "enterprises/offerLetter";
  exportTalentDataUrl = process.env.REACT_APP_API_URL + "enterprises/dashboard/statistics/exportcsv";

  async movetointerview(payLoad) {
    return ApiCalls.put(this.scheduleInterview, payLoad);
  }
  async exportTalentConsoleData(fromDate, toDate, fileName) {
    const params = new URLSearchParams();
    if (fromDate) params.append('fromDate', fromDate);
    if (toDate) params.append('toDate', toDate);
    const queryString = params.toString();
    const url = `${this.exportTalentDataUrl}${queryString ? '?' + queryString : ''}`;
    return ApiCalls.getCSV(url, fileName);
  }
  async smartFilter(method, payload) {
    if (method === "put") {
      return ApiCalls.put(this.smartFilterUrl, payload);
    } else {
      return ApiCalls.get(this.smartFilterUrl);
    }
  }
  async sendShortlistEmail(payload) {
    return ApiCalls.put(this.sendShortlistEmailUrl, payload);
  }
  async sendSMS(candidateId, payload) {
    return ApiCalls.post(`${this.sendSMSUrl}/${candidateId}`, payload);
  }
  async getUserList() {
      return ApiCalls.get(this.enterpriseUrl);
    }
  async getProjectDetails(candidateId, query) {
    return ApiCalls.get(`${this.projectLabelDetails}/${candidateId}?${query}`);
  }
    async getLoggedInUserDetails(enterpriseId) {
      return ApiCalls.get(`${this.getLoggedInUserDetailsUrl}${enterpriseId}`);
    }
  async getMyJobList(pageNo) {
    return ApiCalls.get(`${this.MyJobSApiCallUrl}${pageNo}?myJobs=true`);
  }
  async getUsers(page, size, keyword) {
    return ApiCalls.getEntireResponseData(this.enterpriseUrl + "/user/" + page + "/" + size + `?candidateSearchName=${keyword}&filters=${(keyword === '' || keyword === undefined) ? false : true}`);
  }
  async getResumeDocuments (pageNo, Size, enterpriseId, candidateId, jobId) {
    if (jobId) {
    return ApiCalls.get(`${this.enterpriseDocuments}/${pageNo}/${Size}?enterpriseId=${enterpriseId}&candidateId=${candidateId}&jobId=${jobId}`);
    } else {
    return ApiCalls.get(`${this.enterpriseDocuments}/${pageNo}/${Size}?enterpriseId=${enterpriseId}&candidateId=${candidateId}`);
    }
  }
  async resumeUpload(payload) {
    return ApiCalls.post(this.enterpriseDocuments, payload, true);
  }
  async fileResumeDelete(payload) {
    return ApiCalls.delete(`${this.enterpriseDocuments}/${payload}`);
  }

  async addEnterpriseName(payload) {
    return ApiCalls.put(this.enterpriseUrl, payload);
  }
  async sendFeedBack(payload) {
    return ApiCalls.post(this.feedBackUrl, payload);
  }
  async getQuestions(pageNo, payload) {
    return ApiCalls.post(this.interviewQuestionsAiUrl + "/" + pageNo + "/10", payload);
  }
  async CreateJobWithWizard(payload) {
    return ApiCalls.post(this.JobWizardApiUrl + "generate-jd", payload);
  }
  async generateSampleForWizard() {
    return ApiCalls.get(this.generateSampleForWizardUrl);
  }
  async CreateJobWithWizard(payload) {
    return ApiCalls.post(this.JobWizardApiUrl +"generate-jd", payload);
  }
  async generateSampleForWizard() {
    return ApiCalls.get(this.generateSampleForWizardUrl);
  }
  async payRateSuggestionAPI(payload) {
    return ApiCalls.put(this.payrateSuggestion, payload);
  }
  async getQuestions(pageNo, payload) {
    return ApiCalls.post(this.interviewQuestionsAiUrl + "/" + pageNo + "/10", payload);
  }

  async getisFeedBackExist() {
    return ApiCalls.get(this.feedBackExist);
  }
  async getAccessibility() {
    return ApiCalls.get(this.accessibilityUrl);
  }
  async putEnterprise(payload) {
    return ApiCalls.put(this.enterpriseUrl, payload);
  }
  async editEnterpriseEmail(payLoad) {
    return ApiCalls.put(this.edit_enterpriseEmail, payLoad);
  }
  async sendMspReques(payload) {
    return ApiCalls.put(this.mspEnterprsiesRequest, payload);
  }
  async userMspInvite(payload) {
    return ApiCalls.put(this.mspInviteUrl, payload);
  }
  async verfiyMspinviteCode(payload) {
    return ApiCalls.put(this.verifyCodeUrl, payload);
  }
  async getMSPEnterprises(candidateId, params) {
    return ApiCalls.get(this.mspEnterprisesListing + candidateId + `?establishmentName=${params}`);
  }
  async getParentMspEnteprises(childEnterpriseId) {
    return ApiCalls.get(this.parentMspListUrl + childEnterpriseId);
  }

  async editEnterpriseUserList(payload) {
    return ApiCalls.put(this.enterpriseListUrl, payload);
  }

  async addScoringModel(payload) {
    return ApiCalls.put(this.enterpriseUrl, payload);
  }

  async deleteEnterpriseUsers(id) {
    return ApiCalls.delete(this.enterpriseListUrl + "/" + id);
  }

  async uploadFile(enterprisId, payload) {
    return ApiCalls.post(this.uploadFileUrl + "/" + enterprisId, payload);
  }

  async getEnterprises() {
    return ApiCalls.get(this.enterpriseUrl);
  }

  async getAllEnterprises() {
    return ApiCalls.get(this.enterpriseUrl + "?isAll=true");
  }

  async getEnterprisesJobs(page, keyword, params) {
    return ApiCalls.get(
      this.enterpriseJobsUrl +
      (page ? page : 0) +
      "/10?" +
      (keyword ? "title=" + keyword + "&" : "") +
      (params && params.status ? "jobStatus=" + params.status + "&" : "") +
      (params && params.type ? "jobType=" + params.type + "&" : "") +
      (params && params.myJobs ? "myJobs=" + params.myJobs + "&" : "")
    );
  }

  async getBlukJobsLog(enterpriseId, page, payload) {
    return ApiCalls.postEntireResponseData(
      this.blukJobsLogUrl + enterpriseId + "/" + (page ? page : 0) + "/10", payload
    );
  }
  async blukJobsLogFilter(filter, enterpriseId) {
    return ApiCalls.get(
      this.blukJobsLogUrl + "filter/" + enterpriseId + `?type=${filter}`
    );
  }
  async getEnterprisesAllJobs() {
    return ApiCalls.get(this.enterpriseJobsUrl + "30");
  }
  async postUserInviteCheck(email) {
    return ApiCalls.get(this.inviteuserExist + "?email=" + btoa(email));
  }
  async postUserInvite(payload) {
    return ApiCalls.post(this.inviteuser, payload);
  }

  async checkIfEnterpriseExists(payload) {
    const header = await authHeader();
    // return axios.put(this.enterpriseUrlExists, payload, { headers: header });
    return ApiCalls.put(this.enterpriseUrlExists, payload);
  }

  async getOuathToken(payload) {
    return ApiCalls.post(this.authToken +
      `?clientId=${payload.clientId}&code=${payload.code}&requestURI=${payload.requestURI}`);
  }

  async postEnterpriseUser(payload) {
    const header = await authHeader();
    return ApiCalls.post(this.enterpriseUserUrl, payload);
  }

  async updateCandidate(payload) {
    return ApiCalls.put(this.updateCandidateUrl, payload);
  }

  async postEnterprise(payload) {
    const header = await authHeader();
    return ApiCalls.post(this.enterpriseUrl, payload);
  }

  async skillsRecommendations(keyword, isHealthcare) {
    return ApiCalls.get(this.skillsRecommendation + keyword + (isHealthcare ? "&is-healthcare=true" : ""));
  }

  async postAJob(payload) {
    return ApiCalls.post(this.postJob, payload);
  }

  async postToJobBoards(jobId) {
    return ApiCalls.post(this.postJob + '/beanbag/' + jobId, '');
  }

  async updateAJob(payload) {
    return ApiCalls.put(this.postJob, payload);
  }

  async updateSkills(payload, jobId) {
    return ApiCalls.put(this.updateSKills + jobId, payload);
  }

  async getJobDetails(Id) {
    return ApiCalls.get(this.jobDetails + Id);
  }

  async deleteSkill(Id) {
    return ApiCalls.delete(this.deleteSkillUrl + Id);
  }

  async uploadJD(payload, enterpriseId) {
    return ApiCalls.post(this.uploadJDUrl + "/" + enterpriseId, payload);
  }

  async uploadBlukJobs(payload, enterpriseId) {
    return ApiCalls.post(this.uploadBlukJobsUrl + "/" + enterpriseId, payload);
  }
  async uploadBlukViaExcelJobs(payload) {
    return ApiCalls.post(this.uploadBlukViaExcelJobsUrl, payload);
  }
  async enterpriseLogout() {
    return ApiCalls.put(this.enterpriseLogoutUrl, "");
  }

  async getIndustries() {
    return ApiCalls.get(this.industriesURl);
  }

  // add device token to receive push notifications
  async addDeviceToken(payload) {
    return ApiCalls.put(this.addDeviceTokenUrl, payload);
  }

  async getJobBoardAnalytics(reportId) {
    return ApiCalls.get(this.analyticsUrl + "/" + reportId);
  }

  async saveSearch(payLoad) {
    return ApiCalls.put(this.saveAdvancedSearch, payLoad);
  }

  async getTopJobs() {
    return ApiCalls.get(this.enterpriseTopJobsUrl + "30");
  }

  async getSaveSearch(keyword) {
    return ApiCalls.get(this.AllSavedsearch + keyword);
  }

  async getSearchDetails(id) {
    return ApiCalls.get(this.particularSearch + "/" + id);
  }

  async updateTimeStamp(id) {
    return ApiCalls.patch(this.refreshTimeStamp + id);
  }

  async getPanelMembers(enterpriseId) {
    return ApiCalls.get(this.panelMembersUrl + `?enterpriseId=${enterpriseId}`);
  }

  async searchPanelMembers(enterpriseId, keyword) {
    let params = `?enterpriseId=${enterpriseId}`;
    if (keyword) {
      params = `?enterpriseId=${enterpriseId}&panelMemberName=${keyword}&panelMemberEmailAddress=${keyword}`;
    }
    return ApiCalls.get(this.panelMembersUrl + params);
  }
  async getTimeZoneByLocation(city, state, country) {
    return ApiCalls.get(this.locationTimezoneUrl + `?country=${country}&state=${state}&city=${city}`);
  }
  async generateOfferLetter(payload) {
    return ApiCalls.post(this.generateOfferLetterUrl, payload);
  }
}

const AccessService = new AccessServiceClass();
export default AccessService;
