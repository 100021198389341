import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Link,
  Modal,
  TextField,
  MenuItem,
  Select,
  Popover,
  Chip,
  IconButton,
  Stack,
  Checkbox,
  RadioGroup,
  Radio,
  Tab,
  Input,
  Slider,
  Autocomplete,
  Drawer,
  FormControlLabel,
  FormControl,
  FormGroup,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Switch
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ClearIcon from "@mui/icons-material/Clear";
import filterIcon from "../../../assets/images/advance-filter-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import LocationSearchInput from "../../../Components/LocationSearchInput";
import AccessService from "../../../Services/Enterprise/Access.service";
import CandidateServiceClass from "../../../Services/Enterprise/Candidate.service";
import closeIcon from "../../../assets/images/close-icon.svg";
import { success, danger } from "../../../helpers/message-box";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import ApiCalls from "../../../api/customAxios";
import { handleSpaces } from "../../../helpers/helper-data";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import KeycloakService from "../../../keycloak";
import InfoIcon from "@mui/icons-material/Info";

const Constant = require("../../../helpers/constants");

const useStyles = makeStyles(() => ({
  iconColor: {
    color: "#1F1F1F !important",
    backgroundColor: "#EFF0F6 !important",
  },
}));
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AdvanceFilter = React.memo(function AdvanceFilter(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const enterpriseDetail = useSelector((state) => state.enterprise);
  const availabilityStatusLabels = JSON.parse(
    enterpriseDetail?.enterprise?.availabilityStatusLabels
  );
  const [checkedFullTime, setCheckedFullTime] = useState(false);
  const [checkedPartTime, setCheckedPartTime] = useState(false);
  const [checkedContract, setCheckedContract] = useState(false);
  const [checkedInternship, setCheckedInternship] = useState(false);
  const [checkedTemporary, setCheckedTemporary] = useState(false);
  const [checkedContingent, setCheckedContingent] = useState(false);
  const [checkedApprentice, setCheckedApprentice] = useState(false);
  const [checkedOther, setCheckedOther] = useState(false);
  const [radiusSearch, setRadiusSearch] = useState(0);
  function handleRadiusChange(e) {
    setRadiusSearch(e.target.value);
  }
  function handleCheckedFullTime(e) {
    if (e.type === "change") {
      setCheckedFullTime(e.target.checkedFullTime);
    } else {
      setCheckedFullTime(!checkedFullTime);
    }
  }
  function handleCheckedPartTime(e) {
    if (e.type === "change") {
      setCheckedPartTime(e.target.checkedPartTime);
    } else {
      setCheckedPartTime(!checkedPartTime);
    }
  }
  function handleCheckedContract(e) {
    if (e.type === "change") {
      setCheckedContract(e.target.checkedContract);
    } else {
      setCheckedContract(!checkedContract);
    }
  }
  function handleCheckedInternship(e) {
    if (e.type === "change") {
      setCheckedInternship(e.target.checkedInternship);
    } else {
      setCheckedInternship(!checkedInternship);
    }
  }
  function handleCheckedTemporary(e) {
    if (e.type === "change") {
      setCheckedTemporary(e.target.checkedTemporary);
    } else {
      setCheckedTemporary(!checkedTemporary);
    }
  }
  function handleCheckedContingent(e) {
    if (e.type === "change") {
      setCheckedContingent(e.target.checkedContingent);
    } else {
      setCheckedContingent(!checkedContingent);
    }
  }
  function handleCheckedApprentice(e) {
    if (e.type === "change") {
      setCheckedApprentice(e.target.checkedApprentice);
    } else {
      setCheckedApprentice(!checkedApprentice);
    }
  }
  function handleCheckedOther(e) {
    if (e.type === "change") {
      setCheckedOther(e.target.checkedOther);
    } else {
      setCheckedOther(!checkedOther);
    }
  }
  function resetWorkType() {
    setCheckedFullTime(false);
    setCheckedPartTime(false);
    setCheckedContract(false);
    setCheckedInternship(false);
    setCheckedTemporary(false);
    setCheckedContingent(false);
    setCheckedApprentice(false);
    setCheckedOther(false);
  }
  const [workPlace, setWorkPlace] = useState(null);
  const [checkedRemote, setCheckedRemote] = useState(true);
  const [checkedHybrid, setCheckedHybrid] = useState(true);
  const [checkedOnsite, setCheckedOnsite] = useState(true);
  const [checkedDayShift, setCheckedDayShift] = useState(true);
  const [checkedEveningShift, setCheckedEveningShift] = useState(true);
  const [checkedNightShift, setCheckedNightShift] = useState(true);
  const [checkedFlexibleShift, setCheckedFlexibleShift] = useState(true);
  const [rating, setRating] = useState(null);
  const [selectOpenRating, setSelectOpenRating] = useState(false);
  const [referredBy, setReferredBy] = useState(false);
  const [newCandidates, setNewCandidates] = useState(false);
  const [referredByFilterList, setReferredByFilterList] = useState([]);
  const [selectedReferredByName, setSelectedReferredByName] = useState("");
  const [selectedReferredById, setSelectedReferredById] = useState("");
  const [hideCrossIcon, setHideCrossIcon] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const handleSelectRating = (event) => {
    if (event.target.value === "--Select--") {
      setRating(null);
    } else {
      setRating(event.target.value);
    }
  };
  const handleWorkplace = (event) => {
    setWorkPlace(event.target.value);
  };
  const handleRemoteChecked = () => {
    if (workPlace === "R") {
      setWorkPlace(null);
    } else {
      setWorkPlace("R");
    }
  };
  const handleOnsiteChecked = () => {
    if (workPlace === "O") {
      setWorkPlace(null);
    } else {
      setWorkPlace("O");
    }
  };
  const handleHybridChecked = () => {
    if (workPlace === "H") {
      setWorkPlace(null);
    } else {
      setWorkPlace("H");
    }
  };

  function handleCheckedRemote(e) {
    if (e.type === "change") {
      setCheckedRemote(e.target.checkedRemote);
    } else {
      setCheckedRemote(!checkedRemote);
    }
  }
  function handleCheckedHybrid(e) {
    if (e.type === "change") {
      setCheckedHybrid(e.target.checkedHybrid);
    } else {
      setCheckedHybrid(!checkedHybrid);
    }
  }
  function handleCheckedOnsite(e) {
    if (e.type === "change") {
      setCheckedOnsite(e.target.checkedOnsite);
    } else {
      setCheckedOnsite(!checkedOnsite);
    }
  }
  function handleCheckedDayShift(e) {
    if (e.type === "change") {
      setCheckedDayShift(e.target.checkedDayShift);
    } else {
      setCheckedDayShift(!checkedDayShift);
    }
  }
  function handleCheckedEveningShift(e) {
    if (e.type === "change") {
      setCheckedEveningShift(e.target.checkedEveningShift);
    } else {
      setCheckedEveningShift(!checkedEveningShift);
    }
  }
  function handleCheckedNightShift(e) {
    if (e.type === "change") {
      setCheckedNightShift(e.target.checkedNightShift);
    } else {
      setCheckedNightShift(!checkedNightShift);
    }
  }
  function handleCheckedFlexibleShift(e) {
    if (e.type === "change") {
      setCheckedFlexibleShift(e.target.checkedFlexibleShift);
    } else {
      setCheckedFlexibleShift(!checkedFlexibleShift);
    }
  }
  function resetWorkPlace() {
    setCheckedRemote(false);
    setCheckedOnsite(false);
    setCheckedHybrid(false);
  }
  const { pathname, search } = useLocation();
  const params = useParams();
  const isAuthenticated = KeycloakService.isLoggedIn();
  const searchParams = new URLSearchParams(search);
  const classes = useStyles();
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const {
    resetAll,
    applyAdvanceFilter,
    setAdvanceFilterObj,
    advanceFilterObj,
    sortValues,
    sort,
    selectedSkillId,
    selectedChecklistId,
    selectedjobTitleFilter,
    selectedjobTitleFilterType,
    openAdvanceFilter,
    handleOpenAdvanceFilter,
    handleCloseAdvanceFilter,
    setOpenAdvanceFilter,
    filterMinExperience,
    filterMaxExperience,
    setQueryAdvanceFilterString,
    isFilterByCompaign,
    isFilterByMatchingCandidate,
    talentPool,
    isFilterByApplied,
    isFilterByShortlisted,
    searchKeyword,
  } = props;
  const [savedSearchData, setSavedSearchData] = useState(undefined);
  const [openAdvanceSearch, setOpenAdvanceSearch] = React.useState(false);
  const handleOpenAdvanceSearch = () => setOpenAdvanceSearch(true);
  const handleCloseAdvanceSearch = () => setOpenAdvanceSearch(false);
  const [value, setValue] = React.useState("1");
  const [skillsAutoFill, setSkillsAutoFill] = useState([]);
  const [jobTitlesAutoFill, setJobTitlesAutoFill] = useState([]);
  const [searchTitlesAutoFill, setSearchTitlesAutoFill] = useState([]);
  const [companiesAutoFill, setCompaniesAutoFill] = useState([]);
  const [currentStageAutoFill, setCurrentStageAutoFill] = useState([]);
  const [referredByLabel, setReferredByLabel] = useState(false);
  const [isCheckedReferredByLabel, setIsCheckedReferredByLabel] = useState(false);
  const [jobTitlesArray, setJobTitlesArray] = useState(
    selectedjobTitleFilter && selectedjobTitleFilterType === "T"
      ? [decodeURIComponent(selectedjobTitleFilter)]
      : []
  );
  const [companiesArray, setCompaniesArray] = useState([]);
  const [currentStagesArray, setCurrentStagesArray] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [skillsArray, setSkillsArray] = useState(
    selectedjobTitleFilter &&
      selectedjobTitleFilterType === "S" &&
      selectedSkillId
      ? [
          {
            id: parseInt(selectedSkillId),
            keyword: decodeURIComponent(selectedjobTitleFilter),
          },
        ]
      : []
  );
  const [clear, setClear] = useState(false);
  const [clearJobTitles, setClearJobTitles] = useState(false);
  const [clearSearchTitles, setClearSearchTitles] = useState(false);
  const autoComplete = useRef(null);
  const autoComplete1 = useRef(null);

  const [error, setError] = useState("");
  const [clearCompanies, setClearCompanies] = useState(false);
  const [clearReferred, SetClearReferred] = useState(false);
  const [user, setUser] = useState(false);
  const [clearCurrentStages, setClearCurrentStages] = useState(false);
  const getEduLevelActiveStatus = (edu) => {
    return selectedjobTitleFilter &&
      selectedjobTitleFilterType === "L" &&
      selectedjobTitleFilter === edu
      ? true
      : false;
  };
  const getAvailableList = (list) => {
    return selectedjobTitleFilter &&
      selectedjobTitleFilterType === "L" &&
      selectedjobTitleFilter === list
      ? true
      : false;
  };
  const getEducationLevelArray = () => {
    let eduLevel = [];
    if (selectedjobTitleFilter && selectedjobTitleFilterType === "L") {
      eduLevel = [selectedjobTitleFilter];
    }
    return eduLevel;
  };

  const getAvailableListArray = () => {
    let availabilityStatus = [];
    if (selectedjobTitleFilter && selectedjobTitleFilterType === "L") {
      availabilityStatus = [selectedjobTitleFilter];
    }
    return availabilityStatus;
  };
  const [isActiveEduLevel, setIsActiveEduLevel] = useState({
    B: getEduLevelActiveStatus("B"),
    P: getEduLevelActiveStatus("P"),
    M: getEduLevelActiveStatus("M"),
    A: getEduLevelActiveStatus("A"),
    D: getEduLevelActiveStatus("D"),
    S: getEduLevelActiveStatus("S"),
  });
  // const [isActiveTalentPool, setIsActiveTalentPool] = useState({
  //   "A": getTalentPoolActiveStatus("A"),
  //   "S": getTalentPoolActiveStatus("S"),
  //   "P": getTalentPoolActiveStatus("P"),
  //   "Z": getTalentPoolActiveStatus("Z"),
  // });

  const [isActiveAvailability, setIsActiveAvailability] = useState({
    Available: getAvailableList("Available"),
    "May be available": getAvailableList("May be available"),
    Onboarded: getAvailableList("Onboarded"),
  });
  const [educationLevel, setEducationLevel] = useState(
    selectedjobTitleFilter && selectedjobTitleFilterType === "L" ? [] : []
  );
  const [talentPoolLevel, setTalentPoolLevel] = useState(
    searchParams.get("talentPool") && !pathname.includes("/matching-candidates")
      ? encodeURIComponent(searchParams.get("talentPool"))
      : "A"
  );
  const [availableList, setAvailableList] = useState(
    selectedjobTitleFilter && selectedjobTitleFilterType === "L" ? [] : []
  );
  const [minExp, setMinExp] = useState(filterMinExperience);
  const [maxExp, setMaxExp] = useState(filterMaxExperience);
  const [searchList, setSearchList] = useState([]);
  const [searchId, setSearchId] = useState();
  const [searchString, setSearchString] = useState(
    decodeURIComponent(selectedjobTitleFilter)
  );
  const [updatedCheck, setUpdatedCheck] = useState(false);
  const [payRateMin, setMinExpPayRate] = useState(undefined);
  const [payRateMax, setMaxExpPayRate] = useState(undefined);
  const [sliderLow, setSliderLow] = React.useState(
    filterMinExperience ? filterMinExperience : 0
  );
  const [sliderHigh, setSliderHigh] = React.useState(
    filterMaxExperience ? filterMaxExperience : 100
  );
  const [sliderLowPayRate, setSliderLowPayRate] = React.useState(
    payRateMin ? payRateMin : 0
  );
  const [sliderHighPayRate, setSliderHighPayRate] = React.useState(
    payRateMax ? payRateMax : 1001
  );
  const [locations, setLocations] = useState([]);
  const [latlng, setLatLng] = useState([]);
  const [filterMetric, setFilterMetric] = useState(0);
  const [locationName, setLocationName] = useState({
    city: "",
    country: "",
    state: "",
  });
  const [openSkillsAutoComplete, setOpenSkillsAutoComplete] = useState(false);
  const [openProfileTitleAutoComplete, setOpenProfileTitleAutoComplete] =
    useState(false);
  const [openSearchTitleAutoComplete, setSearchProfileTitleAutoComplete] =
    useState(false);
  const [openCompanyAutoComplete, setOpenCompanyAutoComplete] = useState(false);
  const [openCurrentStageAutoComplete, setOpenCurrentStageAutoComplete] = useState(false);
  const onScroll = () => {
    setOpenSkillsAutoComplete(false);
    setOpenProfileTitleAutoComplete(false);
    setOpenCompanyAutoComplete(false);
    setSearchProfileTitleAutoComplete(false);
    setOpenCurrentStageAutoComplete(false);
  };
  const [debouncedSkillValue, setDebouncedSkillValue] = React.useState();
  const [debouncedTitleValue, setDebouncedTitleValue] = React.useState();
  const [debouncedCompanyValue, setDebouncedCompanyValue] = React.useState();
  const skillsList = async (event) => {
    setDebouncedTitleValue();
    setDebouncedCompanyValue();
    if (!event) {
      setSkillsAutoFill([]);
    } else if (event.length > 0) {
      let isHealthcare = appOrigin === "H";
      await AccessService.skillsRecommendations(event, isHealthcare)
        .then((res) => {
          setSkillsAutoFill(res);
        })
        .catch(function (error) {});
    }
  };
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      skillsList(debouncedSkillValue);
    }, Constant.DEBOUNCE_TIME);
    return () => clearTimeout(timeoutId);
  }, [debouncedSkillValue]);
  React.useEffect(() => {
    if (props?.resetFilterState !=null) {
      props.setResetFilterState(null);
      resetAll();
      populateExistingFilter({});
      setJobTitlesArray([]);
      setSkillsArray([]);
      setLocationName({});
      resetWorkType();
      resetWorkPlace();
      setWorkPlace(null);
      setReferredBy(false);
      setSelectedReferredByName("");
      setSelectedReferredById("");
      setRadiusSearch(0);
      setLatLng([]);
      setNewCandidates(false);
      setIsCheckedReferredByLabel(false);
      setCurrentStagesArray([]);
      localStorage.removeItem("searchId");
    }
  }, [props?.resetFilterState]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      companiesList(debouncedCompanyValue);
    }, Constant.DEBOUNCE_TIME);
    return () => clearTimeout(timeoutId);
  }, [debouncedCompanyValue]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      jobTitlesList(debouncedTitleValue);
    }, Constant.DEBOUNCE_TIME);
    return () => clearTimeout(timeoutId);
  }, [debouncedTitleValue]);
    React.useEffect(() => {
    if (props?.isSmartFilterApplied) {
      const smartFilterToBeApplied = async() => {
      if (props?.job?.city ) {
          let locationData = {
            city: props?.job?.city,
            state: props?.job?.state,
            country: props?.job?.country,
          };
          onSelectLocation(locationData);
          setLatLng((prevValue) => [
            ...prevValue,
            {
              lat: props?.job?.latitude,
              lng: props?.job?.longitude,
            },
          ]);
        }
        const { query, searchQuery } = await getQuerySmartFilter(0);
    if (localStorage.getItem("searchId") !== null && searchString) {
      let searchQuery = "";
      searchQuery += "keyword=" + encodeURIComponent(searchString) + "&";
      navigate(pathname + "?" + searchQuery + query);
    } else if (localStorage.getItem("searchId") !== null && !searchString) {
      navigate(pathname + "?" + query);
    } else {
      navigate(pathname + "?" + searchQuery + query + `&triggerSearch=${searchTrigger}`);
    }
    const savedObj = await getSavedSearchObjectSmartFilter();
    populateExistingFilter(savedObj);
    setAdvanceFilterObj(savedObj);
    // localStorage.setItem("advanceFilterObj", JSON.stringify(savedObj));
    setQueryAdvanceFilterString(query);
  };
  smartFilterToBeApplied();
    }
  }, [props?.isSmartFilterApplied]);
  const getSavedSearchObjectSmartFilter = async() => {
    let obj = {};
    if (minExp || minExp === 0) {
      obj.minimumExperience = minExp;
    }
    if (maxExp || maxExp === 0) {
      obj.maximumExperience = maxExp;
    }
    if (talentPoolLevel) {
      obj.talentPool = talentPoolLevel;
    }
    let locationData = {
      city: props?.job?.city,
      state: props?.job?.state,
      country: props?.job?.country,
    };
    if (props?.job?.city) {
      obj.locations = [locationData];
    }
    if (props?.job?.latitude) {
      const latLongPayload = {
         lat: props?.job?.latitude,
       lng: props?.job?.longitude
      };
      setLatLng([latLongPayload]);
    }
      if (props?.job?.latitude) {
        setFilterMetric(0);
        setRadiusSearch(100);
    }
    // if (payRateMin || payRateMin === 0) {
    //   obj.payRateMin = payRateMin;
    // }
    if (payRateMax && payRateMin !== 0) {
      obj.payRateMin = 0;
    }
    if (payRateMax && payRateMax !== 0) {
      obj.payRateMax = payRateMax;
    }
    // let workTypes = [];
    // if (props?.job?.jobType?.includes("FT")) {
    //   setCheckedFullTime(true);
    //   workTypes.push("FT");
    // }
    // if (props?.job?.jobType?.includes("PT")) {
    //   setCheckedPartTime(true);
    //   workTypes.push("PT");
    // }
    // if (props?.job?.jobType?.includes("FL")) {
    //   setCheckedContract(true);
    //   workTypes.push("FL");
    // }
    // if (props?.job?.jobType?.includes("IN")) {
    //   setCheckedInternship(true);
    //   workTypes.push("IN");
    // }
    // if (props?.job?.jobType?.includes("OT")) {
    //   setCheckedOther(true);
    //   workTypes.push("OT");
    // }
    // if (workTypes.length) {
    //   obj.workType = workTypes;
    // }
    if (props?.job?.budgetMinimum ) {
      obj.payRateMin =0;
    }
    if (props?.job?.budgetMaximum) {
      obj.payRateMax = props?.job?.budgetMaximum;
    }
    // budgetMaximum

    return obj;
  };
  const getQuerySmartFilter = async(pgNo) => {
    let query = "";
    let searchQuery = "";
    if (localStorage.getItem("enterpriseId")) {
      query += `enterpriseId=${
        localStorage.getItem("childEnterpriseId")
          ? localStorage.getItem("childEnterpriseId")
          : localStorage.getItem("enterpriseId")
      }&`;
    }
    if (minExp || minExp === 0) {
      if (minExp > 30) {
        query += `minYearsOfExperience=30` + encodeURIComponent("+") + "&";
      } else {
        query += `minYearsOfExperience=${minExp}&`;
      }
    }
    if (maxExp || maxExp === 0) {
      if (maxExp > 30) {
        query += `maxYearsOfExperience=30` + encodeURIComponent("+") + "&";
      } else {
        query += `maxYearsOfExperience=${maxExp}&`;
      }
    }
    if (props?.job?.city) {
      let city = [props?.job?.city];
      let state = [props?.job?.state];
      let country = [props?.job?.country];
      // query += `city=${city}&state=${state}&country=${country}&`;
      if (city?.length) {
        query += `city=${city}&`;
      }
      if (state?.length) {
        query += `state=${state}&`;
      }
      if (country?.length) {
        query += `country=${country}&`;
      }
    }
    // if (radiusSearch && latlng?.length > 0) {
    //   let toMeter = filterMetric ? 1609.34 : 1000;
    //   query += `searchRadiusInMeters=${Math.floor(radiusSearch * toMeter)}&`;
    // }
    if (props?.job?.latitude) {
      let lat = [props?.job?.latitude];
      let lng = [props?.job?.longitude];
      query += `latitudes=${lat}&longitudes=${lng}&`;
    }
    if (props?.job?.latitude) {
      query += `searchRadiusInMeters=${Math.floor(100 * 1000)}&`;
    }
    console.log(props?.job?.budgetMinimum, "min");
      query += `payRateMin=0&`;
    if (props?.job?.budgetMaximum) {
      if (props?.job?.budgetMaximum > 1000) {
        query += `payRateMax=1000` + encodeURIComponent("+") + "&";
      } else {
        query += `payRateMax=${props?.job?.budgetMaximum }&`;
      }
    } else {
      query += `payRateMax=1000` + encodeURIComponent("+") + "&";
    }
    // let workTypes = [];
    // if (props?.job?.jobType?.includes("FT")) {
    //   workTypes.push("FT");
    // }
    // if (props?.job?.jobType?.includes("PT")) {
    //   workTypes.push("PT");
    // }
    // if (props?.job?.jobType?.includes("FL")) {
    //   workTypes.push("FL");
    // }
    // if (props?.job?.jobType?.includes("IN")) {
    //   workTypes.push("IN");
    // }
    // if (props?.job?.jobType?.includes("OT")) {
    //   workTypes.push("OT");
    // }
    // if (workTypes.length) {
    //   query += `workType=${workTypes.join(",")}&`;
    // }
    if (pgNo !== 0) {
      sortValues?.map((sortValue) => {
        query += "sortValues=" + sortValue + "&";
      });
    }
      query += `relevance=${sort === "2" ? true : false}&`;
    return { query, searchQuery };
  };

  const jobTitlesList = async (event) => {
    setDebouncedCompanyValue();
    setDebouncedSkillValue();
    if (!event) {
      setJobTitlesAutoFill([]);
    } else if (event.length > 0) {
      await CandidateServiceClass.searchBar("title=" + event.split(","), 0, 3)
        .then((res) => {
          setJobTitlesAutoFill(res);
        })
        .catch(function (error) {});
    }
  };
  const companiesList = async (event) => {
    setDebouncedTitleValue();
    setDebouncedSkillValue();
    if (!event) {
      setCompaniesAutoFill([]);
    } else if (event.length > 0) {
      await CandidateServiceClass.searchBar("company=" + event.split(","), 0, 3)
        .then((res) => {
          setCompaniesAutoFill(res);
        })
        .catch(function (error) {});
    }
  };
  const findById = async (id) => {
    return skillsArray.filter((item) => {
      return item.id === id;
    })[0];
  };
  const addSkill = async (skill) => {
    if (skill) {
      if (!(await findById(skill.id))) {
        var skills = [...skillsArray];
        skills.push({ id: skill.id, keyword: skill.keyword });
        setSkillsArray(skills);
      } else {
        danger("This skill is already added.");
      }
    }
    setClear(!clear);
  };
  const findByIdJobTitle = async (name) => {
    return jobTitlesArray.filter((item) => {
      return item === name;
    })[0];
  };
  const findByIdCompanies = async (name) => {
    return companiesArray.filter((item) => {
      return item === name;
    })[0];
  };
  const findStageInSelectedCurrentStages = async (stage) => {
    return currentStagesArray.filter((item) => {
      return item === stage;
    })[0];
  };
  const addJobTitle = async (jobTitl) => {
    if (jobTitl) {
      if (!(await findByIdJobTitle(jobTitl.name))) {
        var jobTitls = [...jobTitlesArray];
        jobTitls.push(jobTitl.name);
        setJobTitlesArray(jobTitls);
      } else {
        danger("This profile title is already added.");
      }
    }
    setClearJobTitles(!clearJobTitles);
  };
  const addCompany = async (comp) => {
    if (comp) {
      if (!(await findByIdCompanies(comp.name))) {
        var comps = [...companiesArray];
        comps.push(comp.name);
        setCompaniesArray(comps);
      } else {
        danger("This company is already added.");
      }
    }
    setClearCompanies(!clearCompanies);
    document.getElementById("modal-description")?.scrollTo({
      top: document.getElementById("modal-description").scrollHeight,
      behavior: "smooth",
    });
  };
  const addCurrentStage = async (stage) => {
    if (stage) {
      if (!(await findStageInSelectedCurrentStages(stage))) {
        var stages = [...currentStagesArray];
        stages.push(stage);
        setCurrentStagesArray(stages);
      } else {
        danger("This stage is already added.");
      }
    }
    setClearCurrentStages(!clearCurrentStages);
    document.getElementById("modal-description")?.scrollTo({
      top: document.getElementById("modal-description").scrollHeight,
      behavior: "smooth",
    });
  };
  const deleteSkill = (index) => {
    var skills = [...skillsArray];
    skills.splice(index, 1);
    setSkillsArray(skills);
  };

  const deleteJobTitle = (index) => {
    var skills = [...jobTitlesArray];
    skills.splice(index, 1);
    setJobTitlesArray(skills);
  };
  const deleteCompany = (index) => {
    var comp = [...companiesArray];
    comp.splice(index, 1);
    setCompaniesArray(comp);
  };
  const deleteCurrentStage = (index) => {
    var stages = [...currentStagesArray];
    stages.splice(index, 1);
    setCurrentStagesArray(stages);
  };
  const handleEducationLevel = (e, type) => {
    if (educationLevel?.includes(type)) {
      const eduLevel = educationLevel;
      const index = eduLevel.indexOf(type);
      if (index > -1) {
        eduLevel.splice(index, 1);
      }
      setIsActiveEduLevel({ ...isActiveEduLevel, [type]: false });
      setEducationLevel(eduLevel);
    } else {
      setIsActiveEduLevel({ ...isActiveEduLevel, [type]: true });
      setEducationLevel([...educationLevel, type]);
    }
  };
  const handleTalentPoolLevel = (type) => {
    setTalentPoolLevel(type);
  };
  const handleAvailabilty = (e, type) => {
    if (availableList.includes(type)) {
      const list = availableList;
      const index = list.indexOf(type);
      if (index > -1) {
        list.splice(index, 1);
      }
      setIsActiveAvailability({ ...isActiveAvailability, [type]: false });
      setAvailableList(list);
    } else {
      setIsActiveAvailability({ ...isActiveAvailability, [type]: true });
      setAvailableList([...availableList, type]);
    }
  };
  const findByCity = async (city) => {
    if (!city) {
      return false;
  }
    return locations.filter((item) => {
      return item.city === city && (!item.zipCode || item.zipCode.length === 0);
    }).length;
  };
  const findByState = async (state) => {
    if (!state) {
      return false;
    }
    return locations.filter((item) => {
      return (
        item.state === state && (!item.zipCode || item.zipCode.length === 0)
      );
    }).length;
  };
  const findByCountry = async (country) => {
    return locations.filter((item) => {
        return !item.city && !item.state && item.country === country;
    })[0];
  };
  const findByZipcode = async (zipCode) => {
    return locations.filter((item) => {
      return item.zipCode === zipCode;
    }).length;
  };
  function removeLatLngatIndex(index) {
    setLatLng((prevData) => {
      return prevData.filter((_, i) => i != index);
    });
  }
  const onSelectLocation = async (data) => {
    // if (!data.city) {
    //   return;
    // };
    if (data) {
      setLocationName(data);
      if (locations.length <= 14) {
        const isDuplicateState = await findByState(data.state);
        const isDuplicateCountry = await findByCountry(data.country);
        if (
          data.zipCode &&
          data.zipCode.length &&
          !(await findByZipcode(data.zipCode))
        ) {
          setLocations([...locations, data]);
        } else if (!data.city && !data.state && isDuplicateCountry) {
          danger("This location is already added.");
        } else if (!data.city && isDuplicateState) {
            danger("This location is already added.");
        } else if (
          !(data.zipCode && data.zipCode.length) &&
          (!(await findByCity(data.city)) || !isDuplicateState)
        ) {
          setLocations([...locations, data]);
        } else {
          danger("This location is already added.");
          if (locations.length > latlng.length) {
            removeLatLngatIndex(locations.length - 1);
          }
        }
      } else {
        danger("You cannot add more than 15 locations to a user.");
        if (locations.length > latlng.length) {
          removeLatLngatIndex(locations.length - 1);
        }
      }
    }
  };
  useEffect(() => {
    // if (searchParams.get("city") && searchParams.get("state") && searchParams.get("country")) {
    //   setLocations([{ city: searchParams.get("city"), state: searchParams.get("state"), country: searchParams.get("country") }]);
    // }
    try {
      let _stages = JSON.parse(
        enterpriseDetail?.enterprise?.customConfigurations
      )?.screening_label_configurations?.filter(
        (config) => config.show_in_dropdown == true
      );
      setCurrentStageAutoFill(_stages);
      const labelConfig = JSON.parse(
        enterpriseDetail?.enterprise?.customConfigurations
      )?.screening_label_configurations;
      if (labelConfig && Array.isArray(labelConfig)) {
        let referredByConfig = labelConfig.find((config) => config.label?.includes("Referred by "));
        if (referredByConfig) {
          setReferredByLabel(referredByConfig?.label);
        }
      }
    } catch (err) {}
    if (!pathname.includes("/matching-candidates")) {
      if (searchParams.get("city")?.includes(",")) {
        let data = [];
        for (let i = 0; i < searchParams.get("city")?.split(",")?.length; i++) {
          data.push({
            city: searchParams.get("city").split(",")[i],
            state: searchParams.get("state").split(",")[i],
            country: searchParams.get("country").split(",")[i],
          });
        }
        setLocations(data);
      } else if (
        searchParams.get("city") &&
        searchParams.get("state") &&
        searchParams.get("country")
      ) {
        setLocations([
          {
            city: searchParams.get("city"),
            state: searchParams.get("state"),
            country: searchParams.get("country"),
          },
        ]);
      }
      if (searchParams.get("minYearsOfExperience")) {
        setMinExp(searchParams.get("minYearsOfExperience"));
        setSliderLow(
          searchParams.get("minYearsOfExperience")
            ? searchParams.get("minYearsOfExperience")
            : 0
        );
      }
      if (searchParams.get("maxYearsOfExperience")) {
        setMaxExp(searchParams.get("maxYearsOfExperience"));
        setSliderHigh(
          searchParams.get("maxYearsOfExperience").includes("30")
            ? 100
            : searchParams.get("maxYearsOfExperience")
        );
      }
      if (searchParams.get("payRateMin")) {
        setMinExpPayRate(searchParams.get("payRateMin"));
        setSliderLowPayRate(
          searchParams.get("payRateMin") ? searchParams.get("payRateMin") : 0
        );
      }
      if (searchParams.get("payRateMax")) {
        setMaxExpPayRate(searchParams.get("payRateMax"));
        setSliderHighPayRate(
          searchParams.get("payRateMax").includes("1000")
            ? 1001
            : (searchParams.get("payRateMax") === 0 ||
                searchParams.get("payRateMax")) &&
                searchParams.get("payRateMax")
        );
      }
      if (searchParams.get("workType")) {
        let workType = searchParams.get("workType");
        setCheckedFullTime(workType?.includes("FT") ? true : false);
        setCheckedPartTime(workType?.includes("PT") ? true : false);
        setCheckedContract(workType?.includes("FL") ? true : false);
        setCheckedInternship(workType?.includes("IN") ? true : false);
        setCheckedTemporary(workType?.includes("TP") ? true : false);
        setCheckedContingent(workType?.includes("CG") ? true : false);
        setCheckedApprentice(workType?.includes("AP") ? true : false);
        setCheckedOther(workType?.includes("OT") ? true : false);
      }
      if (searchParams.get("workPlace")) {
        let workPlace = searchParams.get("workPlace");
        setCheckedRemote(workPlace?.includes("R") ? true : false);
        setCheckedOnsite(workPlace?.includes("O") ? true : false);
        setCheckedHybrid(workPlace?.includes("H") ? true : false);
      }
      if (searchParams.getAll("preferredShift")) {
        let preferredShift = searchParams.getAll("preferredShift");
        setCheckedDayShift(preferredShift?.includes("D") ? true : false);
        setCheckedEveningShift(preferredShift?.includes("E") ? true : false);
        setCheckedNightShift(preferredShift?.includes("N") ? true : false);
        setCheckedFlexibleShift(preferredShift?.includes("F") ? true : false);
      }
      if (searchParams.get("minRating")) {
        setRating(parseInt(searchParams.get("minRating")));
      }
      if (searchParams.get("levelOfEducation")) {
        let x = {
          educationLevels: searchParams.get("levelOfEducation").split(","),
        };
        setEducationLevel(searchParams.get("levelOfEducation")?.split(","));
        setIsActiveEduLevel({
          B: getEduLevelActiveStatusSaved(x, "B"),
          P: getEduLevelActiveStatusSaved(x, "P"),
          M: getEduLevelActiveStatusSaved(x, "M"),
          A: getEduLevelActiveStatusSaved(x, "A"),
          D: getEduLevelActiveStatusSaved(x, "D"),
          S: getEduLevelActiveStatusSaved(x, "S"),
        });
      }
      if (searchParams.get("profileTitle")?.includes(",")) {
        let data = [];
        for (
          let i = 0;
          i < searchParams.get("profileTitle")?.split(",")?.length;
          i++
        ) {
          data.push(searchParams.get("profileTitle").split(",")[i]);
        }
        setJobTitlesArray(data);
      } else if (searchParams.get("profileTitle")) {
        setJobTitlesArray([searchParams.get("profileTitle")]);
      }
      if (searchParams.get("company")?.includes(",")) {
        let data = [];
        for (
          let i = 0;
          i < searchParams.get("company")?.split(",")?.length;
          i++
        ) {
          data.push(searchParams.get("company").split(",")[i]);
        }
        setCompaniesArray(data);
      } else if (searchParams.get("company")) {
        setCompaniesArray([searchParams.get("company")]);
      }
      if (searchParams.get("newCandidates")) {
        setNewCandidates(searchParams.get("newCandidates"));
      }
      if (searchParams.get("currentStage")?.includes(",")) {
        let data = [];
        for (
          let i = 0;
          i < searchParams.get("currentStage")?.split(",")?.length;
          i++
        ) {
          data.push(searchParams.get("currentStage").split(",")[i]);
        }
        setCurrentStagesArray(data);
      } else if (searchParams.get("currentStage")) {
        setCurrentStagesArray([searchParams.get("currentStage")]);
      }
      if (searchParams.get("skill")) {
        setSkillsArray(JSON.parse(localStorage.getItem("AdvanceSkills")));
      }
      if (searchParams.get("referredBy")) {
        setReferredBy(searchParams.get("referredBy"));
      }
    } else {
      if (searchParams.get("availabilityStatus")) {
        let availableList = searchParams.get("availabilityStatus");
        let data = [];
        if (availableList.includes("Onboarded")) {
          data.push("Onboarded");
        }
        if (availableList.includes("May be available")) {
          data.push("May be available");
        }
        if (availableList.includes("Available immediately")) {
          data.push("Available immediately");
        }
        setIsActiveAvailability({
          Available: getAvailableListActiveStatus(data, "Available"),
          "May be available": getAvailableListActiveStatus(
            data,
            "May be available"
          ),
          Onboarded: getAvailableListActiveStatus(data, "Onboarded"),
        });
        setAvailableList(data);
      }
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    functionenable();
  };
  function valueLabelFormat(value) {
    let scaledValue = value;
    if (value > 30) {
      scaledValue = `30+`;
    }
    return `${scaledValue}`;
  }
  function valueLabelFormatPayRate(value) {
    let scaledValue = value;
    if (value > 1000) {
      scaledValue = `1000+`;
    }
    return `${scaledValue}`;
  }

  function valueLabelFormatRadiusFilter(value) {
    return `${value} ${filterMetric ? "mile" : "km"}${
      filterMetric && value > 1 ? "s" : ""
    }`;
  }

  const handleChangeSlider = (event, newValue) => {
    setSliderLow(newValue[0]);
    setSliderHigh(newValue[1] === 31 ? 100 : newValue[1]);
  };

  const handleChangeCommited = (event, newValue) => {
    setSliderLow(newValue[0]);
    setSliderHigh(newValue[1] === 31 ? 100 : newValue[1]);
    setMinExp(newValue[0]);
    setMaxExp(newValue[1] === 31 ? 100 : newValue[1]);
  };
  const handleChangeSliderPayRate = (event, newValue) => {
    setSliderLowPayRate(newValue[0] > 1000 ? 1000 : newValue[0]);
    setSliderHighPayRate(newValue[1]);
  };

  const handleChangeCommitedPayRate = (event, newValue) => {
    setSliderLowPayRate(newValue[0] > 1000 ? 1000 : newValue[0]);
    setSliderHighPayRate(newValue[1]);
    setMinExpPayRate(newValue[0] > 1000 ? 1000 : newValue[0]);
    setMaxExpPayRate(newValue[1]);
  };
  const handleChange = (event, newValue) => {
    if (newValue === "2") {
      setSavedSearchData(
        savedSearchData
          ? savedSearchData
          : user.searchData
          ? JSON.parse(user.searchData)
          : undefined
      );
      // setSavedSearchData({ "minimumExperience": 4, "maximumExperience": 22, "educationLevel": ["B", "M"], "skillsArray": [{ "id": 648, "keyword": "reactjs" }, { "id": 1, "keyword": "java" }], "jobTitlesArray": ["React js developer", "PHP Developer"], "companiesArray": ["SmplyfyVMS", "DSP Dimand"], "locations": [{ "city": "Aligarh", "state": "Uttar Pradesh", "country": "IN", "placeId": "ChIJi5xFhoakdDkREzYybSdn2ZU" }, { "city": null, "state": "West Bengal", "country": "IN", "placeId": "ChIJh-iXE_8W-jkRCqLnwz06VHE" }] });
    } else {
      if (advanceFilterObj) {
        populateExistingFilter(advanceFilterObj);
      } else {
        populateExistingFilter({});
        setSavedSearchData(undefined);
      }
    }
    setValue(newValue);
  };
  // useEffect(() => {
  //     if (document.getElementById("location-search-input-box")) {
  //         if (value === '2') {
  //             document.getElementById("location-search-input-box").disabled = true;
  //         } else {
  //             document.getElementById("location-search-input-box").disabled = false;
  //         }
  //     }
  // }, [value]);
  const handleLocationClick = (index) => {
    // console.info('You clicked the Chip.');
  };

  const handleLocationDelete = (index) => {
    setLocations(locations.filter((location, _index) => _index !== index));
    removeLatLngatIndex(index);
  };
  const populateExistingFilter = (data) => {
    setJobTitlesArray(
      data.jobTitles == undefined
        ? []
        : data.jobTitles
        ? data.jobTitles
        : jobTitlesArray
    );
    setSkillsArray(
      data.skills == undefined ? [] : data.skills ? data.skills : skillsArray
    );
    setCompaniesArray(
      data.companies == undefined
        ? []
        : data.companies
        ? data.companies
        : companiesArray
    );
    setNewCandidates(data.newCandidates ? true : false);
    setIsCheckedReferredByLabel(data.isCheckedReferredByLabel ? true : false);
    setCurrentStagesArray(
      data.currentStages == undefined
        ? []
        : data.currentStages
        ? data.currentStages
        : currentStagesArray
    );
    setLocations(data.locations ? data.locations : []);
    setIsActiveEduLevel({
      B: getEduLevelActiveStatusSaved(data, "B"),
      P: getEduLevelActiveStatusSaved(data, "P"),
      M: getEduLevelActiveStatusSaved(data, "M"),
      A: getEduLevelActiveStatusSaved(data, "A"),
      D: getEduLevelActiveStatusSaved(data, "D"),
      S: getEduLevelActiveStatusSaved(data, "S"),
    });
    setIsActiveAvailability({
      Available: getAvailableListActiveStatus(data, "Available"),
      "May be available": getAvailableListActiveStatus(
        data,
        "May be available"
      ),
      Onboarded: getAvailableListActiveStatus(data, "Onboarded"),
    });
    setEducationLevel(
      data.educationLevels ? data.educationLevels : getEducationLevelArray()
    );
    setTalentPoolLevel(data.talentPool ? data.talentPool : talentPoolLevel);
    setAvailableList(
      data.availabilityStatus
        ? data.availabilityStatus
        : getAvailableListArray()
    );
    setSliderLow(data.minimumExperience ? data.minimumExperience : 0);
    setSliderHigh(
      data.maximumExperience == undefined
        ? 100
        : data.maximumExperience
        ? data.maximumExperience
        : filterMaxExperience
        ? filterMaxExperience
        : 100
    );
    setMinExp(data.minimumExperience ? data.minimumExperience : 0);
    setMaxExp(
      data.maximumExperience == undefined
        ? 100
        : data.maximumExperience
        ? data.maximumExperience
        : filterMaxExperience
        ? filterMaxExperience
        : 100
    );
    setSliderLowPayRate(data.payRateMin ? data.payRateMin : 0);
    setSliderHighPayRate(
      data.payRateMax || data.payRateMax === 0 ? data.payRateMax : 1001
    );
    setMinExpPayRate(data.payRateMin ? data.payRateMin : 0);
    setMaxExpPayRate(
      data.payRateMax || data.payRateMax === 0 ? data.payRateMax : 1001
    );
    setWorkPlace(data.workPlace ? data.workPlace : null);
    setCheckedRemote(data.workPlace?.includes("R") ? true : false);
    setCheckedOnsite(data.workPlace?.includes("O") ? true : false);
    setCheckedHybrid(data.workPlace?.includes("H") ? true : false);
    setRating(data.minRating ? data.minRating : null);
    setCheckedFullTime(data.workType?.includes("FT") ? true : false);
    setCheckedPartTime(data.workType?.includes("PT") ? true : false);
    setCheckedContract(data.workType?.includes("FL") ? true : false);
    setCheckedInternship(data.workType?.includes("IN") ? true : false);
    setCheckedTemporary(data.workType?.includes("TP") ? true : false);
    setCheckedContingent(data.workType?.includes("CG") ? true : false);
    setCheckedApprentice(data.workType?.includes("AP") ? true : false);
    setCheckedOther(data.workType?.includes("OT") ? true : false);
    setCheckedDayShift(data.preferredShift?.includes("D") ? true : false);
    setCheckedEveningShift(data.preferredShift?.includes("E") ? true : false);
    setCheckedNightShift(data.preferredShift?.includes("N") ? true : false);
    setCheckedFlexibleShift(data.preferredShift?.includes("F") ? true : false);
  };
  useEffect(() => {
    if (advanceFilterObj && value === "1") {
      populateExistingFilter(advanceFilterObj);
    }
  }, [advanceFilterObj, value]);
  const getQuery = (pgNo) => {
    let query = "";
    let searchQuery = "";
    searchQuery += searchParams.get("keyword")
      ? "keyword=" + encodeURIComponent(searchParams.get("keyword")) + "&"
      : "keyword=" + encodeURIComponent(searchString) + "&";
    searchQuery += searchParams.get("type")
      ? "type=" + searchParams.get("type") + "&"
      : "";
    searchQuery += searchParams.get("skillid")
      ? "skillid=" + searchParams.get("skillid") + "&"
      : "";
    searchQuery += searchParams.get("checklistid")
      ? "checklistid=" + searchParams.get("checklistid") + "&"
      : "";
    // console.log(searchParams.get("checklistid"));
    if (localStorage.getItem("enterpriseId")) {
      query += `enterpriseId=${
        localStorage.getItem("childEnterpriseId")
          ? localStorage.getItem("childEnterpriseId")
          : localStorage.getItem("enterpriseId")
      }&`;
    }
    if (searchParams.get("talentPoolId")) {
      query += `talentPoolId=${encodeURIComponent(
        searchParams.get("talentPoolId")
      )}&`;
    }
    if (searchParams.get("talentPoolName")) {
      query += `talentPoolName=${encodeURIComponent(
        searchParams.get("talentPoolName")
      )}&`;
    }
    if (minExp || minExp === 0) {
      if (minExp > 30) {
        query += `minYearsOfExperience=30` + encodeURIComponent("+") + "&";
      } else {
        query += `minYearsOfExperience=${minExp}&`;
      }
    }
    if (maxExp || maxExp === 0) {
      if (maxExp > 30) {
        query += `maxYearsOfExperience=30` + encodeURIComponent("+") + "&";
      } else {
        query += `maxYearsOfExperience=${maxExp}&`;
      }
    }
    if (educationLevel.length > 0) {
      query += `levelOfEducation=${educationLevel}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "L") {
      query += `levelOfEducation=${selectedjobTitleFilter.split(",")}&`;
    }
    if (talentPoolLevel) {
      query += `talentPool=${talentPoolLevel}&`;
    }
    if (availableList?.length > 0) {
      query += `availabilityStatus=${availableList.join(",")}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "L") {
      query += `availabilityStatus=${selectedjobTitleFilter.split(",")}&`;
    }
    if (skillsArray?.length > 0) {
      query += `skill=${skillsArray.map((o) => o.id)}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "S") {
      query += `skill=${selectedSkillId}&`;
    }
    if (jobTitlesArray?.length > 0) {
      query += `profileTitle=${jobTitlesArray}&`;
    } else if (selectedjobTitleFilter && selectedjobTitleFilterType === "T") {
      query += `profileTitle=${selectedjobTitleFilter.split(",")}&`;
    }
    if (companiesArray?.length > 0) {
      query += `company=${companiesArray}&`;
    }
    if (locations?.length > 0) {
      let city = [];
      let state = [];
      let country = [];
      locations?.map((data) => {
        data.city ? city.push(data.city) : "";
        data.state ? state.push(data.state) : "";
        data.country ? country.push(data.country) : "";
      });
      // query += `city=${city}&state=${state}&country=${country}&`;
      if (city?.length) {
        query += `city=${city}&`;
      }
      if (state?.length) {
        query += `state=${state}&`;
      }
      if (country?.length) {
        query += `country=${country}&`;
      }
    }
    if (radiusSearch && latlng?.length > 0) {
      let toMeter = filterMetric ? 1609.34 : 1000;
      query += `searchRadiusInMeters=${Math.floor(radiusSearch * toMeter)}&`;
    }
    if (latlng?.length > 0) {
      let lat = [];
      let lng = [];
      latlng.map((data) => {
        if (data?.lat && data?.lng) {
          lat.push(data?.lat);
          lng.push(data?.lng);
        }
      });
      query += `latitudes=${lat}&longitudes=${lng}&`;
    }
    if (payRateMin || payRateMin === 0) {
      if (payRateMin > 1000) {
        query += `payRateMin=1000` + encodeURIComponent("+") + "&";
      } else {
        query += `payRateMin=${payRateMin}&`;
      }
    }
    if (payRateMax || payRateMax === 0) {
      if (payRateMax > 1000) {
        query += `payRateMax=1000` + encodeURIComponent("+") + "&";
      } else {
        query += `payRateMax=${payRateMax}&`;
      }
    }
    let worktype = [];
    if (checkedFullTime) {
      worktype.push("FT");
    }
    if (checkedPartTime) {
      worktype.push("PT");
    }
    if (checkedContract) {
      worktype.push("FL");
    }
    if (checkedInternship) {
      worktype.push("IN");
    }
    if (checkedOther) {
      worktype.push("OT");
    }
    if (worktype.length) {
      query += `workType=${worktype.join(",")}&`;
    }
    let workPlace = [];
    if (checkedRemote) {
      workPlace.push("R");
    }
    if (checkedOnsite) {
      workPlace.push("O");
    }
    if (checkedHybrid) {
      workPlace.push("H");
    }
    if (workPlace.length) {
      query += `workPlace=${workPlace.join(",")}&`;
    }
    let shiftPreference = [];
    if (checkedDayShift) {
      shiftPreference.push("D");
    }
    if (checkedEveningShift) {
      shiftPreference.push("E");
    }
    if (checkedNightShift) {
      shiftPreference.push("N");
    }
    if (checkedFlexibleShift) {
      shiftPreference.push("F");
    }
    if (shiftPreference?.length) {
      shiftPreference.map((shift, i) => {
        query += `preferredShift=${shift}&`;
      });
    }
    if (rating) {
      query += `minRating=${rating}&maxRating=5&`;
    }
    if (
      (selectedjobTitleFilter || searchString) &&
      selectedjobTitleFilterType !== "S" &&
      selectedjobTitleFilterType !== "C" &&
      selectedjobTitleFilterType !== "L" &&
      selectedjobTitleFilterType !== "T"
    ) {
      query += `${
        selectedjobTitleFilterType === "K" ||
        selectedjobTitleFilterType === null
          ? "input"
          : selectedjobTitleFilterType === "D"
          ? "nameOfDegree"
          : selectedjobTitleFilterType === "I"
          ? "instituteName"
          : selectedjobTitleFilterType === "M"
          ? "modeOfEducation"
          : selectedjobTitleFilterType === "U"
          ? "universityName"
          : "checklist"
      }=${
        selectedjobTitleFilterType === "CL"
          ? selectedChecklistId
          : localStorage.getItem("searchId") === null
          ? selectedjobTitleFilter.split(",")
          : searchString.split("-")[0]
      }&`;
    }
    if (
      searchParams.get("certifications") &&
      selectedjobTitleFilterType == "C"
    ) {
      query += `certifications=${searchParams.get("certifications")}&`;
    }
    // if ((searchString || localStorage.getItem("searchId") !== null) && (selectedjobTitleFilterType == "S" || selectedjobTitleFilterType == "L" || selectedjobTitleFilterType == "T")) {
    //   query += `input=${searchString}&`;
    // }
    if (pgNo !== 0) {
      sortValues?.map((sortValue) => {
        query += "sortValues=" + sortValue + "&";
      });
    }
    if (referredBy) {
      query += `referredBy=${referredBy}&`;
      if (selectedReferredById) {
        query += `referrerId=${selectedReferredById}&`;
      }
    }
    if (newCandidates) {
      query += `newCandidates=${newCandidates}&`;
    }
    if (isCheckedReferredByLabel) {
      let referredByTag = referredByLabel;
      referredByTag = referredByTag?.toUpperCase()?.split(" ")?.join("_");
      query += `referredByTag=${referredByTag}&`;
    }
    if (currentStagesArray.length > 0) {
      query += `currentStage=${currentStagesArray}&`;
    }
    if (pathname === "/enterprise/talent-pool-candidate-list") {
      if (searchKeyword) {
        query += `input=${searchKeyword}&`;
      }
      query += `sortByExperience=${sort === "2" ? true : false}&`;
    } else {
      query += `relevance=${sort === "2" ? true : false}&`;
    }
    return { query, searchQuery };
  };
  const getSavedSearchObject = () => {
    let obj = {};
    if (selectedjobTitleFilter) {
      obj.keyword = selectedjobTitleFilter;
    }
    // if (filterCity) {
    //   query += `city=${filterCity}&`;
    // }
    // if (filterState) {
    //   query += `state=${filterState}&`;
    // }
    // if (filterCountry) {
    //   query += `country=${filterCountry}&`;
    // }
    if (minExp || minExp === 0) {
      obj.minimumExperience = minExp;
    }
    if (maxExp || maxExp === 0) {
      obj.maximumExperience = maxExp;
    }
    if (educationLevel?.length > 0) {
      obj.educationLevels = educationLevel;
    }
    if (talentPoolLevel) {
      obj.talentPool = talentPoolLevel;
    }
    if (availableList?.length > 0) {
      obj.availabilityStatus = availableList;
    }
    if (skillsArray?.length > 0) {
      obj.skills = skillsArray;
      localStorage.setItem("AdvanceSkills", JSON.stringify(skillsArray));
    }
    if (jobTitlesArray?.length > 0) {
      obj.jobTitles = jobTitlesArray;
    }
    if (companiesArray?.length > 0) {
      obj.companies = companiesArray;
    }
    if (newCandidates) {
      obj.newCandidates = newCandidates;
    }
    if (isCheckedReferredByLabel) {
      obj.isCheckedReferredByLabel = isCheckedReferredByLabel;
    }
    if (currentStagesArray.length > 0) {
      obj.currentStages = currentStagesArray;
    }
    if (locations?.length > 0) {
      obj.locations = locations;
    }
    if (payRateMin || payRateMin === 0) {
      obj.payRateMin = payRateMin;
    }
    if (payRateMax || payRateMax === 0) {
      obj.payRateMax = payRateMax;
    }
    let worktype = [];
    if (checkedFullTime) {
      worktype.push("FT");
    }
    if (checkedPartTime) {
      worktype.push("PT");
    }
    if (checkedContract) {
      worktype.push("FL");
    }
    if (checkedInternship) {
      worktype.push("IN");
    }
    if (checkedOther) {
      worktype.push("OT");
    }
    if (worktype.length) {
      obj.workType = worktype;
    }
    let workPlace = [];
    if (checkedRemote) {
      workPlace.push("R");
    }
    if (checkedOnsite) {
      workPlace.push("O");
    }
    if (checkedHybrid) {
      workPlace.push("H");
    }
    if (workPlace.length) {
      obj.workPlace = workPlace;
    }
    let shiftPreference = [];
    if (checkedDayShift) {
      shiftPreference.push("D");
    }
    if (checkedEveningShift) {
      shiftPreference.push("E");
    }
    if (checkedNightShift) {
      shiftPreference.push("N");
    }
    if (checkedFlexibleShift) {
      shiftPreference.push("F");
    }
    if (shiftPreference?.length) {
      obj.preferredShift = shiftPreference;
    }
    if (rating) {
      obj.minRating = rating;
      obj.maxRating = 5;
    }
    return obj;
  };
  const handleApply = () => {
    setSearchTrigger((prev) => !prev);
    if (searchId) {
      localStorage.setItem("searchId", searchId);
    }
    if (searchParams.get("keyword") == null) {
      setSearchString("");
    }
    const { query, searchQuery } = getQuery(0);
    if (localStorage.getItem("searchId") !== null && searchString) {
      let searchQuery = "";
      searchQuery += "keyword=" + encodeURIComponent(searchString) + "&";
      navigate(pathname + "?" + searchQuery + query);
    } else if (localStorage.getItem("searchId") !== null && !searchString) {
      navigate(pathname + "?" + query);
    } else {
      navigate(pathname + "?" + (searchParams.get("keyword") == null ? "" : searchQuery) + query + `&triggerSearch=${searchTrigger}`);
    }
    const savedObj = getSavedSearchObject();
    populateExistingFilter(savedObj);
    setAdvanceFilterObj(savedObj);
    // localStorage.setItem("advanceFilterObj", JSON.stringify(savedObj));
    setQueryAdvanceFilterString(query);
    handleCloseAdvanceFilter();
  };
  const handleSaveSearch = async () => {
    if (searchName && searchName.length >= 256) {
      setError("Exceeded maximum character length of 256");
      return false;
    }
    const savedObj = getSavedSearchObject();
    let payLoad = {
      searchString: selectedjobTitleFilter,
      searchName: searchName,
      filters: JSON.stringify(savedObj),
    };
    let updatePayLoad = {
      id: searchId || localStorage.getItem("searchId"),
      searchString: searchString,
      searchName: searchName
        ? searchName
        : searchList &&
          searchList.length &&
          searchList.find(
            (l) =>
              l.id === JSON.parse(localStorage.getItem("searchId")) &&
              l.searchName
          )?.searchName,
      filters: JSON.stringify(savedObj),
    };
    await AccessService.saveSearch(
      updatedCheck || localStorage.getItem("searchId") != null
        ? updatePayLoad
        : payLoad
    )
      .then((res) => {
        populateExistingFilter(res.filters);
        handleApply();
        handleCloseAdvanceSearch();
        handleCloseAdvanceFilter();
        getSavedSearch();
        localStorage.setItem("searchId", res.id);
        success("Search saved successfully.");
        setError("");
        localStorage.removeItem("AdvanceSkills");
      })
      .catch(function (error) {
        setError(error.errorMessage);
        // danger("somethimg went wrong.");
      });
    // const savedObj = getSavedSearchObject();
    // localStorage.setItem('searchName', searchName);
    // let payLoad = {
    //     accessValidTill: user.accessValidTill,
    //     activeOrNot: user.activeOrNot,
    //     id: user.id,
    //     locations: user.locations === null ? [] : user.locations,
    //     userType: user.userType,
    //     searchData: JSON.stringify(savedObj),
    // };
    // await AccessService.editEnterpriseUserList(payLoad).then((res) => {
    //     populateExistingFilter(savedObj);
    //     setSavedSearchData(savedObj);
    //     handleApply();
    //     handleCloseAdvanceSearch();
    //     handleCloseAdvanceFilter();
    //     success("Search saved successfully");
    //     // setUser(res);
    // })
    // .catch(function (error) {
    //     danger("somethimg went wrong.");
    // });
  };
  const getSavedSearch = async () => {
    await ApiCalls.get(
      process.env.REACT_APP_API_URL + "enterprises/user/search/all?keyword="
    )
      .then((res) => {
        setSearchList(res);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    if (localStorage.getItem("searchId") != null) {
      getSearchDetails(localStorage.getItem("searchId"));
    }
    if (isFilterByApplied || isFilterByShortlisted) {
      getReferredByCandidate();
    }
  }, []);

  const getReferredByCandidate = async () => {
    await ApiCalls.get(
      process.env.REACT_APP_API_URL +
        `enterprises/pool/referrers/${params.job_id}`
    )
      .then((res) => {
        const data = res;
        data.unshift({ fullName: "All" });
        setReferredByFilterList(data);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    if (props.clearCheck != undefined) {
      modelClose();
    }
  }, [props.clearCheck]);

  useEffect(() => {
    if (openAdvanceFilter && advanceFilterObj) {
      populateExistingFilter(advanceFilterObj);
    } else {
      const check =
        searchParams.get("minYearsOfExperience") ||
        searchParams.get("maxYearsOfExperience") ||
        searchParams.get("levelOfEducation") ||
        searchParams.get("availabilityStatus") ||
        searchParams.get("profileTitle") ||
        searchParams.get("company") ||
        searchParams.get("payRateMax") ||
        searchParams.get("payRateMin") ||
        searchParams.get("city") ||
        searchParams.get("skill") ||
        searchParams.get("workType") ||
        searchParams.get("workPlace") ||
        searchParams.get("preferredShift") ||
        searchParams.get("minRating");
      if (!check && openAdvanceFilter) {
        populateExistingFilter({});
      }
    }
    if (
      openAdvanceFilter &&
      !searchParams.get("workType") &&
      !searchParams.get("workPlace")
    ) {
      setCheckedRemote(false);
      setCheckedOnsite(false);
      setCheckedHybrid(false);
      setCheckedFullTime(false);
      setCheckedPartTime(false);
      setCheckedContract(false);
      setCheckedInternship(false);
      setCheckedTemporary(false);
      setCheckedContingent(false);
      setCheckedApprentice(false);
      setCheckedOther(false);
    }
  }, [openAdvanceFilter]);

  useEffect(() => {
    setTimeout(async () => {
      const close = await document.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (close) {
        close.addEventListener("click", () => {
          localStorage.removeItem("searchId");
          if (!referredBy) {
            populateExistingFilter({});
            setJobTitlesArray([]);
            setSkillsArray([]);
            setCompaniesArray([]);
            setLocations([]);
            setIsActiveEduLevel({});
            setEducationLevel({});
            setSearchString("");
            setSearchId();
            setReferredBy(false);
            setSelectedReferredByName("");
            setSelectedReferredById("");
            setNewCandidates(false);
            setIsCheckedReferredByLabel(false);
            setCurrentStagesArray([]);
          }
          const savedObj = getSavedSearchObject();
          populateExistingFilter(savedObj);
          setAdvanceFilterObj(savedObj);
        });
      }
    }, 0);
  }, [
    document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0] ||
      openAdvanceFilter,
  ]);
  const SearchTitlesList = async (event) => {
    if (!event.target.value) {
      setSearchTitlesAutoFill([]);
    } else if (event.target.value.length === 1) {
      await AccessService.getSaveSearch(
        "keyword=" + event.target.value.split(","),
        0,
        3
      )
        .then((res) => setSearchTitlesAutoFill(res))
        .catch(function (error) {});
    }
  };
  const getSearchDetails = (value) => {
    if (value != null) {
      setSearchId(value?.id ? value?.id : value);
      AccessService.getSearchDetails(value?.id ? value?.id : value)
        .then((res) => {
          setUpdatedCheck(true);
          setSearchName(res.searchName);
          // localStorage.setItem("searchId", value?.id ? value?.id : value);
          setSearchString(decodeURIComponent(res.searchString));
          populateExistingFilter(JSON.parse(res.filters));
        })
        .catch(function (error) {
          danger("somethimg went wrong.");
        });
    } else {
      setTalentPoolLevel("A");
    }
  };
  const modelClose = () => {
    let check =
      searchParams.get("minYearsOfExperience") ||
      searchParams.get("maxYearsOfExperience") ||
      searchParams.get("levelOfEducation") ||
      searchParams.get("availabilityStatus") ||
      searchParams.get("profileTitle") ||
      searchParams.get("company") ||
      searchParams.get("payRateMax") ||
      searchParams.get("payRateMin") ||
      searchParams.get("city") ||
      searchParams.get("skill") ||
      searchParams.get("workType") ||
      searchParams.get("workPlace") ||
      searchParams.get("preferredShift") ||
      searchParams.get("minRating") ||
      searchParams.get("referredBy");
    searchParams.get("selectedReferredById");

    if (check === null || check === false) {
      populateExistingFilter({});
      searchParams.get("skillid")
        ? setSkillsArray(
            selectedjobTitleFilter &&
              selectedjobTitleFilterType === "S" &&
              selectedSkillId
              ? [
                  {
                    id: parseInt(selectedSkillId),
                    keyword: decodeURIComponent(selectedjobTitleFilter),
                  },
                ]
              : []
          )
        : setSkillsArray([]);
      searchParams.get("type") == "T"
        ? setJobTitlesArray(
            selectedjobTitleFilter && selectedjobTitleFilterType === "T"
              ? [decodeURIComponent(selectedjobTitleFilter)]
              : []
          )
        : setJobTitlesArray([]);
      setLocations([]);
      setLocationName({});
      setSearchName("");
      setTalentPoolLevel("A");
      setReferredBy(false);
      setSelectedReferredByName("");
      setSelectedReferredById("");
      localStorage.removeItem("searchId");
      setError("");
    }
    if (
      (searchParams.get("keyword") === null &&
        (check === null || check === false)) ||
      searchParams.get("keyword") != searchString
    ) {
      setSearchString("");
    }
    handleCloseAdvanceFilter();
    setSearchId();
  };
  const getEduLevelActiveStatusSaved = (data, edu) => {
    if (data?.educationLevels) {
      return data.educationLevels.includes(edu) ? true : false;
    } else {
      return getEduLevelActiveStatus(edu);
    }
  };

  const getAvailableListActiveStatus = (data, list) => {
    if (data?.availabilityStatus) {
      return data.availabilityStatus.includes(list) ? true : false;
    } else {
      return getAvailableList(list);
    }
  };
  const handleChangeRefferdBy = (e) => {
    setReferredBy(e.target.checked);
    if (e.target.checked == false) {
      setSelectedReferredByName("");
      setSelectedReferredById("");
    }
  };
  // useEffect(() => {
  //     if ( savedSearchData ) {
  //       populateExistingFilter(savedSearchData);
  //     }
  // }, [savedSearchData]);
  useEffect(async () => {
    if (!isFilterByMatchingCandidate && openAdvanceFilter) {
      getSavedSearch();
    }
    // if (localStorage.getItem("advanceFilterObj")) {
    //   if (search === "") {
    //     populateExistingFilter({});
    //     localStorage.removeItem("advanceFilterObj");
    //   } else {
    //     populateExistingFilter(JSON.parse(localStorage.getItem("advanceFilterObj")));
    //   }
    // } else {
    //   populateExistingFilter({});
    // }
    if (talentPool?.candidateAttributes) {
      const filterValue = JSON.parse(talentPool?.candidateAttributes);
      setJobTitlesArray(
        filterValue?.jobTitles ? filterValue?.jobTitles : jobTitlesArray
      );
      setSkillsArray(filterValue?.skills ? filterValue?.skills : skillsArray);
    }
    // await AccessService.getEnterprises()
    //   .then((res) => {
    //     const enterpriseUser = res.enterprisesUsers.find(
    //       (usr) => usr.emailAddress === localStorage.getItem("email")
    //     );
    //     setUser(enterpriseUser);
    //   })
    //   .catch(function (error) {});
  }, [openAdvanceFilter]);
  const getFilterTab = () => {
    if (value === "2" && !savedSearchData) {
      return (
        <Grid item className="mt-10">
          <Typography className="gray8 font-weight-500 f-14 text-center">
            No Search Found
          </Typography>
        </Grid>
      );
    }
    return (
      <>
        <Grid item>
          {value === "2" && (
            <>
              {/* <Typography className="gray9 font-weight-700 f-16">Search Name</Typography>
                      <Typography>
                        <Input
                            disabled={true}
                            value={localStorage.getItem('searchName')?localStorage.getItem('searchName'):''}
                          type="text"
                          name="searchname"
                          id="searchname"
                          className="form-control input-group mt-10 w-100"
                          margin="normal"
                          placeholder="Enter Search Name"
                        />
                      </Typography> */}
            </>
          )}
          <Grid
            container
            item
            spacing={1}
            className="responsive-block galaxy-block"
          >
            <Grid
              item
              xs={6}
              style={{
                display:
                  isFilterByCompaign || isFilterByMatchingCandidate
                    ? "none"
                    : "block",
              }}
            >
              <Box>
                <Typography className="gray8 font-weight-500 f-14">
                  Keyword or Boolean
                </Typography>
                <Typography>
                  <Input
                    disabled={true}
                    value={
                      searchString
                        ? searchString
                        : decodeURIComponent(selectedjobTitleFilter)
                    }
                    type="text"
                    name="keyword"
                    inputProps={{ className: "textEllipse-table" }}
                    id="keyword"
                    className="form-control input-group mt-10 w-100 inputcontrasts"
                    margin="normal"
                    placeholder="Enter keyword"
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={isFilterByCompaign ? 12 : 6}
              style={{
                display: isFilterByMatchingCandidate ? "none" : "block",
              }}
            >
              {isAuthenticated && (
                <Box>
                  <Typography className="gray8 font-weight-500 f-14">
                    Saved Search
                  </Typography>
                  <Typography>
                    <Autocomplete
                      open={openSearchTitleAutoComplete}
                      onClose={() => setSearchProfileTitleAutoComplete(false)}
                      onOpen={() => setSearchProfileTitleAutoComplete(true)}
                      id="free-solo-demo"
                      key={clearSearchTitles}
                      className="textEllipse-input saved-input"
                      options={searchList ? searchList : searchTitlesAutoFill}
                      popupIcon={""}
                      defaultValue={
                        searchList &&
                        searchList.find((l) =>
                          l.id ===
                          (typeof (localStorage.getItem("searchId") != "string")
                            ? JSON.parse(localStorage.getItem("searchId"))
                            : localStorage.getItem("searchId"))
                            ? l
                            : ""
                        )
                      }
                      noOptionsText={
                        <span style={{ color: "#C92938" }}>
                          Please select from the list
                        </span>
                      }
                      getOptionLabel={(option) => option.searchName}
                      disabled={searchList && searchList.length === 0}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="text"
                          className="form-control input-group mb-0 textEllipse w-100 inputcontrasts p-0"
                          margin="normal"
                          placeholder="Search by saved name"
                          // style={{ padding: "5px 8px" }}
                          onChange={SearchTitlesList}
                        />
                      )}
                      onChange={(event, jobTitl) => getSearchDetails(jobTitl)}
                      ListboxProps={{
                        style: { maxHeight: "10rem", wordBreak: "break-word" },
                      }}
                    />
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12} md={12} className="mt-15">
            <Box className="cityName p-relative">
              <Typography className="gray8 font-weight-500 f-14">
                Candidates
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radio-color"
                >
                  <FormControlLabel
                    value="All Candidates"
                    control={
                      <Radio
                        tabindex={0}
                        sx={{
                          color: "#667085",
                          "&.Mui-checked": {
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--secondary-color"),
                            "&.hoveer": {
                              background: "none",
                            },
                          },
                        }}
                        onClick={(e) => handleTalentPoolLevel("A")}
                        checked={talentPoolLevel === "A"}
                      />
                    }
                    label="All Candidates"
                  />
                  {!isFilterByApplied && !isFilterByShortlisted && (
                    <FormControlLabel
                      value="Public Candidates"
                      control={
                        <Radio
                          tabindex={0}
                          sx={{
                            color: "#667085",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--secondary-color"),
                              "&.hoveer": {
                                background: "none",
                              },
                            },
                          }}
                          onClick={(e) => handleTalentPoolLevel("S")}
                          checked={talentPoolLevel === "S"}
                        />
                      }
                      label="Public Candidates"
                    />
                  )}
                  <FormControlLabel
                    value="Qualified Candidates"
                    control={
                      <Radio
                        tabindex={0}
                        sx={{
                          color: "#667085",
                          "&.Mui-checked": {
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--secondary-color"),
                            "&.hoveer": {
                              background: "none",
                            },
                          },
                        }}
                        onClick={(e) => handleTalentPoolLevel("P")}
                        checked={talentPoolLevel === "P"}
                      />
                    }
                    label="Qualified Candidates"
                  />
                  <FormControlLabel
                    value="Exclusive Candidates"
                    control={
                      <Radio
                        tabindex={0}
                        sx={{
                          color: "#667085",
                          "&.Mui-checked": {
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--primary-color"),
                            "&.hoveer": {
                              background: "none",
                            },
                          },
                        }}
                        onClick={(e) => handleTalentPoolLevel("Z")}
                        checked={talentPoolLevel === "Z"}
                      />
                    }
                    label="Exclusive Candidates"
                  />
                </RadioGroup>
              </FormControl>
              {/* <Button
                className={
                  talentPoolLevel === "A" || !talentPoolLevel
                    ? "level-of-education-active m-r-10"
                    : "level-of-education m-r-10"
                }
                onClick={(e) => handleTalentPoolLevel("A")}
                disableRipple="true"
              >
                All Candidates
              </Button>
              <Button
                className={
                  talentPoolLevel === "S"
                    ? "level-of-education-active m-r-10"
                    : "level-of-education m-r-10"
                }
                onClick={(e) => handleTalentPoolLevel("S")}
                disableRipple="true"
              >
                Shared Candidates
              </Button>
              <Button
                className={
                  talentPoolLevel === "P"
                    ? "level-of-education-active m-r-10"
                    : "level-of-education m-r-10"
                }
                onClick={(e) => handleTalentPoolLevel("P")}
                disableRipple="true"
              >
                Private Candidates
              </Button>
              <Button
                className={
                  talentPoolLevel === "Z"
                    ? "level-of-education-active m-r-10"
                    : "level-of-education m-r-10"
                }
                onClick={(e) => handleTalentPoolLevel("Z")}
                disableRipple="true"
              >
                Priority Candidates
              </Button> */}
            </Box>
          </Grid>
          <Grid item xs={12} lg={12} md={12} className="mt-15">
            <Box className="cityName p-relative">
              <Typography className="gray8 font-weight-500 f-14">
                Location
                {(props?.job?.city || props?.job?.state) && (
                  <Typography className="radio-color">
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#667085",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--primary-color"),
                            },
                          }}
                          onClick={(e) => {
                            if (e.target.checked) {
                              let locationData = {
                                city: props?.job?.city,
                                state: props?.job?.state,
                                country: props?.job?.country,
                              };
                              onSelectLocation(locationData);
                              setLatLng((prevValue) => [
                                ...prevValue,
                                {
                                  lat: props?.job?.latitude,
                                  lng: props?.job?.longitude,
                                },
                              ]);
                            } else {
                              const locationIndex = locations.findIndex(
                                (item) =>
                                  item.city === props?.job?.city &&
                                  item.state === props?.job?.state
                              );
                              if (locationIndex !== -1) {
                                handleLocationDelete(locationIndex);
                                removeLatLngatIndex(locationIndex);
                              }
                            }
                          }}
                          checked={
                            locations.findIndex(
                              (item) =>
                                item.city === props?.job?.city &&
                                item.state === props?.job?.state
                            ) !== -1
                          }
                        />
                      }
                      label={"Get candidates based on job location"}
                    />
                  </Typography>
                )}
              </Typography>
              <LocationSearchInput
                // city={locationName?.city}
                addLocation={true}
                onSelectLocation={onSelectLocation}
                stateCheck={false}
                placeholder="Search by location or zip code"
                searchWithZipcode={true}
                handleLatLong={(e) => {
                  setLatLng([...latlng, e]);
                }}
                latlng={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={12} md={12} style={{ marginLeft: "-9px" }}>
            <Stack direction="row" spacing={1} className="d-block">
              {locations.length > 0 &&
                locations.map((location, index) => {
                  return (
                    <Tooltip
                      key={index}
                      title={`${
                        location.zipCode ? `${location.zipCode} - ` : ""
                      }${location.city ? location.city + " - " : location.state ? location.state + " - " : ""} ${
                        location.country
                      }`}
                      position="top"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <Chip
                        label={
                          location.zipCode
                            ? location.zipCode
                            : location.city
                            ? location.city
                            : location.state
                            ? location.state
                            : location.country
                        }
                        onClick={handleLocationClick}
                        onKeyPress={handleLocationClick}
                        className="m-10"
                        onDelete={() => handleLocationDelete(index)}
                        tabIndex={0}
                      />
                    </Tooltip>
                  );
                })}
            </Stack>
          </Grid>
          {props?.job && locations.length > 0 && (
            <Grid item xs={12} lg={12} md={12} className="mt-15">
              <Box>
                <Grid className="d-flex justify-between align-center">
                  <Grid className="d-flex justify-between align-center">
                    <Typography className="gray8 font-weight-500 f-14">
                      Location within Radius
                    </Typography>
                    <Tooltip
                      title="0 is ignored"
                      placement="right" // Adjusted placement to "right"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <IconButton
                        style={{
                          marginLeft: "3px",
                          // marginTop: "1px",
                        }}
                        aria-label="Info icon"
                      >
                        <InfoIcon aria-label="Info icon" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Typography className="d-flex align-center location-btn">
                    <Button
                      className={`btn border-r-tb-0 ${
                        !filterMetric ? "primary-button" : "secondary-button"
                      }`}
                      onClick={() => setFilterMetric(0)}
                    >
                      km
                    </Button>
                    <Button
                      className={`btn border-l-tb-0 ${
                        filterMetric ? "primary-button" : "secondary-button"
                      }`}
                      onClick={() => setFilterMetric(1)}
                    >
                      mi
                    </Button>
                  </Typography>
                </Grid>
                <Typography className="d-flex align-center mt-15">
                  <Slider
                    valueLabelDisplay="auto"
                    valueLabelFormat={valueLabelFormatRadiusFilter}
                    min={0}
                    max={200}
                    value={radiusSearch}
                    onChange={handleRadiusChange}
                    style={{ width: "100%", margin: "0px" }}
                    aria-label="slider"
                    className="location-slider"
                  />
                </Typography>
                <Typography
                  className="justify-between filter-payrate"
                  sx={{ lineHeight: "0px" }}
                >
                  <span className="d-flex align-center">
                    <TextField
                      className="inputcontrasts mt-5 location-miles"
                      id="keywordWhat_field"
                      type="search"
                      variant="outlined"
                      margin="normal"
                      placeholder="Max"
                      value={0}
                      style={{
                        width: "30px",
                      }}
                      disabled
                    />
                    <Box className="ml-5 mb-5 mile-km-align">
                      {filterMetric ? "mile" : "km"}
                    </Box>
                  </span>
                  <span className="d-flex align-center">
                    <TextField
                      className="inputcontrasts mt-5"
                      id="keywordWhat_field"
                      type="search"
                      variant="outlined"
                      margin="normal"
                      placeholder="Max"
                      onChange={(e) => {
                        let onlyNums = e.target.value.replace(/[^0-9]/g, "");
                        onlyNums = onlyNums ? parseInt(onlyNums) : 0;
                        if (onlyNums > 200) {
                          e.target.value = 200;
                          onlyNums = 200;
                        }
                        setRadiusSearch(onlyNums);
                      }}
                      value={`${radiusSearch}`}
                      style={{
                        width: "40px",
                      }}
                    />
                    <Box className="ml-5 mb-5 mile-km-align mile-range">
                      {filterMetric ? "mile" : "km"}
                      {filterMetric && radiusSearch > 1 ? "s" : ""}
                    </Box>
                  </span>
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        {(isFilterByMatchingCandidate || isFilterByApplied || isFilterByShortlisted) && (
          <Grid item xs={12} lg={12} md={12} className="mt-25">
            <Box className="cityName p-relative">
              <Typography className="gray8 font-weight-500 f-14">
                Availability
              </Typography>
              <Typography className="radio-color">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#667085",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                        },
                      }}
                      onClick={(e) => {
                        handleAvailabilty(e, "Available immediately");
                      }}
                      checked={availableList.includes("Available immediately")}
                    />
                  }
                  label={availabilityStatusLabels?.statusA?.value}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#667085",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                        },
                      }}
                      onClick={(e) => {
                        handleAvailabilty(e, "Onboarded");
                      }}
                      checked={availableList.includes("Onboarded")}
                    />
                  }
                  label={availabilityStatusLabels?.statusO?.value}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#667085",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                        },
                      }}
                      onClick={(e) => {
                        handleAvailabilty(e, "May be available");
                      }}
                      checked={availableList.includes("May be available")}
                    />
                  }
                  label={availabilityStatusLabels?.statusM?.value && "Unknown"}
                />
              </Typography>
              {/* <Button
                className={
                  isActiveAvailability["Available immediately"]
                    ? "level-of-education-active m-r-10"
                    : "level-of-education m-r-10"
                }
                onClick={(e) => {
                  handleAvailabilty(e, "Available immediately");
                }}
                disableRipple="true"
              >
                Available Immediately
              </Button>
              <Button
                className={
                  isActiveAvailability["May be available"]
                    ? "level-of-education-active m-r-10"
                    : "level-of-education m-r-10"
                }
                onClick={(e) => {
                  handleAvailabilty(e, "May be available");
                }}
                disableRipple="true"
              >
                May be Available
              </Button> */}
            </Box>
          </Grid>
        )}
        {!isFilterByMatchingCandidate && (
          <Grid item className="mt-25">
            <Typography className="gray8 font-weight-500 f-14">
              Years of Experience
            </Typography>
            <Typography className="d-flex align-center mt-10">
              {/* <Box>0</Box> */}
              <Slider
                //   disabled={value === '2'}
                value={[sliderLow, sliderHigh]}
                onChangeCommitted={handleChangeCommited}
                onChange={handleChangeSlider}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                min={0}
                max={31}
                style={{ width: "100%", margin: "0px 6px" }}
                aria-label="slider"
              />
              {/* <Box>30+</Box> */}
            </Typography>
          </Grid>
        )}
        {!isFilterByMatchingCandidate && (
          <Grid className="mt-25">
            <Typography className="gray8 font-weight-500 f-14">
              {appOrigin === "H" ? "Specialization" : "Skills"}
            </Typography>
            <Typography>
              <Autocomplete
                open={openSkillsAutoComplete}
                onClose={() => setOpenSkillsAutoComplete(false)}
                // onOpen={() => setOpenSkillsAutoComplete(true)}
                id="free-solo-demo"
                key={clear}
                options={skillsAutoFill}
                popupIcon={""}
                noOptionsText={
                  <span style={{ color: "#C92938" }}>
                    Please select from the list
                  </span>
                }
                getOptionLabel={(option) =>
                  `${option.keyword} [${option.subcategoryKeyword} - ${option.industry}]`
                }
                onInputChange={(event, value) => {
                  if (value.length === 0) {
                    if (openSkillsAutoComplete) {
                      setOpenSkillsAutoComplete(false);
                    }
                  } else {
                    if (!openSkillsAutoComplete) {
                      setOpenSkillsAutoComplete(true);
                    }
                  }
                }}
                // disabled={value==='2'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    className="form-control input-group mb-0 inputcontrasts"
                    margin="normal"
                    placeholder={
                      appOrigin === "H"
                        ? "Search by specialization"
                        : "Search by skills"
                    }
                    onChange={(event) =>
                      setDebouncedSkillValue(event.target.value)
                    }
                  />
                )}
                onChange={(event, skill) => addSkill(skill)}
                ListboxProps={{ style: { maxHeight: "10rem" } }}
              />
            </Typography>
            <Typography className="">
              {skillsArray?.map((skill, index) => {
                return (
                  <span key={index} className="skills align-center d-flex">
                    {skill.keyword}
                    <img
                      className="small-text-font ml-5 cursor-hover"
                      sx={{ width: "9px" }}
                      src={closeIcon}
                      onClick={() => deleteSkill(index)}
                      tabIndex={0}
                      alt="close icon"
                      onKeyPress={() => deleteSkill(index)}
                    />
                  </span>
                );
              })}
            </Typography>
          </Grid>
        )}
        {!isFilterByMatchingCandidate && (
          <Grid item lg={12} md={12} xs={12} className="mt-25">
            <Box>
              <Typography className="gray8 font-weight-500 f-14">
                Education
              </Typography>
              <Box className="mt-5">
                <Button
                  className={
                    isActiveEduLevel.P === true
                      ? "level-of-education-active m-r-10"
                      : "level-of-education m-r-10"
                  }
                  onClick={(e) => {
                    // if (value==='1') {
                    handleEducationLevel(e, "P");
                    // }
                  }}
                  disableRipple="true"
                >
                  Doctorate
                </Button>
                <Button
                  className={
                    isActiveEduLevel.M === true
                      ? "level-of-education-active m-r-10"
                      : "level-of-education m-r-10"
                  }
                  onClick={(e) => {
                    // if (value==='1') {
                    handleEducationLevel(e, "M");
                    // }
                  }}
                  disableRipple="true"
                >
                  Masters
                </Button>
                <Button
                  className={
                    isActiveEduLevel.B === true
                      ? "level-of-education-active m-r-10"
                      : "level-of-education m-r-10"
                  }
                  onClick={(e) => {
                    // if (value==='1') {
                    handleEducationLevel(e, "B");
                    // }
                  }}
                  disableRipple="true"
                >
                  Bachelors
                </Button>
                <Button
                  className={
                    isActiveEduLevel.A === true
                      ? "level-of-education-active m-r-10"
                      : "level-of-education m-r-10"
                  }
                  onClick={(e) => {
                    // if (value==='1') {
                    handleEducationLevel(e, "A");
                    // }
                  }}
                  disableRipple="true"
                  aria-label="button"
                >
                  Associate
                </Button>
                <Button
                  className={
                    isActiveEduLevel.D === true
                      ? "level-of-education-active m-r-10"
                      : "level-of-education m-r-10"
                  }
                  onClick={(e) => {
                    // if (value==='1') {
                    handleEducationLevel(e, "D");
                    // }
                  }}
                  disableRipple="true"
                >
                  Diploma
                </Button>
                <Button
                  className={
                    isActiveEduLevel.S === true
                      ? "level-of-education-active m-r-10"
                      : "level-of-education m-r-10"
                  }
                  onClick={(e) => {
                    // if (value==='1') {
                    handleEducationLevel(e, "S");
                    // }
                  }}
                  disableRipple="true"
                >
                  School
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item className="mt-25">
          {isFilterByMatchingCandidate ? (
            <Typography className="gray8 font-weight-500 f-14">
              Pay Rate
            </Typography>
          ) : (
            <Typography className="gray8 font-weight-500 f-14">
              Pay Rate
            </Typography>
          )}
          <Typography className="d-flex align-center mt-10">
            {/* <Box>0</Box> */}
            <Slider
              //   disabled={value === '2'}
              value={[sliderLowPayRate, sliderHighPayRate]}
              onChangeCommitted={handleChangeCommitedPayRate}
              onChange={handleChangeSliderPayRate}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormatPayRate}
              min={0}
              max={1001}
              style={{ width: "100%", margin: "0px 6px" }}
              aria-label="slider"
            />
            {/* <Box>$100K+</Box> */}
          </Typography>
          <Typography
            className="justify-between filter-payrate"
            sx={{ lineHeight: "0px" }}
          >
            <span>
              <TextField
                className="inputcontrasts mt-5"
                id="keywordWhat_field"
                type="search"
                variant="outlined"
                margin="normal"
                placeholder="Min"
                style={{
                  width: "40px",
                }}
                value={sliderLowPayRate}
                onBlur={(e) => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                  setSliderLowPayRate(onlyNums);
                }}
                onChange={(e) => {
                  let onlyNums = e.target.value.replace(/[^0-9]/g, "");
                  onlyNums = onlyNums ? parseInt(onlyNums) : 0;
                  if (onlyNums > 1000) {
                    e.target.value = 1000;
                    onlyNums = 1000;
                  }
                  if (onlyNums > sliderHighPayRate) {
                    e.target.value = sliderHighPayRate;
                    onlyNums = sliderHighPayRate;
                  }
                  setSliderLowPayRate(onlyNums);
                  setMinExpPayRate(onlyNums);
                }}
              />
            </span>
            <span>
              <TextField
                className="inputcontrasts mt-5"
                id="keywordWhat_field"
                type="search"
                variant="outlined"
                margin="normal"
                placeholder="Max"
                style={{
                  width: "55px",
                }}
                value={sliderHighPayRate > 1000 ? "1000+" : sliderHighPayRate}
                onBlur={(e) => {}}
                onChange={(e) => {
                  let onlyNums = e.target.value.replace(/[^0-9]/g, "");
                  onlyNums = onlyNums ? parseInt(onlyNums) : 0;
                  if (onlyNums < sliderLowPayRate) {
                    setSliderLowPayRate(0);
                    setMinExpPayRate(0);
                  }
                  setSliderHighPayRate(onlyNums);
                  setMaxExpPayRate(onlyNums);
                }}
              />
            </span>
          </Typography>
        </Grid>
        {/* <Grid item className="mt-25">
                      <Typography className="gray9 font-weight-700 f-16">Work Authorization</Typography>
                      <Typography>
                        <Button className="btn edu-filter-name">H - 1B</Button>
                        <Button className="btn edu-filter-name">H - 1B1</Button>
                        <Button className="btn edu-filter-name">H - 2B</Button>
                        <Button className="btn edu-filter-name">J - 1</Button>
                        <Button className="btn edu-filter-name">L - 1</Button>
                      </Typography>
                    </Grid>
                    <Grid item className="mt-25">
                      <Typography className="gray9 font-weight-700 f-16">Languages</Typography>
                      <Typography>
                        <Input
                          type="text"
                          name="language"
                          id="language"
                          className="form-control input-group mt-10 w-100"
                          margin="normal"
                          placeholder="Enter language"
                        />
                      </Typography>
                      <Typography className="mt-10">
                        <Box className="d-flex skills align-center text-initial">
                          <span className="gray7 f-14 font-weight-500">Chinese</span>
                          <ClearIcon className="ml-5 gray7 f-14" />
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item className="mt-25">
                      <Typography className="gray9 font-weight-700 f-16">Compensation</Typography>
                      <Typography className="d-flex align-center mt-10">
                        <Box>0</Box>
                        <Slider
                          value={[sliderLow, sliderHigh]}
                          onChangeCommitted={handleChangeCommited}
                          onChange={handleChangeSlider}
                          valueLabelDisplay="auto"
                          valueLabelFormat={valueLabelFormat}
                          min={0}
                          max={200}
                          style={{ width: '60%', margin: '0px 20px' }}
                        />
                        <Box>$ 100K+</Box>
                      </Typography>
                    </Grid> */}
        <Grid item className="mt-15">
          <Typography className="gray8 font-weight-500 f-14">
            Work Type
          </Typography>
          <Grid container lg={12} md={12} xs={12}>
            <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedFullTime}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedFullTime}
                  onKeyPress={handleCheckedFullTime}
                />
              </Typography>
              <Typography
                className="f-14 font-weight-400 gray10 p-l-5 cursor-hover"
                onClick={handleCheckedFullTime}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedFullTime}
              >
                Full Time
              </Typography>
            </Grid>
            <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedPartTime}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedPartTime}
                  onKeyPress={handleCheckedPartTime}
                />
              </Typography>
              <Typography
                className="f-14 font-weight-400 gray10 p-l-5 cursor-hover"
                onClick={handleCheckedPartTime}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedPartTime}
              >
                Part Time
              </Typography>
            </Grid>
            <Grid item lg={3.2} md={3.2} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedContract}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedContract}
                  onKeyPress={handleCheckedContract}
                />
              </Typography>
              <Typography
                className="f-14 font-weight-400 gray10 p-l-5 cursor-hover"
                onClick={handleCheckedContract}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedContract}
              >
                Contingent / Contract
              </Typography>
            </Grid>
            <Grid item lg={2} md={2} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedInternship}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedInternship}
                  onKeyPress={handleCheckedInternship}
                />
              </Typography>
              <Typography
                className="f-14 font-weight-400 gray10 p-l-5 cursor-hover"
                onClick={handleCheckedInternship}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedInternship}
              >
                Internship
              </Typography>
            </Grid>
            <Grid item lg={2} md={2} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedOther}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedOther}
                  onKeyPress={handleCheckedOther}
                />
              </Typography>
              <Typography
                className="f-14 font-weight-400 gray10 p-l-5 cursor-hover"
                onClick={handleCheckedOther}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedOther}
              >
                Other
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="mt-25">
          <Typography className="gray8 font-weight-500 f-14">
            Workplace
          </Typography>
          <Grid container lg={12} md={12} xs={12}>
            <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedRemote}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedRemote}
                  onKeyPress={handleCheckedRemote}
                />
              </Typography>
              <Typography
                className="small-text-font p-l-5 cursor-hover"
                onClick={handleCheckedRemote}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedRemote}
              >
                Remote
              </Typography>
            </Grid>
            <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedOnsite}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedOnsite}
                  onKeyPress={handleCheckedOnsite}
                />
              </Typography>
              <Typography
                className="small-text-font p-l-5 cursor-hover"
                onClick={handleCheckedOnsite}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedOnsite}
              >
                Onsite
              </Typography>
            </Grid>
            <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
              <Typography>
                <Checkbox
                  {...label}
                  checked={checkedHybrid}
                  sx={{
                    color: "#667085",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  onClick={handleCheckedHybrid}
                  onKeyPress={handleCheckedHybrid}
                />
              </Typography>
              <Typography
                className="small-text-font p-l-5 cursor-hover"
                onClick={handleCheckedHybrid}
                style={{ marginTop: "3px" }}
                onKeyPress={handleCheckedHybrid}
              >
                Hybrid
              </Typography>
            </Grid>
            {/* <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
                          <Typography>
                          <Checkbox
                              {...label}
                              checked={checkedRemote}
                              sx={{
                              color: "#667085",
                              padding: "0px",
                              "&.Mui-checked": {
                                  color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                  padding: "0px",
                                  "&.hoveer": {
                                  background: "none",
                                  padding: "0px",
                                  },
                              },
                              }}
                              onClick={handleCheckedRemote}
                              onKeyPress={handleCheckedRemote}
                          />
                          </Typography>
                          <Typography
                          className="small-text-font p-l-5 cursor-hover"
                          onClick={handleCheckedRemote}
                          style={{ marginTop: '3px' }}
                          onKeyPress={handleCheckedRemote}
                          >
                          Remote
                          </Typography>
                      </Grid>
                      <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
                          <Typography>
                          <Checkbox
                              {...label}
                              checked={checkedHybrid}
                              sx={{
                              color: "#667085",
                              padding: "0px",
                              "&.Mui-checked": {
                                  color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                  padding: "0px",
                                  "&.hoveer": {
                                  background: "none",
                                  padding: "0px",
                                  },
                              },
                              }}
                              onClick={handleCheckedHybrid}
                              onKeyPress={handleCheckedHybrid}
                          />
                          </Typography>
                          <Typography
                          className="small-text-font p-l-5 cursor-hover"
                          onClick={handleCheckedHybrid}
                          style={{ marginTop: '3px' }}
                          onKeyPress={handleCheckedHybrid}
                          >
                          Hybrid
                          </Typography>
                      </Grid>
                      <Grid item lg={2.4} md={2.4} xs={12} className="mt-10 d-flex">
                          <Typography>
                          <Checkbox
                              {...label}
                              checked={checkedOnsite}
                              sx={{
                              color: "#667085",
                              padding: "0px",
                              "&.Mui-checked": {
                                  color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                  padding: "0px",
                                  "&.hoveer": {
                                  background: "none",
                                  padding: "0px",
                                  },
                              },
                              }}
                              onClick={handleCheckedOnsite}
                              onKeyPress={handleCheckedOnsite}
                          />
                          </Typography>
                          <Typography
                          className="small-text-font p-l-5 cursor-hover"
                          onClick={handleCheckedOnsite}
                          style={{ marginTop: '3px' }}
                          onKeyPress={handleCheckedOnsite}
                          >
                          Onsite
                          </Typography>
                      </Grid> */}
          </Grid>
        </Grid>
        {!isFilterByMatchingCandidate && (
          <Grid item className="mt-25">
            <Typography className="gray8 font-weight-500 f-14">
              Profile Title
            </Typography>
            <Typography>
              <Autocomplete
                open={openProfileTitleAutoComplete}
                onClose={() => setOpenProfileTitleAutoComplete(false)}
                // onOpen={() => setOpenProfileTitleAutoComplete(true)}
                id="free-solo-demo"
                key={clearJobTitles}
                options={jobTitlesAutoFill}
                popupIcon={""}
                noOptionsText={
                  <span style={{ color: "#C92938" }}>
                    Please select from the list
                  </span>
                }
                getOptionLabel={(option) => option.name}
                onInputChange={(_, value) => {
                  if (value.length === 0) {
                    if (openProfileTitleAutoComplete) {
                      setOpenProfileTitleAutoComplete(false);
                    }
                  } else {
                    if (!openProfileTitleAutoComplete) {
                      setOpenProfileTitleAutoComplete(true);
                    }
                  }
                }}
                // disabled={value==='2'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    className="form-control input-group mb-0 inputcontrasts"
                    margin="normal"
                    placeholder="Search by profile title"
                    onChange={(e) => setDebouncedTitleValue(e.target.value)}
                  />
                )}
                onChange={(event, jobTitl) => addJobTitle(jobTitl)}
                ListboxProps={{ style: { maxHeight: "10rem" } }}
              />
            </Typography>
            <Typography className="">
              {jobTitlesArray.map((jobT, index) => {
                return (
                  <span key={index} className="skills align-center d-flex">
                    {jobT}
                    <img
                      className="small-text-font ml-5 cursor-hover"
                      sx={{ width: "9px" }}
                      src={closeIcon}
                      onClick={() => deleteJobTitle(index)}
                      tabIndex={0}
                      alt="close icon"
                      onKeyPress={() => deleteJobTitle(index)}
                    />
                  </span>
                );
              })}
            </Typography>
          </Grid>
        )}
        {!isFilterByApplied && !isFilterByShortlisted && (
          <Grid item className="mt-25">
            <Typography className="gray8 font-weight-500 f-14">
              Company
            </Typography>
            <Typography>
              <Autocomplete
                open={openCompanyAutoComplete}
                onClose={() => setOpenCompanyAutoComplete(false)}
                // onOpen={() => setOpenCompanyAutoComplete(true)}
                id="free-solo-demo"
                key={clearCompanies}
                options={companiesAutoFill}
                popupIcon={""}
                noOptionsText={
                  <span style={{ color: "#C92938" }}>
                    Please select from the list
                  </span>
                }
                getOptionLabel={(option) => option.name}
                onInputChange={(_, value) => {
                  if (value.length === 0) {
                    if (openCompanyAutoComplete) {
                      setOpenCompanyAutoComplete(false);
                    }
                  } else {
                    if (!openCompanyAutoComplete) {
                      setOpenCompanyAutoComplete(true);
                    }
                  }
                }}
                // disabled={value==='2'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    className="form-control input-group mb-0 inputcontrasts"
                    margin="normal"
                    placeholder="Search by company"
                    onChange={(e) => setDebouncedCompanyValue(e.target.value)}
                  />
                )}
                onChange={(event, comp) => addCompany(comp)}
                ListboxProps={{ style: { maxHeight: "10rem" } }}
              />
            </Typography>
            <Typography className="">
              {companiesArray.map((comp, index) => {
                return (
                  <span
                    key={index}
                    className="companiesFilter align-center d-flex"
                  >
                    {comp}
                    <img
                      className="small-text-font ml-5 cursor-hover"
                      sx={{ width: "9px" }}
                      src={closeIcon}
                      onClick={() => deleteCompany(index)}
                      onKeyPress={() => deleteCompany(index)}
                      tabIndex={0}
                      alt="close icon"
                    />
                  </span>
                );
              })}
            </Typography>
          </Grid>
        )}
        {appOrigin === "H" && (
          <>
            <Grid item className="mt-25">
              <Typography className="w-100 shift-filter">
                <Typography className="gray8 f-14 font-weight-500">
                  Shift Preferences
                </Typography>
                <Grid
                  container
                  spacing={1}
                  lg={12}
                  md={12}
                  xs={12}
                  className="m-0"
                  id="companyOption"
                >
                  <Grid container lg={12} md={12} xs={12} className="m-auto">
                    <Grid
                      item
                      lg={2.4}
                      md={2.4}
                      xs={12}
                      className="mt-10 d-flex"
                    >
                      <Typography>
                        <Checkbox
                          {...label}
                          checked={checkedDayShift}
                          sx={{
                            color: "#667085",
                            padding: "0px",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--primary-color"),
                              padding: "0px",
                              "&.hoveer": {
                                background: "none",
                                padding: "0px",
                              },
                            },
                          }}
                          onClick={handleCheckedDayShift}
                          onKeyPress={handleCheckedDayShift}
                        />
                      </Typography>
                      <Typography
                        className="small-text-font p-l-5 cursor-hover"
                        onClick={handleCheckedDayShift}
                        style={{ marginTop: "3px" }}
                        onKeyPress={handleCheckedDayShift}
                      >
                        Day
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={2.4}
                      md={2.4}
                      xs={12}
                      className="mt-10 d-flex"
                    >
                      <Typography>
                        <Checkbox
                          {...label}
                          checked={checkedEveningShift}
                          sx={{
                            color: "#667085",
                            padding: "0px",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--primary-color"),
                              padding: "0px",
                              "&.hoveer": {
                                background: "none",
                                padding: "0px",
                              },
                            },
                          }}
                          onClick={handleCheckedEveningShift}
                          onKeyPress={handleCheckedEveningShift}
                        />
                      </Typography>
                      <Typography
                        className="small-text-font p-l-5 cursor-hover"
                        onClick={handleCheckedEveningShift}
                        style={{ marginTop: "3px" }}
                        onKeyPress={handleCheckedEveningShift}
                      >
                        Evening
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={2.4}
                      md={2.4}
                      xs={12}
                      className="mt-10 d-flex"
                    >
                      <Typography>
                        <Checkbox
                          {...label}
                          checked={checkedNightShift}
                          sx={{
                            color: "#667085",
                            padding: "0px",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--primary-color"),
                              padding: "0px",
                              "&.hoveer": {
                                background: "none",
                                padding: "0px",
                              },
                            },
                          }}
                          onClick={handleCheckedNightShift}
                          onKeyPress={handleCheckedNightShift}
                        />
                      </Typography>
                      <Typography
                        className="small-text-font p-l-5 cursor-hover"
                        onClick={handleCheckedNightShift}
                        style={{ marginTop: "3px" }}
                        onKeyPress={handleCheckedNightShift}
                      >
                        Night
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={2.4}
                      md={2.4}
                      xs={12}
                      className="mt-10 d-flex"
                    >
                      <Typography>
                        <Checkbox
                          {...label}
                          checked={checkedFlexibleShift}
                          sx={{
                            color: "#667085",
                            padding: "0px",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--primary-color"),
                              padding: "0px",
                              "&.hoveer": {
                                background: "none",
                                padding: "0px",
                              },
                            },
                          }}
                          onClick={handleCheckedFlexibleShift}
                          onKeyPress={handleCheckedFlexibleShift}
                        />
                      </Typography>
                      <Typography
                        className="small-text-font p-l-5 cursor-hover"
                        onClick={handleCheckedFlexibleShift}
                        style={{ marginTop: "3px" }}
                        onKeyPress={handleCheckedFlexibleShift}
                      >
                        Flexible
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </>
        )}
        <Grid item className="mt-25">
          <Typography className="gray8 font-weight-500 f-14">
            Ratings
          </Typography>
          <Grid container lg={12} md={12} xs={12} className="rating-category">
            <Select
              className="w-100 mb-0 job-category rating-select-align form-control input-group"
              tabIndex={0}
              value={rating ? rating : "--Select--"}
              open={selectOpenRating}
              onClose={() => setSelectOpenRating(false)}
              onOpen={() => setSelectOpenRating(true)}
              onChange={handleSelectRating}
              displayEmpty
            >
              <MenuItem value="--Select--">--Select--</MenuItem>
              <MenuItem value={5}>5 only</MenuItem>
              <MenuItem value={4}>4 and up</MenuItem>
              <MenuItem value={3}>3 and up</MenuItem>
              <MenuItem value={2}>2 and up</MenuItem>
              <MenuItem value={1}>1 and up</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {(isFilterByApplied || isFilterByShortlisted) && (
          <>
            <Grid item className="mt-25">
              <Typography className="mt-10  align-center d-flex responsive-block">
                <Box className="gray8 font-weight-500 f-14">
                  Show only “Referred by” Candidates
                </Box>
                <Typography align="left" className="switch-case-section ml-10">
                  <Switch
                    checked={referredBy}
                    onChange={handleChangeRefferdBy}
                    onKeyPress={handleChangeRefferdBy}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Typography>
              </Typography>
            </Grid>
            <Grid item className="mt-15">
              <Box className="gray8 font-weight-500 f-14">Referred by</Box>
              <Typography className="referree-name">
                {referredBy ? (
                  <Autocomplete
                    // freeSolo
                    id="free-solo-demo"
                    // className="skill-checklist-autocomplete checklist-input"
                    // ref={autoComplete1}
                    options={referredByFilterList}
                    disabled={referredBy ? false : true}
                    getOptionLabel={(option) =>
                      option.fullName ? option.fullName : selectedReferredByName
                    }
                    // key={clearReferred}
                    popupIcon={""}
                    // disableClearable
                    value={
                      selectedReferredByName?.fullName
                        ? selectedReferredByName?.fullName
                        : selectedReferredByName
                    }
                    noOptionsText={
                      <span style={{ color: "#C92938" }}>
                        Please select from the list
                      </span>
                    }
                    onBlur={() => {
                      if (!selectedReferredByName) {
                        setSelectedReferredByName("");
                        setSelectedReferredById("");
                      }
                    }}
                    clearIcon={
                      hideCrossIcon ? (
                        ""
                      ) : (
                        <ClearIcon
                          onClick={() => {
                            setSelectedReferredByName("");
                            setSelectedReferredById("");
                          }}
                          aria-label="Clear icon"
                          className="cursor-hover f-15 textEllipse  Clearicon-issue"
                          style={{ marginTop: "1px" }}
                        />
                      )
                    }
                    onInputChange={(event, newInputValue) => {
                      if (event && event.target.value === "") {
                        setHideCrossIcon(true);
                      } else {
                        setHideCrossIcon(false);
                      }
                    }}
                    ListboxProps={{ style: { maxHeight: "10rem" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        type="text"
                        name="Search"
                        margin="normal"
                        className="form-control input-group mb-0 inputcontrasts"
                        placeholder="Type referrer name"
                      />
                    )}
                    onChange={(event, referred) => {
                      if (!referred) {
                        setHideCrossIcon(true);
                      } else {
                        setHideCrossIcon(false);
                      }
                      setSelectedReferredByName(referred?.fullName);
                      setSelectedReferredById(referred?.id);
                    }}
                    tabIndex={0}
                  />
                ) : (
                  <TextField
                    type="text"
                    name="Search"
                    margin="normal"
                    disabled={referredBy ? false : true}
                    className="form-control input-group mb-0 inputcontrasts p-0 referree-input"
                    placeholder="Type referrer name"
                  />
                )}
              </Typography>
            </Grid>
          </>
        )}
        {isFilterByMatchingCandidate &&
        enterpriseDetail?.enterprise?.customConfigurations != null &&
        enterpriseDetail?.enterprise?.customConfigurations != "" &&
        (userType === "Z" || userType === "T" || userType === "R") &&
        <>
        <Grid item className="mt-25">
          <Typography className="mt-10  align-center d-flex responsive-block">
            <Box className="gray8 font-weight-500 f-14">
              Show only “New” Candidates
            </Box>
            <Typography align="left" className="switch-case-section ml-10">
              <Switch
                checked={newCandidates}
                onChange={(e)=>setNewCandidates(e.target.checked)}
                onKeyPress={(e)=>setNewCandidates(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Typography>
          </Typography>
        </Grid>
        {(isFilterByApplied || isFilterByShortlisted) && referredByLabel &&
        <Grid item className="mt-25">
          <Typography className="mt-10  align-center d-flex responsive-block">
            <Box className="gray8 font-weight-500 f-14">
              {`Show only “${referredByLabel}” Candidates`}
            </Box>
            <Typography align="left" className="switch-case-section ml-10">
              <Switch
                checked={isCheckedReferredByLabel}
                onChange={(e)=>setIsCheckedReferredByLabel(e.target.checked)}
                onKeyPress={(e)=>setIsCheckedReferredByLabel(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Typography>
          </Typography>
        </Grid>
        }
        <Grid item className="mt-25">
          <Typography className="gray8 font-weight-500 f-14">
            Current Stage
          </Typography>
          <Typography>
            <Autocomplete
              open={openCurrentStageAutoComplete}
              onClose={() => setOpenCurrentStageAutoComplete(false)}
              id="free-solo-demo"
              key={clearCurrentStages}
              options={currentStageAutoFill}
              popupIcon={""}
              noOptionsText={
                <span style={{ color: "#C92938" }}>
                  Please select from the list
                </span>
              }
              getOptionLabel={(option) => option.label}
              onInputChange={(_, value) => {
                if (value.length === 0) {
                  if (openCurrentStageAutoComplete) {
                    setOpenCurrentStageAutoComplete(false);
                  }
                } else {
                  if (!openCurrentStageAutoComplete) {
                    setOpenCurrentStageAutoComplete(true);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  className="form-control input-group mb-0 inputcontrasts"
                  margin="normal"
                  placeholder="Search by current stage"
                  onFocus={() => setOpenCurrentStageAutoComplete(true)}
                  // onChange={(e) => setDebouncedCompanyValue(e.target.value)}
                />
              )}
              onChange={(event, stage) => addCurrentStage(stage.label)}
              ListboxProps={{ style: { maxHeight: "10rem" } }}
            />
          </Typography>
          <Typography className="">
            {currentStagesArray.map((stage, index) => {
              return (
                <span
                  key={index}
                  className="companiesFilter align-center d-flex"
                >
                  {stage}
                  <img
                    className="small-text-font ml-5 cursor-hover"
                    sx={{ width: "9px" }}
                    src={closeIcon}
                    onClick={() => deleteCurrentStage(index)}
                    onKeyPress={() => deleteCurrentStage(index)}
                    tabIndex={0}
                    alt="close icon"
                  />
                </span>
              );
            })}
          </Typography>
        </Grid>
        </>}
        {/* <Grid item className="mt-35 justify-center d-flex" >
                    <Box
                          onClick={() => resetAll()}
                          className="f-14 font-weight-500 link-color cursor-hover text-decoration-none ml-10 m-r-10 d-flex align-center"
                        >
                          Reset All
                        </Box>
                        {value === "1" && <Button className="btn secondary-button mr-20" onClick={handleSaveSearch}>Save Search</Button>}
                        <Button className="btn primary-button" onClick={() => handleApply()}>Apply</Button>
                    </Grid> */}
      </>
    );
  };
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (openAdvanceFilter) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
  }, [openAdvanceFilter]);
  return (
    <>
      {/* <Modal
        open={openAdvanceFilter}
        onClose={() => modelClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      <Drawer
        disableEnforceFocus
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={openAdvanceFilter}
        onClose={() => modelClose()}
        className="jobDetail-drawer "
      >
        <Grid className="p-30 ipad-view-align drawer-align">
          <Grid className="d-flex align-center mb-20">
            <Typography
              onClick={() => modelClose()}
              onKeyPress={() => modelClose()}
              tabIndex={0}
              className="d-flex align-center cursor-hover"
              id="drawer-close-icon"
            >
              <svg
                width="18px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#808191"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </Typography>
            <Typography className="f-20 font-weight-600 heading-text-color ml-15">
              Filters
            </Typography>
          </Grid>
          <Grid item onScroll={onScroll}>
            {getFilterTab()}
            {/* <Typography>
              <TabContext value={value}>
                <Box className="advanced-search mt-10">
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Filters" value="1" />
                    <Tab label="Saved Search" value="2" />
                  </TabList>
                </Box>
                <Grid item id="modal-description">
                  <TabPanel value="1" className="p-0">
                    {getFilterTab()}
                  </TabPanel>
                  <TabPanel value="2" className="p-0">{getFilterTab()}</TabPanel>
                </Grid>
              </TabContext>
            </Typography> */}
          </Grid>
          <Grid
            lg={12}
            md={12}
            xs={12}
            item
            className="justify-end d-flex mt-30 pb-30"
            id="modal-footer"
          >
            <Box
              onClick={() => {
                resetAll();
                populateExistingFilter({});
                setJobTitlesArray([]);
                setSkillsArray([]);
                setCompaniesArray([]);
                setTalentPoolLevel("A");
                setAvailableList([]);
                setLocationName({});
                resetWorkType();
                resetWorkPlace();
                setWorkPlace(null);
                setReferredBy(false);
                setSelectedReferredByName("");
                setSelectedReferredById("");
                setRadiusSearch(0);
                setLatLng([]);
                setNewCandidates(false);
                setIsCheckedReferredByLabel(false);
                setCurrentStagesArray([]);
                localStorage.removeItem("searchId");
              }}
              className="f-14 font-weight-500 link-color cursor-hover text-decoration-none mr-20 d-flex align-center"
              tabIndex={0}
              onKeyPress={() => {
                resetAll();
                populateExistingFilter({});
                setJobTitlesArray([]);
                setSkillsArray([]);
                setLocationName({});
                resetWorkType();
                resetWorkPlace();
                setWorkPlace(null);
                setReferredBy(false);
                setSelectedReferredByName("");
                setSelectedReferredById("");
                setRadiusSearch(0);
                setLatLng([]);
                setNewCandidates(false);
                setIsCheckedReferredByLabel(false);
                setCurrentStagesArray([]);
                localStorage.removeItem("searchId");
              }}
            >
              Reset All
            </Box>
            {/* {value === "1" && <Button className="btn secondary-button mr-20" onClick={handleSaveSearch}>Save Search</Button>} */}
            {!isFilterByMatchingCandidate && isAuthenticated && (
              <Button
                className="btn secondary-button mr-20"
                onClick={() => {
                  handleOpenAdvanceSearch();
                  handleCloseAdvanceFilter();
                  setSearchName(
                    searchName
                      ? searchName
                      : searchList && searchList?.length
                      ? searchList.find(
                          (l) =>
                            l.id ===
                              JSON.parse(localStorage.getItem("searchId")) &&
                            l.searchName
                        )?.searchName
                      : ""
                  );
                }}
                disableRipple="true"
              >
                Save Search
              </Button>
            )}
            <Button
              className="btn primary-button"
              onClick={() => handleApply()}
              disableRipple="true"
            >
              Apply
            </Button>
          </Grid>
        </Grid>
        {/* </Modal> */}
      </Drawer>

      <Modal
        open={openAdvanceSearch}
        onClose={() => {
          handleCloseAdvanceSearch();
          setSearchName("");
          setError("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          item
          className="modalStyle p-20 inputcontrasts"
          style={{ width: "480px" }}
        >
          <Grid container item spacing={1} lg={12} md={12} xs={12}>
            <Grid item lg={6} md={6} xs={6} align="left">
              <Typography
                className="f-20 font-weight-600 heading-text-color modalTitle"
                variant="h5"
              >
                Name Your Search
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              align="right"
              className="cursor-hover gray7"
            >
              <Typography className="icon-button">
                <CloseIcon
                  onClick={() => {
                    handleCloseAdvanceSearch();
                    setSearchName("");
                    setError("");
                    handleCloseAdvanceFilter();
                  }}
                  aria-label="Close icon"
                  title="Close"
                  tabIndex={0}
                  onKeyPress={() => {
                    handleCloseAdvanceSearch();
                    setSearchName("");
                    setError("");
                    handleCloseAdvanceFilter();
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mt-15">
            <Typography>
              <Input
                value={searchName}
                onKeyPress={handleSpaces}
                type="text"
                name="name"
                id="name"
                className="form-control input-group mt-10 w-100 inputcontrasts"
                margin="normal"
                placeholder="Enter name here"
                style={{
                  border: `${
                    error === "Exceeded maximum character length of 256"
                      ? "1px solid #c92938"
                      : ""
                  }`,
                }}
                onChange={(e) => {
                  setSearchName(e.target.value);
                  e.target.value.length > 256
                    ? setError("Exceeded maximum character length of 256")
                    : setError("");
                }}
              />
              {error && (
                <Typography
                  className="danger-color error-msg"
                  style={{ float: "left" }}
                >
                  {error}
                </Typography>
              )}
            </Typography>
            <Typography className="mt-35 justify-center d-flex align-center">
              <Box
                className="link-color mr-20"
                onClick={() => {
                  handleCloseAdvanceSearch();
                  handleOpenAdvanceFilter();
                  setSearchName("");
                  setError("");
                }}
                onKeyPress={() => {
                  handleCloseAdvanceSearch();
                  handleOpenAdvanceFilter();
                  setSearchName("");
                  setError("");
                }}
                tabIndex={0}
              >
                Back
              </Box>
              <Button
                className="btn primary-button"
                disabled={
                  error === "Exceeded maximum character length of 256" ||
                  !searchName
                }
                onClick={() => {
                  handleSaveSearch();
                  handleCloseAdvanceFilter();
                }}
                disableRipple="true"
              >
                Save & Search
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
});
export default AdvanceFilter;
