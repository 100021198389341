import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import HeaderNew from "../Views/EnterprisePortal/HeaderNew";
import Dashboard from "../assets/images/Dashboard.svg";
import Jobs from "../assets/images/Jobs.svg";
import ClientPortal from "../assets/images/client-portal.svg";
import EmailIcon from "../assets/images/emai-icon.svg";
import MyJobs from "../assets/images/Saved-Jobs.svg";
import { Helmet } from "react-helmet";
import SideBarNew from "../Views/SideMenu/SideBarNew";
import { useState } from "react";
import { useSelector } from "react-redux";
import Constants from "../helpers/constants";

const EnterprisePortalLayout = (props) => {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  const location = useLocation();
  const URLPath = location.pathname;
  const goToClient = (e) => {
    e.preventDefault();
    navigate("/candidatePortal");
  };
  const goToCampaign = (e) => {
    e.preventDefault();
    navigate("/enterprise/campaignList");
  };
  const [openSideBar, setOpenSideBar] = useState(window.location.pathname?.includes("/enterprise/edit-candidate-details/") ? false : true);
  const [open, setOpen] = React.useState(
    window.location.pathname?.includes("/enterprise/edit-candidate-details/") ?
    false :
    localStorage.getItem("sidebarOpened")
      ? localStorage.getItem("sidebarOpened") === "true"
        ? true
        : false
      : true
  );

  const handleOpenSideBar = () => {
    localStorage.setItem("sidebarOpened", true);
    setOpenSideBar(false);
    setOpen(false);
  };
  const handleCloseSideBar = () => {
    localStorage.setItem("sidebarOpened", true);
    setOpenSideBar(true);
    setOpen(true);
  };
  return (
    <>
      <Helmet>
        {appOrigin === "N" ?
        <title>Your one stop shop for your hiring needs | {Constants.APP_NAME_NEURODIVERSIFY}</title>
          : appOrigin === "H" ? <title>Your one stop shop for your hiring needs | {Constants.APP_NAME_DIRECT_HEALTH}</title> :
          <title>Your one stop shop for your hiring needs {Constants.APP_NAME_GENERAL}</title>
        }
        {/* <title>hireHQ | Your one stop shop for your hiring needs</title> */}
        <meta
          property="og:description"
          content={`${Constants.APP_NAME_GENERAL} | Your one stop shop for your hiring needs`}
        />
        <link
          property="og:url"
          content={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Grid item lg={12} md={12} xs={12} className="">
        <Grid item lg={12} md={12} xs={12}>
          <HeaderNew
            profileImage={props.profileImage}
            handleOpenSideBar={handleOpenSideBar}
            handleCloseSideBar={handleCloseSideBar}
            openSideBar={openSideBar}
          />
          <SideBarNew open={open} openSideBar={openSideBar} />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className="p-t-b-10 matchingJobSpace container-scroll"
          // style={{ paddingTop: "85px" }}
        >
          {/* every child component pass from there */}
          {/* {URLPath !== '/enterprise/access' &&  */}
          {/* <Grid item lg={12} md={12} xs={12} className="bg-white dashboard-list-tab"> */}
          {/* <Container> */}
          {/* <Grid item container spacing={1} className="align-center mt-5 jobs-portal"> */}
          {/* <Grid item lg={12} md={12} xs={12}> */}
          {/* <Grid item lg={1} md={1} xs={2} className="ml-0 pl-0">
                                        <Typography>
                                            <span className="align-item-center small-text-font link-color navbarMargin mr-30" onClick={()=>navigate('/enterprise/jobList')}><img src={Jobs} alt="img" className="mr-10 portalIcon" /> Jobs</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={3} xs={5} className="justify-left d-flex">
                                        <Typography>
                                            <span className="align-item-center small-text-font gray7 navbarMargin mr-30 font-weight-500 cursor-hover" onClick={goToClient}><img src={ClientPortal} alt="img" className="mr-10 portalIcon" />Candidate portal</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item> */}
          {/* <Typography>
                                            <span className="align-item-center small-text-font gray7 navbarMargin mr-30 cursor-hover"><img src={Dashboard} alt="img" className="mr-10 portalIcon" /> Dashboard</span>
                                        </Typography>*/}

          {/* <Typography>
                                            <span className="align-item-center small-text-font gray7 cursor-hover"><img src={MyJobs} alt="img" className="mr-10 portalIcon" /> My Saved Candidates</span>
                                        </Typography>*/}
          {/* </Grid> */}
          {/* </Grid> */}
          {/* </Grid>
                        </Container> */}
          {/* </Grid> */}
          {/* }  */}
          {/* <Outlet /> */}
        </Grid>

        {/* <Grid item lg={12}>
                    <Footer />
                </Grid> */}
      </Grid>
    </>
  );
};

export default EnterprisePortalLayout;
