import React, { useEffect } from "react";
import "./App.scss";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import EnterprisePortal from "./Views/EnterprisePortal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";
import Loader from "./Views/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import ErrorBoundary from "../src/ErrorBoundary.jsx";
import DefaultLayout from "./layout/DefaultLayout";
import CandidateList from "./Views/EnterprisePortal/Project/CandidateList";
import EnterpriseCandidateDescription from "./Views/EnterprisePortal/Project/EnterpriseCandidateDescription";
import { useDispatch, useSelector } from "react-redux";
import { getAppOriginRecode } from "./redux/appOriginReducer";
import KeycloakService from "./keycloak";
import Accessibility from "./Views/Accessibility.js";
import { success, danger } from "./helpers/message-box";
import ApiCalls from "./api/customAxios";
import { saveCookie } from "./helpers/helper-data.js";
const QRCode = React.lazy(() => import("../src/Views/PublicPortal/QRCode"));
const NotFound = React.lazy(() => import("../src/PageNotFound.jsx"));
import CookieConsent from "./cookie.js";
import { addDataToKeycloakRedirectURI } from "./helpers/helper.js";

const PublicPortal = React.lazy(() => import("./Views/PublicPortal/index"));
document.addEventListener(
  "mousedown",
  function (event) {
    if (event.detail > 1) {
      event.preventDefault();
    }
  },
  false
);
document.addEventListener('mousedown', function (e) {
  toast?.dismiss();
});
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appOrigin = useSelector((state) => state.location.origin);
  const queryParams = new URLSearchParams(window.location.search);
  const cssVariables = {
    "--primary-color": "#150349",
    "--secondary-color": "#EEEDFE",
    "--hover-button-color": "#FF9F7C",
    "--hover-button-text-color": "#150349",
    "--heading-text-color": "#101828",
    "--main-text-color": "#150349",
    "--list-hover-color": "#EEEDFE",
    "--font-family": "DM Sans', sans-serif",
    "--font-size-10": "12px",
    "--font-size-12": "14px",
    "--font-size-14": "16px",
    "--font-size-16": "18px",
    "--font-size-18": "20px",
    "--font-size-20": "24px",
    "--font-size-24": "26px",
    "--font-size-28": "30px",
    "--font-size-36": "38px",
    "--gray6": "#344054",
    "--gray7": "#344054",
    "--gray8": "#1D2939",
    "--gray9": "#1D2939",
    "--gray10": "#1D2939",
    "--gray11": "#1D2939",
    "--toast-image-height": "20px",
    "--dashboard-color": "var(--primary-color)"
  };
  function isBase64Encoded(str) {
    try {
      atob(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  React.useEffect(() => {
    //saving cookie into custom url when we switch to another enterprise (multi entity change)
    if (queryParams.get("enterpriseId")) {
      if (isBase64Encoded(queryParams.get("enterpriseId"))) {
        let id = atob(queryParams.get("enterpriseId"));
        localStorage.setItem("enterpriseId", id);
        saveCookie("enterprise_id", id, 365);
      }
    };
  }, [queryParams.get("enterpriseId")]);
  React.useEffect(() => {
    if (localStorage.getItem("token") == null) {
      let url = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "enterprises/email/verification/status";
      if (queryParams.get("isEmailIdentityVerified") == "true") {
        success("Your communication email address is verified.");
        ApiCalls.put(url).then(() => {});
      } else if (queryParams.get("isEmailIdentityVerified") == "false") {
        danger("Failed to verify provided communication email address.");
      };
    }
  }, []);
  React.useEffect(() => {
    //removing kc_logout_reason from url once user authenticated
    if (queryParams.get('kc_logout_reason') != null && KeycloakService.isLoggedIn()) {
      const baseUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, baseUrl);
    }
  }, [queryParams.get('kc_logout_reason')]);
  React.useEffect(async () => {
    AOS.init({
      duration: 2000,
    });
    if ("healthcare" === process.env.REACT_APP_ORIGIN) {
      dispatch(getAppOriginRecode("H"));
      document.documentElement.style.setProperty(
        "--primary-color",
        "#45AC8B",
        "important"
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#F0FFFA",
        "important"
      );
      document.documentElement.style.setProperty(
        "--dashboard-color",
        "linear-gradient(92.14deg, var(--primary-color) 44.94%, var(--secondary-color) 157.15%)"
      );
    } else if ("neurodiversify" === process.env.REACT_APP_ORIGIN) {
      dispatch(getAppOriginRecode("N"));
      Object.keys(cssVariables).forEach(function(key, index) {
        document.documentElement.style.setProperty(key, cssVariables[key], "important");
      });
    } else {
      dispatch(getAppOriginRecode("D"));
      document.documentElement.style.setProperty(
        "--dashboard-color",
        "linear-gradient(92.14deg, var(--primary-color) 44.94%, var(--secondary-color) 157.15%)"
      );
    }
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/enterprise/' || location.pathname === '/enterprise' ) {
      navigate('/enterprise/jobList');
    }
  }, [location, navigate]);
  useEffect(() => {
    const path = window.location.pathname;
    if (!["/termsofuse", "/privacypolicy", "/ukprivacypolicy", "/cookiepolicy"].includes(path)) {
        localStorage.removeItem("beanbag");
    }
  }, [window.location.href]);
  return (
    <main>
      {"neurodiversify" === process.env.REACT_APP_ORIGIN && <Accessibility />}
      <React.Suspense
        fallback={
          <>
            <Loader />
          </>
        }
      >
      <CookieConsent/>
        <ErrorBoundary>
          <Routes>
            <Route
              path="/*"
              element={
                <AuthGuardEnterpriseRedirect redirectTo={window.location.pathname.includes("/domain-approval") ? "/enterprise/domain-approval"+window.location.search : queryParams?.get("isEmailIdentityVerified") == "true" ? "/enterprise/jobList?isEmailIdentityVerified=true" : "/enterprise/jobList"}>
                  <PublicPortal />
                </AuthGuardEnterpriseRedirect>
              }
            />
            <Route
              path="enterprise/*"
              element={
                <RequireAuth redirectTo="/enterprise/jobList">
                  <EnterprisePortal />
                </RequireAuth>
              }
            />
             <Route
                path="/qr"
                element={
                  <React.Suspense fallback="">
                    <QRCode />
                  </React.Suspense>
                }
              />
              <Route path="*" element={<NotFound  isAuthenticated = {false}/>} />
            {/* <Route
          path="CandidateList"
          element={
            <React.Suspense fallback="">
              <DefaultLayout doNotDisplay={true}/>
              <br />
              <CandidateList />
            </React.Suspense>
          }
        />
        <Route
          path="candidate-detail/:candidate_id"
          element={
            <React.Suspense fallback="">
              <DefaultLayout doNotDisplay={true}/>
              <br />
              <EnterpriseCandidateDescription />
            </React.Suspense>
          }
        /> */}
          </Routes>
        </ErrorBoundary>
        <ToastContainer
          hideProgressBar
          position="top-right"
          autoClose={15000}
        />
      </React.Suspense>
    </main>
  );
}

export default App;
function RequireAuth({ children, redirectTo }) {
  const location = useLocation();
  const path = location.pathname;
  const isAuthenticated = localStorage.getItem("childEnterpriseId");

  if (!KeycloakService.isLoggedIn()) {
    KeycloakService.doLogin({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.href}`) });
    return (<div></div>);
  }

  if (isAuthenticated && path.includes("/access")) {
    return <Navigate to={redirectTo} />;
  } else {
    return children;
  }
}

function AuthGuardEnterpriseRedirect({ children, redirectTo }) {
  let isAuthenticated = KeycloakService.isLoggedIn();
  const { search, pathname: path } = useLocation();
  const searchParams = new URLSearchParams(search);
  if (isAuthenticated && searchParams.get("msp_token")) {
    localStorage.setItem("msp-token", searchParams.get("msp_token"));
  }

  let emailQP = searchParams.get('email');

  if (path.includes('/signin')) {
    var paramsKey = new URLSearchParams(window.location.search);
    KeycloakService.doLogin({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/enterprise/jobList${paramsKey.get('kc_logout_reason') ? "?kc_logout_reason=" + paramsKey.get('kc_logout_reason') : ""}`), loginHint: emailQP });
    return (<div></div>);
  }

  if (path.includes('/signup')) {
    KeycloakService.doRegisteration({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/enterprise/jobList`), loginHint: emailQP });
    return (<div></div>);
  }

  return isAuthenticated ? (
    window.location.pathname == "/privacypolicy" ||
    window.location.pathname == "/termsofuse" ||
    window.location.pathname == "/cookiepolicy" || window.location.pathname == "/ukprivacypolicy" ? (
      children
    ) : (
      <Navigate to={redirectTo} />
    )
  ) : (
    children
  );
}
