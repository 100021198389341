import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  Stack,
  Typography,
  MenuItem,
  MenuList
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ControlCheckboxGroup from "../../../Components/Checkbox/ControlCheckboxGroup";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import TalentPoolManagementServiceClass from "../../../Services/Enterprise/TalentPoolManagement.service";
import { ReactComponent as ActionIcon } from "../../../assets/images/action-cta-icon.svg";
import { danger, success } from "../../../helpers/message-box";

export default function EditTalentActionButton(props) {
  const [open, setOpen] = useState(false);
  const params = useParams();
  let navigate = useNavigate();
  const [openRemovePopUp, setOpenRemovePopUp] = useState(false);
  const [candidate, setCandidate] = useState();
  const anchorRef = React.useRef(null);
  const [fromDropdown, setFromDropdown] = useState(false);

  const handleOpenRemove = (data) => {
    setOpen(false);
    setCandidate(data);
    setOpenRemovePopUp(true);
  };

  const removeCandidateHandler = () => {
    setOpenRemovePopUp(false);
    TalentPoolManagementServiceClass.removeCandidateFromTalentPool(params.id, [
      // props.user.candidateId,
      props?.candidateId ? props?.candidateId : props.user.candidateId,
    ]).then(
      (response) => {
        setCandidate("");
        success(`Candidate is removed from the talent pool.`);
        if (!props?.isCandidateDetail) {
          props.setUserList(
            props.userList.filter(
              (_userList) => _userList.candidateId !== props.user.candidateId
            )
          );
          props.setTotalCount(props.totalCount - 1);
          props.setCandidateProfileDrawer(false);
          if (props.setCandidateListId) {
            props.setCandidateListId("");
          }
        } else {
          navigate(props.isTalentDetail);
        }
      });
    //   .catch((error) => {
    //     danger(<div>{error.response.errorDescription}</div>);
    //   }
    // );
  };
  // CTA with shortlist and remove options
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setOpen(false);
    });
  });

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (fromDropdown) {
      setFromDropdown(false);
      return;
    }
    setOpen(false);
  };

  const handleDropdownClick = () => {
    setFromDropdown(true);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Stack direction="row" spacing={2} className="talent-pool-cta">
      <div className="assess-update d-grid editPool-shortlist">
        <Button
          ref={anchorRef}
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          aria-label="Menu icon"
          disableRipple="true"
          className="cursor-hover d-flex justify-end"
        >
          <Typography className="secondary-bg-color border-radius-4 p-7-10 d-flex">
            <ActionIcon width="16px" height="16px" className="svg-icons" />
          </Typography>
        </Button>

        <Popper
          style={{ zIndex: "9" }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          className="shortlist-modal-fix options-align"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={handleClose}
                  tabIndex={0}
                  onKeyPress={handleClose}
                >
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.showEditCandidateDetails && localStorage.getItem("userType") != "S" &&
                    <MenuItem
                      onClick={(e) => {
                        setOpen(false);
                        props.goToEditCandidate(e, props.user);
                      }}
                      onKeyPress={(e) => {
                        setOpen(false);
                        props.goToEditCandidate(e, props.user);
                      }}
                    >
                      <Typography className="gray9 f-12 font-weight-400">
                        <span>Edit Candidate Details</span>
                      </Typography>
                    </MenuItem>}
                    <MenuItem>
                      <Typography className="link-color f-12 font-weight-400 ">
                        <ControlCheckboxGroup
                          title={"Jobs"}
                          buttonTitle={"Shortlist"}
                          submitButtonTitle={"Shortlist"}
                          // candidateId={props.user.candidateId}
                          candidateId={props?.candidateId ? props?.candidateId : props.user.candidateId}
                          className={"shortList"}
                          candidateInfo={props.user}
                          setOpen={setOpen}
                          setIsLoading={props.setIsLoading}
                          handleDropdownClick={handleDropdownClick}
                        />
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleOpenRemove(props.user)}
                      tabIndex={0}
                    >
                      <Grid item onClick={() => handleOpenRemove(props.user)}>
                        <Typography className="cursor-hover">
                          <span className="danger-color f-12 font-weight-400">Remove</span>
                        </Typography>
                      </Grid>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      </Stack>
      <ConfirmDialog
        open={openRemovePopUp}
        setOpen={setOpenRemovePopUp}
        dialogTitle="Remove User"
        description={`Are you sure you want to remove ${candidate?.candidateName ? candidate?.candidateName : `${candidate?.firstName} ${candidate?.lastName && candidate?.lastName}` } from this ${props?.isCandidateDetail? props?.talentPool : props?.talentPool?.name}?`}
        handleConfirmYes={removeCandidateHandler}
      />
    </>
  );
}
