import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Stack,
  Typography,
  Grid,
  Box,
  Link,
  Popover,
  Breadcrumbs,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  TextareaAutosize,
  Radio,
  RadioGroup,
  Input,
  MenuList,
  Modal,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { useLocation, useNavigate } from "react-router-dom";
import { danger, success } from "../../../helpers/message-box";
import CloseIcon from "@mui/icons-material/Close";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import ApiCalls from "../../../api/customAxios";
import Loader from "../../Loader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import InfoIcon from "@mui/icons-material/Info";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import zipIcon from "../../../assets/images/extension-icon/zip.png";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import DeleteSignedDoc from "../../../assets/images/extension-icon/delete-signed-doc.svg";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ControlCheckboxGroup from "../../../Components/Checkbox/ControlCheckboxGroup";
import InfiniteScroll from "react-infinite-scroll-component";
import { functiondisable, functionenable } from "../../../helpers/helper";
import { getFormattedLocation } from "../../../helpers/helper-data";
import noCandidate from "../../../assets/images/no-candidate.png";
import MatchingScoreBreakdownModal from "../Project/MatchingScoreBreakdownModal";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import { ReactComponent as ActionIcon } from "../../../assets/images/action-cta-icon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/images/location-new-icon-gray.svg";
import EnterpriseCandidateDescriptionDrawer from "../../EnterprisePortal/Project/EnterpriseCandidateDescriptionDrawer";
import ShortlistCandidateDrawer from "../../EnterprisePortal/Project/ShortlistCandidateDrawer";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';

export default function CandidateListActionButton(props) {
  const { state } = useLocation();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [openHireAndRehire, setOpenHireAndRehire] = useState(false);
  const handleOpenHireAndRehire = () => setOpenHireAndRehire(true);
  const handleCloseHireAndRehire = () => setOpenHireAndRehire(false);
  const [jobs, setJobs] = useState({ allChecked: false, list: [] });
  const [values, setValues] = useState(moment().add(2, "d").toDate());
  const [documentFile, setdocumentFile] = useState();
  const [fromDropdown, setFromDropdown] = useState(false);
  const [documentIndex, setdocumentIndex] = useState();
  const [openDocumentIndex, setOpenDocumentIndex] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [candidatePopUp, setCandidatePopUp] = useState();
  const [candidateProfileDrawer, setCandidateProfileDrawer] = useState(false);

  const handleOpenOffer = () => {
    handleCloseHireAndRehire();
    setOpenOffer(true);
  };
  const handleCloseOffer = () => {
    setOpenOffer(false);
    setdocumentFile("");
  };
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    document.addEventListener("scroll", () => {
      setOpen(false);
    });
  }, []);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (fromDropdown) {
      setFromDropdown(false);
      return;
    }
    setOpen(false);
  };

  const handleDropdownClick = () => {
    setFromDropdown(true);
  };


  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const hireAndRehire = () => {
    setIsLoading(true);
    setOpen(false);
    const page = 0;
    CandidateService.candidateOfferJobs(props.user.candidateId, page)
      .then((response) => {
        if (response.length > 0) {
          response.forEach((obj) => (obj["isChecked"] = false));
        }
        if (response.length < 10) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        setJobs({ allChecked: false, list: response });
        handleOpenHireAndRehire();
        setIsLoading(false);
      })
      .catch((res) => {
        setIsLoading(false);
      });
  };
  const fetchMoreData = () => {
    let pgNo = pageNumber + 1;
    setPageNumber(pgNo);
    CandidateService.candidateOfferJobs(props.user.candidateId, pgNo)
      .then((response) => {
        if (response.length > 0) {
          response.forEach((obj) => (obj["isChecked"] = false));
        }
        setJobs({ allChecked: false, list: jobs.list.concat(response) });
        handleOpenHireAndRehire();
        setIsLoading(false);
      })
      .catch((res) => {
        setIsLoading(false);
      });
  };
  const addToTalentPool = () => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    bodyElement.style.overflow = "";
    htmlElement.style.overflow = "";
    navigate(
      // `/enterprise/my-candidate/leads-talent-pool-list/${props.user.candidateId}`,
      `/enterprise/my-candidate/leads-talent-pool-list/${props?.candidateId ? props?.candidateId: props?.candidate_id ? props?.candidate_id : props?.user.candidateId}`,
      { state: { name: props.user.candidateName } }
    );
  };

  const handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    setJobs((prevState) => {
      let { list, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        list = list.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list.map((item) =>
          item.id === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = list.every((item) => item.isChecked);
      }
      return { list, allChecked };
    });
  };

  function ValidateSingleInput(event) {
    var validFileExtensions = ["pdf", "doc", "docx", "zip"];
    var file = event.target.files[0];
    if (file) {
      if (file.name.length <= 80) {
        var extension = file.name.split(".").pop();
        if (validFileExtensions.includes(extension)) {
          setdocumentFile({
            filename: file.name,
            extension: extension,
            file: file,
          });
        } else {
          danger("Please upload only DOC, DOCX, PDF and ZIP file formats!");
          event.target.value = "";
        }
      } else {
        danger("Filename is too long!");
        event.target.value = "";
      }
    }
    return true;
  }

  const handleDeleteDocumentWithIndex = () => {
    setOpenDocumentIndex(true);
  };
  const deleteDocumentFromIndex = () => {
    setdocumentFile();
    setdocumentIndex();
    setOpenDocumentIndex(false);
    success("Document is deleted successfully.");
  };

  const createOfferHandler = async () => {
    setIsLoading(true);
    const selectedJobs = jobs.list.filter((o) => o.isChecked);
    const selectedJobsIds = selectedJobs.map((o) => o.id);
    var formData = new FormData();
    formData.append("offer-document", documentFile ? documentFile.file : "");
    formData.append(
      "request-body",
      new Blob(
        [
          JSON.stringify({
            jobIds: selectedJobsIds,
            offerExpiryDate: values,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    await CandidateService.createOfferMultipleJobs(
      props.user.candidateId,
      formData
    )
      .then((response) => {
        success("Candidate is offered.");
        setIsLoading(false);
        setOpenOffer(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };
  const [optionValue, setOptionValue] = useState("");

  const handleOpenNotes = () => {
    setOptionValue("Notes");
    setCandidateProfileDrawer(true);
  };

  const [anchorElement, setAnchorElement] = React.useState(null);
  const open3 = Boolean(anchorElement);
  const id2 = open3 ? "simple-popover-list" : undefined;
  const handleOpenMatchingModal = (event, job) => {
    event.stopPropagation();
    setCandidatePopUp({
      firstName: props.user?.candidateName?.split(" ")[0],
      lastName:
        props.user?.candidateName?.split(" ").length > 1
          ? props.user?.candidateName?.split(" ")[1]
          : "",
      profileTitle: props.user?.profileTitle,
      mediaFileSigned: props.user?.profileImageUrlSigned,
      matchingScore: job.matchingScore,
      matchingPercentage: job.matchingPercentage,
    });
    setAnchorElement(true);
    functiondisable();
  };
  const handleCloseMatchingModal = useCallback((e) => {
    e.stopPropagation();
    setAnchorElement(null);
    functionenable();
  }, []);
  const jobDetailPage = (jobId) => {
    window.open(`/enterprise/jobs/${jobId}`, "_blank");
  };
  const convertAccess = (value) => {
    if (value === "PC") return "P";
    if (["AC", "AE"].includes(value)) return "A";
    return value;
  };
  const getPreferredSendEmailAddress = () => {
    const emailAddressAccess = (convertAccess(props.user.enterpriseEmailAddressForAccess) ||
    props.user.emailAddressForAccess);
    if (!emailAddressAccess || emailAddressAccess === "P") {
      return props.user.emailAddress;
    }
    let alternateEmailAddress = (props.user.enterpriseAlternateEmailAddress != null
      ? props.user.enterpriseAlternateEmailAddress
      : props.user.alternateEmailAddress);
    return alternateEmailAddress;
  };
  return (
    <>
      {isLoading && <Loader />}

      <Stack direction="row" spacing={2}>
        <div className="assess-update d-grid matching-menu jobdetail-menu">
          <Button
            ref={anchorRef}
            // id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            aria-label="Menu icon"
            disableRipple="true"
            // style={{ minWidth: "50px" }}
            className="cursor-hover d-flex justify-end menus"
          >
            <Typography className="secondary-bg-color border-radius-4 p-7-10 d-flex">
              <ActionIcon width="16px" height="16px" className="svg-icons"/>
            </Typography>
          </Button>

          <Popper
            style={{ zIndex: "9" }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            className="shortlist-modal-fix options-align"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={handleClose}
                    tabIndex={0}
                    onKeyPress={handleClose}
                  >
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {/* {props.status === "My Candidates" && props.user.noOfProjectsWorking === 0 &&
                          <MenuItem onClick={hireAndRehire}
                          onKeyPress={hireAndRehire}
                            className="p-0" tabIndex={0}>
                            <Typography className="d-flex gray7 f-12 font-weight-400 p-5 pl-10 align-center">
                              <span>Hire/Rehire</span>
                            </Typography>
                          </MenuItem>} */}
                      {/* {props.status === "All Leads" && ( */}
                        <>
                          {props.showEditCandidateDetails && localStorage.getItem("userType") != "S" &&
                          <MenuItem
                            className="p-0" tabIndex={0}
                            onClick={(e) => {
                              setOpen(false);
                              props.goToEditCandidate(e, props.user);
                            }}
                            onKeyPress={(e) => {
                              setOpen(false);
                              props.goToEditCandidate(e, props.user);
                            }}
                          >
                            <Typography className="gray9 f-12 font-weight-400">
                              <span>Edit Candidate Details</span>
                            </Typography>
                          </MenuItem>}
                          <MenuItem className="p-0" tabIndex={0}>
                            <Typography className="d-flex gray9 f-12 font-weight-400 align-center lead-shortlist-btn">
                              <span className="d-grid lead-shortlist">
                                <ControlCheckboxGroup
                                  title={"Jobs"}
                                  buttonTitle={"Shortlist"}
                                  submitButtonTitle={"Shortlist"}
                                  // options={JobTitle}
                                  // setOptions={setJobTitle}
                                  // candidateId={props.user.candidateId}
                                  candidateId={props?.candidateId ? props?.candidateId : props?.candidate_id ? props?.candidate_id : props.user.candidateId}
                                  className={
                                    "shortList gray9 font-weight-400 f-12 d-block text-left"
                                  }
                                  candidateInfo={props.user}
                                  setOpen={setOpen}
                                  setIsLoading={props.setIsLoading}
                                  candidateProfileDrawer={props.candidateProfileDrawer}
                                  setCandidateProfileDrawer={props.setCandidateProfileDrawer}
                                  setCandidateListId={props.setCandidateListId}
                                  candidate_id={props.candidate_id}
                                  optionValue={optionValue}
                                  fromShortlistDrawer={true}
                                  user={props.user2}
                                  setUserList={props.setUserList}
                                  isFromMyCandidates={true}
                                  viewType={props.viewType}
                                  handleDropdownClick={handleDropdownClick}
                                />
                              </span>
                            </Typography>
                          </MenuItem>
                          {(props.user.enterpriseHidePhoneEmail ?
                          (props.user.enterpriseHidePhoneEmail !== "E" && props.user.enterpriseHidePhoneEmail !== "B")
                          : (props.user.hidePhoneEmail !== "E" && props.user.hidePhoneEmail !== "B")) && (
                            <MenuItem className="p-0" tabIndex={0}>
                              <Typography className="d-flex gray9 f-12 font-weight-400 align-center">
                                <span>
                                  <Link
                                    className="text-decoration-none gray9"
                                    onClick={(e)=>{
                                      e.preventDefault();
                                      window.location.href = `mailto:${getPreferredSendEmailAddress()}`;
                                    }}
                                  >
                                    Send Email
                                  </Link>
                                </span>
                              </Typography>
                            </MenuItem>
                          )}
                        </>
                      {/* )} */}
                      {!props?.isFromMyCandidateNotes &&
                      <MenuItem
                        onClick={handleOpenNotes}
                        onKeyPress={handleOpenNotes}
                        className="p-0"
                        tabIndex={0}
                      >
                        <Typography className="d-flex gray9 f-12 font-weight-400 align-center">
                          <span className="">Add Notes</span>
                        </Typography>
                      </MenuItem>
                      }
                      <MenuItem
                        onClick={addToTalentPool}
                        onKeyPress={addToTalentPool}
                        className="p-0"
                        tabIndex={0}
                      >
                        <Typography className="d-flex gray9 f-12 font-weight-400 align-center">
                          <span className="">Add to Talent Pool</span>
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>

      <Modal
        open={openHireAndRehire}
        onClose={handleCloseHireAndRehire}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content">
          <Grid item className="d-flex justify-between align-center">
            <Typography className="f-20 font-weight-600 heading-text-color p-5">
              Jobs
            </Typography>
            <Typography className="icon-button cursor-hover gray7">
              <CloseIcon
                onClick={handleCloseHireAndRehire}
                aria-label="Close icon"
                tabIndex={0}
              />
            </Typography>
          </Grid>
          <Grid item className="mt-20">
            <Grid item>
              {jobs.list.length > 0 && (
                <Typography className="mt-15 d-flex mb-20">
                  <FormGroup>
                    <FormControlLabel
                      className="f-16 font-weight-400 select-all-pool align-center mt-2"
                      control={
                        <Checkbox
                          name="checkAll"
                          checked={jobs.allChecked}
                          onChange={handleChange}
                          sx={{
                            color: "#98A2B3",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            "&.Mui-checked": {
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--secondary-color"),
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            },
                          }}
                          tabIndex={0}
                          className="mr-0"
                        />
                      }
                      label="Select All"
                    />
                  </FormGroup>
                </Typography>
              )}
              <Grid item>
                <InfiniteScroll
                  dataLength={jobs.list.length}
                  next={() => fetchMoreData()}
                  hasMore={loadMore}
                  loader={<Loader />}
                  inverse={false}
                  height={300}
                  //  scrollableTarget="scrollableDiv"
                  // style={{ height: "910px" }}
                >
                  {jobs.list.length > 0 ? (
                    jobs.list.map((job, index) => {
                      return (
                        <>
                          <Typography className="border-top-1" key={index}>
                            <Typography className="d-flex mt-10 mb-10">
                              <Typography style={{ marginTop: "3px" }}>
                                {/* <Typography> */}
                                <FormGroup>
                                  <FormControlLabel
                                    key={index}
                                    className="f-16 font-weight-400 select-all-pool align-center mt-2"
                                    control={
                                      <Checkbox
                                        key={job.id}
                                        name={job.id}
                                        checked={job.isChecked}
                                        onChange={handleChange}
                                        sx={{
                                          color: "#98A2B3",
                                          paddingTop: "0px",
                                          "&.Mui-checked": {
                                            color: getComputedStyle(
                                              document.documentElement
                                            ).getPropertyValue(
                                              "--secondary-color"
                                            ),
                                            paddingTop: "0px",
                                          },
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </FormGroup>
                              </Typography>
                              <Typography
                                className="d-flex align-center justify-between"
                                style={{ width: "90%" }}
                              >
                                {/* <Typography className="d-flex align-center"> */}
                                <Typography>
                                  <Typography
                                    onClick={() => jobDetailPage(job.id)}
                                    className="main-text-color cursor-hover f-14 font-weight-500"
                                  >
                                    {job?.jobTitle}
                                  </Typography>
                                  {job?.city && job?.country && (
                                    <Box className="f-12 font-weight-400 gray7 align-center d-flex candidate-info-align mt-2">
                                      <LocationIcon
                                        width="15px"
                                        className="mr-5 gray7 f-12 font-weight-400 icon-margin"
                                      />
                                      {job.city?.trim()}
                                      {(job?.city?.trim() && job?.country?.trim()) ? ", " : ""}{job.country}
                                      {/* {getFormattedLocation(
                                        job?.city,
                                        // job?.state,
                                        job?.country
                                      )} */}
                                    </Box>
                                  )}
                                </Typography>
                                <Typography
                                  className="d-flex justify-end cursor-hover"
                                  onClick={(e) => {
                                    handleOpenMatchingModal(e, job);
                                  }}
                                >
                                  <Tooltip
                                    role="tooltip"
                                    title="Matching score"
                                    position="bottom"
                                    trigger="mouseenter"
                                    animation="fade"
                                    arrow
                                  >
                                    <Typography
                                      className="justify-end d-flex sort-filter-center p-5 align-center matching-score-align"
                                      style={{ height: "65px", width: "65px" }}
                                      aria-describedby={id2}
                                    >
                                      <CircularProgressbar
                                        aria-label="Circular Progress"
                                        strokeWidth={12}
                                        value={
                                          job.matchingScore
                                            ? job.matchingScore
                                            : 0
                                        }
                                        // text={`${Math.round(
                                        //   job.matchingScore
                                        //     ? job.matchingScore
                                        //     : 0
                                        // )}%`}
                                        styles={buildStyles({
                                          textColor: "#027A48",
                                          pathColor: "#027A48",
                                          trailColor: "#adc6ce",
                                          textSize: "25px",
                                        })}
                                      />
                                      <span className="d-flex align-center success-color f-14 font-weight-500 ml-5">
                                        {`${Math.round(
                                          job.matchingScore
                                            ? job.matchingScore
                                            : 0
                                        )}%`}
                                      </span>
                                    </Typography>
                                  </Tooltip>
                                </Typography>
                              </Typography>
                            </Typography>
                          </Typography>
                        </>
                      );
                    })
                  ) : (
                    <Grid
                      container
                      item
                      spacing={1}
                      lg={12}
                      md={12}
                      xs={12}
                      className="mt-5"
                    >
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                        className="m-auto text-center"
                      >
                        <img src={noCandidate} alt="img" />
                        <Box className="mt-5 f-16 font-weight-600">
                          No matched job found
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Grid>
          <Grid item id="modal-footer" className="justify-center d-flex mt-20">
            {jobs.list.length > 0 && (
              <Button
                type="submit"
                className="btn primary-button ml-10"
                tabIndex={0}
                aria-label="Accept"
                disableRipple="true"
                onClick={handleOpenOffer}
                onKeyPress={handleOpenOffer}
                disabled={jobs.list.filter((o) => o.isChecked).length == 0}
              >
                Offer
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>

      {/* Offer Reason */}
      <Modal
        open={openOffer}
        onClose={handleCloseOffer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-24 font-weight-600 heading-text-color">
                Offer
              </Typography>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleCloseOffer}
                  onKeyPress={handleCloseOffer}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description">
              <Box>
                <p
                  className="sub-text-font mb-0 font-weight-500 mt-0"
                  align="left"
                >
                  Expiry Date<span className="text-black">*</span>
                </p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className="form-control input-group inputcontrasts"
                    value={values}
                    onChange={(newValue) => {
                      setValues(newValue);
                    }}
                    disablePast
                    format="MMM dd, yyyy"
                    emptyLabel="DD/MM/YYYY"
                    disableFuture={false}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Grid item lg={12} md={12} xs={12} className="mt-20 mb-10">
                <Grid
                  item
                  className="justify-between pb-5 border-bottom-1 d-flex"
                >
                  <span className="f-14  gray9 font-weight-400">
                    Document
                    <Tooltip
                      position="right"
                      title="You can upload pdf, doc, docx or zip file"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <IconButton aria-label="Info icon">
                        <InfoIcon aria-label="Info icon" />
                      </IconButton>
                    </Tooltip>
                  </span>
                  {!documentFile && (
                    <Link
                      className="link-color f-14 text-decoration-none cursor-hover m-r-10"
                      variant="contained"
                      component="label"
                      style={{ marginTop: "6px" }}
                      tabIndex={0}
                    >
                      {" "}
                      <input
                        type="file"
                        onChange={ValidateSingleInput}
                        hidden
                      />
                      Attach File +
                    </Link>
                  )}
                </Grid>
                <Grid item className="justify-between mt-10 d-flex">
                  <Grid item className="d-flex workDocument align-center">
                    {documentFile && documentFile.extension === "pdf" && (
                      <img
                        src={Resumepdf}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="40px"
                        height="40px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && documentFile.extension === "doc" && (
                      <img
                        src={docIcon}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="40px"
                        height="40px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && documentFile.extension === "zip" && (
                      <img
                        src={zipIcon}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="40px"
                        height="40px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && documentFile.extension === "docx" && (
                      <img
                        src={docIcon}
                        alt="img"
                        className="m-r-10 file-extension-image wth-50"
                        width="40px"
                        height="40px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {documentFile && (
                      <p className="textEllipse w-500">
                        {documentFile.filename}
                      </p>
                    )}
                  </Grid>
                  {documentFile && (
                    <Grid
                      item
                      className="d-flex gray7 font-13 align-center d-flex justify-center"
                    >
                      <span
                        onClick={() => handleDeleteDocumentWithIndex()}
                        style={{ cursor: "pointer" }}
                        tabIndex={0}
                        onKeyPress={() => handleDeleteDocumentWithIndex()}
                      >
                        <img
                          className="sub-text-font m-r-10"
                          src={DeleteSignedDoc}
                          alt="delete icon"
                          width="18px"
                          height="18px"
                          tabIndex={0}
                        />
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Typography>
            <Typography className="justify-center d-flex mt-20">
              <Button
                onClick={createOfferHandler}
                className="btn primary-button"
                disableRipple="true"
              >
                Offer
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>

      <ConfirmDialog
        open={openDocumentIndex}
        setOpen={setOpenDocumentIndex}
        dialogTitle="Delete Document "
        description={"Are you sure?"}
        handleConfirmYes={deleteDocumentFromIndex}
      />
      <MatchingScoreBreakdownModal
        open3={open3}
        handleClosePopover={handleCloseMatchingModal}
        candidatePopUp={candidatePopUp}
      />
      {optionValue && (
        <EnterpriseCandidateDescriptionDrawer
          candidateProfileDrawer={candidateProfileDrawer}
          setCandidateProfileDrawer={setCandidateProfileDrawer}
          setCandidateListId={props.setCandidateListId}
          candidate_id={props.user.candidateId}
          optionValue={optionValue}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
}
