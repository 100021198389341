import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  Input,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { handleSpaces } from "../../../helpers/helper-data";
import calenderIcon from "../../../assets/images/assessment-calender.svg";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CandidateServiceClass from "../../../Services/Enterprise/Candidate.service";
import DateFnsUtils from "@date-io/date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import MissingDetails from "./missingDetails";
import { success, danger } from "../../../helpers/message-box";
import { json } from "d3";

function ScreeningAnswerDrawer({
  openAssessment,
  setOpenAssessment,
  setIsDrawerVisible,
  question,
  profileEssentials,
  fieldMapping,
  jobId,
  candidateId,
  setIsLoading,
  openConfirmation,
  profileDetails,
  profileDetailsGroup,
  handleDropdownClick,
  handleClosePopover,
  candidate,
  getCandidatesCall,
  isFromTalentConsole,
  fetchData,
}) {
  const [QuestionArray, setQuestionArray] = useState(question);
  const [openDatePicker, setOpenDatePicker] = useState({});
  const [hideDateClearIcon, setHideDateClearIcon] = useState({});
  const [profileEssentialsValidations, setProfileEssentialsValidations] = useState(false);
  const [profileEssentialsApiCall, setProfileEssentialsApiCall] = useState(false);
  const [resumeApiCall, setResumeApiCall] = useState(false);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const updatedQuestions = addAnswersToQuestions(question, candidate);
    question.length === 0 && setIsValid(true);
    setQuestionArray(updatedQuestions);
  }, [question]);

  function handleChangeCheckbox(event, question, option, index) {
    var prevAnswers = QuestionArray.find(
      (_question, indexKey) => indexKey === index
    );
    let answersArray = [];
    if (event.target.checked === false) {
      if (prevAnswers?.Answer) {
        answersArray = prevAnswers?.Answer;
        answersArray.splice(answersArray.indexOf(option), 1);
      }
    }
    if (event.target.checked === true) {
      if (prevAnswers?.Answer) {
        answersArray = prevAnswers?.Answer;
        answersArray.push(option);
      } else {
        answersArray.push(option);
      }
    }
    setQuestionArray(
      QuestionArray.map((_question, indexKey) => {
        return indexKey === index
          ? _question?.key
            ? {
                ..._question,
                Question: _question.Question,
                Answer: answersArray,
                required: _question.required,
                key: _question?.key ? _question?.key : null,
              }
            : {
                ..._question,
                Question: _question.Question,
                Answer: answersArray,
                required: _question.required,
              }
          : _question;
      })
    );
    if (question?.triggerQuestion && question?.triggerQuestion !== undefined) {
      if (question?.triggerQuestion[option]) {
        if (event.target.checked) {
          setQuestionArray((prevQuestions) => {
            const nextQuestions = [...prevQuestions];
            nextQuestions.splice(index + 1, 0, {
              ...question.triggerQuestion[option],
              Answer: "",
              referredQuestionKey: question.key,
              questionValue: option, // Add the new field referredQuestionKey
            });
            return nextQuestions;
          });
        } else {
          setQuestionArray((prevQuestions) =>
            prevQuestions.filter(
              (questions) =>
                !(
                  questions?.referredQuestionKey === question.key &&
                  questions?.questionValue === option
                )
            )
          );
        }
      } else {
      }
    }
  }
  useEffect(() => {
    profileEssentials?.length == 0 && setProfileEssentialsValidations(true);
    const validationResult = validateQuestions(QuestionArray);
    // alert(validationResult);
    setIsValid(validationResult);
  }, [QuestionArray]);
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (openAssessment) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
  }, [openAssessment]);
  const handleDropDownSelect = (value, question, index) => {
    setQuestionArray((prevState) =>
      prevState?.map((item, i) =>
        i === index
          ? {
              ...item,
              Answer: value === "default" ? "" : value,
            }
          : item
      )
    );
    if (question?.triggerQuestion && question.triggerQuestion[value]) {
      if (
        (QuestionArray[index + 1]?.questionValue &&
          QuestionArray[index + 1]?.questionValue !== value) ||
        !QuestionArray[index + 1]?.questionValue
      ) {
        if (QuestionArray[index + 1]?.questionValue) {
          setQuestionArray((prevQuestions) => {
            const nextQuestions = [...prevQuestions];
            nextQuestions.splice(index + 1, 1, {
              ...question.triggerQuestion[value],
              Answer: "",
              referredQuestionKey: question.key,
              questionValue: value,
            });
            return nextQuestions;
          });
        } else {
          // Add the next question
          setQuestionArray((prevQuestions) => {
            const nextQuestions = [...prevQuestions];
            nextQuestions.splice(index + 1, 0, {
              ...question.triggerQuestion[value],
              Answer: "",
              referredQuestionKey: question.key,
              questionValue: value,
            });
            return nextQuestions;
          });
        }
      }
    } else {
      // Remove the next question if it exists and is associated with this question
      if (QuestionArray[index + 1]?.referredQuestionKey === question.key) {
        setQuestionArray((prevQuestions) => {
          return prevQuestions.filter((_, ind) => ind !== index + 1);
        });
      }
    }
  };

  function addAnswersToQuestions(questions, candidate) {
    const educationMapping = {
        "P": "DOCTORATE",
        "M": "MASTERS",
        "B": "BACHELORS",
        "A": "ASSOCIATE",
        "D": "DIPLOMA",
        "S": "SCHOOL"
    };
    // Parse the defaultScreeningResponsesForEnterprise (assuming it's a JSON string)
    const defaultScreeningResponses = candidate.defaultScreeningResponsesForEnterprise
      ? JSON.parse(candidate.defaultScreeningResponsesForEnterprise)
      : [];
    if (questions && questions.length > 0 && candidate && Object.keys(candidate).length > 0) {
        return questions.map((question) => {
            let candidateValue = undefined;
            // Handle the "auto" collection questions (existing logic)
            if (question.collection === "auto") {
              if (question.fieldName === "levelOfEducation" && Array.isArray(candidate.education)) {
                if (candidate.education.length > 0) {
                    candidateValue = candidate.education[0].levelOfEducation;
                    if (educationMapping[candidateValue]) {
                        candidateValue = educationMapping[candidateValue];
                    }
                  } else {
                    candidateValue = "-";
                }
                } else if (candidate.hasOwnProperty(question.fieldName)) {
                    candidateValue = candidate[question.fieldName];
                }
                if (candidateValue !== null && candidateValue !== "") {
                    if (question.fieldName === "dateOfBirth") {
                        const date = new Date(candidateValue);
                        const options = { year: 'numeric', month: 'short', day: 'numeric' };
                        candidateValue = date.toLocaleDateString('en-US', options);
                    }
                } else {
                    candidateValue = "-"; // Only apply "-" for auto questions with no valid answer
                }
                question.Answer = candidateValue;
            }
            // Handle the DEFAULT# questions (new logic)
            if (question.key && question.key.startsWith("DEFAULT#") && question.collection !== "auto") {
                // Find matching response in the defaultScreeningResponses
                const defaultAnswer = defaultScreeningResponses.find((res) => res.key === question.key && res.Question === question.Question);
                // If there's a valid answer, update the question's Answer
                if (defaultAnswer && defaultAnswer.Answer && defaultAnswer.Answer !== "" && defaultAnswer.Answer !== null && defaultAnswer.Answer !== undefined) {
                    question.Answer = defaultAnswer.Answer;
                }
                // If no valid answer, do nothing and leave the Answer as is (do not set to "-")
            }
              // Convert answer to array for checkbox or checkBox type questions
              if (question.Type && (question.Type.toLowerCase() === "checkbox")) {
                // If Answer is a string, convert it to an array
                if (typeof question.Answer === "string") {
                    question.Answer = question.Answer.split(", ");
                }
                // If Answer is already an array, no change is needed
            }
            return question;
        });
    }
    return questions;
  }
  function handleChange(event, question, index) {
    const selectedValue = event.target.value;
    // Update the answer for the current question
    setQuestionArray((prevQuestions) => {
      const nextQuestions = [...prevQuestions];
      // Update the answer for the current question
      nextQuestions[index] = {
        ...nextQuestions[index],
        Answer:
          question.Question ===
            "For how many hours are you available per day?" && selectedValue < 0
            ? ""
            : selectedValue,
      };
      // Check if we need to add or remove follow-up questions
      if (question.Type === "radio") {
        const triggerQuestion = question.triggerQuestion?.[selectedValue];
        // Remove existing follow-up question if it exists
        const followUpIndex = nextQuestions.findIndex(
          (q) => q.referredQuestionKey === question.key
        );
        if (followUpIndex !== -1) {
          nextQuestions.splice(followUpIndex, 1);
        }
        // If there's a valid trigger question, add it
        if (triggerQuestion) {
          const newFollowUpQuestion = {
            ...triggerQuestion,
            Answer: "",
            referredQuestionKey: question.key,
            questionValue: selectedValue,
          };
          // Check if the follow-up question already exists
          const isFollowUpPresent = nextQuestions.some(
            (q) => q.referredQuestionKey === question.key
          );
          // Add the new follow-up question if it's not already present
          if (!isFollowUpPresent) {
            nextQuestions.splice(index + 1, 0, newFollowUpQuestion);
          }
        }
      }
      return nextQuestions;
    });
  }
  const handleHideClearIcon = (question, hide) => {
    setHideDateClearIcon((prev) => ({
      ...prev,
      [question.index]: hide, // Use index for state management
    }));
  };
  const handleChangeDate = (date, index) => {
    setQuestionArray(
      QuestionArray.map((item, idx) =>
        idx === index ? { ...item, Answer: date } : item
      )
    );
  };
  const handleShortlist = async() => {
    setIsLoading(true);
    const updatedDataArray = QuestionArray.map((item) => {
      const newItem = { ...item };
      if (newItem.Key !== undefined) {
        newItem.key = newItem.Key;
        delete newItem.Key;
      }
      // Check if the item is a checkbox type and has an Answer array
      if ((newItem.Type === "checkBox" || newItem.Type === "checkbox") && Array.isArray(newItem.Answer)) {
        newItem.Answer = newItem.Answer.join(", "); // Convert the Answer array to a string
      }
      if (newItem.triggerQuestion) {
        Object.keys(newItem.triggerQuestion).forEach((triggerKey) => {
          const triggerQuestionItem = newItem.triggerQuestion[triggerKey];
          if (triggerQuestionItem.Key !== undefined) {
            triggerQuestionItem.key = triggerQuestionItem.Key;
            delete triggerQuestionItem.Key;
          }
        });
      }
      return newItem;
    });
    let answers = JSON.stringify(updatedDataArray);
    let payload = {
      jobId: jobId,
      candidateId: candidateId,
      shortlistingRemarks: null,
      answers: answers,
    };
    await CandidateServiceClass.shortList(payload).then((response) => {
      if (response) {
        setIsLoading(false);
        fetchData();
        if (isFromTalentConsole) {
          getCandidatesCall();
        }
        setResumeApiCall(true);
        success("Candidate shortlisted successfully.");
        setProfileEssentialsApiCall(true);
        setOpenAssessment(false);
        handleClosePopover();
     }
    }).catch((error) => {
        setIsLoading(false);
        if (error) {
          if (error.statusCode == 500 && (error.errorMessage != "Number of shortlisted candidates exceeded the limit")) {
            danger("Oops! Something went wrong. Please try again later.");
          } else {
            danger(error.errorMessage);
          }
        }
    });
  };

  const questionTypeToReturn = (type, data, index) => {
    if (type === "checkbox" || type === "checkBox") {
      return (
        <Typography className="mt-10 mb-10 assessment-calender">
          <p className="f-16 gray9 font-weight-500 mb-10 h-auto mt-20 d-flex">
            <span className="gray7">{`Q${index + 1}. `}</span>
            <span className="word-break pl-5">
              {data?.Question}{" "}
              {(data?.required === "true" || data?.required === true) && "*"}
            </span>
          </p>
          <Box
            className={`m-t-5 file-upload question-asses screening-radio
                        }`}
          >
            {data?.Options.map((option, ind) => (
              <FormControlLabel
                key={ind}
                value={option}
                onChange={(event) =>
                  handleChangeCheckbox(event, data, option, index)
                }
                control={
                  <Checkbox
                    sx={{
                      color: "#98A2B3",
                      "&.Mui-checked": {
                        color: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--primary-color"),
                        "&.hoveer": {
                          background: "none",
                        },
                      },
                    }}
                    checked={data.Answer?.includes(option) ? true : false}
                    // checked={data?.Answer?.length > 0 && data?.Answer?.split(",")?.includes(option)}
                  />
                }
                className="sub-text-font file-upload pl-2  assessmentDetails screeningPreview checkbox-view"
                label={option}
              />
            ))}
          </Box>
        </Typography>
      );
    } else if (type === "radio") {
      return (
        <Typography
          // id={question.key}
          className="assessment-calender mt-10 mb-10"
        >
          <p className="f-16 gray9 font-weight-500 mb-10 h-auto mt-20 d-flex">
            <span className="gray7">{`Q${index + 1}. `}</span>
            <span className="word-break pl-5">
              {data?.Question}{" "}
              {(data?.required === "true" || data?.required === true) && "*"}
            </span>
          </p>
          <Box className="screening-radio">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="file-upload d-block"
              onChange={(event) => handleChange(event, data, index)}
              value={data.Answer || ""}
            >
              {data?.Options.map((option, index) => (
                <FormControlLabel
                  className="file-upload assessment-details  availability-assess d-block screeningPreview pl-2 radio-view"
                  key={index}
                  value={option}
                  control={
                    <Radio
                      sx={{
                        color: "#667085",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                          "&.hoveer": {
                            background: "none",
                          },
                        },
                      }}
                    />
                  }
                  label={option}
                />
              ))}
            </RadioGroup>
          </Box>
        </Typography>
      );
    } else if (type === "dropDown") {
      return (
        <Typography
          id={question.key}
          className="mt-10 mb-10 assessment-calender"
        >
          {" "}
          <p className="f-16 gray9 font-weight-500 mb-10 h-auto mt-20 d-flex">
            <span className="gray7">{`Q${index + 1}. `}</span>
            <span className="word-break pl-5">
              {data?.Question}{" "}
              {(data?.required === "true" || data?.required === true) && "*"}
            </span>
          </p>
          <Select
            // inputProps={{ "aria-label": "With label" }}
            onMouseDown={handleDropdownClick}
            className={`m-b-0 w-100 inputcontrasts form-control input-group  screening-select`}
            //className="m-b-0 w-100 inputcontrasts form-control input-group"
            name="screening-question"
            style={{ marginTop: "6px" }}
            value={data?.Answer ? data?.Answer : "default"}
            onChange={(e) => {
              handleDropDownSelect(e.target.value, data, index);
            }}
            defaultValue={"default"}
            autoHighlight
          >
            <MenuItem className="preview-dropdown" value="default">--Select--</MenuItem>
            {data.Options?.map((data, ind) => {
              return (
                <MenuItem className="preview-dropdown" key={ind} value={data}>
                  {data}
                </MenuItem>
              );
            })}
          </Select>
        </Typography>
      );
    } else if (type === "date") {
      return (
        <Typography className="p-b-5 screening-date screening-date-preview">
          <p className="f-16 gray9 font-weight-500 mb-10 h-auto mt-20 d-flex">
            <span className="gray7">{`Q${index + 1}. `}</span>
            <span className="word-break pl-5">
              {data?.Question}{" "}
              {(data?.required === "true" || data?.required === true) && "*"}
            </span>
          </p>
          <Typography
            className={`display-flex justify-between p-8 mt-10 form-control input-group no-wrap inputcontrasts`}
            onMouseEnter={() => handleHideClearIcon(question, false)}
            onMouseLeave={() => handleHideClearIcon(question, true)}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} id="job-detail">
              <DatePicker
                className="sub-text-color f-16 font-weight-400 w-100 d-content select-date certificate-date"
                placeholder="This question will be having date selector"
                open={openDatePicker[index] ? true : false}
                onOpen={() => {
                  setOpenDatePicker((prev) => ({ ...prev, [index]: true }));
                }}
                onClose={() => {
                  setOpenDatePicker((prev) => ({ ...prev, [index]: false }));
                }}
                value={data.Answer || null}
                onChange={(date) => handleChangeDate(date, index)}
                format="MMM dd, yyyy"
                minDate={new Date("1970-01-01")}
                emptyLabel=""
                // disabled
              />
              <Box className="d-flex align-center date-icon-cross-sell">
                {data.Answer && !hideDateClearIcon[index] ? (
                  <ClearIcon
                    onClick={() => handleChangeDate(null, index)}
                    aria-label="Clear icon"
                    className="Clearicon-issue dateScreeningQuesClearIcon mt-0"
                  />
                ) : null}
                <img
                  className="cursor-hover"
                  style={{ width: "15px", height: "15px" }}
                  src={calenderIcon}
                  alt="Calendar Icon"
                  onClick={() => setOpenDatePicker((prev) => ({ ...prev, [index]: true }))}
                />
              </Box>
            </MuiPickersUtilsProvider>
          </Typography>
        </Typography>
      );
    } else {
      return (
        <Typography className="input-focus-none mt-10 mb-20 screening-answer">
          <p className="f-16 gray9 font-weight-500 mb-15 h-auto mt-15 d-flex">
            <span className="gray7">{`Q${index + 1}. `}</span>
            <span className="word-break pl-5">
              {" "}
              {data?.Question}{" "}
              {(data?.required === "true" ||
                data?.required === true ||
                data?.collection === "auto") &&
                "*"}
            </span>
          </p>
          <TextField
            type="text"
            name="state"
            id="referrerName"
            placeholder={`This question will be having an answer box`}
            className={`form-control input-group mb-0 inputcontrasts p-0`}
            disabled={data?.collection === "auto"}
            margin="normal"
            onKeyPress={handleSpaces}
            onChange={(event) => handleChange(event, question, index)}
            value={data.Answer || ""}
            // disabled
            // InputProps={{ readOnly: true }}
          />
        </Typography>
      );
    }
  };
  const validateQuestions = (questionsArray) => {
    for (const question of questionsArray) {
        if (question.required === true || question.required === "true" || question.collection === "auto") {
            const answer = question.Answer;
            if (answer === undefined || answer === null || (typeof answer === "string" && answer.trim() === "")) {
              return false;
          }
        }
    }
    return true;
};

  console.log(QuestionArray);
  return (
    <>
      <Drawer
        disableEnforceFocus
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={openAssessment}
        onClose={(e) => {
          setOpenAssessment(false);
          setIsDrawerVisible(true);
        }}
        className="jobDetail-drawer"
      >
        <Grid className="pt-30 pb-20 pr-20 pl-30">
          <Grid className="d-flex align-center mb-10">
            <Typography
              onClick={(e) => {
                setIsLoading(false);
                setOpenAssessment(false);
                setIsDrawerVisible(true);
              }}
              className="cursor-hover gray7 display-flex align-center apply-job-align"
            >
              <ChevronLeftIcon className="f-30" />
            </Typography>
            <Typography className="text-black f-20 font-weight-600 ml-10">
              Complete Candidate Screening
            </Typography>
          </Grid>
          {/* Profile Essentials */}
          {profileEssentials.length > 0 && (
            <Grid className="mt-15 ml-5 profile-essentials-new pr-10 mb-20  cross-profile">
              <Typography className="f-16 gray9 font-weight-600">
                Profile Essentials
              </Typography>
              <MissingDetails
                openAssessment={openAssessment}
                setOpenAssessment={setOpenAssessment}
                profileEssentialsApiCall={profileEssentialsApiCall}
                setProfileEssentialsApiCall={setProfileEssentialsApiCall}
                resumeApiCall={resumeApiCall}
                setResumeApiCall={setResumeApiCall}
                isFromTalentConsole={isFromTalentConsole}
                getCandidatesCall={getCandidatesCall}
                candidateId={candidateId}
                candidate={candidate}
                questions={profileEssentials}
                fieldMapping={fieldMapping}
                setIsLoading={setIsLoading}
                //  openConfirmation={true}
                profileDetails={profileDetails}
                profileDetailsGroup={profileDetailsGroup}
                handleDropdownClick={handleDropdownClick}
                profileEssentialsValidations={profileEssentialsValidations}
                setProfileEssentialsValidations={setProfileEssentialsValidations}
                jobId={jobId}
              />
            </Grid>
          )}
          {/* Screening Questions */}
          {question.length > 0 && (
            <Grid className="ml-5 screening-preview-new pr-10 mb-10">
              <Typography className="f-16 gray9 font-weight-600">
                Screening questions
              </Typography>
              {QuestionArray.map((ques, index) => (
                <React.Fragment key={index}>
                  {questionTypeToReturn(ques.Type, ques, index)}
                  {index != QuestionArray?.length - 1 && (
                    <Typography className="border-bottom-gray4"></Typography>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
          <Grid className="d-flex justify-end mb-50">
            <Grid>
              {/* <Button className="btn secondary-button mr-20" onClick={(e) => {
                e.stopPropagation();
                setOpenAssessment(false);
                setIsDrawerVisible(true);
              }}>Back</Button> */}
              <Button
                className="btn primary-button"
                onClick={handleShortlist}
                // disabled={!isValid}
                disabled={!(isValid && profileEssentialsValidations)}
              >
                Shortlist
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
export default ScreeningAnswerDrawer;
