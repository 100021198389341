import * as React from "react";
import {
  Typography,
  Grid,
  Container,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Box
} from "@mui/material";
import { useEffect } from "react";
import { ReactComponent as AnalyticsIcon } from "../../assets/images/analytics-black-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import constants from "../../helpers/constants";
import { ReactComponent as DownloadIcon } from "../../assets/images/download-icon.svg";
import DownloadAnalytics from "./Download_analytics";
// import { Tooltip } from "react-tippy";
import { Tooltip } from "@mui/material";

export default function AnalyticsSideBar(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  let navigate = useNavigate();
  const [option, setOption] = React.useState(null);
  const [downloadPopup, setDownloadPopup] = React.useState(false);
  const handleCloseAnalyticsSideBar = () => {
    props.setOpenAnalytics(false);
    if (props.anchorEl) {
      props.setAnchorEl(null);
    }
  };
  const handleCloseMenu = (event) => {
    props.setOpenAnalytics(false);
    if (props.anchorEl) {
      props.setAnchorEl(null);
    }
    // props.setOpen(false);
  };
  useEffect(() => {
    toast.dismiss();
    console.log("iiii");
  }, []);


  const goToReport = (report, option, title) => {
    handleCloseMenu();
    setOption(option);
    localStorage.setItem("analyticsTitle", title);
    navigate("/enterprise/Analytics", {
      state: { report: report, option: option, title: title },
    });
  };

  return (
    <>
      <Helmet>
        {appOrigin === "N" ?
        <title>Analytics | {constants.APP_NAME_NEURODIVERSIFY}</title>
          : appOrigin === "H" ? <title>Analytics | {constants.APP_NAME_DIRECT_HEALTH}</title> :
            <title>Analytics | {constants.APP_NAME_GENERAL}</title>
        }
        {/* <title>Analytics | hireHQ</title> */}
        <meta
          name="description"
          content={`Analytics | ${constants.APP_NAME_GENERAL}`}
        />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Grid item
        className="bg-white"
      // style={{
      //     width: '270px',
      //     height: '100vh',
      //     boxShadow: '0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08)',
      // }}
      >
        {/* <ClickAwayListener onClickAway={handleCloseAnalyticsSideBar}> */}
        <MenuList tabIndex={-1}>
          <Grid item className="d-flex align-between pt-20 pl-15 pr-20">
            <Typography className="d-flex align-center">
              <AnalyticsIcon width="16px" height="16px" aria-label="Analytics icon" />
              <Box
                className="font-weight-600 gray9 f-14 ml-10"
                style={{ paddingTop: "2px" }}
              >
                Analytics
              </Box>
            </Typography>
            <Typography className="icon-button d-flex align-between" >
            <Tooltip
                    title="Download"
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
            <DownloadIcon
                className="cursor-hover"
                onClick={() => setDownloadPopup(true)}
                onKeyPress={() => setDownloadPopup(true)}
                aria-label="download icon"
                width="18px" height="16px"
              />
              </Tooltip>
              <CloseIcon
                className="f-16 gray7 cursor-hover icon-button ml-10"
                onClick={handleCloseAnalyticsSideBar}
                onKeyPress={handleCloseAnalyticsSideBar}
                aria-label="close icon"
                tabIndex={0}
              />
            </Typography>
          </Grid>
          <Grid item className="analytic-options mt-15"
          // style={{ height: '470px', overflowY: 'auto' }}
          >
            <MenuItem
              tabIndex={0}
              className={localStorage.getItem("analyticsTitle") == "Direct Sourcing Dashboard" ? "analytics p-15 " : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_DIRECT_SOURCING_DASHBOARD,
                  0,
                  "Direct Sourcing Dashboard"
                )
              }
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_DIRECT_SOURCING_DASHBOARD,
                  0,
                  "Direct Sourcing Dashboard"
                )
              }
              disableRipple="true"
            >
              <Typography className="f-14 font-weight-500">
                Direct Sourcing Dashboard
              </Typography>
            </MenuItem>

            <MenuItem
              tabIndex={0}
              className={localStorage.getItem("analyticsTitle") == "Job Summary Report" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() => goToReport(
                process.env.REACT_APP_REPORTS_JOB_SUMMARY,
                1,
                "Job Summary Report"
              )}
              disableRipple="true"
              onKeyPress={() => goToReport(
                process.env.REACT_APP_REPORTS_JOB_SUMMARY,
                1,
                "Job Summary Report"
              )}
            >
              <Typography className="f-14 font-weight-500">
                Job Summary Report
              </Typography>
            </MenuItem>

            <MenuItem
              tabIndex={0}
              className={localStorage.getItem("analyticsTitle") == "Talent Pool Summary" ? "analytics p-15 " : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_TALENT_POOL_SUMMARY,
                  2,
                  "Talent Pool Summary"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_TALENT_POOL_SUMMARY,
                  2,
                  "Talent Pool Summary"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Talent Pool Summary
              </Typography>
            </MenuItem>

            <MenuItem
              tabIndex={0}
              className={localStorage.getItem("analyticsTitle") == "Skill Radar" ? "analytics p-15 " : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_SKILL_RADAR,
                  3,
                  "Skill Radar"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_SKILL_RADAR,
                  3,
                  "Skill Radar"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Skill Radar
              </Typography>
            </MenuItem>

            <MenuItem
              tabIndex={0}
              className={localStorage.getItem("analyticsTitle") == "Hiring Velocity" ? "analytics p-15 " : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_HIRING_VELOCITY,
                  4,
                  "Hiring Velocity"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_HIRING_VELOCITY,
                  4,
                  "Hiring Velocity"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Hiring Velocity
              </Typography>
            </MenuItem>

            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Candidate Engagement Analysis" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_CANDIDATE_ENGAGEMENT,
                  5,
                  "Candidate Engagement Analysis"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_CANDIDATE_ENGAGEMENT,
                  5,
                  "Candidate Engagement Analysis"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Candidate Engagement Analysis
              </Typography>
            </MenuItem>

            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Rogue Spend Analysis" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_ROGUE_SPENT,
                  6,
                  "Rogue Spend Analysis"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_ROGUE_SPENT,
                  6,
                  "Rogue Spend Analysis"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Rogue Spend Analysis
              </Typography>
            </MenuItem>

            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Job Performance Report" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_JOB_PERFORMANCE,
                  7,
                  "Job Performance Report"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_JOB_PERFORMANCE,
                  7,
                  "Job Performance Report"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Job Performance Report
              </Typography>
            </MenuItem>
            {appOrigin != "N" && (
              <MenuItem
                className={localStorage.getItem("analyticsTitle") == "Candidate Density" ? "analytics p-15" : "p-15 analytic-res-align"}
                onClick={() =>
                  goToReport(
                    process.env.REACT_APP_REPORTS_CANDIDATE_DENSITY,
                    9,
                    "Candidate Density"
                  )
                }
                disableRipple="true"
                onKeyPress={() =>
                  goToReport(
                    process.env.REACT_APP_REPORTS_CANDIDATE_DENSITY,
                    9,
                    "Candidate Density"
                  )
                }
              >
                <Typography className="f-14 font-weight-500">
                  Candidate Density
                </Typography>
              </MenuItem>
            )}

            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Candidate Bounce Analysis" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_CANDIDATE_BOUNCE,
                  10,
                  "Candidate Bounce Analysis"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_CANDIDATE_BOUNCE,
                  10,
                  "Candidate Bounce Analysis"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Candidate Bounce Analysis
              </Typography>
            </MenuItem>
            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Diversity and Inclusion Dashboard" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_DIVERSITY,
                  11,
                  "Diversity and Inclusion Dashboard"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_DIVERSITY,
                  11,
                  "Diversity and Inclusion Dashboard"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Diversity and Inclusion Dashboard
              </Typography>
            </MenuItem>
            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Recruitment Overview Dashboard" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_RECRUITMENT,
                  12,
                  "Recruitment Overview Dashboard"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_RECRUITMENT,
                  12,
                  "Recruitment Overview Dashboard"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Recruitment Overview Dashboard
              </Typography>
            </MenuItem>
            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Worker Satisfaction Survey" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_SATISFACTION_SURVEY,
                  13,
                  "Worker Satisfaction Survey"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_SATISFACTION_SURVEY,
                  13,
                  "Worker Satisfaction Survey"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Worker Satisfaction Survey
              </Typography>
            </MenuItem>
            {enterpriseDetails?.interviewConfigurations ?
            <MenuItem
              className={localStorage.getItem("analyticsTitle") == "Interview Analytics" ? "analytics p-15" : "p-15 analytic-res-align"}
              onClick={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_INTERVIEW_ANALYTICS,
                  14,
                  "Interview Analytics"
                )
              }
              disableRipple="true"
              onKeyPress={() =>
                goToReport(
                  process.env.REACT_APP_REPORTS_INTERVIEW_ANALYTICS,
                  14,
                  "Interview Analytics"
                )
              }
            >
              <Typography className="f-14 font-weight-500">
                Interview Analytics
              </Typography>
            </MenuItem>
            : ""}
          </Grid>
        </MenuList>
        {/* </ClickAwayListener> */}
      </Grid>
      <DownloadAnalytics
        open={downloadPopup}
        close={setDownloadPopup}
      />
    </>
  );
}
