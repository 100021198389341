import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Popover,
  Typography,
  Stack,
  Grow,
  ClickAwayListener,
  Popper,
  Paper,
  IconButton,
  Tooltip as TooltipData,
  Drawer,
  Modal,
  Rating,
  Checkbox,
  Tab,
  Tabs,
  MenuList,
  MenuItem,
  styled,
  LinearProgress,
  linearProgressClasses
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cellIcon from "../../../assets/images/cellIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import bgImage from "../../../assets/images/matchingBg.svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import microsoftLogo from "../../../assets/images/microsoftLogo.svg";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import Loader from "../../Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AccessService from "../../../Services/Enterprise/Access.service";
import moment from "moment";
import Skills from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/Skills";
import ActionButtons from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/ActionButtons";
import Education from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/Education";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import hourlyRateImage from "../../../assets/images/hourlyRate-profile.svg";
import overallExp from "../../../assets/images/overallExp-profile.svg";
import viewAssessment from "../../../assets/images/view-assessment.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { functiondisable, functionenable } from "../../../helpers/helper";
import * as Constants from "../../../helpers/constants";
import RatingsAndReviews from "./_JobCandidateDetailsPartials/RatingsAndReviews";
import starEmpty from "../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../assets/images/star-icon-filled.svg";
import workLogo from "../../../assets/images/workExp.svg";
import CandidateProfileVideoPreview from "../../../Components/shared/CandidateProfileVideoPreview";
import { ReactComponent as ActionIcon } from "../../../assets/images/action-cta-icon.svg";
import {
  checkIsImage,
  formatDate,
  getCandidateShiftTimings,
  populateNationality,
  normalizeKeys,
  getReferredByLabel,
  PreferredCommunicationTooltip,
  dateFormat,
} from "../../../helpers/helper-data";
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import defaultUser from "../../../assets/images/default-user-video.svg";
import playIcon from "../../../assets/images/playIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download-icon.svg";

import zipIcon from "../../../assets/images/extension-icon/zip.png";
import eyeIcon from "../../../assets/images/eye-icon.png";
import licensure from "../../../assets/images/licensure.svg";
import references from "../../../assets/images/references.svg";
import offerDetails from "../../../assets/images/gender-info.svg";
import linkIcon from "../../../assets/images/link-icon.svg";
import { ReactComponent as AddSkills } from "../../../assets/images/addSkills.svg";
import checklistIndustry from "../../../assets/images/checklist-new-icon.svg";
import ApiCalls from "../../../api/customAxios";
import ChecklistForm from "./Checklist/ChecklistForm";
import ChecklistOverlay from "./ChecklistOverlay";
import { Helmet } from "react-helmet";
import { success, danger } from "../../../helpers/message-box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ReactComponent as NotesIcon } from "../../../assets/images/enterprise-notes.svg";
import hourlyRateIcon from "../../../assets/images/payRate-new-icon.svg";
import overExpIcon from "../../../assets/images/exp-new-icon.svg";
import ratingIcon from "../../../assets/images/rating-profile-icon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import JobHeader from "../../EnterprisePortal/Project/_JobCandidateDetailsPartials/JobHeader";
import availabilityIcon from "../../../assets/images/pool-availability.svg";
import InfoIcon from "@mui/icons-material/Info";
import WorkplaceIcon from "../../../assets/images/workplace.svg";
import WorkTypeIcon from "../../../assets/images/worktype.svg";
import { ReactComponent as EyeIconSvg } from "../../../assets/images/new-eye-icon.svg";
import { ReactComponent as NewBriefCase } from "../../../assets/images/new-brief-case.svg";
import { ReactComponent as NewLocation } from "../../../assets/images/location-new-icon-gray.svg";
import { ReactComponent as DetailIcon } from "../../../assets/images/detail-screen.svg";
import { ReactComponent as PrivateIcon } from "../../../assets/images/private-pool-icon.svg";
import { ReactComponent as PriorityIcon } from "../../../assets/images/priority-pool-icon.svg";
import { ReactComponent as PriorityIconGreen } from "../../../assets/images/starRename.svg";
import { ReactComponent as OccupiedIcon } from "../../../assets/images/occupied-calendar.svg";
import { ReactComponent as ReferralIcon } from "../../../assets/images/ReferralIcon.svg";
import nueroIcon from "../../../assets/images/nuero-icon.svg";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";

import Notes from "./_JobCandidateDetailsPartials/Notes";
import Certification from "./Certification";
import MatchingScoreBreakdownModal from "./MatchingScoreBreakdownModal";
import TimelineCandidate from "./_JobCandidateDetailsPartials/TimelineCandidate";
import disciplineIcon from "../../../assets/images/education-new-icon.svg";
import specializationIcon from "../../../assets/images/specializationIcon.svg";
import shiftPreferenceIcon from "../../../assets/images/shift-preferance-img.svg";
import jobPreferenceIcon from "../../../assets/images/job-preferance-icon.svg";
import startDateIcon from "../../../assets/images/hc-start-date-icon.svg";
import { ReactComponent as ReasonIcon } from "../../../assets/images/declineIcon.svg";
import { useSelector } from "react-redux";
import AvailabilityStatusLabels from "../../../Components/shared/AvailabilityStatusLabels";
import { getCredentialingtoken } from "../../../helpers/credentialingToken";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import ProjectLabelEnhancement from "./_JobCandidateDetailsPartials/ProjectLabelEnhancement";
import UploadResumeModal from "./UploadResumeModal";
import FileUploadModal from "./FileUploadModal";
import deleteIcon from "../../../assets/images/delete-new-icon.svg";
import DocumentListing from "./DocumentListing";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageDialogModel from "../../../Components/shared/MessageDialogModel";
import ScreeningResponseDrawer from "../JobSearch/ScreeningResponseDrawer";
import TooltipShared from "../../../Components/shared/TooltipShared";
import GdprRestrictDownloadModal from "../../../Components/shared/GdprRestrictDownloadModal";
import GdprRestrictEditCandidateModal from "../../../Components/shared/GdprRestrictEditCandidateModal";
import ContactInfo from "./ContactInfo";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#445BC7 60%",
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#445BC7" : "#445BC7",
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ITEM_HEIGHT = 48;

const columns = [
    { id: "jobReference", label: "Job Reference #", minWidth: 50 },
    { id: "jobTitle", label: "Job Title", minWidth: 50 },
    { id: "companyName", label: "Company Name", minWidth: 50, align: "left" },
    { id: "onboardedDate", label: "Onboarded Date", minWidth: 50 },
    { id: "duration", label: "Duration", minWidth: 50 },
    { id: "jobEndDate", label: "Job End Date", minWidth: 50, align: "left" },
    { id: "offboardedDate", label: "Offboarded Date", minWidth: 50 },
    { id: "currentStatus", label: "Current Status", minWidth: 50, align: "left" },
];

export default function MatchingCandidateListingDrawer(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const enterpriseDetails = useSelector((state) => state.enterprise);
  const [confirmSendSms, setConfirmSendSms] = React.useState(false);
  const [specializationString, setSpecializationString] = useState("");
  const [openGDPREditCandidateRestrictPopup, setOpenGDPREditCandidateRestrictPopup] = useState(false);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  // const [value, setValue] = useState(4);
  let navigate = useNavigate();
  const { state } = useLocation();
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [openGDPRDownloadRestrictPopup, setOpenGDPRDownloadRestrictPopup] = useState(false);
  const LicenseStatus = {
    A: "Current License",
    I: "Inactive License",
    P: "License Applied",
  };
  const LicenseType = {
    L: "License is limited",
    U: "License is unlimited",
  };
  const jobStatusValues = {
    P: "Pending",
    O: "Open",
    C: "Closed",
    D: "Dropped / Cancelled",
    H: "on hold"
  };
  // let candidateApplicationD;
  // if (state) {
  //   candidateApplicationD = state.candidateApplicationData;
  // } else {
  //   candidateApplicationD = undefined;
  // }
  // const { candidateApplicationData } = state;
  const showMatchingScore = localStorage.getItem("showMatchingScore");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openStatus, setOpenStatus] = useState(false);

  const [openAssessment, setOpenAssessment] = React.useState(false);
  const handleOpenAssessment = () => setOpenAssessment(true);
  const handleCloseAssessment = () => setOpenAssessment(false);

  const [openOffer, setOpenOffer] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [job, setJob] = React.useState(
    props?.jobDetails ? props?.jobDetails : {}
  );
  const [candidate, setCandidate] = React.useState({});
  const [candidateAllDetail, setCandidateAllDetail] = useState({});
  const [workPlace, setWorkPlace] = React.useState(null);
  const [workType, setWorkType] = React.useState(null);
  const [candidateApplicationData, setCandidateApplication] = useState({});
  // candidateApplicationD === null ? undefined : candidateApplicationD
  // );
  const [showCandidateChecklistOverlay, setShowCandidateChecklistOverlay] =
    useState(false);
  const [shortlistingRemarks, setShortlistingRemarks] = useState("");
  const [answers, setAnswers] = useState([]);
  const [showMoreAnswer, setShowMoreAnswer] = useState({});
  const [pitchVideo, setPitchVideo] = useState(null);
  const [pitchVideoDetails, setPitchVideoDetails] = useState();
  const [openPitchVideoPreview, setOpenPitchVideoPreview] = useState(false);
  const [candidateChecklist, setCandidateChecklist] = useState([]);
  const [selectedCandidateChecklist, setSelectedCandidateChecklist] = useState(
    []
  );
const [isDrawerVisible, setIsDrawerVisible] = useState(true);
  const [showChecklistOverlay, setShowChecklistOverlay] = useState(false);
  const handleOpenPitchVideoPreview = () => {
    setOpenPitchVideoPreview(true);
  };
  const params = useParams();
  const status = props.status;
  const [openRatingAndReview, setRatingAndReview] = useState(true);
  const ratingAndReviewsRef = React.useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [showMoreWorkSummary, setShowMoreWorkSummary] = useState([]);
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    profile: true,
    checkList: [],
    resumes: [],
  });
  const [openPanelMember, setOpenPanelMember] = useState(false);
  const [openDocument, setOpenDocument] = React.useState(false);
  const user_type = localStorage.getItem("userType");
  const toShowInterview = (((user_type === "H") || (user_type === "R") || (user_type === "T") || (user_type === "Z")) && enterpriseDetails?.enterprise?.interviewConfigurations != null && enterpriseDetails?.enterprise?.interviewConfigurations != "");
  const superadminrestriction = (user_type === "Z");
  const handleOpenDocument = () => setOpenDocument(true);
  const handleCloseDocument = () => setOpenDocument(false);
  const intervalRef = useRef(null);
  const resumeRef = useRef(null);
  const [shortlistPopUpOpener, setShortlistPopUpOpener] = useState(false);
  const handleOpenVideoPreview = useCallback((profileVideo, candidateName) => {
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  }, []);
  const baseURL = window.location.origin;
  const [openAddResumeModal, setOpenAddResumeModal] = useState(false);
  const [openAddFileModal, setOpenAddFileModal] = useState(false);
  const [resumeDocuments, setResumeDocuments] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [enterpriseSelectedResumes, setEnterpriseSelectedResumes] = useState([]);
  const [enterpriseSelectedFiles, setEnterpriseSelectedFiles] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [allDocuments, setAllDocuments] = useState([]);
  const [message, setMessage] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  const openRatingAndReviewSection = () => {
    setRatingAndReview(true);
    if (openRatingAndReview) {
      ratingAndReviewsRef?.current?.scrollIntoView();
    }
  };
  const translateJobStatus = (status) => {
    return jobStatusValues[status] || status;
  };
  const translateLicenseStatus = (status) => {
    return LicenseStatus[status] || status;
  };
  const translateLicenseType = (type) => {
    return LicenseType[type] || type;
  };
const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };
  /* React.useEffect(()=>{
           if(openRatingAndReview)
           {
               ratingAndReviewsRef?.current?.scrollIntoView();
           }
       },[openRatingAndReview])*/

  const handleGotoJobsList = () => {
    navigate("/enterprise/JobList");
  };

  const handleGotoJob = () => {
    navigate(
      "/enterprise/jobs/" +
        props.JobId +
        "/matching-candidates?tab=" +
        props.status
    );
  };

  useEffect(()=>{
    if (candidate?.workHistory?.length) {
      setShowMoreWorkSummary(Array.from({ length: candidate?.workHistory?.length }, ()=>false));
    } else {
      setShowMoreWorkSummary([]);
    }
  }, [candidate?.workHistory]);

  useEffect(() => {
    if (props.jobStatus && props.jobStatus != "O") {
      setJob({ ...job, status: props.jobStatus });
    }
  }, [props.jobStatus]);

  useEffect(async () => {
    setIsLoading(true);
    // if (props.JobId && props.status != "matching-candidate") {
    //   await ApiCalls.get(`enterprises/skillChecklist/${props.candidateId}`)
    //     .then((response) => {
    //       if (response) {
    //         setCandidateChecklist(
    //           response.filter((chkList) => chkList.status !== "D")
    //         );
    //       }
    //     })
    //     .catch(function (error) {});
    //   }
      if (props.JobId && !Object.keys(job).length) {
        await AccessService.getJobDetails(props.JobId)
          .then((response) => {
            setJob(response);
            setIsLoading(false);
          })
          .catch(function (error) {
            setIsLoading(false);
          });
      }
  }, [props.candidateId]);

  useEffect(() => {
    let data = {
      candidateId: props.candidateId,
      jobId: props.JobId,
      isProfileViewed: true,
    };
    if (props.status === "applied") {
      data["isApplicationViewed"] = true;
    }
    if (props.candidateId) {
      CandidateService.getStatus(data).then((res) => {});
    }
  }, [props.candidateId]);

  useEffect(async () => {
    if (props.candidateId) {
      setIsLoading(true);
      await CandidateService.getCandidateAllDetails(
        props.candidateId,
        props.JobId,
        props.status === "matching-candidate" ? true : false
      )
        .then((response) => {
          setCandidate(response);
          setCandidateAllDetail(response);
          let specialization = [];
          response?.skills?.map((skill, index) => {
            specialization.push(skill.skills.keyword);
          });
          setSpecializationString(specialization.join(", "));
          setWorkPlace(response.workPlace);
          setWorkType(response.workType);
          setCandidateApplication(response);
          if (props.JobId && props.status != "matching-candidate") {
            setCandidateChecklist(
            [...response?.candidateSkillChecklistResponse]
          );
        }
          // setIsLoading(false);
        })
        .catch(function (error) {
          // setIsLoading(false);
        });
      getMatchingCandidates();
    }
  }, [props.candidateId]);

  const getMatchingCandidates = () => {
    // setIsLoading(true);
    CandidateService.getCandidateAllDetails(
      props.candidateId,
      props.JobId,
      false
    )
      .then((response) => {
        setCandidate(response);
        setCandidateApplication(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  useEffect(async () => {
    if (props.candidateId) {
      setIsLoading(true);
      await CandidateService.getAssessmentReponse(
        props.JobId,
        props.candidateId
      )
        .then((response) => {
          if (response.answers !== null) {
            setAnswers(JSON.parse(response.answers));
          }
          setPitchVideo(response.applyMediaFileSigned);
          setPitchVideoDetails(response);
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  }, [props.candidateId]);

  const handleShowMoreAnswer = (answer) => {
    let show_more = { ...showMoreAnswer };
    show_more[answer.question] = !show_more[answer.question];
    setShowMoreAnswer(show_more);
  };

  function workDuration(workedFrom, workedTo) {
    var currentDate = moment().format("YYYY-MM-DD");
    var start = moment(workedFrom, "YYYY-MM-DD");
    var end = moment(
      workedTo === "1900-01-01" ? currentDate : workedTo,
      "YYYY-MM-DD"
    );
    var years = end.diff(start, "years");
    var months = end.diff(start, "months");
    var output = "";
    if (years > 0 && months === 0) {
      output =
        years === 0 ? "" : `${years} ${years === 1 ? "Year " : "Years "}`;
    }
    if (years === 0 && months > 0) {
      output =
        months === 0
          ? "1 month"
          : `${months} ${months === 1 ? "month " : "months "}`;
    }
    if (years === 0 && months === 0) output = "1 month";
    if (years > 0 && months % years === 0) {
      output = `${years} ${years === 1 ? "Year " : "Years "}`;
    }
    if (years > 0 && months % 12 > 0) {
      output =
        `${years} ${years === 1 ? "Year " : "Years "}` +
        `${months % 12} ${months % 12 === 1 ? "month " : "months "}`;
    }

    return output;
  }

  const goToAssessment = () => {
    navigate("/enterprise/screeningQuestions");
  };
  const [isCredWidgetLoading, setIsCredWidgetLoading] = useState(false);
  const [openWorkFlowModal, setOpenWorkFlowModal] = useState(false);
  const [invitationCode, setInvitationCode] = useState(null);
  const handleOpenWorkFlowModal = (e, _invitationCode) => {
    e.stopPropagation();
    setIsCredWidgetLoading(true);
    setInvitationCode(_invitationCode);
    callcredentaling(_invitationCode);
  };
  const handleCloseWorkFlowModal = () => {
    setInvitationCode(null);
    setOpenWorkFlowModal(false);
  };
  const callcredentaling = async (_invitationCode) => {
    try {
      const data = await getCredentialingtoken();
      if (data) {
        workflowReview(data, _invitationCode);
      } else {
        // Handle the case where data is not available or falsy
        console.error("Credentialing token data is empty or falsy.");
      }
    } catch (error) {
      // Handle errors from getCredentialingtoken
      console.error("Error while fetching credentialing token:", error);
    }
  };

  function workflowReview(data, _invitationCode) {
    let workflowReviewDiv = document.createElement("div");
    workflowReviewDiv.id = 'inline-wrapper';
    let primary_color = getComputedStyle(document.documentElement).getPropertyValue("--primary-color");
    let secondary_color = getComputedStyle(document.documentElement).getPropertyValue("--secondary-color");
    let primary_color_dark = getComputedStyle(document.documentElement).getPropertyValue("--gray9");
    CredWidgetLibrary.createWidget(workflowReviewDiv, {
      "view": "WorkflowView",
      "data": {
        "invitationCode": _invitationCode,
      },
      "workflowReviewDecoration": {
        "taskLoadingIndicatorColor": primary_color.slice(1, primary_color.length),
        "taskLoadingIndicatorSize": 15,
        "noDataTextStyle": {
          "fontFamily": "Inter",
          "fontSize": 16,
          "color": primary_color_dark.slice(1, primary_color_dark.length),
          "fontWeight": "bold"
        },
        "errorTextStyle": {
          "fontFamily": "Inter",
          "fontSize": 16,
          "color": "B42318",
          "fontWeight": "bold"
        },
        "workflowNameStyle": {
          "fontFamily": "Inter",
          "fontSize": 20,
          "color": primary_color_dark.slice(1, primary_color_dark.length),
          "fontWeight": "bold"
        },
        "previewWidgetDecoration": {
          "titleBackgroundColor": "FFFFFF",
          "titleTextStyle": {
            "fontFamily": "Inter",
            "fontSize": 16,
            "color": primary_color_dark.slice(1, primary_color_dark.length),
          },
          "borderColor": "FFFFFF",
          "questionAndAnswerTypeDecoration": {
            "questionTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "bold"
            },
            "answerTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "normal"
            }
          },
          "tabularTypeDecoration": {
            "tableDataRowColor": "FFFFFF",
            "tableHeaderRowColor": "F2F4F7",
            "tableRowTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "normal"
            },
            "tableColumnTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "bold"
            },
          },
          "signedDocumentTypeDecoration": {
            "linkTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 16,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            }
          },
          "validityTypeDecoration": {
            "issueDateTitleStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "bold"
            },
            "expiryDateTitleStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "bold"
            },
            "expiryDateStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "normal"
            },
            "issueDateStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
              "fontWeight": "normal"
            },
            "linkTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 16,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            }
          },
          "documentTypeDecoration": {
            "linkTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 16,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            }
          },
          "ratingTypeDecoration": {
            "size": 40,
            "activeRatingBackGroundColor": primary_color.slice(1, primary_color.length),
            "inactiveRatingBackGroundColor": "FFFFFF",
            "activeRatingTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 16,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            },
            "inactiveRatingTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 16,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            }
          },
          "consentTypeDecoration": {
            "buttonRoundedRadius": 6,
            "buttonBackgroundColor": primary_color.slice(1, primary_color.length),
            "buttonShadowColor": "FFFFFF",
            "previewButtonTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": "FFFFFF",
            },
            "agreementTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 14,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            },
            "agreementTitleTextStyle": {
              "fontFamily": "Inter",
              "fontSize": 16,
              "color": primary_color_dark.slice(1, primary_color_dark.length),
            }
          },
          "reviewButtonsDecoration": {
            "acceptButtonDecoration": {
              "buttonCornerRadius": 6,
              "buttonBackgroundColor": primary_color.slice(1, primary_color.length),
              "buttonShadowColor": "FFFFFF",
              "buttonTextStyle": {
                "fontFamily": "Inter",
                "fontSize": 14,
                "color": "FFFFFF"
              }
            },
            "rejectButtonDecoration": {
              "buttonCornerRadius": 6,
              "buttonBackgroundColor": secondary_color.slice(1, secondary_color.length),
              "buttonShadowColor": "FFFFFF",
              "buttonTextStyle": {
                "fontFamily": "Inter",
                "fontSize": 14,
                "color": primary_color.slice(1, primary_color.length),
              }
            }
          }
        }
      },
      "auth": {
        "token": data,
      }
    });
    setIsCredWidgetLoading(false);
    setOpenWorkFlowModal(true);
    document.getElementById("modal-description")?.replaceChildren();
    document.getElementById("modal-description")?.appendChild(workflowReviewDiv);
  }

  const [candidatePopUp, setCandidatePopUp] = useState();
  const [preferredExp, setPreferredExp] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open3 = Boolean(anchorEl);
  const id = open3 ? "simple-popover" : undefined;

  const handleClick = (event, candidateApplicationD) => {
    setCandidatePopUp(candidateApplicationD);
    setAnchorEl(event.currentTarget);
    functiondisable();
  };

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
    functionenable();
  }, []);

  const getFormattedSkills = (skills) => {
    let s = skills.split(",");
    if (s.length > 1) {
      let skl = "";
      s.map((o, i) => {
        skl += o + ", ";
        if ((i + 1) % 2 === 0) {
          skl += "\n  ";
        }
      });

      return skl.trim().slice(0, -1);
    }

    return skills;
  };

  useEffect(() => {
    if (props.query?.includes("yearsOfExperience")) {
      let q = props.query?.split("&");
      q.map((x) => {
        if (x.includes("yearsOfExperience")) {
          setPreferredExp(x.split("=")[1] + " Years");
        }
      });
    } else {
      setPreferredExp(null);
    }
  }, [props.query]);
  if (!candidateApplicationData) {
    return <Loader />;
  }

  const ratingChanged = (newRating) => {};

  const ProflieStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: props.candidateId,
      jobId: props.JobId,
      isProfileDownloaded: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  };
  const ResumeStatusCapture = () => {
    //for capturing status
    let data = {
      candidateId: props.candidateId,
      jobId: props.JobId,
      isResumeDownloaded: true,
    };
    CandidateService.getStatus(data).then((res) => {});
  };

  const DownloadProfile = () => {
    if ((candidate.candidateName === "**** ****") || (candidate.firstName === "****" &&
      (candidate.lastName !== null ||
      candidate.lastName !== "" ||
      candidate.lastName === "****"))) {
      setOpenGDPRDownloadRestrictPopup(true);
      return;
    }
    let obj = {
      profile: selectedValues.profile,
      checkList: selectedValues.checkList,
      enterpriseId: enterpriseDetails.id
    };
    if (selectedValues.profile || selectedValues.checkList.length > 0) {
      CandidateService.getPdfs(props.candidateId, obj)
        .then((response) => {
          const data = [...response, ...selectedValues.resumes, ...enterpriseSelectedFiles, ...enterpriseSelectedResumes];
          downloadPdf(data);
          success("File downloaded successfully.");
        })
        .catch((error) => {
          danger("Something went wrong.");
        });
    }
    if (!selectedValues.profile && !selectedValues.checkList.length) {
      ResumeStatusCapture();
      const data = [...selectedValues.resumes, ...enterpriseSelectedFiles, ...enterpriseSelectedResumes];
      downloadPdf(data);
    }
    if (
      selectedValues.profile &&
      !selectedValues.resumes.length &&
      !selectedValues.checkList.length
    ) {
      ProflieStatusCapture();
    }
    if (
      (selectedValues.profile &&
        selectedValues.resumes.length &&
        selectedValues.checkList.length) ||
      (selectedValues.profile && selectedValues.resumes.length)
    ) {
      let data = {
        candidateId: props.candidateId,
        jobId: props.JobId,
        isResumeDownloaded: true,
        isProfileDownloaded: true,
      };
      CandidateService.getStatus(data).then((res) => {});
    }
    setDownloadPopup(false);
    setSelectedValues({ profile: true, checkList: [], resumes: [] });
    setEnterpriseSelectedFiles([]);
    setEnterpriseSelectedResumes([]);
  };
  const handleSelectCheklist = (e, value) => {
    let check = e.target.checked;
    if (check) {
      selectedValues.checkList.push(value);
      setSelectedValues({
        ...selectedValues,
        checkList: [...selectedValues.checkList],
      });
    } else {
      var idsList = selectedValues.checkList.filter((compare) => {
        return compare != value;
      });
      setSelectedValues({ ...selectedValues, checkList: [...idsList] });
    }
  };
  const handleSelectResumes = (e, value) => {
    let check = e.target.checked;
    if (check) {
      selectedValues.resumes.push({
        name: value.title,
        urlSigned: value.urlSigned,
        id: value.resumeId,
      });
      setSelectedValues({
        ...selectedValues,
        resumes: [...selectedValues.resumes],
      });
    } else {
      var idsList = selectedValues.resumes.filter((compare) => {
        return compare.id != value.resumeId;
      });
      setSelectedValues({ ...selectedValues, resumes: [...idsList] });
    }
  };
  const handleSelectCheklist1 = (value) => {
    if (selectedValues.checkList && selectedValues.checkList.length > 0) {
      let list = selectedValues.checkList.filter((list) => list == value);
      if (list.length > 0) {
        var idsList = selectedValues.checkList.filter((compare) => {
          return compare != value;
        });
        setSelectedValues({ ...selectedValues, checkList: [...idsList] });
      } else {
        setSelectedValues({
          ...selectedValues,
          checkList: [...selectedValues.checkList, value],
        });
      }
    } else {
      setSelectedValues({
        ...selectedValues,
        checkList: [...selectedValues.checkList, value],
      });
    }
  };
  const handleSelectResumes1 = (value) => {
    if (selectedValues.resumes && selectedValues.resumes.length > 0) {
      let list = selectedValues.resumes.filter(
        (list) => list.id == value.resumeId
      );
      if (list.length > 0) {
        var idsList = selectedValues.resumes.filter((compare) => {
          return compare.id != value.resumeId;
        });
        setSelectedValues({ ...selectedValues, resumes: [...idsList] });
      } else {
        let x = {
          name: value.title,
          urlSigned: value.urlSigned,
          id: value.resumeId,
        };
        setSelectedValues({
          ...selectedValues,
          resumes: [...selectedValues.resumes, x],
        });
      }
    } else {
      let y = {
        name: value.title,
        urlSigned: value.urlSigned,
        id: value.resumeId,
      };
      setSelectedValues({
        ...selectedValues,
        resumes: [...selectedValues.resumes, y],
      });
    }
  };
  const downloadPdf = (data) => {
    function download_next(i) {
      if (i >= data.length) {
        return;
      }
      var a = document.createElement("a");
      a.href = data[i].urlSigned;
      if ("download" in a) {
        a.download = data[i].urlSigned;
      }
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click();
      } else {
        window.open(data[i].urlSigned);
      }
      a.parentNode.removeChild(a);
      setTimeout(function () {
        download_next(i + 1);
      }, 1500);
    }
    download_next(0);
  };
  const downloadAttachDocument = (url) => {
    handleCloseDocument();
    success("File downloaded successfully.");
    var a = document.createElement("a");
    a.href = url;
    if (a.click) {
      a.click();
    } else {
      window.open(url);
    }
    a.parentNode.removeChild(a);
  };

  /*tabs function*/
  const [valueTabs, setValueTabs] = useState("basic info");
  const handleChange = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
    setEnterpriseSelectedFiles([]);
    setEnterpriseSelectedResumes([]);
    setAllDocuments([]);
    setPageNumber(0);
    setResumeDocuments([]);
    setOtherDocuments([]);
  };
  useEffect(() => {
    if (valueTabs === "Download") {
      props.setIsLoading(true);
      // pageNumber
      AccessService.getResumeDocuments(pageNumber, size, localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"), props.candidateId, params.job_id).then((resp) => {
        if (resp?.length > 0) {
          let newResumeDocuments = [];
          let newOtherDocuments = [];
          if (resp?.length < 10) {
            setLoadMore(false);
          } else {
            setLoadMore(true);
          }
          setAllDocuments(resp);
          resp.forEach((data) => {
            if (data.documentCategory === "R") {
              newResumeDocuments.push(data);
            } else {
              newOtherDocuments.push(data);
            }
          });
          setResumeDocuments(newResumeDocuments);
          setOtherDocuments(newOtherDocuments);
        } else {
          setResumeDocuments([]);
          setOtherDocuments([]);
        }
        props.setIsLoading(false);
      }).catch((error) => {
        console.error("Error fetching documents:", error);
        props.setIsLoading(false);
      });
    }
  }, [valueTabs, props.candidateId, params.job_id]);

  const handleChangeReview = (newValue) => {
    setValueTabs(newValue);
    setSelectedValues({
      profile: true,
      checkList: [],
      resumes: [],
    });
  };
  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => {
    resetFilters();
    setOpenNotes(true);
  };

  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpenStatus((prevOpen) => !prevOpen);
    // getChecklist();
    // getAssessments();
  };
  const handleCloseStatus = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenStatus(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenStatus(false);
    } else if (event.key === "Escape") {
      setOpenStatus(false);
    }
  }
  const handleHideCandidate = async () => {
    setIsLoading(true);
    var payload = {
      jobId: props.JobId,
      candidateId: props.candidateId,
    };
    await CandidateService.hideCandidate(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("This candidate is hidden.");
        if (props.status) {
          props.updatedstatus();
          props.getMatchingCandidates(params.job_id, true);
          props.setCanduadteDrawer(false);
        }
        setIsLoading(false);
        navigate(
          "/enterprise/jobs/" +
            props.JobId +
            "/matching-candidates?tab=" +
            props.status
        );
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error) {
          danger(error.errorMessage);
          // if (error.response.status !== 403) {
          //   danger(error.response.data.errorDescription);
          // }
        }
      });
  };
  const unshortListHandler = async () => {
    setIsLoading(true);
    var payload = {
      jobId: props.JobId,
      candidateId: props.candidateId,
    };
    await CandidateService.unshortList(payload)
      .then((response) => {
        setCandidateApplication(response);
        success("This candidate is unshortlisted.");
        if (props.status) {
          props.updatedstatus();
          props.getCandidatesByStatus("S", true);
          props.setCanduadteDrawer(false);
        }
        setIsLoading(false);
        navigate(
          "/enterprise/jobs/" +
            props.JobId +
            "/matching-candidates?tab=" +
            props.status
        );
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error) {
          danger(error.errorMessage);
          // if (error.response.status !== 403) {
          //   danger(error.response.data.errorDescription);
          // }
        }
      });
  };
  const getFormattedWorkplace = (workPlace) => {
    let work_place = workPlace?.split(",");
    if (work_place.length === 0) {
      return "-";
    }
    let formatted_work_place = [];
    if (work_place.includes("R")) {
      formatted_work_place.push("Remote");
    }
    if (work_place.includes("H")) {
      formatted_work_place.push("Hybrid");
    }
    if (work_place.includes("O")) {
      formatted_work_place.push("Onsite");
    }
    return formatted_work_place.join(", ");
    // if (workPlace === "R") {
    //   return "Remote";
    // }
    // if (workPlace === "H") {
    //   return "Hybrid";
    // }
    // if (workPlace === "O") {
    //   return "Onsite";
    // }
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleOpenJobType = (event) => {
    setAnchorEl2(event.currentTarget);
    functiondisable();
  };

  const handleCloseJobType = () => {
    setAnchorEl2(null);
    functionenable();
  };

  const openJobType = Boolean(anchorEl2);
  const getFormattedWorkType = (workType, viewAll) => {
    let work_type = workType?.replace(/\s|\[|\]/g, "")
      ? workType.replace(/\s|\[|\]/g, "").split(",")
      : [];
    if (work_type.length === 0) {
      return "-";
    }
    let formatted_work_type = [];
    if (work_type.includes("FT")) {
      formatted_work_type.push("Full Time");
    }
    if (work_type.includes("PT")) {
      formatted_work_type.push("Part Time");
    }
    if (work_type.includes("FL")) {
      formatted_work_type.push("Contingent / Contract");
    }
    if (work_type.includes("IN")) {
      formatted_work_type.push("Internship");
    }
    if (work_type.includes("OT")) {
      formatted_work_type.push("Other");
    }
    if (!viewAll && formatted_work_type.length > 1) {
      formatted_work_type = formatted_work_type.slice(0, 1);
    }
    return formatted_work_type.join(", ");
  };
  const gotoCandidateDescription = (e, candidate) => {
    e.preventDefault();
    var candidate_id = candidate.candidateId;

    window.open(
      `/enterprise/matching-candidate-description/${params.job_id}/${candidate_id}/${props.status}`,
      "_blank"
    );
  };
  // useEffect(() => {
  //   const bodyElement = document.body;
  //   const htmlElement = document.documentElement;
  //   if (props.canduadteDrawer) {
  //     bodyElement.style.overflow = "hidden";
  //     htmlElement.style.overflow = "hidden";
  //   } else {
  //     bodyElement.style.overflow = "";
  //     htmlElement.style.overflow = "";
  //   }
  //   setValueTabs(props.optionValue ? props.optionValue : "basic info");
  // }, [props.canduadteDrawer]);
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.canduadteDrawer) {
      bodyElement.classList.add('overflow-hidden');
      htmlElement.classList.add('overflow-hidden');
    } else {
      bodyElement.classList.remove('overflow-hidden');
      htmlElement.classList.remove('overflow-hidden');
    }
    setValueTabs(props.optionValue ? props.optionValue : "basic info");
  }, [props.canduadteDrawer, props.optionValue]);
  const getFormattedShift = (preferredShift) => {
    if (preferredShift === "D") {
      return "Day Shift";
    } else if (preferredShift === "E") {
      return "Evening Shift";
    } else if (preferredShift === "N") {
      return "Night Shift";
    }
    return "Flexible Shift";
  };
  const handleOpenJobPreference = (event) => {
    setAnchorEl3(event.currentTarget);
    functiondisable();
  };

  const handleCloseJobPreference = () => {
    setAnchorEl3(null);
    functionenable();
  };

  const openJobPreference = Boolean(anchorEl3);

  const handleOpenSpecialization = (event) => {
    setAnchorEl4(event.currentTarget);
    functiondisable();
  };
const goToProjectTab = (e) => {
  e.stopPropagation();
  e.preventDefault();
  setValueTabs("ProjectDetails");
  setSelectedValues({
    profile: true,
    checkList: [],
    resumes: [],
  });
};
  const handleCloseSpecialization = () => {
    setAnchorEl4(null);
    functionenable();
  };
  const goToScheduleInterview = () => {
    setOpenStatus(false);
    try {
      if (!job?.interviewConfigurations || JSON.parse(job?.interviewConfigurations)[0]?.interviewPanelMembers?.length === 0) {
        setOpenPanelMember(true);
      } else {
        const bodyElement = document.body;
        const htmlElement = document.documentElement;
        bodyElement.classList.remove('overflow-hidden');
        htmlElement.classList.remove('overflow-hidden');
        navigate(`/enterprise/schedule-interviews/${params.job_id}/${props.candidateId}`);
      }
    } catch (err) {}
  };
  const handleClosePanelMember = () => {
    setOpenPanelMember(false);
  };
  const  handleOpenShortlistEmailPopup = () => {
    setOpenStatus(false);
    setShortlistPopUpOpener(true);
  };
  const sendEmailShortlist = async() => {
    setIsLoading(true);
    var payload = {
      jobId: params.job_id,
      candidateId: props.candidateId,
    };
    setShortlistPopUpOpener(false);
    await AccessService.sendShortlistEmail(payload).then((res)=>{
      if (res) {
        setIsLoading(false);
        success("Shortlist email has been sent to the candidate.");
      } else {
        setIsLoading(false);
        success("Something went wrong.");
      }
    });
  };
  const sendSmsToCandidates = async() => {
    setIsLoading(true);
    var payload = {
      message: message,
    };
    setConfirmSendSms(false);
    await AccessService.sendSMS(props.candidateId, payload).then((res)=>{
      if (res) {
        setIsLoading(false);
        success("SMS sent successfully to the candidate.");
      } else {
        setIsLoading(false);
        success("Something went wrong.");
      }
      setMessage("");
    }).catch((res)=>{
      setIsLoading(false);
      setMessage("");
    });
  };
  const goToInterviewProcess = () => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    bodyElement.classList.remove('overflow-hidden');
    htmlElement.classList.remove('overflow-hidden');
    navigate("/enterprise/postJob", {
      state: { id: params.job_id, checkListId: job?.checkListId, goToInterviewProcess: true, redirectToSchedulePage: true, candidateid: candidate.id ? candidate.id : candidate.candidateId },
    });
  };
  const openSpecialization = Boolean(anchorEl4);
  const getExclusiveEnterprise = (id) => {
    const currentEnterprise = localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId");
    const enterprisesList = JSON.parse(localStorage.getItem("enterprisesList"));
    const childEnterprisesList = JSON.parse(localStorage.getItem("mspEnterprsieData"))?.childEnterprises;
    const childEnterprise = childEnterprisesList?.find((ent) => ent.id == id);
    const enterprise = enterprisesList?.find((ent) => ent.id == id);
    return currentEnterprise != id ? (enterprise ? "To " + enterprise?.establishmentName : childEnterprise ? "To " + childEnterprise?.establishmentName : "") : "";
  };
  const addNewResumeDocument = () => {
    setOpenAddResumeModal(true);
  };
  const addNewFileDocument = () => {
    setOpenAddFileModal(true);
  };
  const fetchMoreDocumentsOthers = () => {
    props.setIsLoading(true);
    let newPageNo = pageNumber + 1;
    setPageNumber(newPageNo);
    AccessService.getResumeDocuments(newPageNo, size, localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"), props.candidateId, params.job_id).then((resp) => {
      if (resp?.length < 10) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      if (resp?.length > 0) {
        let newResumeDocuments = [];
        let newOtherDocuments = [];
        setAllDocuments([...allDocuments, resp]);
        resp.forEach((data) => {
          if (data.documentCategory === "R") {
            newResumeDocuments.push(data);
          } else {
            newOtherDocuments.push(data);
          }
        });
        setResumeDocuments([...resumeDocuments, ...newResumeDocuments]);
        setOtherDocuments([...otherDocuments, ...newOtherDocuments]);
      }
      props.setIsLoading(false);
    }).catch((error) => {
      console.error("Error fetching documents:", error);
      props.setIsLoading(false);
    });
  };
  const goToEditCandidate = (e, candidate) => {
    e.preventDefault();
    setOpenStatus(false);
    if (candidate.candidateName === "**** ****" || (candidate.firstName === "****" && candidate.lastName === "****")) {
      setOpenGDPREditCandidateRestrictPopup(true);
      return;
    }
    var candidate_id = candidate.candidateId ? candidate.candidateId : candidate.id ? candidate.id : props.candidateId;
    candidate_id = window.btoa(candidate_id);
    window.open(
      `/enterprise/edit-candidate-details/${candidate_id}`,
      "_blank"
    );
  };
  const wageExpectations = {
    HR: "Hourly",
    DA: "Daily",
    WK: "Weekly",
    FN: "Fortnightly",
    MN: "Monthly",
    QA: "Quarterly",
    HY: "Half-Yearly",
    AL: "Annually",
    EP: "End of Project",
  };
  return (
    <>
      <Helmet>
      {appOrigin === "N" ? (
          <title>Candidate Detail | {Constants.APP_NAME_NEURODIVERSIFY}</title>
        ) : appOrigin === "H" ? (
          <title>Candidate Detail | {Constants.APP_NAME_DIRECT_HEALTH}</title>
        ) : (
          <title>Candidate Detail | {Constants.APP_NAME_GENERAL}</title>
        )}
        {/* <title>Candidate Detail | hireHQ</title> */}
        <meta name="description" content={`Candidate Details | ${Constants.APP_NAME_GENERAL}`} />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Drawer
        disableEnforceFocus
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.canduadteDrawer}
        onClose={() => {
          setIsDrawerVisible(true);
          props.setCanduadteDrawer(false);
          if (props.setOptionValue) {
            props.setOptionValue("");
          }
          }}
        className={`job-detail-drawer ${isDrawerVisible ? '' : 'hide_side_drawer'}`}
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sx={{ p: 2 }}
          className="talent-pool-status drawer-align"
        >
          {isCredWidgetLoading && <Loader/>}
          {isNotesLoading && <Loader/>}
          {isLoading && (
            // <Loader/>
            <Typography className="display-flex align-center height-100v">
              <div className="lazy-loader2">
                <div className="shimmerBG title-line end"></div>
                <Typography className="d-flex justify-left mt-24">
                  <div className="shimmerBG title-line icon"></div>
                  <div className="ml-20 w-100">
                    <div className="m-10 shimmerBG title-line-small"></div>
                    <div className="m-10 shimmerBG content-line end"></div>
                    <div className="m-10 shimmerBG content-line end"></div>
                  </div>
                  <div className="m-10 ml-20 shimmerBG title-line-small end"></div>
                  <div className="m-10 shimmerBG title-line-small mid"></div>
                </Typography>
                <div className="py-32 ml-20">
                  <div className="shimmerBG title-line end"></div>
                  <div className="shimmerBG content-line"></div>
                  <div className="shimmerBG title-line end"></div>
                  <div className="shimmerBG content-line"></div>
                  <div className="shimmerBG title-line end"></div>
                  <div className="shimmerBG title-line-small"></div>
                  <div className="shimmerBG title-line media py-32"></div>
                  <div className="shimmerBG title-line media py-32"></div>
                </div>
              </div>
            </Typography>
          )}
          <Grid className="d-flex align-center">
            <Grid
              onClick={() => {
                setIsDrawerVisible(true);
                setValueTabs("basic info"), props.setCanduadteDrawer(false);
                if (props.setOptionValue) {
                  props.setOptionValue("");
                }
              }}
              className="cursor-hover d-flex"
              style={{ color: "#98A2B3" }}
            >
              <ChevronLeftIcon className="f-30" />
            </Grid>
            <Typography className="heading-text-color f-20 font-weight-600 ml-20">
              {props.status === "matching-candidate"
                ? " Matching Candidate"
                : ""}
              {props.status === "shortlisted" ? "Shortlisted Candidate" : ""}
              {props.status === "interviews" ? "Interview Candidates" : ""}
              {props.status === "applied" ? "Applied Candidate" : ""}
              {props.status === "offered" ? "Offered Candidate" : ""}
              {props.status === "onboarded" ? "Onboarded Candidate" : ""}
              {props.status === "completed-cancelled"
                ? "Offboarded Candidate"
                : ""}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="p-t-b-10 userBg-space notification-align pr-5"
          >
             {(job.status && job.status !== "O" && job.status !== "H") && (
              <Typography className="d-flex align-center pt-10 pb-10 pr-20 pl-10 bg-light-blue border-radius-10 mt-5">
                <img width="20" height="20" src={offerDetails} alt="img" />
                <span className="f-14 pl-10 gray9">
                  The status of this job is{" "}
                  {translateJobStatus(job.status).toLowerCase()}. You can only
                  perform actions for open jobs.
                </span>
              </Typography>
            )}
            <Grid
              container
              item
              spacing={1}
              lg={12}
              md={12}
              xs={12}
              className="m-0 responsive-block"
            >
              <Grid item lg={12} md={12} xs={12} className="pl-0">
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className="bg-white d-flex align-between pl-20 pr-20 pb-20  jobSearch galaxy-block ipad-block"
                >
                  <Grid item lg={6.5} md={6.5} xs={12}>
                    <Grid className="d-flex responsive-block galaxy-block">
                      <Typography>
                        <Box className="justify-end d-flex">
                          <Grid item lg={12} md={12} xs={12} className="">
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                              className="profile-pic justify-content-start"
                            >
                              {(candidate.mediaFile === "****" ||
                                candidate.mediaFileSigned === null ||
                                candidate.mediaFileSigned === "") && (
                                <Typography
                                  className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-30 font-weight-700 text-capitalize profileImage"
                                  width="100px"
                                  height="100px"
                                  left="2px"
                                  aria-label="Default image"
                                >
                                  {candidate.firstName.trim().substring(0, 1)}
                                </Typography>
                              )}
                              {candidate.mediaFile !== "****" &&
                                candidate.mediaFileSigned &&
                                candidate.mediaFileSigned !== null &&
                                candidate.mediaFileSigned !== "" &&
                                !checkIsImage(candidate.mediaFileSigned) && (
                                  <>
                                    <video
                                      className="profileImage default-img-border cursor-hover p-relative"
                                      style={{ left: "2px" }}
                                      src={candidate.mediaFileSigned}
                                      onClick={() => {
                                        handleOpenVideoPreview(
                                          candidate.mediaFileSigned,
                                          candidate.firstName +
                                            " " +
                                            candidate.lastName
                                        );
                                      }}
                                      muted
                                      onKeyPress={() => {
                                        handleOpenVideoPreview(
                                          candidate.mediaFileSigned,
                                          candidate.firstName +
                                            " " +
                                            candidate.lastName
                                        );
                                      }}
                                      tabIndex={0}
                                    />
                                    <img
                                      onClick={() => {
                                        handleOpenVideoPreview(
                                          candidate.mediaFileSigned,
                                          candidate.firstName +
                                            " " +
                                            candidate.lastName
                                        );
                                      }}
                                      className="profileImage-overlay play-icon-profile cursor-hover"
                                      src={playIcon}
                                      style={{ top: "105px", left: "80px" }}
                                      alt="img"
                                      tabIndex={0}
                                      onKeyPress={() => {
                                        handleOpenVideoPreview(
                                          candidate.mediaFileSigned,
                                          candidate.firstName +
                                            " " +
                                            candidate.lastName
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              {candidate.mediaFile !== "****" &&
                                candidate.mediaFileSigned &&
                                candidate.mediaFileSigned !== null &&
                                candidate.mediaFileSigned !== "" &&
                                checkIsImage(candidate.mediaFileSigned) && (
                                  <img
                                    className="profileImage default-img-border"
                                    style={{ left: "2px" }}
                                    src={candidate.mediaFileSigned}
                                    alt="img"
                                  />
                                )}
                            </Grid>
                            <Grid className={`image-section ${candidate?.talentPool === "Z" && localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) && "ml-11"}`}>
                            <TooltipData
                                                    title={
                                                      <div className="mt-15">
                                                        <Typography
                                                          // style={{
                                                          //   width: `${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "110px" : "80px"}`,
                                                          // }}
                                                        >
                                                          <div className="d-flex align-center">
                                                          <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "mspExclusive": "priorityStatus"} d-flex align-center justify-center`}>
                                                            <span className="d-flex align-center">
                                                              {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                                                            </span>
                                                            <span className="pl-2">
                                                              {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}
                                                            </span>
                                                          </span>
                                                          <span className="f-12 c-white pl-5">{getExclusiveEnterprise(candidate?.sourceEnterpriseId)}</span>
                                                        </div>
                                                        </Typography>
                                                        <Typography className="f-12 c-white mt-5">
                                                          {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE_Detail :"These are the candidates who have come to the platform through you and only you have the access to their profile"}
                                                        </Typography>
                                                        <br />
                                                      </div>
                                                    }
                                                    position="top"
                                                    trigger="mouseenter"
                                                    animation="fade"
                                                    arrow
                                                  >
                              <Typography className="mt-5 mb-5">
                                {candidate?.talentPool === "Z" && (
                                  <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? "priority-image-status-mspExclusive": "priority-image-status"} d-flex align-center`}>
                                    <span className="d-flex align-center">
                                      {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> :  <PriorityIcon />}
                                    </span>
                                    <span className="pl-5">{localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidate?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE :"Exclusive"}</span>
                                  </span>
                                )}
                              </Typography>
                              </TooltipData>
                              <TooltipData
                                                    title={
                                                      <div className="mt-15">
                                                        <Typography
                                                          style={{
                                                            width: "110px",
                                                          }}
                                                        >
                                                          <span className="myCandidate-Ongoing d-flex align-center justify-center">
                                                            <span className="d-flex align-center">
                                                              <PrivateIcon />
                                                            </span>
                                                            <span className="pl-5">
                                                              Qualified
                                                            </span>
                                                          </span>
                                                        </Typography>
                                                        <Typography className="f-12 c-white mt-5">
                                                          These are the
                                                          candidates with whom
                                                          you have interacted on
                                                          our platform
                                                        </Typography>
                                                        <br />
                                                                                                              </div>
                                                    }
                                                    placement="top"
                                                    trigger="mouseenter"
                                                    animation="fade"
                                                    arrow
                                                  >
                              <Typography className="mt-5 mb-5">
                                {candidate?.talentPool === "P" && (
                                  <span className="private-image-status d-flex align-center">
                                    <span className="d-flex align-center">
                                      <PrivateIcon />
                                    </span>
                                    <span className="pl-5">Qualified</span>
                                  </span>
                                )}
                              </Typography>
                              </TooltipData>
                            </Grid>
                          </Grid>
                        </Box>
                      </Typography>
                      <Typography>
                        <Box className="pl-20">
                          <Box className="">
                            <Typography className="d-flex responsive-block">
                              <Typography className="align-center font-weight-600 mt-10 f-16 gray9 break-word launchTitle width-responsive-100 status-align responsive-block candidateList-align">
                                {enterpriseDetails?.enterprise?.customConfigurations && (
                                  ["R", "Z", "T"].includes(localStorage.getItem("userType")) &&
                                  <>
                                    {(candidate?.profileCreation == "-" || candidate?.profileCreation == null) ?
                                      <></> :
                                      <Box className="mb-2">
                                        <span className="new-tag-chip">
                                          {candidate?.profileCreation}
                                        </span>
                                      </Box>}
                                  </>
                                )}
                                <Typography className="align-center d-flex">
                                  <Typography className="font-weight-600 f-16 main-text-color">
                                    {candidate.firstName} {candidate.lastName}
                                  </Typography>
                                  <Typography className="cursor-hover icon-button">
                                    <DetailIcon
                                      className="ml-10 success-color small-text-font cursor-hover svg-icons"
                                      onClick={(e) =>
                                        gotoCandidateDescription(e, candidate)
                                      }
                                      tabIndex={0}
                                      onKeyPress={(e) =>
                                        gotoCandidateDescription(e, candidate)
                                      }
                                    />
                                  </Typography>
                                  <Typography className="add-checklist-align">
                                    {(props.status === "matching-candidate" ||
                                      props.status === "shortlisted" ||
                                      props.status === "applied" ||
                                      props.status === "onboarded" ||
                                      props.status === "completed-cancelled" ||
                                      props.status === "offered") && (
                                      <Typography className="gray9 font-weight-400 f-14 align-center d-flex responsive-view-align pl-20 ">
                                        <AvailabilityStatusLabels
                                          candidate={candidate}
                                          isFromMatchingDrawer={true}
                                          goToProjectTab={goToProjectTab}
                                        />
                                      </Typography>
                                    )}
                                  </Typography>
                                  <Typography>
                                    { candidate?.enterpriseProcessStatus === "F" &&
                                      candidate?.candidateProcessStatus === "P" && (
                                          <span className="available-status offer_accept ml-10">
                                            Offer Accepted
                                          </span>
                                    )}
                                    { candidate?.enterpriseProcessStatus === "F" &&
                                      candidate?.candidateProcessStatus === "D" && (
                                      <span className="offer_decline f-14 font-weight-400 ">
                                        Offer Declined
                                      </span>
                                    )}
                                  </Typography>
                                </Typography>
                                {/* <VerifiedIcon className="ml-5 success-color small-text-font" aria-label="Verified icon" /> */}
                                <Typography className="d-flex align-center mt-5">
                                  <Typography className="d-flex mr-20">
                                    <NewBriefCase className="min-w-14 mt-3" width="14px" height="14px" />
                                    <Typography className="gray7 f-14 font-weight-400 pl-5">
                                          {candidate?.profileTitle || "-"}{" "}
                                    </Typography>
                                  </Typography>
                                  {candidate?.city && candidate?.country && (
                                    <Typography className="d-flex">
                                      <NewLocation className="min-w-14 mt-3" width="14px" height="14px" />
                                      <Typography className="gray7 f-14 font-weight-400 pl-5">
                                        {/* {candidate?.city ? candidate?.city : "-"},{" "}
                                    {candidate?.country
                                      ? candidate?.country
                                      : ""} */}
                                        {candidate.city}
                                        {candidate?.city ? "," : ""}{" "}
                                        {candidate.country}{candidate.zipCode
                                          ? `, ${candidate.zipCode}`
                                          : ""}
                                      </Typography>
                                    </Typography>
                                  )}
                                </Typography>
                                {/* <Typography className="d-flex">
                                      <NewLocation width="14px" />
                                      <Typography className="gray7 f-14 font-weight-400 pl-5">
                                        {candidate?.city
                                          ? candidate?.city
                                          : "-"}
                                        ,{" "}
                                        {candidate?.country
                                          ? candidate?.country
                                          : "-"}
                                      </Typography>
                                    </Typography> */}
                                <Grid
                                  variant="span"
                                  className="gray7 f-14 d-flex mt-10 jobListFilter cursor-hover skill-labl adding-skills jobDetail-user"
                                  tabIndex={0}
                                >
                                  {candidate.candidateRating ? (
                                    <>
                                      <Typography
                                        className="mb-5 d-flex"
                                        onClick={(e) =>
                                          handleOpenReviewModal(e, candidate)
                                        }
                                      >
                                        <Rating
                                          role=""
                                          count={5}
                                          value={candidate?.candidateRating}
                                          name="half-rating"
                                          readOnly
                                          icon={
                                            <img
                                              alt="star"
                                              src={starFilled}
                                              style={{
                                                width: "17px",
                                                height: "20px",
                                              }}
                                            />
                                          }
                                          emptyIcon={
                                            <img
                                              alt="star"
                                              src={starEmpty}
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            />
                                          }
                                        />
                                        <Typography className="d-flex align-center mt-1">
                                          <a
                                            href="javascript:void(0)"
                                            className="link-color text-decoration-none curso-hover f-12 font-weight-500 ml-5"
                                            tabIndex={0}
                                            onClick={() => {
                                              handleChangeReview("Reviews");
                                              openRatingAndReviewSection;
                                            }}
                                          >
                                            ({candidate.noOfRatings}
                                            {/* {candidate.noOfRatings <= 1
                                              ? "review"
                                              : "reviews"} */}
                                            )
                                          </a>
                                        </Typography>
                                      </Typography>
                                    </>
                                  ) : (
                                    <Typography className="f-12 gray7 font-weight-400 align-center d-flex">
                                      No rating available
                                    </Typography>
                                  )}
                                </Grid>
                                {candidate?.lastUpdatedTimestamp ?
                                <Typography className="d-flex align-center mt-8">
                                  <Typography className="gray7 f-14 font-weight-400">
                                    {`Last Updated: ${
                                      moment().diff(candidate?.lastUpdatedTimestamp, 'days') > 0
                                      ? moment().diff(candidate?.lastUpdatedTimestamp, 'days') > 100
                                      ? "100+ days ago"
                                      : moment().diff(candidate?.lastUpdatedTimestamp, 'days') + (moment().diff(candidate?.lastUpdatedTimestamp, 'days') === 1 ? " day " : " days ") + "ago"
                                      : "Today"}`}
                                  </Typography>
                                </Typography>
                                : ""}
                                {/* {(process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y" &&
                                candidate?.workflowUniqueIdentifier &&
                                candidate?.candidateProcessStatus != "A" &&
                                candidate?.candidateProcessStatus != "S" &&
                                candidate?.candidateProcessStatus != null) ?
                                  <Box
                                    className="f-14 font-weight-500 link-color pt-5 pb-5 pl-5 pr-5 cursor-hover secondary-bg-color border-radius-4 mt-10 fit-content"
                                    onClick={(e)=>handleOpenWorkFlowModal(e, candidate?.workflowUniqueIdentifier)}
                                  >
                                    Review Credentialing
                                  </Box> : ""} */}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Typography>
                    </Grid>
                    <Typography className="d-flex mt-20">
                    {props.status !== "matching-candidate" && (getReferredByLabel(candidate?.answers, enterpriseDetails) ? (
                      <Typography className="d-flex align-center">
                          <span className="d-flex gray7">
                            <ReferralIcon width="14px" height="14px" />
                          </span>
                          <span className="gray7 f-14 font-weight-400 pl-5">
                            {getReferredByLabel(candidate?.answers, enterpriseDetails)}
                          </span>
                          </Typography>
                      ) : (candidate?.referrerId != null) ? (
                        <Typography className="d-flex align-center">
                          <span className="d-flex gray7">
                            <ReferralIcon width="14px" height="14px" />
                          </span>
                          <span className="gray7 f-14 font-weight-400 pl-5">
                            Referred by
                          </span>
                          {(candidate?.referredFrom === "C" || candidate?.referredFrom == null) &&(
                          <span className="d-flex">
                            <a
                              className="link-color f-12 pl-5 font-weight-600 text-decoration-none font-family"
                              href={`${baseURL}/enterprise/candidate-detail/${candidate?.referrerId}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {candidate?.referrerName}
                            </a>
                          </span>)}
                          {candidate?.referredFrom === "E" && (
                                        <span
                                          className="d-flex gray8 f-12 pl-5 font-weight-600 text-decoration-none font-family"
                                        >
                                          {candidate?.referrerName} {`(${localStorage.getItem("enterpriseName")})`}
                                        </span>
                                      )}
                        </Typography>
                      ) : null)}
                    </Typography>
                  </Grid>
                  <Grid item lg={5.5} md={5.5} xs={12}>
                    <Typography>
                      <Typography className="d-flex align-center descriptionSpace justify-end responsive-layout sortby-align responsive-block galaxy-block">
                        <Typography className="d-flex align-center">
                          <Grid className="">
                            {showMatchingScore === "true" && (
                              <Typography className="d-flex align-center">
                                <Typography>
                                  {props.status !== "" && (
                                    <>
                                      <Button
                                        aria-describedby={id}
                                        role="button"
                                        onClick={(e) =>
                                          handleClick(
                                            e,
                                            props.status ===
                                              "matching-candidate"
                                              ? state
                                                ? state.candidateApplicationData
                                                : candidateApplicationData
                                              : state
                                              ? state.candidateApplicationData
                                              : candidateApplicationData
                                          )
                                        }
                                        aria-label="Matching score"
                                        disableRipple="true"
                                        className="pt-0 responsive-view-align responsive-layout sortby-align matchingScore"
                                      >
                                        <Tooltip
                                          role="tooltip"
                                          title="Matching score"
                                          placement="bottom"
                                          trigger="mouseenter"
                                          animation="fade"
                                          arrow
                                        >
                                          <Typography
                                            className="justify-end d-flex cursor-hover sort-filter-center align-center mr-5"
                                            style={{
                                              height: "65px",
                                              width: "65px",
                                            }}
                                          >
                                            <span className="matching-score-align w-100 p-5 d-flex align-center w-100">
                                              <CircularProgressbar
                                                strokeWidth={12}
                                                aria-label="Circular Progressbar"
                                                value={
                                                  props.status ===
                                                  "matching-candidate"
                                                    ? (
                                                        state
                                                          ? state
                                                              .candidateApplicationData
                                                              ?.matchingScore
                                                          : candidateApplicationData?.matchingScore
                                                      )
                                                      ? state
                                                        ? state
                                                            .candidateApplicationData
                                                            ?.matchingScore
                                                        : candidateApplicationData?.matchingScore
                                                      : 0
                                                    : (
                                                        state
                                                          ? state
                                                              .candidateApplicationData
                                                              ?.matchingScore
                                                          : candidateApplicationData?.matchingScore
                                                      )
                                                    ? state
                                                      ? state
                                                          .candidateApplicationData
                                                          ?.matchingScore
                                                      : candidateApplicationData?.matchingScore
                                                    : 0
                                                }
                                                // text={
                                                //     props.status ===
                                                //         "matching-candidate"
                                                //         ? `${Math.round(
                                                //             (
                                                //                 state
                                                //                     ? state
                                                //                         .candidateApplicationData
                                                //                         ?.matchingScore
                                                //                     : candidateApplicationData?.matchingScore
                                                //             )
                                                //                 ? state
                                                //                     ? state
                                                //                         .candidateApplicationData
                                                //                         ?.matchingScore
                                                //                     : candidateApplicationData?.matchingScore
                                                //                 : 0
                                                //         )}%`
                                                //         : `${Math.round(
                                                //             (
                                                //                 state
                                                //                     ? state
                                                //                         .candidateApplicationData
                                                //                         ?.matchingScore
                                                //                     : candidateApplicationData?.matchingScore
                                                //             )
                                                //                 ? state
                                                //                     ? state
                                                //                         .candidateApplicationData
                                                //                         ?.matchingScore
                                                //                     : candidateApplicationData?.matchingScore
                                                //                 : 0
                                                //         )}%`
                                                // }
                                                styles={buildStyles({
                                                  textColor: "#027A48",
                                                  pathColor: "#027A48",
                                                  trailColor: "#adc6ce",
                                                  textSize: "25px",
                                                })}
                                              />
                                              <span className="d-flex align-center success-color f-14 font-weight-500 ml-5">
                                                {props.status ===
                                                "matching-candidate"
                                                  ? `${Math.round(
                                                      (
                                                        state
                                                          ? state
                                                              .candidateApplicationData
                                                              ?.matchingScore
                                                          : candidateApplicationData?.matchingScore
                                                      )
                                                        ? state
                                                          ? state
                                                              .candidateApplicationData
                                                              ?.matchingScore
                                                          : candidateApplicationData?.matchingScore
                                                        : 0
                                                    )}%`
                                                  : `${Math.round(
                                                      (
                                                        state
                                                          ? state
                                                              .candidateApplicationData
                                                              ?.matchingScore
                                                          : candidateApplicationData?.matchingScore
                                                      )
                                                        ? state
                                                          ? state
                                                              .candidateApplicationData
                                                              ?.matchingScore
                                                          : candidateApplicationData?.matchingScore
                                                        : 0
                                                    )}%`}
                                              </span>
                                            </span>
                                          </Typography>
                                        </Tooltip>
                                      </Button>

                                      <Popover
                                        id={id}
                                        open={false}
                                        anchorEl={anchorEl}
                                        onClose={handleClosePopover}
                                        /*PaperProps={{
                                                                                                                                            elevation: 0,
                                                                                                                                            sx: {
                                                                                                                                                overflow: 'visible',
                                                                                                                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                                                                                                mt: 1.5,
                                                                                                                                                '& .MuiAvatar-root': {
                                                                                                                                                },
                                                                                                                                                '&:before': {
                                                                                                                                                    content: '""',
                                                                                                                                                    display: 'block',
                                                                                                                                                    position: 'absolute',
                                                                                                                                                    top: '162px',
                                                                                                                                                    right: '-5px',
                                                                                                                                                    width: 10,
                                                                                                                                                    height: 10,
                                                                                                                                                    bgcolor: 'background.paper',
                                                                                                                                                    transform: 'translateY(-50%) rotate(134deg)',
                                                                                                                                                    zIndex: 0,
                                                                                                                                                },
                                                                                                                                            },
                                                                                                                                        }}*/
                                        anchorOrigin={{
                                          vertical: "center",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "center",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Grid
                                          item
                                          lg={12}
                                          md={12}
                                          xs={12}
                                          style={{ width: "260px" }}
                                          sx={{ p: 1 }}
                                        >
                                          <Box className="align-between mb-5">
                                            <Grid item lg={12} md={12} xs={12}>
                                              <Typography
                                                align="right"
                                                className="cursor-hover sub-text-color icon-button"
                                              >
                                                <CloseIcon
                                                  onClick={handleClosePopover}
                                                  onKeyPress={
                                                    handleClosePopover
                                                  }
                                                  aria-label="Close icon"
                                                  tabIndex={0}
                                                />
                                              </Typography>
                                            </Grid>
                                          </Box>
                                          <Box className="border-bottom-1">
                                            <Box className="align-between mb-5 ">
                                              <span className="gray9 f-14 font-weight-600 pl-7">
                                                Overall Score
                                              </span>
                                              <span
                                                className="f-14 success-color font-weight-600"
                                                style={{
                                                  paddingRight: "17px",
                                                }}
                                              >
                                                {candidatePopUp
                                                  ? Math.round(
                                                      candidatePopUp.matchingScore
                                                        ? candidatePopUp.matchingScore
                                                        : 0
                                                    )
                                                  : ""}
                                                %
                                              </span>
                                            </Box>
                                          </Box>

                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            className="p-10"
                                            style={{
                                              maxHeight: "250px",
                                              overflow: "auto",
                                            }}
                                          >
                                            <Box className="mb-5 mt-10">
                                              <Typography className="align-between">
                                                <span className="gray9 f-14 font-weight-600">
                                                  Skills
                                                </span>
                                                <span className="f-14 success-color text-lowercase font-weight-600">
                                                  {candidatePopUp
                                                    ?.matchedFilters?.skills
                                                    ?.score
                                                    ? Math.round(
                                                        candidatePopUp
                                                          .matchedFilters.skills
                                                          .score
                                                      ) + "%"
                                                    : ""}
                                                </span>
                                              </Typography>
                                              <Typography className="sub-text-color f-14 mt-5 text-lowercase ">
                                                <CheckCircleIcon
                                                  className="success-color vertical-align-middle"
                                                  aria-label="Matched icon"
                                                />
                                                <span className="pl-7 font-weight-600 text-capitalize">
                                                  Matched
                                                </span>
                                                <br />
                                                <span
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                    width: "185px",
                                                  }}
                                                  className="gray9 pl-25 break-all d-flex"
                                                >
                                                  {candidatePopUp
                                                    ?.matchedFilters?.skills
                                                    ?.keyword
                                                    ? getFormattedSkills(
                                                        candidatePopUp
                                                          .matchedFilters.skills
                                                          .keyword
                                                      )
                                                    : "-"}
                                                  {/* {(candidatePopUp?.matchedFilters?.skills)?getFormattedSkills(candidatePopUp.matchedFilters.skills.keyword):"-"}
                                                                {(candidatePopUp?.matchedFilters?.skills.keyword === "")&& "-"}*/}
                                                </span>
                                              </Typography>
                                              <Typography className="sub-text-color f-14 mt-5 text-lowercase">
                                                <CheckCircleIcon
                                                  className="warn-color vertical-align-middle"
                                                  aria-label="Matched icon"
                                                />
                                                <span className="pl-7 font-weight-600 text-capitalize">
                                                  Partially Matched
                                                </span>
                                                <br />
                                                <span
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                    width: "185px",
                                                  }}
                                                  className="gray9 pl-25 break-all d-flex"
                                                >
                                                  {candidatePopUp
                                                    ?.matchedFilters
                                                    ?.partialSkills?.keyword
                                                    ? getFormattedSkills(
                                                        candidatePopUp
                                                          .matchedFilters
                                                          .partialSkills.keyword
                                                      )
                                                    : "-"}
                                                </span>
                                              </Typography>
                                              <Typography className="sub-text-color f-14 mt-5 text-lowercase">
                                                <CancelRoundedIcon
                                                  className="danger-color vertical-align-middle"
                                                  aria-label="Unmatched icon"
                                                />
                                                <span className="pl-7 font-weight-600 text-capitalize">
                                                  Unmatched
                                                </span>
                                                <br />
                                                <span
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                    width: "185px",
                                                  }}
                                                  className="gray9 pl-25 break-all d-flex"
                                                >
                                                  {candidatePopUp
                                                    ?.unmatchedFilters?.skills
                                                    ? getFormattedSkills(
                                                        candidatePopUp
                                                          .unmatchedFilters
                                                          .skills
                                                      )
                                                    : "-"}
                                                </span>
                                              </Typography>
                                            </Box>

                                            <Box className="mb-5 mt-10">
                                              <Typography className="align-between">
                                                <span className="gray9 f-14 font-weight-600">
                                                  Experience
                                                </span>
                                                <span className="f-14 success-color font-weight-600">
                                                  {preferredExp
                                                    ? ""
                                                    : candidatePopUp
                                                        ?.matchedFilters
                                                        ?.overallYearsOfExperience
                                                        ?.score
                                                    ? Math.round(
                                                        candidatePopUp
                                                          .matchedFilters
                                                          .overallYearsOfExperience
                                                          .score
                                                      ) + "%"
                                                    : candidatePopUp
                                                        ?.matchedFilters
                                                        ?.partialYearsOfExperience
                                                        ?.score
                                                    ? Math.round(
                                                        candidatePopUp
                                                          ?.matchedFilters
                                                          ?.partialYearsOfExperience
                                                          ?.score
                                                      ) + "%"
                                                    : "0%"}
                                                </span>
                                              </Typography>
                                              <Typography>
                                                <span className="sub-text-color f-14 font-weight-600">
                                                  {candidatePopUp
                                                    ?.matchedFilters
                                                    ?.overallYearsOfExperience
                                                    ? "Matched"
                                                    : candidatePopUp
                                                        ?.matchedFilters
                                                        ?.partialYearsOfExperience
                                                    ? "Partially Matched"
                                                    : "Unmatched"}
                                                </span>
                                              </Typography>
                                              <Typography>
                                                <Box className=" align-center mt-5">
                                                  {candidatePopUp
                                                    ?.matchedFilters
                                                    ?.overallYearsOfExperience ? (
                                                    <CheckCircleIcon
                                                      className="success-color vertical-align-middle"
                                                      aria-label="Matched icon"
                                                    />
                                                  ) : candidatePopUp
                                                      ?.matchedFilters
                                                      ?.partialYearsOfExperience ? (
                                                    <CheckCircleIcon
                                                      className="warn-color vertical-align-middle"
                                                      aria-label="Matched icon"
                                                    />
                                                  ) : (
                                                    <CancelRoundedIcon
                                                      className="danger-color vertical-align-middle"
                                                      aria-label="Unmatched icon"
                                                    />
                                                  )}
                                                  <span className="f-14 sub-text-color pl-7 font-weight-600">
                                                    Preferred Job Experience
                                                  </span>
                                                  <br />
                                                  {job.overallExperienceMaximum >=
                                                  1 ? (
                                                    <span className="f-14 gray9 pl-25">
                                                      {
                                                        job.overallExperienceMinimum
                                                      }
                                                      -
                                                      {
                                                        job.overallExperienceMaximum
                                                      }{" "}
                                                      Years
                                                    </span>
                                                  ) : (
                                                    <span className="f-14 gray9 pl-25">
                                                      {
                                                        job.overallExperienceMinimum
                                                      }
                                                      -
                                                      {
                                                        job.overallExperienceMaximum
                                                      }{" "}
                                                      Year
                                                    </span>
                                                  )}
                                                </Box>
                                              </Typography>
                                              <Typography>
                                                <Box className=" align-center mt-5">
                                                  {candidatePopUp
                                                    ?.matchedFilters
                                                    ?.overallYearsOfExperience ? (
                                                    <CheckCircleIcon
                                                      className="success-color vertical-align-middle"
                                                      aria-label="Matched icon"
                                                    />
                                                  ) : candidatePopUp
                                                      ?.matchedFilters
                                                      ?.partialYearsOfExperience ? (
                                                    <CheckCircleIcon
                                                      className="warn-color vertical-align-middle"
                                                      aria-label="Matched icon"
                                                    />
                                                  ) : (
                                                    <CancelRoundedIcon
                                                      className="danger-color vertical-align-middle"
                                                      aria-label="Unmatched icon"
                                                    />
                                                  )}
                                                  <span className="f-14 sub-text-color pl-7 font-weight-600">
                                                    Candidate Experience
                                                  </span>
                                                  <br />
                                                  <span className="f-14 gray9 pl-25">
                                                    {candidatePopUp
                                                      ? candidatePopUp.overallYearsOfExperience +
                                                        (candidatePopUp.overallYearsOfExperience <=
                                                        1.0
                                                          ? " Year"
                                                          : " Years")
                                                      : ""}
                                                  </span>
                                                </Box>
                                              </Typography>
                                            </Box>
                                            {(candidatePopUp?.matchedFilters
                                              ?.location ||
                                              candidatePopUp?.unmatchedFilters
                                                ?.location) && (
                                              <Box className="m-b-5 mt-10">
                                                <Typography className="align-between">
                                                  <span className="gray9 f-14 font-weight-600">
                                                    Location{" "}
                                                  </span>
                                                  <span className="f-14 success-color font-weight-600">
                                                    {candidatePopUp
                                                      ?.matchedFilters?.location
                                                      ?.score
                                                      ? Math.round(
                                                          candidatePopUp
                                                            .matchedFilters
                                                            .location.score
                                                        ) + "%"
                                                      : ""}
                                                  </span>
                                                </Typography>
                                                {/* {(props.jobs?.matchedFilters?.location?.keyword) && <Typography className="gray9 f-14 mt-5"><CheckCircleIcon style={{verticalAlign: 'middle',marginRight:5}} className='success-color vertical-align-middle'/><span className='p-l-5'>{props.jobs.matchedFilters.location.keyword}</span></Typography>} */}
                                                {/* {(props.jobs?.unmatchedFilters?.location) && <Typography className="gray9 f-14 mt-5"><CancelRoundedIcon style={{verticalAlign: 'middle',marginRight:5}} className='success-color vertical-align-middle'/><span className='p-l-5'>{props.jobs.unmatchedFilters.location}</span></Typography>} */}
                                                {candidatePopUp?.matchedFilters
                                                  ?.location?.score ? (
                                                  <Box className=" align-center mt-5">
                                                    <CheckCircleIcon
                                                      style={{
                                                        verticalAlign: "middle",
                                                        marginRight: 5,
                                                      }}
                                                      className="success-color vertical-align-middle"
                                                      aria-label="Matched icon"
                                                    />
                                                    <span className="gray9 f-14 pl-28">
                                                      {candidatePopUp?.city
                                                        ? candidatePopUp?.country
                                                          ? candidatePopUp.city +
                                                            ", " +
                                                            candidatePopUp.country
                                                          : candidatePopUp.city
                                                        : candidatePopUp?.country
                                                        ? candidatePopUp.country
                                                        : "-"}
                                                    </span>
                                                  </Box>
                                                ) : (
                                                  <>
                                                    <Box className=" align-center mt-5">
                                                      {candidatePopUp
                                                        ?.unmatchedFilters
                                                        ?.location ? (
                                                        <CancelRoundedIcon
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                            marginRight: 5,
                                                          }}
                                                          className="danger-color vertical-align-middle"
                                                          aria-label="Unmatched icon"
                                                        />
                                                      ) : (
                                                        <CheckCircleIcon
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                            marginRight: 5,
                                                          }}
                                                          className="success-color vertical-align-middle"
                                                          aria-label="Matched icon"
                                                        />
                                                      )}
                                                      <span className="f-14 sub-text-color p-l-5 font-weight-600">
                                                        Job Location
                                                      </span>
                                                      <br />
                                                      {candidatePopUp
                                                        ?.unmatchedFilters
                                                        ?.location && (
                                                        <Typography className="gray9 f-14 pl-28">
                                                          <span className="f-14 gray9 pl-25">
                                                            {candidatePopUp.unmatchedFilters.location
                                                              .split(",")
                                                              .join(", ")}
                                                          </span>
                                                        </Typography>
                                                      )}
                                                    </Box>

                                                    <Box className=" align-center mt-5">
                                                      {candidatePopUp
                                                        ?.unmatchedFilters
                                                        ?.location ? (
                                                        <CancelRoundedIcon
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                            marginRight: 5,
                                                          }}
                                                          className="danger-color vertical-align-middle"
                                                          aria-label="Unmatched icon"
                                                        />
                                                      ) : (
                                                        <CheckCircleIcon
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                            marginRight: 5,
                                                          }}
                                                          className="success-color vertical-align-middle"
                                                          aria-label="Matched icon"
                                                        />
                                                      )}
                                                      <span className="f-14 sub-text-color p-l-5 font-weight-600">
                                                        Candidate Location
                                                      </span>
                                                      <br />
                                                      {
                                                        <Typography className="gray9 f-14 pl-28">
                                                          <span className="f-14 gray9 pl-25">
                                                            {candidatePopUp?.city
                                                              ? candidatePopUp?.country
                                                                ? candidatePopUp.city +
                                                                  ", " +
                                                                  candidatePopUp.country
                                                                : candidatePopUp.city
                                                              : candidatePopUp?.country
                                                              ? candidatePopUp.country
                                                              : "-"}
                                                          </span>
                                                        </Typography>
                                                      }
                                                    </Box>
                                                  </>
                                                )}
                                              </Box>
                                            )}
                                            <Box className="mb-5 mt-10">
                                              <Typography className="align-between">
                                                <span className="gray9 f-14 font-weight-600">
                                                  Profile Title
                                                </span>

                                                {candidatePopUp?.matchedFilters
                                                  ?.title && (
                                                  <span className="f-14 success-color font-weight-600">
                                                    {preferredExp
                                                      ? ""
                                                      : candidatePopUp
                                                          ?.matchedFilters
                                                          ?.title?.score
                                                      ? Math.round(
                                                          candidatePopUp
                                                            .matchedFilters
                                                            .title.score
                                                        ) + "%"
                                                      : "0%"}
                                                  </span>
                                                )}
                                                {candidatePopUp
                                                  ?.unmatchedFilters?.title && (
                                                  <span className="f-14 success-color font-weight-600">
                                                    {preferredExp
                                                      ? ""
                                                      : candidatePopUp
                                                          ?.matchedFilters
                                                          ?.title?.score
                                                      ? Math.round(
                                                          candidatePopUp
                                                            .matchedFilters
                                                            .title.score
                                                        ) + "%"
                                                      : "0%"}
                                                  </span>
                                                )}
                                              </Typography>

                                              {candidatePopUp?.matchedFilters
                                                ?.title && (
                                                <Box className=" align-center mt-5">
                                                  <CheckCircleIcon
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: 5,
                                                    }}
                                                    className="success-color vertical-align-middle"
                                                    aria-label="Matched icon"
                                                  />
                                                  <span className="gray9 f-14 pl-28">
                                                    {
                                                      candidatePopUp
                                                        ?.matchedFilters.title
                                                        .keyword
                                                    }
                                                  </span>
                                                </Box>
                                              )}

                                              {candidatePopUp?.unmatchedFilters
                                                ?.title && (
                                                <Box className=" align-center mt-5">
                                                  <CancelRoundedIcon
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: 5,
                                                    }}
                                                    className="danger-color vertical-align-middle"
                                                    aria-label="Unmatched icon"
                                                  />
                                                  <span className="gray9 f-14 pl-28">
                                                    {candidatePopUp?.profileTitle
                                                      ? candidatePopUp.profileTitle
                                                      : "-"}
                                                  </span>
                                                </Box>
                                              )}
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Popover>
                                    </>
                                  )}
                                </Typography>
                              </Typography>
                            )}
                          </Grid>
                        </Typography>
                        {/* {props.status !== "offered" && */}
                        {(job.status === "O" || job.status === "H") && (
                        <Typography className="d-flex align-center">
                            <ActionButtons
                              isLoading={isLoading}
parentLoading={props.setIsLoading}
                              isOnboard={true}
                              setIsLoading={setIsLoading}
                              setIsScreeningQsnsLoading={props.setIsLoading}
                              candidateApplicationData={
                                candidateApplicationData
                              }
                              status={props.status}
                              candidateId={props.candidateId}
                              JobId={props.JobId}
                              getCandidatesByStatus={
                                props.getCandidatesByStatus
                              }
                              getMatchingCandidates={
                                props.getMatchingCandidates
                              }
                              updatedstatus={props.updatedstatus}
                              closeMatchingCandidateDrawer={() => {
                                setIsDrawerVisible(true);
                                props.setCanduadteDrawer(false);
                                if (props.setOptionValue) {
                                  props.setOptionValue("");
                                }
                                }
                              }
                              allowedToWorkInOtherJobs={
                                job?.allowedToWorkInOtherJobs
                              }
                              jobDetails={job}
                              isFromDrawer={true}
setIsDrawerVisible={setIsDrawerVisible}
                            />
                          <Typography>
                            {(((answers.length > 0 && answers !== null) ||
                              (candidateApplicationData?.candidateSkillChecklistResponse &&
                                candidateApplicationData?.candidateSkillChecklistResponse !==
                                  null &&
                                candidateApplicationData
                                  ?.candidateSkillChecklistResponse.status !==
                                  "D") ||
                              candidateApplicationData.offerAcceptedDocumentUrlSigned?.length >0
                                ||
                              candidateApplicationData.offerDocumentUrlSigned?.length >0
                                ||
                              candidateApplicationData.offerAcceptedDocumentUrlSigned?.length >0
                                ||
                              candidateApplicationData.offerDocumentUrlSigned?.length >0
                                ||
                              (pitchVideo !== null && pitchVideo !== "") ||
                              props.status === "shortlisted" ||
                              props.status === "matching-candidate") &&
                              (props.status === "matching-candidate" ||
                                props.status === "shortlisted" ||
                                props.status === "applied" ||
                                props.status === "offered" ||
                                props.status === "onboarded" ||
                                props.status === "completed-cancelled") || (candidate?.talentPool === "Z" && user_type !== "S")) && (
                                <Typography className="responsive-contents">
                                  <Stack direction="row" spacing={2}>
                                    <div className="d-grid">
                                      <Button
                                        ref={anchorRef}
                                        // id="composition-button"
                                        aria-controls={
                                          openStatus
                                            ? "composition-menu"
                                            : undefined
                                        }
                                        aria-expanded={
                                          openStatus ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        aria-label="Menu icon"
                                        disableRipple="true"
                                        role="button"
                                      >
                                        <Typography className="secondary-bg-color border-radius-4 p-10 d-flex">
                                          <ActionIcon
                                            height="20px"
                                            width="20px"
                                            className="svg-icons experience-cont cursor-hover f-25"
                                            aria-label="Menu icon"
                                          />
                                        </Typography>
                                      </Button>
                                      <Popper
                                        style={{ zIndex: "9" }}
                                        open={openStatus}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        placement="bottom-end"
                                        transition
                                        disablePortal
                                        className="options-align"
                                      >
                                        {({ TransitionProps, placement }) => (
                                          <Grow
                                            {...TransitionProps}
                                            style={{
                                              transformOrigin:
                                                placement === "bottom-start"
                                                  ? "left top"
                                                  : "left bottom",
                                            }}
                                          >
                                            <Paper>
                                              <ClickAwayListener
                                                onClickAway={handleCloseStatus}
                                                tabIndex={0}
                                                onKeyPress={handleCloseStatus}
                                              >
                                                <MenuList
                                                  autoFocusItem={openStatus}
                                                  id="composition-menu"
                                                  aria-labelledby="composition-button"
                                                  onKeyDown={handleListKeyDown}
                                                >
                                                {(candidate?.talentPool === "Z" && user_type !== "S") &&
                                                <MenuItem
                                                  onClick={(e) => goToEditCandidate(e, candidate)}
                                                  onKeyPress={(e) => goToEditCandidate(e, candidate)}
                                                >
                                                  <Typography className="gray9 f-12 font-weight-400">
                                                    <span>Edit Candidate Details</span>
                                                  </Typography>
                                                </MenuItem>}
                                                {props.status ===
                                                    "shortlisted" && (<MenuItem
                                                      onClick={handleOpenShortlistEmailPopup}
                                                    >
                                                      <Typography className="gray9 f-12 font-weight-400">
                                                        <span>Send Shortlist Email</span>
                                                      </Typography>
                                                    </MenuItem>)}
                                                  {props.status ===
                                                    "shortlisted" && toShowInterview &&(
                                                    <MenuItem
                                                      onClick={goToScheduleInterview}
                                                    >
                                                      <Typography className="gray9 f-12 font-weight-400">
                                                        <span>Schedule Interview</span>
                                                      </Typography>
                                                    </MenuItem>
                                                  )}
                                                  {props.status ===
                                                    "shortlisted" && (
                                                    <MenuItem
                                                      onClick={() =>
                                                        unshortListHandler()
                                                      }
                                                    >
                                                      <Typography className="gray9 f-12 font-weight-400">
                                                        <span>Unshortlist</span>
                                                      </Typography>
                                                    </MenuItem>
                                                  )}
                                                  {props.status ===
                                                    "matching-candidate" && (
                                                    <MenuItem
                                                      onClick={
                                                        handleHideCandidate
                                                      }
                                                      onKeyPress={
                                                        handleHideCandidate
                                                      }
                                                    >
                                                      <Typography className="gray9 f-12 font-weight-400">
                                                        <span>Hide</span>
                                                      </Typography>
                                                    </MenuItem>
                                                  )}
                                                  <>
                                                    {(props.status ===
                                                      "applied" ||
                                                      props.status ===
                                                        "offered" ||
                                                      props.status ===
                                                        "onboarded" ||
                                                      props.status ===
                                                        "completed-cancelled") &&
                                                      (candidateApplicationData.offerAcceptedDocumentUrlSigned?.length >0
                                                        // !==null
                                                        ||
                                                        candidateApplicationData.offerDocumentUrlSigned?.length >0
                                                        // !==null
                                                      ) && (
                                                        <>
                                                          <MenuItem>
                                                            <Typography
                                                              className="gray9 f-12 font-weight-400"
                                                              onClick={() =>
                                                                handleOpenDocument(
                                                                  true
                                                                )
                                                              }
                                                              onKeyPress={() =>
                                                                handleOpenDocument(
                                                                  true
                                                                )
                                                              }
                                                              tabIndex={0}
                                                            >
                                                              <span>
                                                                View Document
                                                              </span>
                                                            </Typography>
                                                          </MenuItem>
                                                        </>
                                                      )}
                                                  </>
                                                  <>
                                                    {(props.status !== "matching-candidate") &&
                                                      answers.length > 0 &&
                                                      answers !== null && (
                                                        <MenuItem>
                                                          <Typography
                                                            onClick={
                                                              handleOpenAssessment
                                                            }
                                                            className="gray9 f-12 font-weight-400"
                                                            vaiant="h5"
                                                            onKeyPress={
                                                              handleOpenAssessment
                                                            }
                                                            tabIndex={0}
                                                          >
                                                            View Screening
                                                            Response
                                                          </Typography>
                                                        </MenuItem>
                                                      )}
                                                  </>
                                                  <>
                                                    {pitchVideo !== null &&
                                                      pitchVideo !== "" && (
                                                        <MenuItem>
                                                          <Typography
                                                            onClick={
                                                              handleOpenPitchVideoPreview
                                                            }
                                                            className="gray9 f-12 font-weight-400"
                                                            vaiant="h5"
                                                            onKeyPress={
                                                              handleOpenPitchVideoPreview
                                                            }
                                                            tabIndex={0}
                                                          >
                                                            View Video Pitch
                                                          </Typography>
                                                        </MenuItem>
                                                      )}
                                                  </>
                                                  <>
                                                    {(props.status ===
                                                      "applied" ||
                                                      props.status ===
                                                        "offered" ||
                                                      props.status ===
                                                        "onboarded" ||
                                                      props.status ===
                                                        "completed-cancelled") &&
                                                      candidate?.candidateSkillChecklistResponse &&
                                                      candidate?.candidateSkillChecklistResponse !==
                                                        null &&
                                                      candidate
                                                        ?.candidateSkillChecklistResponse
                                                        .status !== "D" && (
                                                        <MenuItem>
                                                          <Box
                                                            onClick={() =>
                                                              setShowChecklistOverlay(
                                                                true
                                                              )
                                                            }
                                                            className="gray9 f-12 font-weight-400"
                                                            tabIndex={0}
                                                            onKeyPress={() =>
                                                              setShowChecklistOverlay(
                                                                true
                                                              )
                                                            }
                                                          >
                                                            View Checklist
                                                          </Box>
                                                        </MenuItem>
                                                      )}
                                                  </>
                                                </MenuList>
                                              </ClickAwayListener>
                                            </Paper>
                                          </Grow>
                                        )}
                                      </Popper>
                                    </div>
                                  </Stack>
                                </Typography>
                              )}
                          </Typography>
                        </Typography>
                        )}
                        {/* } */}
                        <Typography>
                          <Typography className="d-flex">
                            <Grid item>
                              {job.status === "O" && (
                                <ActionButtons
                                  isLoading={isLoading}
                                  isOnboard={true}
                                  setIsLoading={setIsLoading}
                                  setIsScreeningQsnsLoading={props.setIsLoading}
                                  candidateApplicationData={
                                    candidateApplicationData
                                  }
                                  allowedToWorkInOtherJobs={
                                    job?.allowedToWorkInOtherJobs
                                  }
                                  jobDetails={job}
                                  isFromDrawer={true}
                                />
                              )}
                            </Grid>
                          </Typography>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                { candidate?.enterpriseProcessStatus === "F" &&
                  candidate?.candidateProcessStatus === "D" &&
                  candidate?.declineRemarks !== null && (
                  <Grid className="mt-10 mb-20 p-10 secondary-bg-color offer-decline-status ml-20 border-radius-8 d-flex align-center">
                      <ReasonIcon className="mr-10" width="20px" height="20px" />
                      <span className="gray7 f-15 font-weight-400 ">
                        Reason: {candidate?.declineRemarks}
                      </span>
                    </Grid>
                  )}
                <Grid item>
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={valueTabs}>
                      <Box className="bg-white  profile-tabs matching-drawer-tab">
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          <Tab label="Profile" value="basic info" />
                          <Tab label="Reviews" value="Reviews" />
                          <Tab label="Notes" value="Notes" />
                          <Tab label="Timeline" value="Timeline" />
                          <Tab label="Documents" value="Download" />
                          <Tab label="Project Details" value="ProjectDetails" />
                        </TabList>
                      </Box>
                      <TabPanel value="basic info" className="p-0 mt-20">
                        <Grid item className="border-radius-7">
                          <Grid item className="mt-10">
                            <Grid item>
                              <Grid
                                item
                                className="bg-white p-20 d-flex notification-align responsive-tabs galaxy-block"
                                xs={12}
                                md={12}
                                lg={12}
                              >
                                <Grid item lg={3.5} md={3.5} xs={12}>
                                  <Typography className="d-flex">
                                    <Typography>
                                      <img
                                        src={hourlyRateIcon}
                                        alt="hourly rate"
                                      />
                                    </Typography>
                                    <Typography className="pl-10">
                                      <Box className="gray7 f-14 font-weight-400 m-b-5">
                                        Pay Rate
                                      </Box>
                                      <Typography className="f-16 gray11 font-weight-400">
                                        {/* <Box className="align-item-center">
                                          <span className="f-16 gray11 font-weight-400 p-r-1">
                                            {candidate.currency
                                              ? `${candidate.currency}`
                                              : "USD"}
                                          </span>
                                          <span className="f-16 gray11 font-weight-400">
                                            &nbsp;{candidate?.ratePerHour}
                                          </span>
                                          <span className="f-16 gray11 font-weight-400">
                                            &nbsp;/ hour
                                          </span>
                                        </Box> */}
                                        {candidate?.ratePerHour
                                          ? `${
                                              candidate?.currency
                                                ? candidate?.currency
                                                : "USD"
                                            } ${candidate?.ratePerHour} / hour`
                                          : "-"}
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  xs={12}
                                  className="jobHeader-align"
                                >
                                  <Typography className="d-flex">
                                    <Typography>
                                      <img
                                        src={overExpIcon}
                                        alt="hourly rate"
                                      />
                                    </Typography>
                                    <Typography className="ml-10">
                                      <Typography className="gray7 f-14 font-weight-400 m-b-5">
                                        Total Experience
                                      </Typography>
                                      {candidate.overallYearsOfExperience >=
                                      1 ? (
                                        <Typography
                                          // variant="h4"
                                          className="f-16 gray11 font-weight-400"
                                        >
                                          <span className="">
                                            {
                                              candidate?.overallYearsOfExperience
                                            }
                                          </span>{" "}
                                          <span> Years</span>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          // variant="h4"
                                          className="f-16 gray11 font-weight-400"
                                        >
                                          <span className="">
                                            {
                                              candidate?.overallYearsOfExperience
                                            }
                                          </span>{" "}
                                          <span> Year </span>
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4.5}
                                  md={4.5}
                                  xs={12}
                                  className="jobHeader-align"
                                >
                                  <Typography className="d-flex">
                                    <Typography>
                                      <img
                                        src={WorkplaceIcon}
                                        alt="workplace"
                                      />
                                    </Typography>
                                    <Typography className="ml-10">
                                      <Box className="gray7 f-14 font-weight-400 m-b-5">
                                        Workplace
                                      </Box>
                                      <Typography
                                        className={
                                          workPlace
                                            ? "f-16 gray11 font-weight-400"
                                            : "f-16 gray11 font-weight-400 ml-5"
                                        }
                                      >
                                        {candidate?.workPlace
                                          ? getFormattedWorkplace(
                                              candidate?.workPlace
                                            )
                                          : "-"}
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  xs={12}
                                  className="jobHeader-align"
                                >
                                  <Typography className="d-flex">
                                    <Typography>
                                      <img src={WorkTypeIcon} alt="WorkType" />
                                    </Typography>
                                    <Typography className="ml-10">
                                      <Box className="gray7 f-14 font-weight-400 m-b-5">
                                        Work Type
                                      </Box>
                                      <Typography
                                        className={
                                          workType
                                            ? "f-16 gray11 font-weight-400"
                                            : "f-16 gray11 font-weight-400 ml-5"
                                        }
                                      >
                                        {workType
                                          ? getFormattedWorkType(
                                              workType,
                                              false
                                            )
                                          : "-"}
                                        {workType
                                          ?.replace(/\s|\[|\]/g, "")
                                          ?.split(",")?.length > 1 && (
                                          <Typography
                                            className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                            onClick={(e) =>
                                              handleOpenJobType(e)
                                            }
                                            tabIndex={0}
                                            onKeyPress={(e) =>
                                              handleOpenJobType(e)
                                            }
                                          >
                                            {`+${
                                              workType
                                                ?.replace(/\s|\[|\]/g, "")
                                                ?.split(",")?.length - 1
                                            } more`}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                              {appOrigin === "H" ? (
                                <>
                                  <Grid
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="bg-white p-20 d-flex galaxy-block responsive-tabs"
                                  >
                                    <Grid item lg={3.5} md={3.5} xs={12}>
                                      <Typography className="d-flex">
                                        <Typography className="hc-startDate-icon">
                                          <img
                                            src={startDateIcon}
                                            alt="startDateIcon"
                                          />
                                        </Typography>
                                        <Typography className="pl-10">
                                          <Box className="gray7 f-14 font-weight-400 m-b-5">
                                            Start Date
                                          </Box>
                                          <Typography>
                                            <Box
                                              className={
                                                candidate?.idealStartDate
                                                  ? "f-16 gray11 font-weight-400"
                                                  : "f-16 gray11 font-weight-400 ml-5"
                                              }
                                            >
                                              {candidate?.idealStartDate
                                                ? moment(
                                                    candidate?.idealStartDate
                                                  ).format("MMM DD, YYYY")
                                                : "-"}
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} xs={12}>
                                      <Typography className="d-flex">
                                        <Typography>
                                          <img
                                            src={shiftPreferenceIcon}
                                            alt="shiftPreferenceIcon"
                                          />
                                        </Typography>
                                        <Typography className="pl-10">
                                          <Box className="gray7 f-14 font-weight-400 m-b-5">
                                            Shift Preferences
                                          </Box>
                                          <Typography>
                                            <Box
                                              className={
                                                candidate?.preferredShift
                                                  ? "f-16 gray11 font-weight-400"
                                                  : "f-16 gray11 font-weight-400 ml-5"
                                              }
                                            >
                                              {candidate?.preferredShift
                                                ? getFormattedShift(
                                                    candidate?.preferredShift
                                                  )
                                                : "-"}
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={4.5} md={4.5} xs={12}>
                                      <Typography className="d-flex">
                                        <Typography className="edu-icon">
                                          <img
                                            src={disciplineIcon}
                                            alt="disciplineIcon"
                                          />
                                        </Typography>
                                        <Typography className="pl-10">
                                          <Box className="gray7 f-14 font-weight-400 m-b-5">
                                            Discipline
                                          </Box>
                                          <Typography>
                                            <Box className="f-16 gray11 font-weight-400">
                                              {candidate?.skills?.length > 0
                                                ? candidate?.skills[0].skills
                                                    .subcategoryKeyword
                                                : candidate?.candidateSkills
                                                    ?.length > 0
                                                ? candidate?.candidateSkills[0]
                                                    .skills.subcategoryKeyword
                                                : "-"}
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} xs={12}>
                                      <Typography className="d-flex">
                                        <Typography className="specialization-icon">
                                          <img
                                            src={specializationIcon}
                                            alt="specializationIcon"
                                          />
                                        </Typography>
                                        <Typography className="pl-10">
                                          <Box className="gray7 f-14 font-weight-400 m-b-5">
                                            Specialization
                                          </Box>
                                          <Typography>
                                            <Box className="f-16 gray11 font-weight-400">
                                              {specializationString
                                                ? specializationString.split(
                                                    ","
                                                  )[0]
                                                : "-"}
                                              {specializationString?.split(",")
                                                ?.length > 1 && (
                                                <Typography
                                                  className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                                  onClick={(e) =>
                                                    handleOpenSpecialization(e)
                                                  }
                                                  tabIndex={0}
                                                  onKeyPress={(e) =>
                                                    handleOpenSpecialization(e)
                                                  }
                                                >
                                                  {`+${
                                                    specializationString?.split(
                                                      ","
                                                    )?.length - 1
                                                  } more`}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="bg-white p-20 d-flex galaxy-block responsive-tabs"
                                  >
                                    <Grid item lg={3.5} md={3.5} xs={12}>
                                      <Typography className="d-flex">
                                        <Typography className="specialization-icon">
                                          <img
                                            src={jobPreferenceIcon}
                                            alt="jobPreferenceIcon"
                                          />
                                        </Typography>
                                        <Typography className="pl-10">
                                          <Box className="gray7 f-14 font-weight-400 m-b-5">
                                            Job Preferences
                                          </Box>
                                          <Typography>
                                            <Box
                                              className={
                                                candidate?.jobPreferences
                                                  ? "f-16 gray11 font-weight-400"
                                                  : "f-16 gray11 font-weight-400 ml-5"
                                              }
                                            >
                                              {candidate?.jobPreferences
                                                ? candidate?.jobPreferences.split(
                                                    ","
                                                  )[0]
                                                : "-"}
                                              {candidate?.jobPreferences?.split(
                                                ","
                                              )?.length > 1 && (
                                                <Typography
                                                  className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                                                  onClick={(e) =>
                                                    handleOpenJobPreference(e)
                                                  }
                                                  tabIndex={0}
                                                  onKeyPress={(e) =>
                                                    handleOpenJobPreference(e)
                                                  }
                                                >
                                                  {`+${
                                                    candidate?.jobPreferences?.split(
                                                      ","
                                                    )?.length - 1
                                                  } more`}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3.5} md={3.5} xs={12}>
                                      <Typography className="d-flex">
                                        <Typography>
                                          <img
                                            src={nueroIcon}
                                            alt="hourly rate"
                                          />
                                        </Typography>
                                        <Typography className="pl-10">
                                          <Box className="gray7 f-14 font-weight-400 m-b-5">
                                            Neurodistinct Preference{" "}
                                          </Box>
                                          <Typography>
                                            <Box className="align-item-center">
                                              <span className="f-16 gray11 font-weight-400">
                                                {candidate.neuroClassification ===
                                                "ND"
                                                  ? "Neurodistinct Jobs Only"
                                                  : "None"}
                                              </span>
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9} xs={12}></Grid>
                                  </Grid>
                                </>
                              ) : (
                                ""
                                // <Grid
                                //   item
                                //   className="bg-white p-20 d-flex notification-align responsive-tabs galaxy-block"
                                //   xs={12}
                                //   md={12}
                                //   lg={12}
                                // >
                                //   <Grid item lg={3.5} md={3.5} xs={12}>
                                //     <Typography className="d-flex">
                                //       <Typography>
                                //         <img
                                //           src={nueroIcon}
                                //           alt="hourly rate"
                                //         />
                                //       </Typography>
                                //       <Typography className="pl-10">
                                //         <Box className="gray7 f-14 font-weight-400 m-b-5">
                                //           Neurodistinct Preference{" "}
                                //         </Box>
                                //         <Typography>
                                //           <Box>
                                //             <span className="f-16 gray11 font-weight-400">
                                //               {candidate.neuroClassification ===
                                //               "ND"
                                //                 ? "Neurodistinct Jobs Only"
                                //                 : "None"}
                                //             </span>
                                //           </Box>
                                //         </Typography>
                                //       </Typography>
                                //     </Typography>
                                //   </Grid>
                                // </Grid>
                              )}
                              <ContactInfo candidate={candidateAllDetail}/>
                              {!(
                                (candidate?.mobileNumber === "" ||
                                  candidate?.mobileNumber === null) &&
                                (candidate?.emailAddress === "" ||
                                  candidate?.emailAddress === null)
                              ) && <Grid></Grid>}

                              <Grid
                                  item
                                  className="bg-white p-20"
                                  xs={12}
                                  lg={12}
                                  md={12}
                                >
                                  <Typography
                                    variant="h5"
                                    className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
                                  >
                                    Personal Info
                                  </Typography>
                                  <Grid container spacing={1} lg={12} md={12} xs={12} className="mt-5">
                                    <Grid item lg={3} md={3} xs={12} className="mt-10">
                                      <Typography className="gray7 f-14 font-weight-400">
                                        Military Status
                                      </Typography>
                                      <Typography className="gray9 f-16 font-weight-400">
                                        {candidate?.militaryStatus ? candidate?.militaryStatus : "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={3} xs={12} className="mt-10">
                                      <Typography className="gray7 f-14 font-weight-400">
                                        Known As
                                      </Typography>
                                      <Typography className="gray9 f-16 font-weight-400">
                                        {candidate?.knownAs ? candidate?.knownAs : "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={3} xs={12} className="mt-10">
                                      <Typography className="gray7 f-14 font-weight-400">
                                        Wage Expectation
                                      </Typography>
                                      <Typography className="gray9 f-16 font-weight-400">
                                        {candidate?.wageExpectationCategory ? wageExpectations[candidate?.wageExpectationCategory] : "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={3} xs={12} className="mt-10">
                                      <Typography className="gray7 f-14 font-weight-400">
                                        Address
                                      </Typography>
                                      <Typography className="gray9 f-16 font-weight-400">
                                        {candidate?.address ? candidate?.address : "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={3} xs={12} className="mt-10">
                                      <Typography className="gray7 f-14 font-weight-400">
                                        Profile Created On
                                      </Typography>
                                      <Typography className="gray9 f-16 font-weight-400">
                                        {candidate?.createdTimestamp ? moment(candidate?.createdTimestamp).format("MMM DD, YYYY") : "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                              </Grid>
                              <Grid
                                item
                                className="bg-white p-20"
                                xs={12}
                                md={12}
                                lg={12}
                              >
                                <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                  Profile Summary
                                </Typography>
                                <Typography className="pt-10 gray9 f-14 break-word">
                                  {showMore || candidate?.brief?.length <= 100
                                    ? candidate?.brief
                                    : `${
                                        candidate?.brief
                                          ? candidate?.brief.substring(0, 100) +
                                            "..."
                                          : ""
                                      }`}
                                  {(candidate.brief?.length
                                    ? candidate.brief?.length
                                    : 0) > 100 && (
                                    <Link
                                      className="link-color cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                                      onClick={() => setShowMore(!showMore)}
                                      tabIndex={0}
                                      onKeyPress={() => setShowMore(!showMore)}
                                    >
                                      {" "}
                                      <span>
                                        &nbsp;
                                        {showMore ? " view less" : " view more"}
                                      </span>
                                    </Link>
                                  )}
                                  {(candidate.brief === "" ||
                                    candidate.brief === null) && (
                                    <Typography className="f-14 gray9">
                                      No profile summary added yet.
                                    </Typography>
                                  )}
                                </Typography>
                              </Grid>
                              {/* )} */}

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="bg-white p-20"
                              >
                                <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                  Work Experience
                                </Typography>

                                <>
                                  {candidate.workHistory?.length > 0 ? (
                                    candidate.workHistory?.map(
                                      (workhistory, index) => {
                                        return (
                                          <div key={index} className="mt-5">
                                            <Grid
                                              item
                                              lg={12}
                                              md={12}
                                              xs={12}
                                              className="mt-20 d-flex justify-between px-5"
                                            >
                                              <Typography className="d-flex">
                                                <Box>
                                                  <img
                                                    width="45"
                                                    height="45"
                                                    src={workLogo}
                                                    alt="img"
                                                  />
                                                  {/* <img style={{width:'65%'}} src={workLogo} /> */}
                                                </Box>
                                                <Box className="px-5 mt-5 pl-10">
                                                  <Typography
                                                    variant="h4"
                                                    className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy"
                                                  >
                                                    {workhistory.title}
                                                  </Typography>
                                                  <span className="mt-0 mb-0 f-12 gray7 font-weight-500 WorkExperienceBullet">
                                                    {workhistory?.workedWith}
                                                    {workhistory?.workedWith ? (
                                                      <span>
                                                        <FiberManualRecordIcon aria-label="Dot icon" />{" "}
                                                      </span>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                  </span>
                                                  <span className="f-12 gray7 WorkExperienceBullet">
                                                    {moment(
                                                      workhistory?.workedFrom
                                                    ).format(
                                                      "MMM DD, YYYY"
                                                    )}{" "}
                                                    -{" "}
                                                    {workhistory?.workedTill ===
                                                      "1900-01-01" ||
                                                    workhistory?.workedTill ===
                                                      null
                                                      ? "Present"
                                                      : moment(
                                                          workhistory?.workedTill
                                                        ).format(
                                                          "MMM DD, YYYY"
                                                        )}{" "}
                                                    <FiberManualRecordIcon aria-label="Dot icon" />{" "}
                                                    {workDuration(
                                                      workhistory?.workedFrom,
                                                      workhistory.workedTill
                                                    )}
                                                  </span>
                                                  <Typography className="pt-10 gray9 f-14 break-word">
                                                    {showMoreWorkSummary[index] ||
                                                    workhistory?.summaryOfWork
                                                      ?.length <= 100
                                                      ? workhistory?.summaryOfWork
                                                      : `${
                                                          workhistory?.summaryOfWork
                                                            ? workhistory?.summaryOfWork.substring(
                                                                0,
                                                                100
                                                              ) + "..."
                                                            : ""
                                                        }`}
                                                    {(workhistory?.summaryOfWork
                                                      ?.length
                                                      ? workhistory?.summaryOfWork
                                                          ?.length
                                                      : 0) > 100 && (
                                                      <Link
                                                        className="link-color cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                                                        onClick={() => {
                                                          let _showMoreWorkSummary = [...showMoreWorkSummary];
                                                          _showMoreWorkSummary[index] = !_showMoreWorkSummary[index];
                                                          setShowMoreWorkSummary(_showMoreWorkSummary);
                                                        }}
                                                        tabIndex={0}
                                                        onKeyPress={() => {
                                                          let _showMoreWorkSummary = [...showMoreWorkSummary];
                                                          _showMoreWorkSummary[index] = !_showMoreWorkSummary[index];
                                                          setShowMoreWorkSummary(_showMoreWorkSummary);
                                                        }}
                                                      >
                                                        {" "}
                                                        <span>
                                                          &nbsp;{" "}
                                                          {showMoreWorkSummary[index]
                                                            ? " view less"
                                                            : " view more"}
                                                        </span>
                                                      </Link>
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={12}
                                              md={12}
                                              xs={12}
                                              className="mt-10 gray7 px-5"
                                            >
                                              <Box>
                                                {workhistory
                                                  .candidateWorkHistoryShowcasesDto
                                                  .length > 0 && (
                                                  <span className="f-12">
                                                    Documents
                                                  </span>
                                                )}
                                                <ul className="pl-15 mt-10 mb-0">
                                                  {workhistory?.candidateWorkHistoryShowcasesDto.map(
                                                    (show, index) => {
                                                      return (
                                                        <li key={index}>
                                                          <a
                                                            className="small-text-font sub-text-color mt-10 break-word"
                                                            href={
                                                              show.showcaseSignedUrl
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                          >
                                                            {show?.showcaseSignedUrl &&
                                                              decodeURIComponent(
                                                                show?.showcaseSignedUrl
                                                                  ?.split("/")
                                                                  .pop()
                                                                  .split("?")[0]
                                                              )}
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </Box>
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 gray9 mt-10">
                                      No work experience details added.
                                    </Typography>
                                  )}
                                </>

                                <>
                                  {/*<Grid item className="mt-20 d-flex align-between" style={{display:'none'}}>
                                    <Typography className="d-flex" style={{display:'none'}}>
                                        <Box>
                                            <img style={{ width: '45px' }} src={microsoftLogo} />
                                        </Box>
                                        <Box className="pl-10 mt-5">
                                            <Typography variant="h4" className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy">
                                                Software Developer
                                            </Typography>
                                            <Typography className="f-14 gray9">
                                                Microsoft
                                            </Typography>
                                            <span className="f-12 sub-text-color align-center d-flex mt-5">Jan 2021 - Present <FiberManualRecordIcon className="px-5" /> 6 months</span>
                                        </Box>
                                    </Typography>
                                </Grid>*/}
                                </>
                              </Grid>
                              {(appOrigin === "D" ||
                                appOrigin === "N") && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="bg-white p-20"
                                  >
                                    <Skills
                                      skills={
                                        candidate.skills
                                          ? candidate.skills
                                          : candidate.candidateSkills
                                      }
                                    />
                                  </Grid>
                                )}

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="bg-white p-20"
                              >
                                <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                  Skill Checklist Submissions
                                </Typography>
                                <Grid>
                                  {candidateChecklist &&
                                  candidateChecklist.length > 0 ? (
                                    candidateChecklist.map(
                                      (checkListData, index) => {
                                        return (
                                          <Grid
                                            className="justify-between mt-10"
                                            key={index}
                                          >
                                            <Grid className="d-flex">
                                              <Typography className="m-r-10">
                                                <img
                                                  src={checklistIndustry}
                                                  alt="img"
                                                />
                                              </Typography>
                                              <Typography>
                                                <Box className="font-weight-500 f-16 gray11">
                                                  {
                                                    checkListData.checkList
                                                      .checkListName
                                                  }
                                                </Box>
                                                <Box>
                                                  <Link
                                                    onClick={() => {
                                                      setSelectedCandidateChecklist(
                                                        checkListData
                                                      );
                                                      setShowCandidateChecklistOverlay(
                                                        true
                                                      );
                                                    }}
                                                    className="link-color f-14 font-weight-400 pr-10 text-decoration-none m-t-5"
                                                    tabIndex={0}
                                                    onKeyPress={() => {
                                                      setSelectedCandidateChecklist(
                                                        checkListData
                                                      );
                                                      setShowCandidateChecklistOverlay(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    View Checklist
                                                  </Link>
                                                </Box>
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 light-text mt-10">
                                      {"No skill checklist added."}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="bg-white p-20"
                              >
                                <Typography
                                  className="font-weight-600 f-16 gray9 border-bottom-1 pb-10"
                                >
                                  What shift are you willing to work?
                                </Typography>
                                <Typography className="font-weight-500 f-16 gray9 pt-10">
                                  {getCandidateShiftTimings(candidate?.shiftStartTime, candidate?.shiftEndTime, candidate?.shiftTimezone)}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="bg-white p-20"
                              >
                                <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                  Licensure
                                </Typography>
                                <>
                                  {candidate.candidateLicenceDtos?.length >
                                  0 ? (
                                    candidate.candidateLicenceDtos?.map(
                                      (license, index) => {
                                        return (
                                          <div key={index}>
                                            <Grid
                                              item
                                              lg={12}
                                              md={12}
                                              xs={12}
                                              className="mt-20 d-flex justify-between"
                                            >
                                              <Typography className="d-flex">
                                                <Box>
                                                  <img
                                                    style={{
                                                      width: "45px",
                                                    }}
                                                    src={licensure}
                                                    alt="img"
                                                  />
                                                  {/* <img style={{width:'65%'}} src={workLogo} /> */}
                                                </Box>
                                                <Box className="mt-5 pl-10">
                                                  <Typography
                                                    variant="h4"
                                                    className={`font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy
                                                        ${
                                                          license.expirationDate &&
                                                          license.status !==
                                                            "P" &&
                                                          !moment(
                                                            license.expirationDate
                                                          )
                                                            .add(1, "days")
                                                            .isAfter(
                                                              moment().format(
                                                                "MMM DD, YYYY"
                                                              ),
                                                              "day"
                                                            )
                                                            ? " danger-color"
                                                            : ""
                                                        }
                                                        `}
                                                  >
                                                    {license.status === "P"
                                                      ? "License Applied"
                                                      : license.lincenseNumber
                                                      ? license.lincenseNumber
                                                      : "-"}
                                                  </Typography>
                                                  <p className="small-text-font mt-0 mb-0">
                                                    {license.status !== "P"
                                                      ? translateLicenseStatus(
                                                          license.status
                                                        ) + " |"
                                                      : ""}{" "}
                                                    {translateLicenseType(
                                                      license.type
                                                    )}
                                                  </p>

                                                  <Typography>
                                                    <span className="sub-text-color f-12 font-weight-400">
                                                      {license.licenseState}
                                                      {license.expirationDate &&
                                                        license.dateIssued &&
                                                        license.status !==
                                                          "P" && (
                                                          <>
                                                            <FiberManualRecordIcon
                                                              className="px-5 pt-10"
                                                              aria-label="Dot icon"
                                                            />{" "}
                                                            {moment(
                                                              license.dateIssued
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}{" "}
                                                            -{" "}
                                                            {moment(
                                                              license.expirationDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}
                                                          </>
                                                        )}
                                                    </span>
                                                  </Typography>
                                                </Box>
                                              </Typography>
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 gray9 mt-10">
                                      {"No license details added."}
                                    </Typography>
                                  )}
                                </>
                              </Grid>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="bg-white p-20"
                              >
                                <Grid item lg={12} md={12} xs={12} className="">
                                  <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                    References
                                  </Typography>
                                </Grid>
                                <>
                                  {candidate.referencesDtos?.length > 0 ? (
                                    candidate.referencesDtos?.map(
                                      (reference, index) => {
                                        return (
                                          <div key={index}>
                                            <Grid
                                              item
                                              lg={12}
                                              md={12}
                                              xs={12}
                                              className="mt-20 d-flex justify-between"
                                            >
                                              <Typography className="d-flex">
                                                <Box>
                                                  <img
                                                    style={{
                                                      width: "45px",
                                                    }}
                                                    src={references}
                                                    alt="img"
                                                  />
                                                  {/* <img style={{width:'65%'}} src={workLogo} /> */}
                                                </Box>
                                                <Box
                                                  className="pl-10"
                                                  style={{
                                                    marginTop: "6px",
                                                  }}
                                                >
                                                  <Typography
                                                    variant="h4"
                                                    className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy"
                                                  >
                                                    {
                                                      reference.referenceFullName
                                                    }
                                                  </Typography>
                                                  <p className="small-text-font mt-0 mb-5 sub-text-color textEllipse w-300">
                                                    {
                                                      reference.referenceOrganization
                                                    }
                                                  </p>
                                                  <Grid item>
                                                    {
                                                      <Typography className="d-flex align-center">
                                                        <img
                                                          className="f-14"
                                                          src={cellIcon}
                                                          alt="img"
                                                        />
                                                        <span className="f-14 gray9 pl-10">
                                                          {
                                                            reference.referenceMobileNumber
                                                          }
                                                        </span>
                                                      </Typography>
                                                    }
                                                    <Typography className="d-flex align-center galaxy-block responsive-block">
                                                      <Typography className="d-flex align-center">
                                                        <MailOutlineIcon
                                                          className="f-14 sub-text-color"
                                                          aria-label="Email icon"
                                                        />
                                                        <span className="f-14 gray9 pl-10">
                                                          {
                                                            reference.referenceEmail
                                                          }
                                                        </span>
                                                      </Typography>
                                                      <Link
                                                        className="link-color small-text-font pr-10 text-decoration-none m-t-5 ml-40"
                                                        href={`mailto:${reference.referenceEmail}`}
                                                      >
                                                        Send Email
                                                      </Link>
                                                    </Typography>
                                                  </Grid>
                                                </Box>
                                              </Typography>
                                              {/* <Typography style={{display: "flex",justifyContent: "end",alignItems: "end"}}>
                                                    </Typography> */}
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography className="f-14 gray9 mt-10">
                                      {"No reference details added."}
                                    </Typography>
                                  )}
                                </>
                              </Grid>

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="bg-white p-20"
                              >
                                <Education educations={candidate.education} />
                              </Grid>

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="bg-white  p-20"
                              >
                                <Grid item lg={12} md={12} xs={12} className="">
                                  <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                                    Portfolio Links
                                  </Typography>
                                </Grid>
                                <Box className="mt-10">
                                  <ul className="pl-0 mt-10 mb-0">
                                    {/* <li> */}
                                    {(candidate?.portfolioUrl1 === null ||
                                      candidate?.portfolioUrl1 === "") &&
                                      (candidate?.portfolioUrl2 === null ||
                                        candidate?.portfolioUrl2 === "") &&
                                      (candidate?.portfolioUrl3 === null ||
                                        candidate?.portfolioUrl3 === "") &&
                                      (candidate?.portfolioUrl4 === null ||
                                        candidate?.portfolioUrl4 === "") && (
                                        <Typography className="f-14 gray9">
                                          No portfolio links added yet.
                                        </Typography>
                                      )}
                                    {/* </li> */}
                                    {candidate?.portfolioUrl1 && (
                                      <li className="mt-10 gray7 d-flex">
                                        <img
                                          className="mr-10 mt-13"
                                          src={linkIcon}
                                          alt="img"
                                          width="16"
                                          height="16"
                                        />
                                        <a
                                          className="small-text-font link-color textEllipse mt-10 w-300"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={
                                            candidate?.portfolioUrl1.indexOf(
                                              "://"
                                            ) === -1
                                              ? "http://" +
                                                candidate?.portfolioUrl1
                                              : candidate?.portfolioUrl1
                                          }
                                        >
                                          {candidate?.portfolioUrl1}
                                        </a>
                                      </li>
                                    )}
                                    {candidate?.portfolioUrl2 && (
                                      <li className="mt-10 gray7 d-flex">
                                        <img
                                          className="mr-10 mt-13"
                                          src={linkIcon}
                                          alt="img"
                                          width="16"
                                          height="16"
                                        />
                                        <a
                                          className="small-text-font link-color textEllipse mt-10 w-300"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={
                                            candidate?.portfolioUrl2.indexOf(
                                              "://"
                                            ) === -1
                                              ? "http://" +
                                                candidate?.portfolioUrl2
                                              : candidate?.portfolioUrl2
                                          }
                                        >
                                          {candidate?.portfolioUrl2}
                                        </a>
                                      </li>
                                    )}
                                    {candidate?.portfolioUrl3 && (
                                      <li className="mt-10 gray7 d-flex">
                                        <img
                                          className="mr-10 mt-13"
                                          src={linkIcon}
                                          alt="img"
                                          width="16"
                                          height="16"
                                        />
                                        <a
                                          className="small-text-font link-color textEllipse mt-10 w-300"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={
                                            candidate?.portfolioUrl3.indexOf(
                                              "://"
                                            ) === -1
                                              ? "http://" +
                                                candidate?.portfolioUrl3
                                              : candidate?.portfolioUrl3
                                          }
                                        >
                                          {candidate?.portfolioUrl3}
                                        </a>
                                      </li>
                                    )}
                                    {candidate?.portfolioUrl4 && (
                                      <li className="mt-10 gray7 d-flex">
                                        <img
                                          className="mr-10 mt-13"
                                          src={linkIcon}
                                          alt="img"
                                          width="16"
                                          height="16"
                                        />
                                        <a
                                          className="small-text-font link-color textEllipse mt-10 w-300"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={
                                            candidate?.portfolioUrl4.indexOf(
                                              "://"
                                            ) === -1
                                              ? "http://" +
                                                candidate?.portfolioUrl4
                                              : candidate?.portfolioUrl4
                                          }
                                        >
                                          {candidate?.portfolioUrl4}
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </Box>
                              </Grid>

                              <Certification
                                certifications={candidate?.certifications}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="Reviews" className="p-0 mt-20">
                        <Grid item className="border-radius-7">
                          {openRatingAndReview && (
                            <Grid
                              item
                              ref={ratingAndReviewsRef}
                              className="bg-white p-20 mt-20"
                              xs={12}
                              md={12}
                              lg={12}
                            >
                              <RatingsAndReviews
                                candidateId={props.candidateId}
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            className={"bg-white  p-20 "}
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10">
                              Candidate Remarks
                            </Typography>

                            {candidateApplicationData?.applicationRemarks
                              ?.length > 0 ? (
                              <span
                                className="pt-10 gray9 f-14 break-word white-space"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    candidateApplicationData?.applicationRemarks,
                                }}
                              />
                            ) : (
                              <Typography className="pt-10 gray9 f-14 break-word">
                                "No remarks."
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="Notes" className="p-0 mt-20">
                        <Grid item className="border-radius-7">
                          <Grid item xs={12} md={12} lg={12}>
                            {(job.status === "O" || job.status === "H") && (
                              <Notes
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                candidateId={props.candidateId}
                                JobId={props.JobId}
                                candidates={props.candidates}
                                setCandidates={props.setCandidates}
                                isFromMatchingDrawer={true}
                                setIsNotesLoading={setIsNotesLoading}
                                status={props.status}
                                candidateApplicationData={candidateApplicationData}
                                setCandidateApplication={setCandidateApplication}
                                setCandidate={setCandidate}
                              />
                              // <ActionButtons
                              //   isLoading={isLoading}
                              //   isNotes={true}
                              //   setIsLoading={setIsLoading}
                              //   candidateApplicationData={candidateApplicationData}
                              // />
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="Timeline" className="p-0 mt-20">
                        <TimelineCandidate
                          candidate={candidate}
                          candidateId={props.candidateId}
                          setIsLoading={setIsLoading}
                          tabStatus={props.status}
                          isSideDrawer={true}
                        />
                      </TabPanel>
                      <TabPanel value="Download" className="p-0 mt-20 pb-30">
                        <Grid item className="border-1 border-radius-7">
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            className="bg-white border-radius-7"
                          >
                            <InfiniteScroll
                              dataLength={allDocuments.length} // This is important to prevent loading more data if there's no more to load
                              next={fetchMoreDocumentsOthers}
                              hasMore={loadMore}
                              inverse={false}
                              height={340}
                            >
                              <Grid item className="pr-20 pl-20 pt-12 pb-15 document-view" lg={12} md={12} xs={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="border-bottom-1"
                              >
                                <Typography className="font-weight-600 f-14 gray9">
                                  Profile
                                </Typography>
                                <Typography className="align-left mt-10 mb-10">
                                  <Checkbox
                                    {...label}
                                    sx={{
                                      color: "#98A2B3",
                                      padding: "0px",
                                      "&.Mui-checked": {
                                        color: getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue("--primary-color"),
                                        padding: "0px",
                                        "&.hoveer": {
                                          background: "none",
                                          padding: "0px",
                                        },
                                      },
                                    }}
                                    checked={selectedValues.profile}
                                    onChange={(e) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        profile: e.target.checked,
                                      })
                                    }
                                  />
                                  <span
                                    className="small-text-font pl-10 text-capitalize gray9 cursor-hover pt-5"
                                    onClick={(e) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        profile: !selectedValues.profile,
                                      })
                                    }
                                    onKeyPress={(e) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        profile: !selectedValues.profile,
                                      })
                                    }
                                    tabIndex={0}
                                  >
                                    {candidate?.firstName}’s Profile
                                  </span>
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                  className="border-bottom-1 pt-12 pb-12"
                              >
                                <Typography className="font-weight-600 f-14 gray9">
                                  Checklist
                                </Typography>
                                {candidateChecklist &&
                                candidateChecklist.length > 0 ? (
                                  candidateChecklist.map((checkList, index) => (
                                    <Typography
                                      className="align-left mt-10 d-flex align-center"
                                      key={index}
                                    >
                                      <Checkbox
                                        {...label}
                                        sx={{
                                          color: "#98A2B3",
                                          padding: "0px",
                                          "&.Mui-checked": {
                                            color: getComputedStyle(
                                              document.documentElement
                                            ).getPropertyValue(
                                              "--primary-color"
                                            ),
                                            padding: "0px",
                                            "&.hoveer": {
                                              background: "none",
                                              padding: "0px",
                                            },
                                          },
                                        }}
                                        checked={
                                          selectedValues.checkList.find(
                                            (d) => d === checkList.checkList.id
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleSelectCheklist(
                                            e,
                                            checkList.checkList.id
                                          )
                                        }
                                      />
                                      <Box
                                        className="small-text-font pl-10 gray9 cursor-hover"
                                        onClick={(e) =>
                                          handleSelectCheklist1(
                                            checkList.checkList.id
                                          )
                                        }
                                        style={{ paddingTop: "2px" }}
                                        tabIndex={0}
                                        onKeyPress={(e) =>
                                          handleSelectCheklist1(
                                            checkList.checkList.id
                                          )
                                        }
                                      >
                                        {checkList.checkList.checkListName}
                                      </Box>
                                    </Typography>
                                  ))
                                ) : (
                                  <Typography
                                    className="mt-10"
                                    style={{ color: "darkgray" }}
                                  >
                                    There is no checklist available at this
                                    time.
                                  </Typography>
                                )}
                              </Grid>

                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                  className="pt-12 border-bottom-1 pb-12"
                              >
                                  <Typography className="d-flex">
                                    <Typography className="font-weight-600 f-14 gray9 mr-15">
                                  Resume
                                </Typography>
                                    <Typography className="d-flex" onClick={addNewResumeDocument}>
                                      <AddSkills
                                        style={{ width: "16px" }}
                                        className="cursor-hover svg-icons"
                                        tabIndex={0}
                                      />
                                      <Box
                                        className="link-color f-14 font-weight-400 ml-5 skill-labl cursor-hover textDecor"
                                        onClick={() => {
                                        }}
                                        onKeyPress={() => {
                                        }}
                                        tabIndex={0}
                                      >
                                        Add Resume
                                      </Box>
                                    </Typography>
                                  </Typography>
                                  {(candidate?.resume && candidate?.resume.length > 0) || (resumeDocuments && resumeDocuments.length) ? (
                                    <>
                                      {candidate?.resume?.map((uploadResume, index) => (
                                        <Typography className="align-left mt-10" key={index}>
                                        <Checkbox
                                          {...label}
                                          sx={{
                                            color: "#98A2B3",
                                            padding: "0px",
                                            "&.Mui-checked": {
                                                color: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
                                              padding: "0px",
                                              "&.hoveer": {
                                                background: "none",
                                                padding: "0px",
                                              },
                                            },
                                          }}
                                          checked={
                                              selectedValues.resumes.find((d) => d.id === uploadResume.resumeId)
                                              ? true
                                              : false
                                          }
                                            onChange={(e) => handleSelectResumes(e, uploadResume)}
                                          />
                                          <span
                                            className="small-text-font pl-10 cursor-hover gray9 pt-5"
                                            onClick={(e) => handleSelectResumes1(uploadResume)}
                                            onKeyPress={(e) => handleSelectResumes1(uploadResume)}
                                          tabIndex={0}
                                        >
                                          {uploadResume?.title}
                                          </span>
                                          {(uploadResume?.createdBy === null || props?.candidateId === uploadResume?.createdBy) ? <span className='ml-30 small-text-font gray9'><i>{`(Uploaded by candidate)`}</i></span> :
                                                <span className='ml-30 small-text-font gray9'><i>{`(Uploaded by ${uploadResume?.createdByName} on ${dateFormat(uploadResume?.createdTimestamp)})`}</i></span>}
                                      </Typography>
                                      ))}
                                      {resumeDocuments.length > 0 && (
                                        <DocumentListing
                                          document={resumeDocuments}
                                          setDocuments={setResumeDocuments}
                                          type={"Resume"}
                                          setSelectValues={setEnterpriseSelectedResumes}
                                          selectedValues={enterpriseSelectedResumes}
                                          setIsLoading={props.setIsLoading}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Typography className="mt-10" style={{ color: "darkgray" }}>
                                    There is no resume available at this time.
                                  </Typography>
                                )}
                              </Grid>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  xs={12}
                                  className="pt-12 border-bottom-1 pb-12"
                                >
                                  <Typography className="d-flex">
                                    <Typography className="font-weight-600 f-14 gray9 mr-15">
                                      Others
                                    </Typography>
                                    <Typography className="d-flex" onClick={addNewFileDocument}>
                                      <AddSkills
                                        style={{ width: "16px" }}
                                        className="cursor-hover svg-icons"
                                        tabIndex={0}
                                      />
                                      <Box
                                        className="link-color f-14 font-weight-400 ml-5 skill-labl cursor-hover textDecor"
                                        onClick={() => {
                                        }}
                                        onKeyPress={() => {
                                        }}
                                        tabIndex={0}
                                      >
                                        Add File
                                      </Box>
                                    </Typography>
                                  </Typography>
                                  {otherDocuments && otherDocuments?.length > 0 ? (<DocumentListing document={otherDocuments} setDocuments={setOtherDocuments} type={"Other"} setSelectValues={setEnterpriseSelectedFiles} selectedValues={enterpriseSelectedFiles} setIsLoading={props.setIsLoading} />) : (
                                    <Typography className="mt-10" style={{ color: "darkgray" }}>
                                      There is no file available at this time.
                                    </Typography>
                                  )}
                            </Grid>
                              </Grid>
                            </InfiniteScroll>
                            <Grid item className="p-20" lg={12} md={12} xs={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                className="justify-end d-flex"
                              >
                                <Button
                                  className="btn primary-button"
                                  disabled={
                                    !selectedValues.profile &&
                                    !selectedValues.checkList.length > 0 &&
                                    !selectedValues.resumes.length > 0 &&
                                    !enterpriseSelectedResumes.length > 0 &&
                                    !enterpriseSelectedFiles.length > 0
                                  }
                                  onClick={() => DownloadProfile()}
                                  disableRipple="true"
                                >
                                  Download
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid></Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="ProjectDetails" className="p-0 mt-20 ml-22 mr-22">
                        <ProjectLabelEnhancement
                          candidate={candidate}
                          candidateId={props.candidateId}
                          setIsLoading={setIsLoading}
                          tabStatus={props.status}
                          isSideDrawer={true}
                        />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item className="mt-10 p-20 c-white job-list-header" style={{ borderRadius: '10px' }}>
                        <JobHeader job={job} />
                    </Grid>*/}
            {/* <Grid item className="mt-10 p-20 danger-color candidateDecline font-weight-500">
                        The offer has been declined by the candidate
                    </Grid> */}
          </Grid>
          <Modal
            open={openAssessment}
            onClose={handleCloseAssessment}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-content inputcontrasts">
              <Typography className="align-left">
                <Grid container item id="modal-title" className="align-center">
                  <Grid item xs={6}>
                    <Typography
                      className="f-24 font-weight-600 heading-text-color"
                      variant="h5"
                    >
                      Screening Response
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      align="right"
                      className="cursor-hover sub-text-color icon-button"
                    >
                      <CloseIcon
                        onClick={handleCloseAssessment}
                        onKeyPress={handleCloseAssessment}
                        aria-label="Close icon"
                        tabIndex={0}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item id="modal-description">
                  {answers?.length > 0
                    ? answers.map((data, index) => {
                      let answer = normalizeKeys(data);
                       return (<Grid
                          item
                          className={index === 0 ? "mt-20" : "mt-30"}
                          key={index}
                        >
                          <Typography className="font-weight-400 f-16 gray9">
                            Q{index + 1}. {answer.question}
                          </Typography>
                          <Typography className="d-flex mt-5">
                            <span className="gray7 f-14 m-t-1">Ans:</span>
                            {answer?.answer ? (
                              <Box className="ml-5 break-word">
                                {moment(answer.answer).isValid() &&
                                answer.question?.toLowerCase().includes("birth")
                                ? moment(answer.answer).format("MM/DD").toUpperCase()
                                : moment(answer.answer).isValid() &&
                                answer.answer.length >= 10
                                  ? moment(answer.answer).format("MMM DD, YYYY")
                                  : typeof answer.answer === "number" ? answer.answer : typeof answer.answer === "string"
                                  ? (showMoreAnswer[answer.question] ? (answer.answer?.includes(",") && !answer.answer?.includes(", ") ? answer.answer?.split(",").join(", ") : answer.answer) : (answer.answer?.includes(",") && !answer.answer?.includes(", ") ? answer.answer?.split(",").join(", ") : answer.answer).substring(0, 200))
                                  : answer.answer?.length > 0 ? answer.answer.join(", ") : "-"}
                                {typeof answer.answer === "string" &&
                                  answer.answer.length > 200 && (
                                    <span
                                      tabIndex={0}
                                      onKeyPress={() =>
                                        handleShowMoreAnswer(answer)
                                      }
                                      className="link-color cursor-hover text-decoration-none d-content f-14 font-weight-600"
                                      onClick={() =>
                                        handleShowMoreAnswer(answer)
                                      }
                                    >
                                      {" "}
                                      {showMoreAnswer[answer.question]
                                        ? "read less"
                                        : "read more"}
                                    </span>
                                  )}
                              </Box>
                            ) : (
                              <Box className="ml-5">-</Box>
                            )}
                          </Typography>
                        </Grid>);
                    })
                    : ""}
                </Grid>
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </Drawer>

      <CandidateProfileVideoPreview
        open={openVideoPreview}
        setOpen={setOpenVideoPreview}
        videoSrc={videoSrc}
        candidateName={candidateName}
      />

      <CandidateProfileVideoPreview
        open={openPitchVideoPreview}
        setOpen={setOpenPitchVideoPreview}
        videoSrc={pitchVideo}
      />
      <Modal
        open={showCandidateChecklistOverlay}
        onClose={() => setShowCandidateChecklistOverlay(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="notes-modal-view"
      >
        <Box className="modal-content checklist-modal responsive-checklist-modal notes-table-view checklist-view-align inputcontrasts">
          <Typography className="align-left">
            <Grid
              container
              item
              id="modal-title"
              className="align-center view-checklist-align"
            >
              <Grid item xs={10} className="view-checklist-align">
                <Typography className="f-24 font-weight-600 heading-text-color pl-5">
                  View Checklist
                </Typography>
              </Grid>
              <Grid item xs={2} className="view-checklist-align">
                <Typography
                  align="right"
                  className="cursor-hover sub-text-color icon-button"
                >
                  <CloseIcon
                    onClick={() => setShowCandidateChecklistOverlay(false)}
                    onKeyPress={() => setShowCandidateChecklistOverlay(false)}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid id="modal-description" style={{ maxHeight: "500px" }}>
              <ChecklistForm
                selectedCandidateChecklist={selectedCandidateChecklist}
              />
            </Grid>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openDocument}
        onClose={handleCloseDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {candidateApplicationData?.offerAcceptedDocumentUrlSigned?.length >0 ? (
          <Box className="modal-content inputcontrasts">
            <Typography className="align-left">
              <Grid container item id="modal-title" className="align-center">
                <Grid item xs={6}>
                  <Typography
                    className="f-24 font-weight-600 heading-text-color"
                    variant="h5"
                  >
                    View Document
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="right"
                    className="cursor-hover sub-text-color icon-button"
                  >
                    <CloseIcon
                      onClick={handleCloseDocument}
                      onKeyPress={handleCloseDocument}
                      aria-label="Close icon"
                      tabIndex={0}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item id="modal-description" className="pr-0 pl-0">
                { candidateApplicationData.offerAcceptedDocumentUrlSigned?.length >0 ?
                candidateApplicationData?.offerAcceptedDocumentUrlSigned.map((offer_document, index) => {
                  return (
                  <Grid key={index} item className="justify-between mt-10 d-flex">
                    {offer_document && (
                      <Grid item className="d-flex workDocument align-center">
                        {offer_document
                          ?.split(".")
                          .pop()
                          .split("?")[0] === "pdf" && (
                          <img
                            src={Resumepdf}
                            alt="img"
                            width="35px" height="35px"
                            className="m-r-10 file-extension-image wth-50"
                          />
                        )}
                        {offer_document
                          ?.split(".")
                          .pop()
                          .split("?")[0] === "doc" && (
                          <img
                            src={docIcon}
                            alt="img"
                            width="35px" height="35px"
                            className="m-r-10 file-extension-image wth-50"
                          />
                        )}
                        {offer_document
                          ?.split(".")
                          .pop()
                          .split("?")[0] === "zip" && (
                          <img
                            src={zipIcon}
                            alt="img"
                            width="35px" height="35px"
                            className="m-r-10 file-extension-image wth-50"
                          />
                        )}
                        {offer_document
                          ?.split(".")
                          .pop()
                          .split("?")[0] === "docx" && (
                          <img
                            src={docIcon}
                            alt="img"
                            width="35px" height="35px"
                            className="m-r-10 file-extension-image wth-50"
                          />
                        )}
                        <p className="textEllipse w-300">
                          {
                            decodeURI(
                              offer_document
                            )
                              ?.split("/")
                              .pop()
                              .split("?")[0]
                          }
                        </p>
                      </Grid>
                      )}
                    <Grid
                      className="d-flex align-center cursor-hover mr-5"
                      onClick={() =>
                        downloadAttachDocument(
                          offer_document
                        )
                      }
                      tabIndex={0}
                      onKeyPress={() =>
                        downloadAttachDocument(
                          offer_document
                        )
                      }
                    >
                      <DownloadIcon width="20px" height="20px" />
                    </Grid>
                  </Grid>
                  );
                }) :("")
              }
              </Grid>
            </Typography>
            <Box className="mt-20 mb-10">
              <span className="sub-text-color font-weight-700">NOTE: </span>
              <span className="f-14">
                {candidateApplicationData.firstName}{" "}
                {candidateApplicationData.lastName} has accepted the offer.
              </span>
            </Box>
          </Box>
        ) : (
          <Box className="modal-content">
            <Typography className="align-left">
              <Grid container item id="modal-title" className="align-center">
                <Grid item xs={6}>
                  <Typography
                    className="f-24 font-weight-600 gray9"
                    variant="h5"
                  >
                    View Document
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="right"
                    className="cursor-hover sub-text-color icon-button"
                  >
                    <CloseIcon
                      onClick={handleCloseDocument}
                      onKeyPress={handleCloseDocument}
                      aria-label="Close icon"
                      tabIndex={0}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item id="modal-description" className="pr-0 pl-0">
                  { candidateApplicationData.offerDocumentUrlSigned?.length > 0 ?
                  candidateApplicationData.offerDocumentUrlSigned?.map((offer_document, index) => {
                    return (
                      <Grid key={index} item className="justify-between mt-10 d-flex">
                        <Grid item className="d-flex workDocument align-center">
                          {offer_document
                            ?.split(".")
                            .pop()
                            .split("?")[0] === "pdf" && (
                              <img
                                src={Resumepdf}
                                alt="img"
                                width="35px" height="35px"
                                className="m-r-10 file-extension-image wth-50"
                              />
                            )}
                          {offer_document
                            ?.split(".")
                            .pop()
                            .split("?")[0] === "doc" && (
                              <img
                                src={docIcon}
                                alt="img"
                                width="35px" height="35px"
                                className="m-r-10 file-extension-image wth-50"
                              />
                            )}
                          {offer_document
                            ?.split(".")
                            .pop()
                            .split("?")[0] === "zip" && (
                              <img
                                src={zipIcon}
                                alt="img"
                                width="35px" height="35px"
                                className="m-r-10 file-extension-image wth-50"
                              />
                            )}
                          {offer_document
                            ?.split(".")
                            .pop()
                            .split("?")[0] === "docx" && (
                              <img
                                src={docIcon}
                                alt="img"
                                width="35px" height="35px"
                                className="m-r-10 file-extension-image wth-50"
                              />
                            )}
                          <p className="textEllipse w-300">
                            {
                              decodeURI(
                                offer_document
                              )
                                ?.split("/")
                                .pop()
                                .split("?")[0]
                            }
                          </p>
                        </Grid>
                        <Grid
                          className="d-flex align-center cursor-hover mr-5"
                          onClick={() =>
                            downloadAttachDocument(
                              offer_document
                            )
                          }
                          onKeyPress={() =>
                            downloadAttachDocument(
                              offer_document
                            )
                          }
                          tabIndex={0}
                        >
                          <DownloadIcon width="20px" height="20px" />
                        </Grid>
                      </Grid>
                    );
                  }) : ("")
                  }
              </Grid>
            </Typography>
          {(!candidateApplicationData?.offerAcceptedDocumentUrlSigned?.length &&  candidate?.candidateProcessStatus === "P") && <Box className="mt-30"><Typography className="f-14 font-weight-400 mt-10"><span className="font-weight-700 ">Note:{" "}</span>The candidate has accepted the offer without uploading any documents.</Typography></Box>}
          </Box>
        )}
      </Modal>
      <Modal
        open={downloadPopup}
        onClose={() => {
          setDownloadPopup(false);
          setSelectedValues({ profile: true, checkList: [], resumes: [] });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Grid container item id="modal-title">
            <Grid item xs={6}>
              <Typography
                className="f-21 font-weight-600 mb-5 heading-text-color pl-5"
                variant="h5"
              >
                Download Profile
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="right"
                className="cursor-hover sub-text-color icon-button"
              >
                <CloseIcon
                  onClick={() => {
                    setDownloadPopup(false);
                    setSelectedValues({
                      profile: true,
                      checkList: [],
                      resumes: [],
                    });
                  }}
                  aria-label="Close icon"
                  tabIndex={0}
                  onKeyPress={() => {
                    setDownloadPopup(false);
                    setSelectedValues({
                      profile: true,
                      checkList: [],
                      resumes: [],
                    });
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid item id="modal-description">
            <Grid item>
              {/* <Grid item xs={12}>
                        <Typography className="gray9 pb-5">
                           You can select the following options to download
                        </Typography>
                </Grid> */}
              <Typography className="font-weight-600">Profile</Typography>
              <Typography className="align-left mt-5">
                <Checkbox
                  {...label}
                  sx={{
                    color: "#98A2B3",
                    padding: "0px",
                    "&.Mui-checked": {
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--primary-color"),
                      padding: "0px",
                      "&.hoveer": {
                        background: "none",
                        padding: "0px",
                      },
                    },
                  }}
                  checked={selectedValues.profile}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      profile: e.target.checked,
                    })
                  }
                />
                <span
                  className="small-text-font pl-10 text-capitalize gray9 cursor-hover pt-5"
                  onClick={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      profile: !selectedValues.profile,
                    })
                  }
                  onKeyPress={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      profile: !selectedValues.profile,
                    })
                  }
                  tabIndex={0}
                >
                  {candidate?.firstName}’s Profile
                </span>
              </Typography>
            </Grid>
            <div className="public-profile-download"></div>
            <Grid item>
              <Typography className="font-weight-600 mt-10">
                Checklist
              </Typography>
              {candidateChecklist && candidateChecklist.length > 0 ? (
                candidateChecklist.map((checkList, index) => (
                  <Typography
                    className="align-left mt-5 d-flex align-center"
                    key={index}
                  >
                    <Checkbox
                      {...label}
                      sx={{
                        color: "#98A2B3",
                        padding: "0px",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                          padding: "0px",
                          "&.hoveer": {
                            background: "none",
                            padding: "0px",
                          },
                        },
                      }}
                      checked={
                        selectedValues.checkList.find(
                          (d) => d === checkList.checkList.id
                        )
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleSelectCheklist(e, checkList.checkList.id)
                      }
                    />
                    <Box
                      className="small-text-font pl-10 gray9 cursor-hover"
                      onClick={(e) =>
                        handleSelectCheklist1(checkList.checkList.id)
                      }
                      style={{ paddingTop: "2px" }}
                      tabIndex={0}
                      onKeyPress={(e) =>
                        handleSelectCheklist1(checkList.checkList.id)
                      }
                    >
                      {checkList.checkList.checkListName}
                    </Box>
                  </Typography>
                ))
              ) : (
                <Typography className="mt-10" style={{ color: "darkgray" }}>
                  There is no checklist available at this time.
                </Typography>
              )}
            </Grid>
            <div className="public-profile-download"></div>
            <Grid item>
              <Typography className="font-weight-600 mt-10">Resume</Typography>
              {candidate?.resume && candidate?.resume.length > 0 ? (
                candidate?.resume?.map((uploadResume, index) => (
                  <Typography className="align-left mt-5" key={index}>
                    <Checkbox
                      {...label}
                      sx={{
                        color: "#98A2B3",
                        padding: "0px",
                        "&.Mui-checked": {
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary-color"),
                          padding: "0px",
                          "&.hoveer": {
                            background: "none",
                            padding: "0px",
                          },
                        },
                      }}
                      checked={
                        selectedValues.resumes.find(
                          (d) => d.id === uploadResume.resumeId
                        )
                          ? true
                          : false
                      }
                      onChange={(e) => handleSelectResumes(e, uploadResume)}
                    />
                    <span
                      className="small-text-font pl-10 cursor-hover gray9 pt-5"
                      onClick={(e) => handleSelectResumes1(uploadResume)}
                      onKeyPress={(e) => handleSelectResumes1(uploadResume)}
                      tabIndex={0}
                    >
                      {uploadResume?.title}
                    </span>
                  </Typography>
                ))
              ) : (
                <Typography className="mt-10" style={{ color: "darkgray" }}>
                  There is no resume available at this time.
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item id="modal-footer">
            <Grid item className="mt-18 justify-center d-flex">
              <Button
                className="btn primary-button"
                disabled={
                  !selectedValues.profile &&
                  !selectedValues.checkList.length > 0 &&
                  !selectedValues.resumes.length > 0
                }
                onClick={() => DownloadProfile()}
                disableRipple="true"
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* matchingScore breakdown modal */}
      <MatchingScoreBreakdownModal
        open3={open3}
        handleClosePopover={handleClosePopover}
        candidatePopUp={candidatePopUp}
        handleOpenVideoPreview={handleOpenVideoPreview}
      />
      {candidate && (
        <ChecklistOverlay
          candidate={candidate}
          setShowChecklistOverlay={setShowChecklistOverlay}
          showChecklistOverlay={showChecklistOverlay}
        />
      )}
      <Popover
        id="simple-popover-list-work"
        open={openJobType}
        anchorEl={anchorEl2}
        onClose={handleCloseJobType}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box>
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 heading-text-color font-weight-600">
                  Work Type
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover f-14 gray7 font-weight-400 icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseJobType}
                    onKeyPress={handleCloseJobType}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="font-family">
              {getFormattedWorkType(workType, true)}
            </Box>
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id="simple-popover-list"
        open={openJobPreference}
        anchorEl={anchorEl3}
        onClose={handleCloseJobPreference}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box>
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 heading-text-color font-weight-600">
                  Job Preferences
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover gray7 f-14 icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseJobPreference}
                    onKeyPress={handleCloseJobPreference}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="f-16 font-family">
              {candidate?.jobPreferences?.split(",").join(", ")}
            </Box>
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id="simple-popover-list"
        open={openSpecialization}
        anchorEl={anchorEl4}
        onClose={handleCloseSpecialization}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box>
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 heading-text-color font-weight-600">
                  Specialization
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover gray7 f-14 icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseSpecialization}
                    onKeyPress={handleCloseSpecialization}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="f-16 font-family">{specializationString}</Box>
          </Grid>
        </Grid>
      </Popover>
      <Modal
        open={openWorkFlowModal}
        onClose={handleCloseWorkFlowModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content checklist-modal responsive-checklist-modal inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
              </Typography>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleCloseWorkFlowModal}
                  onKeyPress={handleCloseWorkFlowModal}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography id="modal-description" style={{ maxHeight: "84vh", overflowY: "overlay" }}>
            </Typography>
          </Typography>
        </Box>
      </Modal>
      {/* if panel member is null */}
      <Modal
        open={openPanelMember}
        onClose={handleClosePanelMember}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="invite-modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                Add Panel Members to Schedule Interviews
              </Typography>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={handleClosePanelMember}
                  onKeyPress={handleClosePanelMember}
                  aria-label="Close icon"
                  tabIndex={0}
                />
              </Typography>
            </Typography>
            <Typography className="mt-20 gray9 f-14 font-weight-400">
              To schedule interviews, you'll need to add panel members to this job. Click
              below to edit hiring workflow and add Panel members.
            </Typography>
            <Grid className="d-flex justify-center mt-50">
              <Button onClick={goToInterviewProcess} className="btn primary-button">Add Panel Members</Button>
            </Grid>
          </Typography>
        </Box>
      </Modal>
      <ConfirmDialog
                open={shortlistPopUpOpener}
                setOpen={setShortlistPopUpOpener}
                dialogTitle="Shortlist Email"
                description={"Are you sure you want to send the shortlist email to the candidate?"}
                handleConfirmYes={() => sendEmailShortlist()}
      />
       <MessageDialogModel
        open={confirmSendSms}
        setOpen={setConfirmSendSms}
        title="Send SMS"
        description={"Message for Candidate"}
        message={message}
        setMessage={setMessage}
        handleConfirmYes={(e) => sendSmsToCandidates()}
      />
      <GdprRestrictDownloadModal
        openGDPRDownloadRestrictPopup={openGDPRDownloadRestrictPopup}
        setOpenGDPRDownloadRestrictPopup={setOpenGDPRDownloadRestrictPopup}
      />
      <UploadResumeModal open={openAddResumeModal} setOpenAddResumeModal={setOpenAddResumeModal} jobId={params.job_id} candidateId={props.candidateId} setIsLoading={props.setIsLoading} setResumeDocuments={setResumeDocuments} resumeDocuments={resumeDocuments}/>
      <FileUploadModal open={openAddFileModal} setOpenAddFileModal={setOpenAddFileModal} jobId={params.job_id} candidateId={props.candidateId} setIsLoading={props.setIsLoading} setOtherDocuments={setOtherDocuments} otherDocuments={otherDocuments}/>
      <GdprRestrictEditCandidateModal
        openGDPREditCandidateRestrictPopup={openGDPREditCandidateRestrictPopup}
        setOpenGDPREditCandidateRestrictPopup={setOpenGDPREditCandidateRestrictPopup}
      />
    </>
  );
}
