import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Menu,
  useTheme,
  useMediaQuery
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiCalls from "../../api/customAxios";
import LogoImgWhite from "../../assets/images/logo-new-white.png";
import logoimgnew from "../../assets/images/logo-new.png";
import { ReactComponent as LogoutNavIcon } from "../../assets/images/logout-navIcon.svg";
import { ReactComponent as MessageNavIcon } from "../../assets/images/messageNav-icon.svg";
import { ReactComponent as MessageIcon } from "../../assets/images/message-icon.svg";
import { ReactComponent as SupportIcon } from "../../assets/images/Support.svg";
import notificatioIcon from "../../assets/images/notificationIcon.svg";
import messageNavIconNew from "../../assets/images/messageNav-icon.svg";
import {
  addDataToKeycloakRedirectURI,
  getEnterpriseLogo,
  getFromLocalStorage,
  isSessionValid,
  logout,
  navigateToSSOSignIn,
  navigateToSSOSignUp,
} from "../../helpers/helper";
import AccessService from "../../Services/Enterprise/Access.service";
import Loader from "../../Views/Loader";
import notificationNavIcon from "../../assets/images/notificationNav-icon.svg";
import SearchFilter from "../../Views/EnterprisePortal/SearchFilter";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import NotificationList from "../../Views/EnterprisePortal/Notification/NotificationList";
import { ReactComponent as NotificationDefaultIcon } from "../../assets/images/notification-default-icon.svg";
import { ReactComponent as NoNotificationIcon } from "../../assets/images/NoJobsFound.svg";
import {
  formatDate,
  handleSpaces,
  getFormattedLocation,
  isMinutesOrHours,
} from "../../helpers/helper-data";
import FeedBackModal from "../../Views/EnterprisePortal/FeedBackModal";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import DirectHealthLogo from "../../assets/images/direct-healthcare-logo.svg";
import NeuroDiversityLogo from "../../assets/images/NeuroDiversityLogo.svg";
import { useSelector } from "react-redux";
import KeycloakService from "../../keycloak";
import Constants from "../../helpers/constants";

export default function Navbar(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let isAuthenticated = KeycloakService.isLoggedIn();
  let navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const keyword = new URLSearchParams(search).get("keyword");
  const type = new URLSearchParams(search).get("type");
  const [isLoading, setIsLoading] = React.useState(null);
  const [profilePicture, setProfilePicture] = React.useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [jobTitleFilterValue, setJobTitleFilterValue] = React.useState("");
  const [filterValue, setFilterVaue] = React.useState(keyword ? keyword : "");
  const [showSearchFilter, setShowSearchFilter] = React.useState(false);
  const [openfeedback, setOpenFeedback] = useState(false);
  const enterpriseLogo = getEnterpriseLogo();

  // const location = useLocation();
  const URLPath = location.pathname;

  const signOut = async () => {
    setIsLoading(true);
    await logout();
    setLoggedIn(false);
    navigate("/signin");
    setIsLoading(false);
  };
  const goToClient = (e) => {
    e.preventDefault();
    navigate("/candidatePortal");
  };

  const goToCampaign = (e) => {
    e.preventDefault();
    navigate("/enterprise/campaignList");
    setAnchorE2(null);
  };
  const handleStateChange = (text) => {
    setFilterVaue(text);
  };

  const [isloggedIn, setLoggedIn] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    isSessionValid().then((res) => setLoggedIn(res));
  }, []);

  useEffect(() => {
    if (
      !(
        location.pathname.includes("CandidateList") ||
        location.pathname.includes("candidate-detail")
      )
    ) {
      localStorage.removeItem("advanceFilterObj");
      localStorage.removeItem("AdvanceSkills");
    }
  }, [location.pathname]);
  useEffect(() => {
    if (keyword === null) {
      setFilterVaue("");
    } else {
      setFilterVaue(keyword);
    }
  }, [keyword]);

  React.useEffect(async () => {
    if (isAuthenticated) {
      await AccessService.getEnterprises().then((res) => {
        if (res.customWeightage) {
          localStorage.setItem("showMatchingScore", true);
        } else {
          localStorage.setItem("showMatchingScore", false);
        }
        if (res.logoFileSignedUrl) setProfilePicture(res.logoFileSignedUrl);
        props.enterpriseName(res.establishmentName);
        if (!localStorage.getItem("enterpriseId")) {
          localStorage.setItem("enterpriseName", res.establishmentName);
          localStorage.setItem("enterpriseId", res.id);
          if (res.logoFileSignedUrl) {
            localStorage.setItem("enterpriseLogo", res.logoFileSignedUrl);
          }
        }
      });
    }
  }, []);
  const handleOpenFeedBack = () => {
    setOpenFeedback(true);
  };
  const handleFeebackModalClose = () => {
    setOpenFeedback(false);
  };

  React.useEffect(() => {
    const handleInvalidToken = (e) => {
      isSessionValid().then((res) => {
        if (res === false) {
          setLoggedIn(false);
        }
      });
    };

    window.addEventListener("storage", handleInvalidToken);

    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  const [anchorE2, setAnchorE2] = React.useState(null);
  const open2 = Boolean(anchorE2);

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const [anchorE3, setAnchorE3] = React.useState(null);
  const open3 = Boolean(anchorE3);

  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE3(null);
  };

  const handleNotificationRead = () => {
    ApiCalls.put("candidates/notifications/E")
      .then((response) => {
        setNotificationsCount(0);
      })
      .catch(function (error) {});
  };
  const [notificationListDrawer, setNotificationListDrawer] = useState(false);

  const viewAllNotification = () => {
    setNotificationListDrawer(true);
    setNotificationsCount(0);
    // navigate("/enterprise/notification");
    setAnchorE3(null);
  };

  useEffect(() => {
    isSessionValid().then((result) => {
      if (result === true) {
        getNotificationsDtoList();
      }
    });
  }, []);

  const getNotificationsDtoList = () => {
    let mounted = true;
    ApiCalls.get("candidates/notifications/E/0/10")
      .then((response) => {
        if (mounted) {
          setNotifications(response.notificationsDtoList);
          setNotificationsCount(response.unReadNotifications);
        }
      })
      .catch(function (error) {});
    return () => (mounted = false);
  };

  const goToJoblist = () => {
    navigate("/enterprise/jobList");
    setAnchorE2(null);
  };

  const enterpriseAdminAccess = () => {
    window.open("/enterprise/access");
    setAnchorE2(null);
  };
  const goToAnalytics = () => {
    navigate("/enterprise/Analytics");
    setAnchorE2(null);
  };
  function goToMessages() {
    navigate("/enterprise/message-list");
    setAnchorE2(null);
  }

  const selectText = (event) => {
    event.currentTarget?.select();
  };

  const getFormattedFilterValue = (name, type) => {
    if (type === "M") {
      if (name === "F") {
        return "full time";
      } else if (name === "P") {
        return "part time";
      } else if (name === "D") {
        return "distance education";
      }
    } else if (type === "L") {
      if (name === "B") {
        return "bachelors";
      } else if (name === "P") {
        return "doctorate";
      } else if (name === "M") {
        return "masters";
      } else if (name === "A") {
        return "associate";
      } else if (name === "D") {
        return "diploma";
      } else if (name === "S") {
        return "school";
      }
    }
    return name;
  };

  const handleJobTitleSearch = (e) => {
    setFilterVaue(e.target.value);
    setShowSearchFilter(true);
    setJobTitleFilterValue(e.target.value);
    if (e.target.value === "" && location.pathname.includes("CandidateList")) {
      window.location.href = `/CandidateList`;
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      navigate(
        "/CandidateList?keyword=" +
          encodeURIComponent(filterValue.split(",")) +
          "&type=K"
      );
      localStorage.removeItem("searchId");
      setShowSearchFilter(false);
    }
  };
  // const isMinutesOrHours = (date) => {
  //   const date1 = moment.utc(date).toDate();
  //   const date2 = new Date();
  //   const diffInMs = Math.abs(date2 - date1);
  //   const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  //   const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  //   if (diffInMinutes == 0) {
  //     return "just now";
  //   }
  //   if (diffInMinutes <= 60) {
  //     return diffInMinutes + "min ago";
  //   } else {
  //     return diffInHours + "hr ago";
  //   }
  // };
  var onedayBefore = new Date();
  onedayBefore.setDate(onedayBefore.getDate() - 1);
  var currentDateWeekFormat = new Date();
  currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);
  useEffect(() => {
    if (appOrigin === "H") {
      document
        .querySelector("link[title=icon]")
        .setAttribute("href", "/healthcare-favicon.png");
    } else if (appOrigin === "N") {
      document
        .querySelector("link[title=icon]")
        .setAttribute("href", "/neuro-favicon.png");
    }
  }, []);

  const goToSignin = () => {
    KeycloakService.doLogin({
      redirectUri: addDataToKeycloakRedirectURI(`${window.location.href}`)
    });
  };
  const goToSignup = () => {
    KeycloakService.doRegisteration({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.href}`) });
  };
  return (
    <>
      {isLoading && <Loader />}
      {isMobile ? (
        <>
          <Box className="justify-between navbar-fixed">
            <Box>
              {localStorage.getItem("navbarLogo") && (
                <Link to="/">
                  <img
                    className="logoImg-navbar"
                    src={localStorage.getItem("navbarLogo")}
                    alt="Logo"
                  />
                </Link>
              )}
              {!localStorage.getItem("navbarLogo") && (
                <Link to="/">
                  {appOrigin === "D" ? (
                    <img
                      className="logoImg-navbar Nav-cont"
                      src={Constants.APP_LOGO_GENERAL}
                      alt="Logo"
                    />
                  ) : appOrigin === "N" ? (
                    <img
                      className="logoImg-navbar Nav-cont"
                      src={Constants.APP_LOGO_NEURODIVERSIFY}
                      alt="Logo"
                    />
                  ) : (
                    <img
                      className="logoImg-navbar Nav-cont"
                      src={Constants.APP_LOGO_HEALTHCARE}
                      alt="Logo"
                    />
                  )}
                </Link>
              )}
            </Box>
            {/* <Box> */}
            {/* <Link to="/">
                <img className="logoImg" src={LogoImgWhite} alt="Logo" />
              </Link> */}
            {/* <Link to="/"><img className="logoWord" src={logoword} alt="img" /></Link>*/}
            {/* </Box> */}
            <Box className="d-flex align-center">
              {/*  <SearchIcon className="gray7 f-25" />*/}
              <Button
                variant="text"
                onClick={handleMenu}
                aria-label="Menu icon"
                role="button"
                disableRipple="true"
              >
                {" "}
                <MenuIcon className="gray9 f-25" aria-label="Menu icon" />
              </Button>
            </Box>
          </Box>

          <Menu
            className="navbar-responsive"
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            disableScrollLock={true}
          >
            <Box className="aboutEnterprise">
              <Box sx={{ textAlign: "right" }} className=" icon-button">
                <CloseIcon
                  className="font-25"
                  onClick={handleCloseMenu}
                  onKeyPress={handleCloseMenu}
                  aria-label="Close icon"
                />
              </Box>
              {isloggedIn ? (
                <>
                  <Grid item className="d-flex" sx={{ mt: 1 }}>
                    <Box sx={{ mt: 1 }}>
                      <img
                        width="50px"
                        height="50px"
                        className="default-img-border"
                        src={Constants.DEFAULT_LOGO}
                        alt="Default image"
                        onClick={goToJoblist}
                        onKeyPress={goToJoblist}
                        tabIndex={0}
                      />
                    </Box>
                    <Box className="m-t-b-10 align-center d-flex pl-15">
                      {getFromLocalStorage("enterpriseName") ? (
                        <Typography
                          variant="h5"
                          className="gray9 small-text-font font-weight-500 cursor-hover break-word align-left"
                        >
                          {getFromLocalStorage("enterpriseName")}
                        </Typography>
                      ) : (
                        <Typography
                          variant="span"
                          className="gray7 small-text-font cursor-hover break-word align-left"
                        >
                          {getFromLocalStorage("email")}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {getFromLocalStorage("enterpriseName") && (
                    <>
                      <Grid
                        item
                        className="d-flex"
                        style={{ marginTop: "9px" }}
                      >
                        <Box
                          sx={{ pl: 1 }}
                          className="gray9 small-text-font"
                          variant="span"
                          target="_blank"
                          onClick={goToJoblist}
                          onKeyPress={goToJoblist}
                          tabIndex={0}
                        >
                          Jobs
                        </Box>
                      </Grid>
                      <Grid
                        item
                        className="d-flex"
                        style={{ marginTop: "9px" }}
                      >
                        <Box
                          sx={{ pl: 1 }}
                          className="gray9 small-text-font"
                          variant="span"
                          target="_blank"
                          onClick={goToCampaign}
                          onKeyPress={goToCampaign}
                          tabIndex={0}
                        >
                          Campaign
                        </Box>
                      </Grid>

                      <Grid
                        item
                        className="d-flex"
                        style={{ marginTop: "9px" }}
                      >
                        <Box
                          sx={{ pl: 1 }}
                          className="gray9 small-text-font"
                          variant="span"
                          target="_blank"
                          onClick={goToClient}
                          onKeyPress={goToClient}
                          tabIndex={0}
                        >
                          Candidate portal
                        </Box>
                      </Grid>

                      {getFromLocalStorage("hideAccessControl") === null && (
                        <Grid
                          item
                          className="d-flex"
                          style={{ marginTop: "9px" }}
                        >
                          <Box
                            sx={{ pl: 1 }}
                            className="gray9 small-text-font"
                            variant="span"
                            target="_blank"
                            onClick={enterpriseAdminAccess}
                            onKeyPress={enterpriseAdminAccess}
                            tabIndex={0}
                          >
                            Configuration Control
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        item
                        className="d-flex"
                        style={{ marginTop: "9px" }}
                      >
                        <Box
                          tabIndex={0}
                          onKeyPress={goToMessages}
                          className="mt-30 cursor-hover"
                          onClick={goToMessages}
                          aria-label="Message icon"
                        >
                          <MessageNavIcon
                            style={{ width: "18px" }}
                            aria-label="Message icon"
                          />
                        </Box>
                      </Grid>
                      <Grid item className="d-flex mr-15">
                        <Button
                          id="basic-button3"
                          aria-controls="basic-menu3"
                          aria-haspopup="true"
                          className="dropdown-arrow text-transform-none cursor-normal"
                          aria-expanded={open3 ? "true" : "false"}
                          onClick={handleClick3}
                          role="button"
                          aria-label="Enotification"
                          disableRipple="true"
                        >
                          {notifications.length > 1 &&
                            notifications.length < 100 && (
                              <Box>
                                <Badge
                                  badgeContent={notificationsCount}
                                  color="secondary"
                                  className="notification-icon"
                                >
                                  <img
                                    src={notificationNavIcon}
                                    alt="Notification icon"
                                    width="20px"
                                    height="20px"
                                    className="cursor-hover contrasts"
                                    onClick={handleNotificationRead}
                                    onKeyPress={handleNotificationRead}
                                    tabIndex={0}
                                  />
                                </Badge>
                              </Box>
                            )}

                          {notifications.length > 99 && (
                            <Box>
                              <Badge
                                badgeContent={notificationsCount}
                                color="secondary"
                                className="notification-icon"
                              >
                                <img
                                  src={notificatioIcon}
                                  alt="Notification icon"
                                  width="20px"
                                  height="20px"
                                  className="cursor-hover contrasts icon-button"
                                  onClick={handleNotificationRead}
                                  onKeyPress={handleNotificationRead}
                                  tabIndex={0}
                                />
                              </Badge>
                            </Box>
                          )}

                          {notifications.length < 1 && (
                            <Box>
                              <img
                                src={notificatioIcon}
                                alt="Notification icon"
                                width="20px"
                                height="20px"
                                className="cursor-default"
                              />
                            </Box>
                          )}
                          {/*  <ArrowDropDownIcon className="f-20 gray9" />*/}
                        </Button>
                        {notifications.length > 1 && (
                          <>
                            <Menu
                              id="basic-menu3"
                              anchorEl={anchorE3}
                              open={open3}
                              onClose={handleClose3}
                              disableScrollLock={true}
                              PaperProps={{
                                style: {
                                  width: 400,
                                },
                              }}
                              MenuListProps={{
                                "aria-labelledby": "basic-button3",
                              }}
                            >
                              <Grid
                                className="notification-body"
                                sx={{ px: 1 }}
                              >
                                <Typography className="ml-10 pr-10 py-10 gray9 f-20 font-weight-600">
                                  Notifications
                                </Typography>
                                {notifications?.length >= 1 ? (
                                  notifications?.map((notification, index) => {
                                    return (
                                      <MenuItem
                                        className="d-block cursor-normal pl-10 pr-10 pt-5 pb-5"
                                        key={index}
                                      >
                                        <Typography className="d-flex border-bottom py-5">
                                          <span>
                                            <Typography className="notification-default-icon justify-center align-center d-flex">
                                              <NotificationDefaultIcon className="rect-svg-icons" />
                                              {/* {getValidFullName(fullname).trim().substring(0, 1)} */}
                                            </Typography>
                                          </span>
                                          <span className="pl-10">
                                            <Typography className="cursor-defaul break-space">
                                              <Typography className="gray9 f-14 font-weight-500">
                                                {notification.notificationValue}
                                              </Typography>
                                            </Typography>
                                            <Typography className="cursor-default">
                                              {formatDate(new Date()) ===
                                              formatDate(
                                                notification?.createdTimestamp
                                              ) ? (
                                                <Typography className="gray7 f-12 pt-5">
                                                  {/* {moment(new Date()).startOf('hour').fromNow()} */}
                                                  {isMinutesOrHours(
                                                    notification?.createdTimestamp
                                                  )}
                                                </Typography>
                                              ) : (
                                                <Typography>
                                                  {formatDate(onedayBefore) ===
                                                  formatDate(
                                                    notification?.createdTimestamp
                                                  ) ? (
                                                    <Typography className="gray7 f-12 pt-5">
                                                      {notification?.createdTimestamp ===
                                                        null ||
                                                      notification?.createdTimestamp ===
                                                        ""
                                                        ? "-"
                                                        : "Yesterday"}
                                                    </Typography>
                                                  ) : (
                                                    <>
                                                      {formatDate(
                                                        currentDateWeekFormat
                                                      ) <=
                                                      formatDate(
                                                        notification?.createdTimestamp
                                                      ) ? (
                                                        <Typography className="gray7 f-12 pt-5">
                                                          {notification?.createdTimestamp ===
                                                            null ||
                                                          notification?.createdTimestamp ===
                                                            ""
                                                            ? "-"
                                                            : moment(
                                                                notification?.createdTimestamp
                                                              ).format(
                                                                "MMM DD, YYYY"
                                                              )}
                                                        </Typography>
                                                      ) : (
                                                        <Typography className="gray7 f-12 pt-5">
                                                          {notification?.createdTimestamp ===
                                                            null ||
                                                          notification?.createdTimestamp ===
                                                            ""
                                                            ? "-"
                                                            : moment(
                                                                notification?.createdTimestamp
                                                              ).format(
                                                                "MMM DD, YYYY"
                                                              )}
                                                        </Typography>
                                                      )}
                                                    </>
                                                  )}
                                                </Typography>
                                              )}
                                            </Typography>
                                          </span>
                                          <span className="pl-10">
                                            {notification.notificationData && (
                                              <Typography
                                                className="cursor-hover"
                                                tabIndex={0}
                                                onClick={() =>
                                                  handleOpenUploadJobsDone(
                                                    notification.notificationData
                                                  )
                                                }
                                                onKeyPress={() =>
                                                  handleOpenUploadJobsDone(
                                                    notification.notificationData
                                                  )
                                                }
                                              >
                                                <span className="link-color f-12 font-weight-400">
                                                  View Status
                                                </span>
                                              </Typography>
                                            )}
                                          </span>
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem className="d-block p-10 mt-20">
                                    <Typography className="l-h-1 cursor-default text-center">
                                      <Typography>
                                        <NoNotificationIcon
                                          width={120}
                                          height={120}
                                        />
                                      </Typography>
                                      <Typography className="py-20">
                                        <span className="gray9 f-16 font-weight-600 py-20">
                                          You don’t have any notifications for
                                          now
                                        </span>
                                      </Typography>
                                    </Typography>
                                  </MenuItem>
                                )}
                              </Grid>
                              {notifications.length >= 1 && (
                                <>
                                  {/* <Divider /> */}
                                  <MenuItem
                                    tabIndex={0}
                                    className="link-color font-weight-600 f-14 align-item-center cursor-hover m-t-5 my-15"
                                    onClick={viewAllNotification}
                                    onKeyPress={viewAllNotification}
                                  >
                                    View all notifications
                                  </MenuItem>
                                </>
                              )}
                            </Menu>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        className="d-flex"
                        style={{ marginTop: "9px" }}
                      >
                        <Box
                          tabIndex={0}
                          onKeyPress={handleOpenFeedBack}
                          className="mt-30 cursor-hover"
                          onClick={handleOpenFeedBack}
                          aria-label="Feedback icon"
                        >
                          <SupportIcon
                            style={{ width: "18px" }}
                            aria-label="Feedback icon"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid item className="d-flex" style={{ marginTop: "9px" }}>
                    <Box
                      sx={{ pl: 1 }}
                      className="small-text-font danger-color"
                      variant="span"
                      onClick={signOut}
                      onKeyPress={signOut}
                      tabIndex={0}
                    >
                      Logout
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  {/* <Grid container item lg={12} md={12} xs={12}>
                      <Box className="enterpriseSearch" style={{ width: "100%" }}>
                        <Tooltip role="tooltip" title="Search candidates by skill, profile title, education, name, email id, checklist or phone number"
                            placement="top">
                          <TextField
                            style={{ width: "100%" }}
                            value={
                              location.pathname.includes("CandidateList") || location.pathname.includes("candidate-detail") ||
                                (showSearchFilter || filterValue)
                                ? (filterValue && type ? (getFormattedFilterValue(filterValue, type)) : filterValue)
                                : ""
                            }
                            type="search"
                            className="position-issue inputcontrasts"
                            variant="outlined"
                            margin="normal"
                            id="globalSearch"
                            placeholder="Search candidates by skill, profile title, education..."
                            onChange={handleJobTitleSearch}
                            onKeyUp={handleEnterKey}
                            inputProps={{ onDoubleClick: selectText, role: "" }}
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start" aria-label="Search icon">
                                  <SearchIcon aria-label="Search icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {filterValue && (
                                    <ClearIcon
                                      onClick={() => {
                                        setJobTitleFilterValue("");
                                        setFilterVaue("");
                                        setShowSearchFilter(false);
                                        if (
                                          location.pathname.includes(
                                            "/CandidateList"
                                          )
                                        ) {
                                          navigate("/CandidateList");
                                        }
                                      }}
                                      className="cursor-hover f-15"
                                    // aria-label="Clear icon"
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </Box>
                      {showSearchFilter && (
                        <SearchFilter
                          selectedjobTitleFilter={jobTitleFilterValue}
                          handleStateChange={handleStateChange}
                          setShowSearchFilter={setShowSearchFilter}
                        />
                      )}
                    </Grid> */}
                  <Box sx={{ pt: 1 }} className="py-10">
                    <Button
                      className=" step-color f-16 cursor-hover text-decoration-none font-weight-600 text-capitalize font-family"
                      style={{ padding: "4px 16px" }}
                      onClick={goToSignin}
                    >
                      Sign In
                    </Button>
                  </Box>
                  <Box sx={{ pt: 1 }} className="py-10">
                    {/*  <Button className="btn primary-button" style={{ padding: '4px 16px' }}>*/}
                    <Button
                      className=" step-color f-16 cursor-hover text-decoration-none font-weight-600 text-capitalize font-family"
                      onClick={goToSignup}
                      style={{ padding: "4px 16px" }}
                    >
                      Sign Up
                    </Button>
                    {/* </Button>*/}
                  </Box>
                </>
              )}
            </Box>
          </Menu>
        </>
      ) : (
        <Grid item className="navbar-fixed-loggedout resolution-issues">
          {/* <Container>*/}
          <Grid
            item
            container
            spacing={1}
            sx={{ px: 4 }}
            className="body-container"
          >
            <Grid
              container
              spacing={1}
              item
              lg={3}
              md={3}
              xs={2}
              className="align-center logoHeader pb-5"
            >
              {/*   {profilePicture ?
                                <Box className="mt-10">
                                    <Link to="/">
                                        <img className="logoImg" src={enterpriseLogo}
                                             alt="img"/>
                                    </Link>
                                </Box>:*/}

              {isloggedIn ? (
                <>
                  {localStorage.getItem("navbarLogo") && (
                    <Link to="/enterprise/jobList">
                      <Box className="cursor-hover navLogo align-item-center p-t-5">
                        <img
                          className="logoImg-header contrasts"
                          src={localStorage.getItem("navbarLogo")}
                          alt="Logo"
                          tabIndex={0}
                        />
                      </Box>
                    </Link>
                  )}
                  {!localStorage.getItem("navbarLogo") && (
                    <Link to="/enterprise/jobList">
                      <Box className="cursor-hover navLogo align-item-center p-t-5">
                        {appOrigin === "D" ?
                          <img
                            className="logoImg-header contrasts"
                            src={Constants.APP_LOGO_GENERAL}
                            alt="Logo"
                            tabIndex={0}
                          />
                          : appOrigin === "N" ?
                            <img
                              className="logoImg-navbar Nav-cont"
                              src={Constants.APP_LOGO_NEURODIVERSIFY}
                              alt="Logo"
                            /> :
                            <img
                              className="logoImg-navbar Nav-cont"
                              src={Constants.APP_LOGO_HEALTHCARE}
                              alt="Logo"
                            />
                        }
                        {/* <img
                          className="logoImg-header contrasts"
                          src={LogoImgWhite}
                          alt="Logo"
                          tabIndex={0}
                        /> */}
                      </Box>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Box className="">
                    {localStorage.getItem("navbarLogo") && (
                      <Box className="cursor-hover navLogo align-item-center">
                        <Link to="/">
                          <img
                            className="logoImg-navbar Nav-cont"
                            src={localStorage.getItem("navbarLogo")}
                            alt="Logo"
                          />
                        </Link>
                      </Box>
                    )}
                    {!localStorage.getItem("navbarLogo") && (
                      <Link to="/">
                        <Box className="cursor-hover navLogo align-item-center">
                          {appOrigin === "D" ?
                            <img
                              className="logoImg-navbar Nav-cont"
                              src={Constants.APP_LOGO_GENERAL}
                              alt="Logo"
                            />
                            : appOrigin === "N" ?
                              <img
                                className="logoImg-navbar Nav-cont"
                                src={Constants.APP_LOGO_NEURODIVERSIFY}
                                alt="Logo"
                              /> :
                              <img
                                className="logoImg-navbar Nav-cont"
                                src={Constants.APP_LOGO_HEALTHCARE}
                                alt="Logo"
                              />
                          }
                          {/* <img
                            className="logoImg-navbar Nav-cont"
                            src={DsaasLogoWhite}
                            alt="Logo"
                          /> */}
                        </Box>
                      </Link>
                    )}
                  </Box>
                </>
              )}

              {/*   }*/}
            </Grid>
            <Grid container item lg={5} md={5} xs={5} className="pt-0 mt-0">
              {/* <Box className="enterpriseSearch" style={{ width: "100%" }}>
                  <Tooltip role="tooltip" title="Search candidates by skill, profile title, education, name, email id, checklist or phone number"
                      placement="top">
                    <TextField
                      style={{ width: "100%" }}
                      value={
                        location.pathname.includes("CandidateList") || location.pathname.includes("candidate-detail") ||
                        (showSearchFilter || filterValue)
                          ? (filterValue && type ? (getFormattedFilterValue(filterValue, type)) : filterValue)
                          : ""
                      }
                      type="search"
                      className="position-issue inputcontrasts"
                      variant="outlined"
                      margin="normal"
                      id="globalSearch"
                      placeholder="Search candidates by skill, profile title, education..."
                      onChange={handleJobTitleSearch}
                      onKeyUp={handleEnterKey}
                      inputProps={{ onDoubleClick: selectText, role: "" }}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start" aria-label="Search icon">
                            <SearchIcon aria-label="Search icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {filterValue && (
                              <ClearIcon
                                onClick={() => {
                                  setJobTitleFilterValue("");
                                  setFilterVaue("");
                                  setShowSearchFilter(false);
                                  if (
                                    location.pathname.includes(
                                      "/CandidateList"
                                    )
                                  ) {
                                    navigate("/CandidateList");
                                  }
                                }}
                                className="cursor-hover f-15"
                              // aria-label="Clear icon"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                </Box>
                {showSearchFilter && (
                  <SearchFilter
                    selectedjobTitleFilter={jobTitleFilterValue}
                    handleStateChange={handleStateChange}
                    setShowSearchFilter={setShowSearchFilter}
                  />
                )} */}
            </Grid>
            <Grid
              container
              item
              spacing={3}
              lg={4}
              md={4}
              xs={5}
              className="align-center justify-end pt-0 mt-0"
            >
              {isloggedIn ? (
                <>
                  <Box
                    tabIndex={0}
                    onKeyPress={goToMessages}
                    className="ml-20 mt-40 cursor-hover nav-user-img message-nav-icon"
                    onClick={goToMessages}
                    aria-label="Message icon"
                  >
                    <Tooltip
                      title="Messages"
                      position="bottom"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <MessageIcon
                        style={{ width: "18px" }}
                        className="contrasts"
                        aria-label="Message icon"
                      />
                    </Tooltip>
                  </Box>
                  <Grid
                    sx={{ ml: 4 }}
                    className="d-flex mr-15 mt-35 headerIcon"
                  >
                    <Button
                      id="basic-button3"
                      aria-controls="basic-menu3"
                      aria-haspopup="true"
                      className="dropdown-arrow text-transform-none cursor-normal m-t-5 mt-1"
                      aria-expanded={open3 ? "true" : "false"}
                      onClick={handleClick3}
                      role="button"
                      aria-label="Notification"
                      disableRipple="true"
                    >
                      {notifications.length > 1 && notifications.length < 100 && (
                        <Box>
                          <Badge
                            badgeContent={notificationsCount}
                            color="secondary"
                            className="notification-icon"
                          >
                            <Tooltip
                              title="Notifications"
                              position="bottom"
                              trigger="mouseenter"
                              animation="fade"
                              arrow
                            >
                              <img
                                src={notificatioIcon}
                                alt="Notification icon"
                                width="20px"
                                height="20px"
                                className="cursor-hover contrasts icon-button"
                                onClick={handleNotificationRead}
                                onKeyPress={handleNotificationRead}
                                tabIndex={0}
                              />
                            </Tooltip>
                          </Badge>
                        </Box>
                      )}

                      {notifications.length > 99 && (
                        <Box>
                          <Badge
                            badgeContent="99+"
                            color="secondary"
                            className="notification-icon"
                          >
                            <Tooltip
                              title="Notifications"
                              position="bottom"
                              trigger="mouseenter"
                              animation="fade"
                              arrow
                            >
                              <img
                                src={notificatioIcon}
                                alt="Notification icon"
                                width="20px"
                                height="20px"
                                className="cursor-hover contrasts icon-button"
                                onClick={handleNotificationRead}
                                onKeyPress={handleNotificationRead}
                                tabIndex={0}
                              />
                            </Tooltip>
                          </Badge>
                        </Box>
                      )}

                      {notifications.length <= 1 && (
                        <Box className="d-flex">
                          <Tooltip
                            title="Notifications"
                            position="bottom"
                            trigger="mouseenter"
                            animation="fade"
                            arrow
                          >
                            <img
                              src={notificatioIcon}
                              alt="Notification icon"
                              width="20px"
                              height="20px"
                              className="cursor-hover icon-button"
                              onClick={handleNotificationRead}
                              onKeyPress={handleNotificationRead}
                              tabIndex={0}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Button>

                    <Menu
                      id="basic-menu3"
                      anchorEl={anchorE3}
                      open={open3}
                      onClose={handleClose3}
                      disableScrollLock={true}
                      PaperProps={{
                        style: {
                          width: 400,
                        },
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button3",
                      }}
                    >
                      <Grid className="notification-body" sx={{ px: 1 }}>
                        <Typography className="ml-10 pr-10 py-10 gray9 f-20 font-weight-600">
                          Notifications
                        </Typography>
                        {notifications?.length >= 1 ? (
                          notifications?.map((notification, index) => {
                            return (
                              <MenuItem
                                className="d-block cursor-normal pl-10 pr-10 pt-5 pb-5"
                                key={index}
                              >
                                <Typography className="d-flex border-bottom py-5">
                                  <span>
                                    <Typography className="notification-default-icon justify-center align-center d-flex">
                                      <NotificationDefaultIcon
                                        width="40px"
                                        height="40px"
                                        className="rect-svg-icons"
                                      />
                                      {/* {getValidFullName(fullname).trim().substring(0, 1)} */}
                                    </Typography>
                                  </span>
                                  <span className="pl-10">
                                    <Typography className="cursor-defaul break-space">
                                      <Typography className="gray9 f-14 font-weight-500">
                                        {notification.notificationValue}
                                      </Typography>
                                    </Typography>
                                    <Typography className="cursor-default">
                                      {formatDate(new Date()) ===
                                      formatDate(
                                        notification?.createdTimestamp
                                      ) ? (
                                        <Typography className="gray7 f-12 pt-5">
                                          {/* {moment(new Date()).startOf('hour').fromNow()} */}
                                          {isMinutesOrHours(
                                            notification?.createdTimestamp
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          {formatDate(onedayBefore) ===
                                          formatDate(
                                            notification?.createdTimestamp
                                          ) ? (
                                            <Typography className="gray7 f-12 pt-5">
                                              {notification?.createdTimestamp ===
                                                null ||
                                              notification?.createdTimestamp ===
                                                ""
                                                ? "-"
                                                : "Yesterday"}
                                            </Typography>
                                          ) : (
                                            <>
                                              {formatDate(
                                                currentDateWeekFormat
                                              ) <=
                                              formatDate(
                                                notification?.createdTimestamp
                                              ) ? (
                                                <Typography className="gray7 f-12 pt-5">
                                                  {notification?.createdTimestamp ===
                                                    null ||
                                                  notification?.createdTimestamp ===
                                                    ""
                                                    ? "-"
                                                    : moment(
                                                        notification?.createdTimestamp
                                                      ).format("HH:mm")}
                                                </Typography>
                                              ) : (
                                                <Typography className="gray7 f-12 pt-5">
                                                  {notification?.createdTimestamp ===
                                                    null ||
                                                  notification?.createdTimestamp ===
                                                    ""
                                                    ? "-"
                                                    : moment(
                                                        notification?.createdTimestamp
                                                      ).format("MMM DD, YYYY")}
                                                </Typography>
                                              )}
                                            </>
                                          )}
                                        </Typography>
                                      )}
                                    </Typography>
                                  </span>
                                  <span className="pl-10">
                                    {notification.notificationData && (
                                      <Typography
                                        className="cursor-hover"
                                        tabIndex={0}
                                        onClick={() =>
                                          handleOpenUploadJobsDone(
                                            notification.notificationData
                                          )
                                        }
                                        onKeyPress={() =>
                                          handleOpenUploadJobsDone(
                                            notification.notificationData
                                          )
                                        }
                                      >
                                        <span className="link-color f-12 font-weight-400">
                                          View Status
                                        </span>
                                      </Typography>
                                    )}
                                  </span>
                                </Typography>
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem className="d-block p-10 mt-20">
                            <Typography className="l-h-1 cursor-default text-center">
                              <Typography>
                                <NoNotificationIcon width={120} height={120} />
                              </Typography>
                              <Typography className="py-20">
                                <span className="gray9 f-16 font-weight-600 py-20">
                                  You don’t have any notifications for now
                                </span>
                              </Typography>
                            </Typography>
                          </MenuItem>
                        )}
                      </Grid>
                      {notifications.length >= 1 && (
                        <>
                          {/* <Divider /> */}
                          <MenuItem
                            tabIndex={0}
                            className="link-color font-weight-600 f-14 align-item-center cursor-hover m-t-5 my-15"
                            onClick={viewAllNotification}
                            onKeyPress={viewAllNotification}
                          >
                            View all notifications
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  </Grid>
                  <Box
                    tabIndex={0}
                    onKeyPress={handleOpenFeedBack}
                    className="ml-20 mt-40 cursor-hover nav-user-img message-nav-icon"
                    onClick={handleOpenFeedBack}
                    aria-label="Message icon"
                  >
                    <Tooltip
                      title="Feedback"
                      position="bottom"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <SupportIcon
                        style={{ width: "18px" }}
                        className="contrasts"
                        aria-label="Message icon"
                      />
                    </Tooltip>
                  </Box>
                  <Grid
                    className="d-flex ml-15 nav-user-img"
                    style={{ marginTop: "40px" }}
                  >
                    <Button
                      id="basic-button2"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-expanded={open ? "true" : "false"}
                      // aria-controls="basic-menu2"
                      aria-haspopup="true"
                      className="dropdown-arrow text-transform-none"
                      onClick={handleClick2}
                      role="button"
                      aria-label="Profile picture"
                      disableRipple="true"
                    >
                      <Grid className="d-flex cursor-hover">
                        <Box className="enterprise-profile">
                          {profilePicture && (
                            <img
                              width="50px"
                              height="50px"
                              style={{
                                objectFit: profilePicture.includes("gif")
                                  ? "cover"
                                  : "",
                              }}
                              className="default-img-border"
                              src={profilePicture}
                              alt="Profile picture"
                            />
                          )}
                          {!profilePicture && (
                            <img
                              width="50px"
                              height="50px"
                              className="default-img-border"
                              src={Constants.DEFAULT_LOGO}
                              alt="Profile picture"
                            />
                          )}
                          {/*   {enterpriseLogo
                                                            &&
                                                            <img width="45px" height="45px" className="round-image" src={enterpriseLogo}
                                                                 alt="img"/>}
                                                        {!enterpriseLogo
                                                            &&
                                                            <img width="45px" height="45px" className="round-image" src="https://daas-prog-test.s3.amazonaws.com/default-logo.png"
                                                                alt="img"/>}*/}
                        </Box>

                        {/*  <Grid className="d-flex" style={{ marginTop: '7px' }}>
                                                <Box sx={{ pl: 1 }} className="link-color small-text-font" variant="span"> {getFromLocalStorage("name")}</Box>
                                            </Grid>*/}
                      </Grid>
                      <Box sx={{ ml: 1 }}>
                        {getFromLocalStorage("enterpriseName") ? (
                          <Tooltip
                            title={
                              <div>
                                {getFromLocalStorage("enterpriseName")}
                                <br />
                                {localStorage.getItem("name")
                                  ? localStorage.getItem("name")
                                  : localStorage
                                      .getItem("email")
                                      ?.split("@")[0]}{" "}
                                <br />
                                {localStorage.getItem("email")} <br />
                                {localStorage.getItem("profileTitle")}
                              </div>
                            }
                            position="top"
                            trigger="mouseenter"
                            animation="fade"
                            arrow
                          >
                            {/*   <Tooltip title={getFromLocalStorage("enterpriseName")} placement="top">*/}

                            <Typography
                              variant="h5"
                              className="gray9 small-text-font font-weight-500 cursor-hover textEllipse enterprise-name-width enterprise-user-name align-left"
                              sx={{ pb: 1 }}
                            >
                              {getFromLocalStorage("enterpriseName")}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={getFromLocalStorage("email")}
                            position="top"
                            trigger="mouseenter"
                            animation="fade"
                            arrow
                          >
                            <Typography
                              variant="span"
                              className="gray9 small-text-font cursor-hover textEllipse w-85 align-left"
                            >
                              {getFromLocalStorage("email")}
                            </Typography>
                          </Tooltip>
                        )}
                      </Box>
                      <ArrowDropDownIcon
                        className="f-20 gray9 ml-10 mb-10 mt-5 inputcontrasts"
                        aria-label="Dropdown icon"
                      />
                    </Button>
                    <Menu
                      id="account-menu"
                      anchorEl={anchorE2}
                      open={open2}
                      onClose={handleClose2}
                      onClick={handleClose2}
                      onKeyPress={handleClose2}
                      className=""
                      disableScrollLock={true}
                      MenuListProps={{
                        "aria-labelledby": "basic-button2",
                      }}
                      KeepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      {/* {getFromLocalStorage("enterpriseName") && <>
                                                    <MenuItem onClick={goToJoblist}><JobIcon className="mr-5" alt="img" width="15px"/>Jobs</MenuItem>
                                                    <MenuItem onClick={goToCampaign}><EmailIcon className="mr-6" alt="img" width="13px"/>Campaign</MenuItem>
                                                    <MenuItem onClick={goToClient}><ClientPortalIcon className="mr-5" alt="img" width="15px"/>Candidate Portal</MenuItem>
                                                    {(getFromLocalStorage("hideAccessControl")===null) && <MenuItem onClick={enterpriseAdminAccess}><AccessIcon className="mr-6" alt="img" width="15px"/>Access Control</MenuItem>}
                                                    {(getFromLocalStorage("hideAccessControl")===null) && <MenuItem onClick={goToAnalytics}><AnalyticsIcon className="mr-6" alt="img" width="15px"/>Analytics</MenuItem>}
                                                </>} */}
                      <MenuItem
                        className="danger-color icon-button"
                        onClick={signOut}
                        onKeyPress={signOut}
                        tabIndex={0}
                        aria-label="Logout icon"
                      >
                        <LogoutNavIcon
                          className="mr-6 icon-button"
                          alt="Logout icon"
                          width="15px"
                          aria-label="Logout icon"
                        />
                        Logout
                      </MenuItem>
                    </Menu>
                  </Grid>
                </>
              ) : (
                <>
                  <Box sx={{ px: 4 }}>
                    <Button
                      className="step-color small-text-font cursor-hover font-weight-600 text-decoration-none d-flex text-capitalize font-family"
                      onClick={goToSignin}
                    >
                      Sign In
                    </Button>
                  </Box>
                  <Box>
                    {/* <Button className="btn primary-button mt-30 ml-20" style={{ padding: '4px 16px' }}>*/}
                    <Button
                      className="step-color small-text-font cursor-hover font-weight-600 text-decoration-none d-flex text-capitalize font-family"
                      // to="/signup"
                      onClick={goToSignup}
                    >
                      Sign Up
                    </Button>
                    {/*</Button>*/}
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
          {/* </Container>*/}
        </Grid>
      )}
      <FeedBackModal open={openfeedback} onClose={handleFeebackModalClose} />
      <NotificationList
        //handleNotificationDrawer={handleNotificationDrawer}
        notificationListDrawer={notificationListDrawer}
        setNotificationListDrawer={setNotificationListDrawer}
      />
    </>
  );
}
