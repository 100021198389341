import React from "react";
import { useEffect } from "react";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./../assets/loader.json";
import { Grid } from "@mui/material";
import { colorify, flatten, getColors } from 'lottie-colorify';

export default function Loader() {
  const convert3DigitHexTo6Digit = (hexcolor) => {
    if (hexcolor.length === 4) {
      return hexcolor.substring(1, 4).split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return hexcolor;
  };
  //let themeColor = convert3DigitHexTo6Digit(localStorage.getItem("primary_color") != null ? localStorage.getItem("primary_color") : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
  let themeColor = (window.location.pathname === "/") ? "#150349" : convert3DigitHexTo6Digit(localStorage.getItem("primary_color") != null ? localStorage.getItem("primary_color") : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
   console.log(themeColor);

  const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, (color) => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  };

  const options = {
    // animationData: groovyWalkAnimation,
    animationData: colorify([themeColor ? themeColor : "#150349", adjustColor(themeColor ? themeColor : "#150349", 40), adjustColor(themeColor ? themeColor : "#150349", 70), adjustColor(themeColor ? themeColor : "#150349", 95)], groovyWalkAnimation),
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  useEffect(() => {
    // handleCreateProfile()
  }, []);


  return (
    <>
      <Grid item className="background-blur"></Grid>
      <Grid item lg={12} className="loader">
        <Grid item lg={12} className="">
          {View}
        </Grid>
      </Grid>
    </>
  );
}
