
import React, { useState, useEffect } from "react";
import { Typography, Button, TextareaAutosize, Link, TextField, Modal, Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import paeprClipIcon from "../../assets/images/paper-clip.svg";
import DocumentIcon from "../../assets/images/document.svg";
import deleteIcon from "../../assets/images/delete-new-icon.svg";
// import { success, warning, danger } from "../../Components/shared/messageBox";
import { handleSpaces } from "../../helpers/helper-data";
import ApiCalls from "../../api/customAxios";
import { browserName, browserVersion } from "react-device-detect";
import AccessService from "../../Services/Enterprise/Access.service";
import { danger, success } from "../../helpers/message-box";
import copy from "copy-to-clipboard";
import Loader from "../Loader";

const FeedBackModal = React.memo(function FeedBackModal(props) {
    const { open, onClose } = props;
    const currentUrl = window.location.href;
    const appVersion = process.env.REACT_APP_APP_VERSION;
    const [review, setReview] = useState("");
    const hiddenFileInput = React.useRef(null);
    const [ipAddress, setIpAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [browserWidth, setBrowserWidth] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [browserHeight, setBrowserHeight] = useState(null);
    const [subject, setSubject] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const browser = browserName;
    const version = browserVersion;
    const operatingSystem = getOperatingSystem();
    function getOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/win64|wow64/i.test(userAgent)) {
            return "Windows 64-bit";
        }

        if (/win32/i.test(userAgent)) {
            return "Windows 32-bit";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iphone|ipad|ipod/i.test(userAgent)) {
            return "iOS";
        }

        if (/mac/i.test(userAgent)) {
            return "Mac OS";
        }

        if (/linux/i.test(userAgent)) {
            return "Linux";
        }

        return null;
    };
    useEffect(() => {
        if (open) {
            const fetchLocation = () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLatitude(position.coords.latitude);
                            setLongitude(position.coords.longitude);
                        },
                        (error) => {
                        }
                    );
                } else {
                    console.error('Geolocation is not supported by this browser.');
                }
            };
            fetchLocation();
        }
    }, [open]);
    useEffect(() => {
        if (open) {
            const fetchIpAddress = async () => {
                try {
                    const response = await fetch('https://api.ipify.org/?format=json');
                    const data = await response.json();
                    setIpAddress(data.ip);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchIpAddress();
        }
    }, [open]);
    useEffect(() => {
        const handleResize = () => {
              setBrowserWidth(window.innerWidth);
              setBrowserHeight(window.innerHeight);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [open]);
    // console.log()
    const [isScreenshotTrue, setIsScreenshotTrue] = useState([]);
    const takefile = () => {
        hiddenFileInput.current.value = null;
        hiddenFileInput.current.click();
    };
    let pageTitle = document.title?.split("|")[0];
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded && fileUploaded.type.startsWith('image/')) {
            if (fileUploaded.size <= 1000000) {
                setIsScreenshotTrue([...isScreenshotTrue, fileUploaded]);
            } else {
                danger("Maximum upload size exceeded. Try to upload a file upto 1MB.");
            }
        } else {
            danger("Please upload only JPG, JPEG, PNG formats!");
        }
        event.target.value = null;
    };

    // html2json
    const TextElem = (e) => ({
        type: 'TextElem',
        textContent: e.textContent
    });
    const fromNode = (e) => {
        switch (e?.nodeType) {
          case 1: return Elem(e);
          case 3: return TextElem(e);
          default: throw Error(`unsupported nodeType: ${e.nodeType}`);
        }
    };
    const Elem = (e) => {
        return {
            type: 'Elem',
            tagName: e.tagName,
            children: e.childNodes ? Array.from(e.childNodes, fromNode) : []
        };
    };
    const html2json = (e) => JSON.stringify(Elem(e), null, '  ');

    const getIds = (url) => {
        let id = "";
          if (url.includes('/enterprise/jobList')) {
            id = document.getElementById('jobs');
          } else if (url.includes('/enterprise/matching-candidate-description')) {
            id = document.getElementById('matching-candidate-description');
        } else if (url.includes('/enterprise/my-candidate/candidate-detail')) {
            id = document.getElementById('enterprise-candidate');
          } else if (url.includes("leads-talent-pool-list")) {
            id = document.getElementById("leadTalentPool");
            } else if (url.includes('/enterprise/my-candidate')) {
            id = document.getElementById('myCandidates');
          } else if (url.includes('/enterprise/campaignList')) {
            id = document.getElementById('campainList');
          } else if (url.includes('/enterprise/talent-pool-management')) {
            id = document.getElementById('talent-pool-management');
          } else if (url.includes('/enterprise/access')) {
            id = document.getElementById('access-control');
          } else if (url.includes('/enterprise/jobs/')) {
            id = document.getElementById('matching-candidates');
          } else if (url.includes('/enterprise/edit-campaign') || url.includes('/enterprise/createCampaign')) {
            id = document.getElementById('create-campaign');
          } else if (url.includes('/enterprise/campaignDetail')) {
            id = document.getElementById('campaign-details');
          } else if (url.includes('/enterprise/CandidateList')) {
            id = document.getElementById('candidateList');
          } else if (url.includes('/enterprise/postJob')) {
            id = document.getElementById('create-job');
          } else if (url.includes('/enterprise/jobsLog')) {
            id = document.getElementById('upload-logs');
          } else if (url.includes('/enterprise/Analytics')) {
            id = document.getElementById('analytics');
          } else if (url.includes('/enterprise/edit_logs')) {
            id = document.getElementById('edit-logs');
          } else if (url.includes('/enterprise/message-list')) {
            id = document.getElementById('message-list');
          } else if (url.includes('/enterprise/screeningQuestions')) {
            id = document.getElementById('screening-questions');
          } else if (url.includes("/enterprise/settings")) {
            id = document.getElementById('settings');
          } else if (url.includes("/enterprise/talent-pool-candidate-list")) {
                id = document.getElementById("TalentPoolCandidateList");
              } else if (url.includes("candidate-detail")) {
                id = document.getElementById('enterprise-candidate');
            } else if (url.includes("enterprise/talent-pool")) {
                id = document.getElementById("talent-pool");
            } else if (url.includes("enterprise/interviewList")) {
                id = document.getElementById("interviewList");
            } else if (url.includes("enterprise/interview-details")) {
                id = document.getElementById("interview-details");
            } else if (url.includes("enterprise/schedule-interview")) {
                id = document.getElementById("schedule-interview");
            } else if (url.includes("enterprise/talent-console")) {
                id = document.getElementById("talent-console");
            } else if (url.includes("enterprise/talent-console-candidates")) {
                id = document.getElementById("talent-console-candidates");
            } else if (url.includes("enterprise/referrals")) {
                id = document.getElementById("Referrals");
            } else {
                id = "";
            }
        return id;
      };
      const getMenuName = (url)=>{
        let id;
        if (url.includes('/enterprise/jobList')) {
            id = "Job List";
          } else if (url.includes('/enterprise/matching-candidate-description')) {
            id = "Matching Candidate Details";
          } else if (url.includes('/enterprise/my-candidate/candidate-detail')) {
            id = "Candidate Details";
          } else if (url.includes("leads-talent-pool-list")) {
            id = "Add to Talent Pool";
            } else if (url.includes('/enterprise/my-candidate')) {
            id = "Candidates";
          } else if (url.includes('/enterprise/campaignList')) {
            id = "Campaign List";
          } else if (url.includes('/enterprise/talent-pool-management')) {
            id = "Talent Pool Management";
          } else if (url.includes('/enterprise/access')) {
            id = "Configuration Control";
          } else if (url.includes('/enterprise/jobs/')) {
            id = "Matching Candidates";
          } else if (url.includes('/enterprise/edit-campaign')) {
            id = "Edit Campaign";
          } else if (url.includes('/enterprise/createCampaign')) {
            id = "Create Campaign";
          } else if (url.includes('/enterprise/campaignDetail')) {
            id = "Campaign Detail";
          } else if (url.includes('/enterprise/CandidateList')) {
            id = "Candidate List";
          } else if (url.includes('/enterprise/postJob')) {
            id = "Post Job";
          } else if (url.includes('/enterprise/jobsLog')) {
            id = "Jobs Log";
          } else if (url.includes('/enterprise/Analytics')) {
            id = "Analytics";
          } else if (url.includes('/enterprise/edit_logs')) {
            id = "Edit logs";
          } else if (url.includes('/enterprise/message-list')) {
            id = "Message List";
          } else if (url.includes('/enterprise/screeningQuestions')) {
            id = "Screening Questions";
          } else if (url.includes("/enterprise/settings")) {
            id = "Settings";
          } else if (url.includes("/enterprise/talent-pool-candidate-list")) {
            id = "Talent Pool Candidate List";
          } else if (url.includes("candidate-detail")) {
                id = "Candidate Details";
            } else if (url.includes("enterprise/talent-pool")) {
                id = "Talent Pool Candidate List";
            } else if (url.includes("enterprise/interviewList") || url.includes("enterprise/interview-details") || url.includes("enterprise/schedule-interview")) {
                id = "Interviews";
            } else if (url.includes("enterprise/talent-console")) {
                id = "Talent Console";
            } else if (url.includes("enterprise/talent-console-candidates")) {
                id = "Talent Console Candidate List";
            } else if (url.includes("enterprise/referrals")) {
                id = "My Referrals";
            } else {
                id = "";
            }
        return id;
      };
    const handleSubmit = () => {
        setIsLoading(true);
        // console.log(getIds(window.location.pathname), window.location.pathname);
        // console.log(html2json(getIds(window.location.pathname)));
        AccessService.getisFeedBackExist().then((res) => {
            if (res) {
                const formData = new FormData();
                isScreenshotTrue.forEach((image, index) => {
                    formData.append("attachments", image);
                });
                const requestBody = {
                    browserName: browser,
                    browserVersion: version,
                    appVersion: appVersion,
                    resolution: `${browserWidth}x${browserHeight}`,
                    platform: operatingSystem,
                    application: "hireHQ Enterprise",
                    menuUrl: getMenuName(window.location.pathname),
                    subject: subject,
                    enterpriseName: localStorage.getItem("enterpriseName"),
                    feedback: review,
                    ipAddress: ipAddress,
                    userId: localStorage.getItem("candidateId"),
                    latitude: latitude,
                    longitude: longitude,
                    formData: html2json(getIds(window.location.pathname))
                  };
                  if (localStorage.getItem("childEnterpriseId")) {
                    requestBody.mspEnterpriseName = localStorage.getItem("mspEnterpriseName");
                }
                  formData.append('request-body', new Blob([JSON.stringify(requestBody)], {
                    type: "application/json"
                  }));
                AccessService.sendFeedBack(formData).then((response) => {
                    if (response) {
                        success("Thanks for the feedback.");
                        setSubject("");
                        setReview("");
                        setIsScreenshotTrue([]);
                        setIsLoading(false);
                        onClose();
                    }
                }).catch((error)=>{
                    setIsLoading(false);
                    danger("Something went Wrong.");
                });
            } else {
                const payload = {
                    Feedback: review,
                    Application: "hireHQ Enterprise",
                    AppVersion: appVersion,
                    Platform: operatingSystem,
                    MenuName: getMenuName(window.location.pathname),
                    Subject: subject,
                    Enterprise_Name: localStorage.getItem("enterpriseName"),
                    UserId: localStorage.getItem("candidateId"),
                    FirstName: localStorage.getItem("name")?.split(" ")[0],
                    LastName: localStorage.getItem("name").split(" ")[1],
                    Email: localStorage.getItem("email"),
                    Latitude: latitude,
            Longitude: longitude,
            IpAddress: ipAddress,
            Resolution: `${browserWidth}x${browserHeight}`,
            BrowserName: browser,
            BrowserVersion: version,
            formData: html2json(getIds(window.location.pathname))
        };
        if (localStorage.getItem("childEnterpriseId")) {
            payload.MSP_Name = localStorage.getItem("mspEnterpriseName");
        }
                const payloadString = Object.entries(payload)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(", ");
                copy(JSON.stringify(payloadString));
                success("Details are copied to the clipboard.");
                setSubject("");
                setReview("");
                setIsScreenshotTrue([]);
                setIsLoading(false);
                onClose();
            }
        }).catch((err) => {
            setIsLoading(false);
        });
    };
    const handleDeleteImage = (id) => {
        if (isScreenshotTrue.length > 1) {
            setIsScreenshotTrue(
                isScreenshotTrue.filter((location, _index) => _index !== id)
            );
        } else {
            setIsScreenshotTrue([]);
        }
    };
    const handleReset = () => {
        setReview("");
        setIsScreenshotTrue([]);
    };
    const handleModalClose = () => {
        setSubject("");
        setReview("");
        setIsScreenshotTrue([]);
        onClose();
    };
    if (isLoading) {
        return <Loader />;
    }
    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-content align-left inputcontrasts feedback-width">
                <Grid container id="modal-title">
                    <Grid item xs={11}>
                        <Typography
                            className="f-16 font-weight-600 heading-text-color modalTitle p-l-5"
                            align="left"
                        >
                            Feedback
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography align="right" className="gray9" onClick={handleModalClose}>
                            <CloseIcon className="cursor-hover" />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid id="modal-description" className="feebackModal-height">
                    <Grid item xs={12}>
                    <Typography className="font-weight-400 f-14 mb-10 gray8">
                            Subject
                            <span className="">*</span>
                        </Typography>
                        <Typography className="input-focus-none mb-10">
                        <TextField
                  type="text"
                  className="form-control input-group mb-0 inputcontrasts"
                  margin="normal"
                  placeholder="Enter subject"
                  value={subject}
                  maxLength={128}
                  onChange={(e) => {
                                    if (e.target.value.length === 1) {
                                        setSubject(e.target.value.toUpperCase());
                                        return;
                                    }
                                    if (e.target.value.length > 128) {
                                        return;
                                        setSubjectError("Exceeded maximum character length of 128");
                                    } else {
                                        setSubjectError("");
                                    }
                                    setSubject(e.target.value);
                                }}
                                onKeyPress={handleSpaces}
                />
                        </Typography>
                        <Typography className="font-weight-400 f-14 mb-10 gray8">
                            Tell us about your experience
                            <span className="">*</span>
                        </Typography>
                        <Typography className="input-focus-none">
                            <TextareaAutosize
                                minRows={2}
                                maxRows={4}
                                aria-label="maximum height"
                                className="form-control input-group inputcontrasts"
                                maxLength={256}
                                inputProps={{ maxLength: 256 }}
                                onChange={(e) => {
                                    if (e.target.value.length === 1) {
                                        setReview(e.target.value.toUpperCase());
                                        return;
                                    }
                                    setReview(e.target.value);
                                }}
                                onKeyPress={handleSpaces}
                                name="review"
                                id="review"
                                value={review}
                                placeholder="Enter feedback"
                                style={{ height: "150px" }}
                            />
                            <Typography
                                align="right"
                                className="sub-text-color sub-text-font"
                            >
                                {review?.length ? review?.length : 0}/256
                            </Typography>
                        </Typography>
                        <Typography>
                            {isScreenshotTrue?.length > 0 && <Typography className="d-flex align-center mb-10" style={{ flexDirection: "column" }}>
                                <>
                                    {isScreenshotTrue?.map((image, key) => {
                                        return <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            key={key}
                                            className="d-flex mt-5  justify-between file-cards p-0 m-l-5"
                                            style={{ width: "100%" }}
                                        >
                                            <Grid
                                                item
                                                lg={10}
                                                md={10}
                                                xs={10}
                                                className="d-flex align-center"
                                            >
                                                {" "}
                                                <img
                                                    src={DocumentIcon}
                                                    alt="img"
                                                    className="m-r-10 ml-10"
                                                    width="18px"
                                                    height="18px"
                                                />
                                                {" "}
                                                <p className="gray7 f-14 textEllipse text-capitalize">
                                                    {image?.name}
                                                </p>{" "}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                xs={2}
                                                className="font-13 align-item-right d-flex"
                                            >
                                                <span
                                                    tabIndex={0}
                                                    onKeyPress={(e) => handleDeleteImage(key)}
                                                    className="align-center d-flex sub-text-font m-r-10 cursor-hover gray7"
                                                    onClick={(e) => handleDeleteImage(key)}
                                                >
                                                    <img
                                                        className="sub-text-font"
                                                        src={deleteIcon}
                                                        alt="delete icon"
                                                    />
                                                </span>
                                            </Grid>
                                        </Grid>;
                                    })}
                                </>
                            </Typography>}
                            {isScreenshotTrue?.length <= 1 &&
                                <Typography className="d-flex align-center">
                                    <img src={paeprClipIcon} />
                                    <Typography className="cursor-hover f-14 font-weight-400 gray8 m-l-5" onClick={takefile}>Add Screenshot</Typography>
                                    <input type="file" accept="image/jpeg, image/png, image/jpg, image/gif" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleChange} />
                                </Typography>}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid id="modal-footer" className="justify-end d-flex">
                    <Typography className="d-flex align-center">
                        {/* <Typography className="link-color f-14 font-weight-400 mr-20" onClick={handleReset}>Reset</Typography> */}
                        <Button
                            className="btn primary-button"
                            disabled={subject === "" || review === "" || subjectError}
                            onClick={handleSubmit}
                            tabIndex={0}
                            disableRipple="true"
                        >
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Box>
        </Modal>
    );
});

export default FeedBackModal;
