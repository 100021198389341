import {
  Grid, Select, Typography, MenuItem, IconButton
} from "@mui/material";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AccessService from "../../Services/Enterprise/Access.service";
import { Helmet } from "react-helmet";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import constants from "../../helpers/constants";

let filters = [];

export default function Analytics() {
  const appOrigin = useSelector((state) => state.location.origin);
  const { state } = useLocation();
  const [option, setOption] = React.useState(0);
  const [jobs, setJobs] = React.useState([]);
  const [reportOptions, setReportOptions] = React.useState({
    id: "",
    token: "",
    emberUrl: "",
  });
  useEffect(() => {
    toast.dismiss();
  }, []);


  useEffect(async () => {
    if (state?.option || state?.report === process.env.REACT_APP_REPORTS_DIRECT_SOURCING_DASHBOARD) {
      getReport(state?.report, state?.option);
    }
  }, [state]);
  useEffect(() => {
    if (state?.toSurvey) {
      getReport(process.env.REACT_APP_SATISFACTION_SURVEY, 13);
    }
  }, [state?.toSurvey]);
  const getReport = (reportId, option) => {
    AccessService.getJobBoardAnalytics(reportId).then((res) => {
      if (option === 7) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "job_performance",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 1) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "candidate_pool_records",
              column: "enterprise_id",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "summary",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
        if (appOrigin === "N") {
          filters.push(
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "job_records",
                column: "boundary",
              },
              operator: "In",
              values: ["2"],
            },
          );
        }
      } else if (option === 2) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "candidate_pool_records",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          // {
          //   $schema: "http://powerbi.com/product/schema#basic",
          //   target: {
          //     table: "candidate_records",
          //     column: "enterpriseId",
          //   },
          //   operator: "In",
          //   values: [localStorage.getItem("enterpriseId")],
          // },
        ];
        if (appOrigin === "N") {
          filters.push(
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "candidate_records",
                column: "neuro_classification",
              },
              operator: "In",
              values: ["ND"],
            }
          );
        }
      } else if (option === 3) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "skill_radar",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 4) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "HiringVelocity",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 5) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "pulse-results",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 6) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "spent",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 0) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "applicantSummary",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "enterpriseDashboard",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "candidate_pool_records",
              column: "enterprise_id",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "jobStatistics",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "offerSummary",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "UnreadMessages",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
        if (appOrigin === "N") {
          filters.push(
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "job_records",
                column: "boundary",
              },
              operator: "In",
              values: ["2"],
            },
          );
        }
      } else if (option === 8) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "gender",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "turnover",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 10) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "candidate_pool_records",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
        if (appOrigin === "N") {
          filters.push(
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "job_records",
                column: "boundary",
              },
              operator: "In",
              values: ["2"],
            });
        }
      } else if (option === 11) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "candidate_pool_records",
              column: "enterprise_id",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 12) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "application-completion-rate",
              column: "enterpriseId",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 13) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "Vitals",
              column: "enterprise_id",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else if (option === 14) {
        filters = [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "interview_analytics",
              column: "enterprise_id",
            },
            operator: "In",
            values: [localStorage.getItem("enterpriseId")],
          },
        ];
      } else {
        filters = [];
      }
      setReportOptions({
        id: res.reportId,
        token: res.embedToken,
        emberUrl: res.embedUrl,
      });
    });
  };

  return (
    <>
      <Helmet>
        {appOrigin === "N" ?
        <title>Analytics | {constants.APP_NAME_NEURODIVERSIFY}</title>
          : appOrigin === "H" ? <title>Analytics | {Constants.APP_NAME_DIRECT_HEALTH}</title> :
          <title>Analytics | {constants.APP_NAME_GENERAL}</title>

        }
        {/* <title>Analytics | hireHQ</title> */}
        <meta
          name="description"
          content={`Analytics | ${constants.APP_NAME_GENERAL}`}
        />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Grid item
        lg={12}
        md={12}
        xs={12}
        className="d-flex bg-white userBg-space joblist-align responsive-tablet-view"
        style={{ marginTop: "-17px" }}
        sx={{ px: 3 }}
      >
        <Grid item className="w-100" sx={{ mt: 10 }}>
          <Grid item style={{ display: "flow-root" }}>
            <Typography className="heading-text-color f-20 font-weight-600 mt-20 mb-20" variant="h3" >
              {state?.title ? state?.title : localStorage.getItem("analyticsTitle")}
              {(state?.title === 'Job Summary Report' || localStorage.getItem("analyticsTitle") == 'Job Summary Report') &&
                <span>
                  <Tooltip
                    position="right"
                    title="Data shown here is for recent 30 Jobs"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                    <IconButton aria-label="Info icon">
                      <InfoIcon aria-label="Info icon" />
                    </IconButton>
                  </Tooltip>
                </span>
              }
            </Typography>
          </Grid>
          <div className="iframe-container-powerbi bg-white shadow-new" id='analytics'>
            <PowerBIEmbed
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                id: reportOptions.id,
                embedUrl: reportOptions.emberUrl,
                accessToken: reportOptions.token,
                tokenType: models.TokenType.Embed,
                height: "560px",
                filters: filters,
                settings: {
                  layoutType: models.LayoutType.Custom,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  navContentPaneEnabled: false,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    function () {
                    },
                  ],
                  [
                    "rendered",
                    function () {
                    },
                  ],
                  [
                    "error",
                    function (event) {
                    },
                  ],
                ])
              }
              cssClassName={"report-style-class"}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
